/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-spinner";
import * as i12 from "@angular/common";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./status-icon.component";
var styles_StatusIconComponent = [];
var RenderType_StatusIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StatusIconComponent, data: {} });
export { RenderType_StatusIconComponent as RenderType_StatusIconComponent };
function View_StatusIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["matTooltip", "Success"], ["role", "img"], ["style", "color: green"]], [[4, "font-size", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(-1, 0, ["check_circle\n"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = "Success"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size + "px"); var currVal_1 = i0.ɵnov(_v, 1).inline; var currVal_2 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StatusIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["matTooltip", "Error"], ["role", "img"], ["style", "color: red"]], [[4, "font-size", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(-1, 0, ["error"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = "Error"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = i0.ɵnov(_v, 1).inline; var currVal_2 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StatusIconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["matTooltip", "Queued"], ["role", "img"], ["style", "color: yellow"]], [[4, "font-size", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(-1, 0, ["access_time"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = "Queued"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = i0.ɵnov(_v, 1).inline; var currVal_2 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StatusIconComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-icon", [["class", "mat-icon notranslate"], ["matTooltip", "Cancelled"], ["role", "img"], ["style", "color: red"]], [[4, "font-size", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(-1, 0, ["cancel"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = "Cancelled"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = i0.ɵnov(_v, 1).inline; var currVal_2 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StatusIconComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["matTooltip", "Running"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i10.View_MatSpinner_0, i10.RenderType_MatSpinner)), i0.ɵdid(1, 114688, null, 0, i11.MatSpinner, [i0.ElementRef, i6.Platform, [2, i12.DOCUMENT], [2, i13.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.size; _ck(_v, 1, 0, currVal_3); var currVal_4 = "Running"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 1).diameter; var currVal_2 = i0.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_StatusIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_1)), i0.ɵdid(1, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_2)), i0.ɵdid(3, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_3)), i0.ɵdid(5, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_4)), i0.ɵdid(7, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_5)), i0.ɵdid(9, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.status === "success"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.status === "error"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.status === "queued"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.status === "cancelled"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.status === "running"); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_StatusIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-status-icon", [], null, null, null, View_StatusIconComponent_0, RenderType_StatusIconComponent)), i0.ɵdid(1, 49152, null, 0, i14.StatusIconComponent, [], null, null)], null, null); }
var StatusIconComponentNgFactory = i0.ɵccf("app-status-icon", i14.StatusIconComponent, View_StatusIconComponent_Host_0, { status: "status", size: "size" }, {}, []);
export { StatusIconComponentNgFactory as StatusIconComponentNgFactory };
