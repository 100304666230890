/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./behavior-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./behavior-panel.component";
import * as i7 from "@angular/forms";
var styles_BehaviorPanelComponent = [i0.styles];
var RenderType_BehaviorPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BehaviorPanelComponent, data: {} });
export { RenderType_BehaviorPanelComponent as RenderType_BehaviorPanelComponent };
export function View_BehaviorPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "selection-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "legendHeader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actions"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClearSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Clear Selection"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectByIds() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Select By ID"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).disabled || null); var currVal_1 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_BehaviorPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-behavior-panel", [], null, null, null, View_BehaviorPanelComponent_0, RenderType_BehaviorPanelComponent)), i1.ɵdid(1, 4243456, null, 0, i6.BehaviorPanelComponent, [i7.FormBuilder, i1.ChangeDetectorRef], null, null)], null, null); }
var BehaviorPanelComponentNgFactory = i1.ɵccf("app-workspace-behavior-panel", i6.BehaviorPanelComponent, View_BehaviorPanelComponent_Host_0, { config: "config", selectionToolConfig: "selectionToolConfig" }, { clearSelection: "clearSelection" }, []);
export { BehaviorPanelComponentNgFactory as BehaviorPanelComponentNgFactory };
