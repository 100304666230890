var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Khonsole } from 'app/khonsole';
import { DatasetDescription } from './../model/dataset-description.model';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { mergeMap, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as data from 'app/action/data.action';
import * as tip from 'app/action/tip.action';
import { EntityTypeEnum, VisualizationEnum } from 'app/model/enum.model';
import { GraphConfig } from 'app/model/graph-config.model';
import * as compute from './../action/compute.action';
import { DataTable, DataFieldFactory } from './../model/data-field.model';
import { OncoData } from 'app/oncoData';
// tslint:disable-next-line:max-line-length
import { DataLoadedAction, DataLoadFromDexieAction, DataUpdateCohortsAction, DataUpdateGenesetsAction, DataUpdatePreprocessingAction, DataUpdatePathwayAction, DataUpdateJobsAction } from './../action/data.action';
import { WorkspaceConfigAction } from './../action/graph.action';
import { LoaderShowAction, LoaderHideAction } from './../action/layout.action';
import { EdgeConfigModel } from './../component/visualization/edges/edges.model';
import { GenomeConfigModel } from './../component/visualization/genome/genome.model';
import { SurvivalConfigModel } from './../component/visualization/survival/survival.model';
import { WorkspaceLayoutEnum } from './../model/enum.model';
import { WorkspaceConfigModel } from './../model/workspace.model';
import { DataService } from './../service/data.service';
import { DatasetService } from './../service/dataset.service';
import { PcaConfigModel } from '../component/visualization/pca/pca.model';
import { TimelinesConfigModel } from 'app/component/visualization/timelines/timelines.model';
import { MdsConfigModel } from 'app/component/visualization/mds/mds.model';
import { SavedPointsConfigModel } from 'app/component/visualization/savedpoints/savedpoints.model';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
export class DataEffect {
    constructor(actions$, dataService, datasetService) {
        this.actions$ = actions$;
        this.dataService = dataService;
        this.datasetService = datasetService;
        // Pathway Crud
        this.addPathway = this.actions$.pipe(ofType(data.DATA_ADD_PATHWAY)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .createCustomPathway(args.payload.database, args.payload.pathway)
                .then(() => this.dataService.getCustomPathways(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdatePathwayAction(args));
        }));
        this.loadVisualizationTip = this.actions$.pipe(ofType(tip.TIP_SET_VISUALIZATION)).pipe(switchMap((args) => {
            return observableFrom(this.dataService.getVisualizationTip(args.payload));
        }), switchMap((args) => {
            return observableOf(new tip.TipSetVisualizationCompleteAction(args));
        }));
        this.delPathway = this.actions$.pipe(ofType(data.DATA_DEL_PATHWAY)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .deleteCustomPathway(args.payload.database, args.payload.pathway)
                .then(() => this.dataService.getCustomPathways(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdatePathwayAction(args));
        }));
        // Cohort Crud
        this.addCohort = this.actions$.pipe(ofType(data.DATA_ADD_COHORT)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .createCustomCohort(args.payload.database, args.payload.cohort)
                .then(() => this.dataService.getCustomCohorts(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateCohortsAction(args));
        }));
        this.delCohort = this.actions$.pipe(ofType(data.DATA_DEL_COHORT)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .deleteCustomCohort(args.payload.database, args.payload.cohort)
                .then(() => this.dataService.getCustomCohorts(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateCohortsAction(args));
        }));
        // Job Crud
        this.addJob = this.actions$.pipe(ofType(data.DATA_ADD_JOB)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .createJob(args.payload.database, args.payload.job)
                .then(() => this.dataService.getJobs(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateJobsAction(args));
        }));
        this.delJob = this.actions$.pipe(ofType(data.DATA_DEL_JOB)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .deleteJob(args.payload.database, args.payload.job)
                .then(() => this.dataService.getJobs(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateJobsAction(args));
        }));
        // Geneset Crud
        this.addGeneset = this.actions$.pipe(ofType(data.DATA_ADD_GENESET)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .createCustomGeneset(args.payload.database, args.payload.geneset)
                .then(() => this.dataService.getCustomGenesets(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateGenesetsAction(args));
        }));
        this.updateGeneset = this.actions$.pipe(ofType(data.DATA_UPDATE_GENESET)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .updateCustomGeneset(args.payload.database, args.payload.geneset)
                .then(() => this.dataService.getCustomGenesets(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateGenesetsAction(args));
        }));
        this.delGeneset = this.actions$.pipe(ofType(data.DATA_DEL_GENESET)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .deleteCustomGeneset(args.payload.database, args.payload.geneset)
                .then(() => this.dataService.getCustomGenesets(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdateGenesetsAction(args));
        }));
        // Geneset Crud
        this.addPreprocessing = this.actions$.pipe(ofType(data.DATA_ADD_PREPROCESSING)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .createCustomPreprocessing(args.payload.database, args.payload.preprocessing)
                .then(() => this.dataService.getCustomPreprocessing(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdatePreprocessingAction(args));
        }));
        this.delPreprocessing = this.actions$.pipe(ofType(data.DATA_DEL_PREPROCESSING)).pipe(switchMap((args) => {
            return observableFrom(this.dataService
                .deleteCustomPreprocessing(args.payload.database, args.payload.preprocessing)
                .then(() => this.dataService.getCustomPreprocessing(args.payload.database)));
        }), switchMap((args) => {
            return observableOf(new DataUpdatePreprocessingAction(args));
        }));
        // Load Data From Public
        this.dataLoadFromPublic$ = this.actions$.pipe(ofType(data.DATA_LOAD_FROM_PUBLIC)).pipe(map((action) => action.payload), switchMap(args => {
            window["oncoscapeTempForcedFig"] = args.fig;
            let essentialBase = 'https://oncoscape.v3.sttrcancer.org/data/';
            if (args['src'] === 'tcga') {
                args['baseUrl'] = essentialBase + args['src'] + '/';
                args['manifest'] = essentialBase + args['src'] + '/' + args['prefix'] + 'manifest.json.gz';
            }
            else { // assume src= "public"
                args['baseUrl'] = essentialBase + args['src'] + '/' + args['uid'] + '/';
                args['manifest'] = essentialBase + args['src'] + '/' + args['uid'] + '/manifest.json.gz';
                args['token'] = '';
            }
            Khonsole.log('dataLoadFromPublic args...');
            Khonsole.dir(args);
            return this.datasetService.load(args, false, null, Boolean(args["goToSplashOnError"]));
        }), mergeMap(args => {
            return [
                // new FilePanelToggleAction(),
                new DataLoadFromDexieAction(args.uid, args.name, window["oncoscapeTempForcedFig"]) //args.fig)
            ];
        }));
        /*
        oncoscape-privatedata-${env}/converted/
        bucket is `${user}/${itemId}`
        */
        // Load Data From Private S3
        this.dataLoadFromPrivate$ = this.actions$.pipe(ofType(data.DATA_LOAD_FROM_PRIVATE)).pipe(map((action) => action.payload), switchMap(args => {
            let env = args['env'];
            Khonsole.log(`MJ CHECK! env is ${env}`);
            Khonsole.log(`MJ dataLoadFromPrivate, args = ${JSON.stringify(args)}`);
            let user = args['bucket'].split('/')[0]; // actual owner of item
            args["owner"] = user;
            let userEncoded = encodeURIComponent(user);
            let itemId = args['bucket'].split('/')[1];
            args['token'] = self['zagerToken']; // MJ window
            args['uid'] = itemId;
            // args['baseUrl'] = 'https://oncoscape.v3.sttrcancer.org/datasets/' + args['bucket'] + '/';
            // args['manifest'] = 'https://oncoscape.v3.sttrcancer.org/datasets/' + args['bucket'] + '/manifest.json.gz';
            let me = null; // Don't need to say who I am if I own the dataset, only if I am a grantee.
            if (user != this.getMyUserId()) {
                // Owned by someone else. Treat ourself as a grantee.
                me = this.getMyUserId();
                args['grantee'] = me;
            }
            args['baseUrl'] = `https://oncoscape-privatedata-${env}.s3-us-west-2.amazonaws.com/converted/${userEncoded}/${itemId}/final/`;
            args['manifest'] = `https://oncoscape-privatedata-${env}.s3-us-west-2.amazonaws.com/converted/${userEncoded}/${itemId}/final/manifest.json.gz`;
            // Khonsole.log('MJ before call load');
            return this.datasetService.load(args, true, me);
        }), mergeMap(args => {
            // Khonsole.log('MJ before guts of mergeMap');
            return [
                // new FilePanelToggleAction(),
                new DataLoadFromDexieAction(args.uid, args.name)
            ];
        }));
        // Load dataset from Dexie. Then figure out the main visualization to show.
        this.dataLoadFromDexie$ = this.actions$.pipe(ofType(data.DATA_LOAD_FROM_DEXIE)).pipe(switchMap((args) => {
            Khonsole.log(`data.effect.dataLoadFromDexie$, #1 args.dataset = [${args.dataset}]`);
            console.log('ALL argsfrom this.dataLoadFromDexie$ ... ');
            console.dir(args);
            GraphConfig.database = args.dataset;
            GraphConfig.datasetName = args.datasetname;
            if ((args.datasetname == null) && args.dataset.includes('.zip_20')) {
                GraphConfig.datasetName = args.dataset.split('.zip_20')[0];
            }
            return observableFrom(Promise.all([
                this.datasetService.getDataset(args.dataset),
                this.dataService.getCustomGenesets(args.dataset),
                this.dataService.getCustomCohorts(args.dataset),
                this.dataService.getCustomPathways(args.dataset),
                this.dataService.getCustomPreprocessing(args.dataset),
                this.dataService.getRowCount(args.dataset, 'mut'),
                this.dataService.getMiscMeta(args.dataset, 'mutationTypes'),
                new Promise((resolve, reject) => {
                    resolve(args.datasetname); //7
                }),
                this.dataService.getCustomVisSettings(args.dataset),
                this.dataService.getSavedPointsWrappers(args.dataset),
                new Promise((resolve, reject) => {
                    resolve(args.fig); //10
                }),
            ]));
        }), 
        // switchMap(args => {
        //   Khonsole.log('** if process updates, do it here in pipe of data effect.');
        //   return observableOf(args);
        // }),
        switchMap(args => {
            console.log(`data.effect.dataLoadFromDexie$, #2 args are...`);
            Khonsole.dir(args);
            if (args[1] === undefined) {
                args[1] = [];
            }
            if (args[2] === undefined) {
                args[2] = [];
            }
            Khonsole.log('dataLoadFromDexie, check fields.');
            // Temp Fix While Converting To New Data Model
            const fields = args[0].hasOwnProperty('fields') ? args[0].fields : args[0].patients.concat(args[0].samples);
            Khonsole.log(`MJ - Please note population of DatasetDescription`);
            Khonsole.log(`MJ - At populating DSD, args[0] (Study details?) is...`);
            Khonsole.dir(args[0]);
            const dsd = new DatasetDescription();
            dsd.hasPrecomputed = false; // Default, but make it clear.
            dsd.hasEvents = args[0].events.length > 0;
            Khonsole.log(`MJ - dsd events length =  ${args[0].events.length}.`);
            dsd.hasPatientFields = args[0].fields.filter(v => v.tbl === 'patient').length > 0;
            dsd.hasSampleFields = args[0].fields.filter(v => v.tbl === 'sample').length > 0;
            dsd.hasMatrixFields = args[0].tables.filter(v => v.ctype & 296832 /* MOLECULAR_FOR_SCATTER */).length > 0;
            dsd.hasHeatmappableFields = args[0].tables.filter(v => v.ctype & 3966904 /* HEATMAPPABLE */).length > 0;
            dsd.hasRNACountsTable = args[0].tables.filter((v) => v.tbl === 'rnacounts').length > 0;
            dsd.hasMutations = args[5] > 1;
            dsd.hasCopyNumber = args[0].tables.filter(v => (v.ctype & 16 /* GISTIC */)
                || (v.ctype & 32 /* GISTIC_THRESHOLD */)
                || (v.ctype & 8 /* CNV */)
                || (v.tbl.toLowerCase().endsWith('nn_data_cna')) // Hardcoded hack for NN glioma. -MJ TBD
            ).length > 0;
            if (args[6]) { // we are setting a mutationVariantNames overrride.
                dsd.mutationVariantNames = Object.keys(args[6]['data']).map(key => args[6]['data'][key]);
                DataFieldFactory.setMutationFields(dsd.mutationVariantNames);
            }
            let hasTcgaSurvival = args[0].fields.filter(v => {
                return v.key === 'days_to_death' || v.key === 'vital_status' || v.key === 'days_to_last_follow_up';
            }).length === 3;
            // try "_followup" [sic]
            if (hasTcgaSurvival == false) {
                hasTcgaSurvival =
                    args[0].fields.filter(v => {
                        return v.key === 'days_to_death' || v.key === 'vital_status' || v.key === 'days_to_last_followup';
                    }).length === 3;
            }
            console.warn(`MJ - DataEffect CHECK ---- hasCbioportalSurvival...`);
            const hasCbioportalSurvival = args[0].fields.some(v => (v.key === 'os_months' || v.key === 'os_years' || v.key === 'os_status' || v.key === 'os_event'));
            dsd.hasSurvival = hasTcgaSurvival || hasCbioportalSurvival;
            if (args[6] === undefined) {
                args[6] = '';
            }
            if (OncoData.instance.dataLoadedAction) {
                // If we came into Oncoscape with a "#project" link, we only want to keep the project name
                // Until a second dataset has been loaded.
                WorkspaceComponent.instance.datasetName = null;
            }
            return observableOf(new DataLoadedAction(args[0].name, args[0].tables, fields, args[0].events, args[1], args[2], args[3], args[4], args[6].toString(), dsd, args[8], args[9], args[0], // DatasetTableInfo,
            args["fig"] // fig
            ));
        }));
        this.dataLoaded$ = this.actions$.pipe(ofType(data.DATA_LOADED)).pipe(mergeMap((args) => {
            // Count mutations if we haven't already.
            let mutationTable = args.tables.find(t => t.ctype == 64 /* MUTATION */);
            // Khonsole.log('MJ past get mutationTable.');
            if (mutationTable &&
                args.fields.find(f => f.key == "oncoscape_mutation_count") == null) {
                // Khonsole.log('MJ =====> need to create field for mutationcount');
                // 1. Create our own field, in patientMeta.
                // 2. Add column in patient table.
                // 3. Populate column in patient table.
                // 4. Update fields in "args" here.
                // All TBD.  TODO
                return this.setupConfigsFromArgs(args);
            }
            else {
                return this.setupConfigsFromArgs(args);
            }
        }));
    }
    getMyUserId() {
        let email = window['storedAuthBits'].email;
        return email;
    }
    findNavItemByShortId(navItems, targetShortId) {
        for (let item of navItems) {
            if (item.shortId === targetShortId) {
                return item;
            }
            if (item.children) {
                const foundChild = this.findNavItemByShortId(item.children, targetShortId);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
        return null;
    }
    waitForOncoscapeConfigureLegendPanelRefs(remainingCalls, ni) {
        let self = this;
        if (window["oncoscapeConfigureLegendPanelRefs"]) {
            let clp = window["oncoscapeConfigureLegendPanelRefs"][0];
            clp.colorbyForm.setAndEmitColorbyId(ni.action);
            return;
        }
        else if (remainingCalls <= 0) {
            // Maximum recursive calls reached. Stopping.
            return;
        }
        else {
            setTimeout(() => self.waitForOncoscapeConfigureLegendPanelRefs(remainingCalls - 1, ni), 100);
        }
    }
    setupConfigsFromArgs(args) {
        let workspaceConfig = new WorkspaceConfigModel();
        workspaceConfig.layout = WorkspaceLayoutEnum.SINGLE;
        const edgeConfig = new EdgeConfigModel();
        // Khonsole.log('MJ edge is ' + JSON.stringify(EntityTypeEnum.EDGE));
        const survivalConfig = new SurvivalConfigModel();
        survivalConfig.graph = 1 /* GRAPH_A */;
        survivalConfig.table = args.tables.filter(v => ((v.ctype & 4 /* PATIENT */) > 0))[0]; // was MOLECULAR, but that was probably copy and paste error. MJ
        // const pathwaysConfig = new PathwaysConfigModel();
        // pathwaysConfig.graph = GraphEnum.GRAPH_A;
        // pathwaysConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        // const chromosomeConfig = new ChromosomeConfigModel();
        // chromosomeConfig.graph = GraphEnum.GRAPH_A;
        // chromosomeConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        // const boxWhiskersConfig = new BoxWhiskersConfigModel();
        // boxWhiskersConfig.graph = GraphEnum.GRAPH_B;
        // boxWhiskersConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[0];
        // const timelinesConfigA = new TimelinesConfigModel();
        // timelinesConfigA.graph = GraphEnum.GRAPH_A;
        // timelinesConfigA.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[0];
        const timelinesConfig = new TimelinesConfigModel();
        timelinesConfig.graph = 1 /* GRAPH_A */;
        timelinesConfig.table = args.tables.filter(v => (v.ctype & 16384 /* EVENT */) > 0)[0]; // was MOLECULAR, but that was probably copy and paste error. MJ
        // const graphBConfig = new PcaIncrementalConfigModel();
        // graphBConfig.graph = GraphEnum.GRAPH_A;
        // graphBConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        // const hicConfig = new HicConfigModel();
        // hicConfig.graph = GraphEnum.GRAPH_B;
        // hicConfig.table = args.tables.filter( v => ( (v.ctype & CollectionTypeEnum.MOLECULAR) > 0) )[1];
        // const graphAConfig = new LinkedGeneConfigModel();
        // graphAConfig.graph = GraphEnum.GRAPH_A;
        // graphAConfig.table = args.tables.filter( v => ( (v.ctype & CollectionTypeEnum.MOLECULAR) > 0) )[1];
        // const pcaIncConfig2 = new PcaIncrementalConfigModel();
        // pcaIncConfig2.graph = GraphEnum.GRAPH_B;
        // pcaIncConfig2.table = args.tables.filter(
        //   v => (v.ctype & CollectionTypeEnum.MOLECULAR) > 0
        // )[1];
        // const pcaIncConfig = new PcaIncrementalConfigModel();
        // pcaIncConfig.graph = GraphEnum.GRAPH_A;
        // pcaIncConfig.table = args.tables.filter(v => (v.ctype & CollectionTypeEnum.MOLECULAR) > 0)[0];
        // const graphBConfig = new PcaIncrementalConfigModel();
        // graphBConfig.graph = GraphEnum.GRAPH_A;
        // graphBConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        const pcaConfig = new PcaConfigModel();
        pcaConfig.graph = 1 /* GRAPH_A */;
        pcaConfig.datasetName = args.datasetName;
        pcaConfig.table = args.tables.filter(v => (v.ctype & 296888 /* MOLECULAR */) > 0)[0];
        const savedPointsConfig = new SavedPointsConfigModel();
        savedPointsConfig.graph = 1 /* GRAPH_A */;
        savedPointsConfig.datasetName = args.datasetName;
        // MJ table is null
        const genomeConfigB = new GenomeConfigModel();
        genomeConfigB.graph = 2 /* GRAPH_B */;
        genomeConfigB.datasetName = args.datasetName;
        genomeConfigB.entity = EntityTypeEnum.GENE;
        genomeConfigB.table = args.tables.filter(v => (v.ctype & 296888 /* MOLECULAR */) > 0)[0];
        // scatterConfig.datasetName = args.datasetName;
        // const histogramConfig = new HistogramConfigModel();
        // histogramConfig.graph = GraphEnum.GRAPH_A;
        // histogramConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        // const heatmapConfig = new HeatmapConfigModel();
        // heatmapConfig.graph = GraphEnum.GRAPH_B;
        // heatmapConfig.table = args.tables.filter(v => ((v.ctype & CollectionTypeEnum.MOLECULAR) > 0))[1];
        // const pathwaysConfig = new PathwaysConfigModel();
        // pathwaysConfig.graph = GraphEnum.GRAPH_A;
        // pathwaysConfig.table = args.tables.filter(v => (v.ctype & CollectionTypeEnum.MOLECULAR) > 0)[1];
        // const chromoConfig = new ChromosomeConfigModel();
        // chromoConfig.graph = GraphEnum.GRAPH_A;
        // chromoConfig.table = args.tables.filter(v => (v.ctype & CollectionTypeEnum.MOLECULAR) > 0)[1];
        // const proteinConfig = new ProteinConfigModel();
        // proteinConfig.graph = GraphEnum.GRAPH_A;
        // proteinConfig.table = args.tables.filter(v => (v.ctype & CollectionTypeEnum.MOLECULAR) > 0)[1];
        // This is the default visualization we show.
        // Try PCA, Survival, Timeline, then SavedPoints.
        let visActionA = new LoaderHideAction(); // compute.NullDataAction();
        if (args.dataset == 'tcga_brain') {
            const mdsConfig = new MdsConfigModel();
            mdsConfig.graph = 1 /* GRAPH_A */;
            mdsConfig.datasetName = args.datasetName;
            mdsConfig.table = args.tables.filter(v => (v.ctype & 296888 /* MOLECULAR */) > 0)[0];
            visActionA = new compute.MdsAction({ config: mdsConfig });
        }
        else {
            let defaultConfig = null;
            let visType;
            if (args.datasetDesc.hasMatrixFields) {
                visType = VisualizationEnum.PCA;
                defaultConfig = pcaConfig;
                // visActionA = new compute.PcaAction({ config: pcaConfig })
            }
            else {
                if (args.datasetDesc.hasEvents) {
                    visType = VisualizationEnum.TIMELINES;
                    defaultConfig = timelinesConfig;
                    //visActionA = new compute.TimelinesAction({ config: timelinesConfig });
                }
                else {
                    if (args.datasetDesc.hasSurvival) {
                        visType = VisualizationEnum.SURVIVAL;
                        defaultConfig = survivalConfig;
                        //visActionA = new compute.SurvivalAction({ config: survivalConfig });
                    }
                    else {
                        const savedPointsConfig = new SavedPointsConfigModel();
                        savedPointsConfig.graph = 1 /* GRAPH_A */;
                        savedPointsConfig.datasetName = args.datasetName;
                        visType = VisualizationEnum.SAVED_POINTS;
                        defaultConfig = savedPointsConfig;
                        //visActionA = new compute.SavedPointsAction({ config: savedPointsConfig })
                        alert('Defaulting to Saved Points visualization, as nothing else matched.');
                        // // What other option is generic? Pass along the NullDataAction
                        // document.querySelector('.loader')['style']['visibility'] = 'hidden';
                        // alert('No visualization was found to match this data. Please contact Oncoscape support for help with checking your data.');
                    }
                }
            }
            // if defaultViz in table, defaultConfig = it
            if (args.datasetTableInfo && args.datasetTableInfo.defaultVizConfig) {
                console.log("-- start defaultVizConfig ---");
                defaultConfig = args.datasetTableInfo.defaultVizConfig;
                // args.fig not flowing all the way.
                let fig = window["oncoscapeTempForcedFig"];
                if (fig) {
                    args.fig = null;
                    window["oncoscapeTempForcedFig"] = null;
                    if (OncoData.instance.dataLoadedAction && OncoData.instance.dataLoadedAction.dataset) {
                        let savedNavItems = OncoData.instance.dataLoadedAction.datasetTableInfo.navItems;
                        if (savedNavItems && savedNavItems.length > 0) {
                            let ni = this.findNavItemByShortId(savedNavItems, fig);
                            if (ni) {
                                console.log("Found ni. action=" + ni.action);
                                this.waitForOncoscapeConfigureLegendPanelRefs(20, ni);
                                // setTimeout(function () {
                                //   // ASSUME view 0. Ignore: let viewId = config.graph == e.GraphEnum.GRAPH_A ? 0 : 1;
                                //   // ===== time to emit colordec from graphPanelviz
                                //   if (window["oncoscapeConfigureLegendPanelRefs"]){
                                //   let gpv: ConfigureLegendPanelComponent = window["oncoscapeConfigureLegendPanelRefs"][0];
                                //   gpv.colorbyForm.setAndEmitColorbyId(ni.action);
                                //   } else {
                                //     console.log("ERROR: window[oncoscapeConfigureLegendPanelRefs] not ready.");
                                //   }
                                // }, 1000);
                            }
                        }
                    }
                }
                visType = args.datasetTableInfo.defaultVizConfig.visualization;
                console.log("-- end defaultVizConfig ---");
            }
            // tranform defaultConfig to use visSettings
            let graphConfigValue = defaultConfig;
            let v = OncoData.instance.visSettings.find(v => v.visEnum == graphConfigValue.visualization);
            if (graphConfigValue.hasAppliedMetafileOverrides == false) {
                if (v) {
                    let settings = JSON.parse(v.settings);
                    if (settings) {
                        Khonsole.log(`TEMPNOTE: Found settings for visEnum=${graphConfigValue.visualization}...`);
                        Khonsole.log(`TEMPNOTE: settings = ${v.settings}!`);
                        let overrideKeys = Object.keys(settings);
                        overrideKeys.map(ok => {
                            Khonsole.log(`TEMPNOTE: override "${ok}" with "${JSON.stringify(settings[ok])}"!`);
                            graphConfigValue[ok] = settings[ok];
                        });
                        // Khonsole.log('MJ settings overridden.');
                        graphConfigValue.hasAppliedMetafileOverrides = true;
                        Khonsole.log(`TEMPNOTE: New config = ${JSON.stringify(graphConfigValue)}!`);
                    }
                }
                // Khonsole.log('MJ Now continue on with dispatching the vis.');
            }
            switch (visType) {
                case VisualizationEnum.PCA:
                    visActionA = new compute.PcaAction({ config: defaultConfig });
                    break;
                case VisualizationEnum.SURVIVAL:
                    visActionA = new compute.SurvivalAction({ config: defaultConfig });
                    break;
                case VisualizationEnum.TIMELINES:
                    visActionA = new compute.TimelinesAction({ config: defaultConfig });
                    break;
                case VisualizationEnum.SAVED_POINTS:
                    visActionA = new compute.SavedPointsAction({ config: defaultConfig });
                    break;
                default:
            }
        }
        return [
            new DataUpdateCohortsAction(args.cohorts),
            new DataUpdateGenesetsAction(args.genesets),
            new DataUpdatePreprocessingAction(args.preprocessings),
            new WorkspaceConfigAction(workspaceConfig),
            // new GraphPanelToggleAction( GraphPanelEnum.GRAPH_A )
            visActionA,
            new compute.GenomeAction({ config: genomeConfigB }),
            new LoaderShowAction()
        ];
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "addPathway", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "loadVisualizationTip", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "delPathway", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "addCohort", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "delCohort", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "addJob", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "delJob", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "addGeneset", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "updateGeneset", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "delGeneset", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "addPreprocessing", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "delPreprocessing", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "dataLoadFromPublic$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "dataLoadFromPrivate$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "dataLoadFromDexie$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Observable)
], DataEffect.prototype, "dataLoaded$", void 0);
