import { OnInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { WidgetModel } from "app/component/workspace/common-side-panel/widgetmodel";
import { Observable } from "rxjs";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export type ScatterThumbnailPoint = {
	position: number[];
	color?: THREE.Color;
	label?: string;
  /**
   * Used for updating colors
   */
  cohort: 'A' | 'B' | null;
};

type ScatterThumbnailConfig = {
	opacity: number;
	noColorOpacity: number;
	baseSize: number;
	defaultColor: THREE.Color;
	/**
	 * Whether the thumbnail is draggable. Defaults to true.
	 */
	draggable?: boolean;
};

@Component({
  selector: "app-volcano-scatter-thumbnail",
  templateUrl: "./volcano-scatter-thumbnail.component.html",
  styleUrls: ["./volcano-scatter-thumbnail.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VolcanoScatterThumbnailComponent implements OnInit {

  static DEFAULT_CONFIG: ScatterThumbnailConfig =  {
      opacity: 0.8,
      noColorOpacity: 0.3,
      baseSize: 0.02,
      defaultColor: new THREE.Color("gray"),
      draggable: true,
    }

  model: WidgetModel<ScatterThumbnailConfig> = new WidgetModel<ScatterThumbnailConfig>();
  showSettings: boolean = false;

  /**
	 * Data can either be 2D or 3D, but all points must be the same dimensionality. If the data is 3D but all z values are the same, it will be treated as 2D.
	 */
  @Input() data: Observable<ScatterThumbnailPoint[]>;
  /**
   * Used for initial positioning of the thumbnail on the page
   */
  @Input() volcanoSvgId: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() config?: Partial<ScatterThumbnailConfig>;
  @Input() set visible(visible: boolean) {
    const container = document.getElementById(this._containerId);
    if (!container) throw new Error("ScatterThumbnail: Container not found");
    container.style.display = visible ? "block" : "none";


  }
  @Output() close = new EventEmitter<void>();


	private _containerId: string = "scatter-thumbnail-container";
  private _config: ScatterThumbnailConfig = VolcanoScatterThumbnailComponent.DEFAULT_CONFIG;
	private _data: ScatterThumbnailPoint[] = [];
	private _width: number | null = null;
	private _height: number | null = null;
	private _dimensionality: 2 | 3 = 3;
	private _camera: THREE.PerspectiveCamera | null;
	private _scene: THREE.Scene | null;
	private _renderer: THREE.WebGLRenderer | null;
	private _controls: OrbitControls | null;
	private _center: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
	private _points: THREE.Points | null;
  private _lastThumbnailPosition: { right: number; top: number; scrollX: number; scrollY: number} | null = null;
  /**
   * Kill the animation cycle when we re-init
   */
  private _kill: boolean = false;

  constructor() {}

  ngOnInit(): void {

    this.model.settings = [
      {
        name: "Cohorts Opacity",
        onChange: (value: number) => {
          this.onSettingChange("opacity", value);
        },
        fieldType: "range",
        options: {
          min: 0.2,
          max: 1,
          step: 0.1,
          showThumbLabel: true,
        },
        value: this._config.opacity,
        key: "opacity",
      },
      {
        name: `"Rest of Data" Opacity`,
        onChange: (value: number) => {
          this.onSettingChange("noColorOpacity", value);
        },
        fieldType: "range",
        options: {
          min: 0.2,
          max: 1,
          step: 0.1,
          showThumbLabel: true,
        },
        value: this._config.opacity,
        key: "opacity",
      },
      {
        name: "Base Size",
        onChange: (value: number) => {
          this.onSettingChange("baseSize", value / 100);
        },
        fieldType: "range",
        options: {
          min: 1,
          max: 10,
          step: 1,
          showThumbLabel: true,
        },
        value: this._config.baseSize * 100,
        key: "baseSize",
      },
      {
        name: `"Rest of Data" Color`,
        onChange: (value: string) => {
          this.onSettingChange("defaultColor", new THREE.Color(value));
        },
        fieldType: "color",
        options: {
          placeholder: "Color",
        },
        value: '#' + this._config.defaultColor.getHexString(),
        key: "defaultColor",
      },
    ]
  }

  ngAfterViewInit() {
    this.data.subscribe((data) => {
      this.init(data);
    });
  }

  render() {
		if (!this._camera) throw new Error("Camera not initialized");
		if (!this._renderer) throw new Error("Renderer not initialized");
		if (!this._controls) throw new Error("Controls not initialized");
		if (!this._scene) throw new Error("Scene not initialized");
		const animate = () => {
      if (this._kill) return;
			requestAnimationFrame(animate);
			this._controls!.update();
			this._renderer!.render(this._scene!, this._camera!);
		};
		animate();
	}

  toggleSettings() {
    this.showSettings = !this.showSettings;

  }

  private init(data: ScatterThumbnailPoint[], dimensionality: 2 | 3 =undefined) {
    this._kill = true;
    const container = document.getElementById(this._containerId);
		if (!container) throw new Error("ScatterThumbnail: Container not found");
    const plotDiv = document.getElementById("scatter-thumbnail-plot");
    if (!plotDiv) throw new Error("ScatterThumbnail: Plot div not found");
    // clear the plot div
    plotDiv.innerHTML = "";

    // if width and height have not been defined yet, set them to the provided width and height
    if (!this._width && !this._height) {
      //  -2 so the border shows up
      this._width = this.width - 2
		  this._height = this.height - 2
    }



    this.initThumbnailPosition();
    this.initHeader();
    this.initConfig(this.config);
    this.initData(data, dimensionality);
    this.initScene();
    this.initPoints();
    this.initCamera();
    this.initRenderer();
    this.initControls();
    this.initEventListeners(container);
    this._kill = false;
		this.render();
  }

  private onSettingChange(key: string, value: any) {
    this._config[key] = value;
    // re-init the thumbnail with the new settings. Since the data is the same, we can just re-use it.
    // We may have adjusted the z-coordinates to 0.01 if we were casting 3D data to 2D. As a result, init would see this as 3D data since all the z values are not the same.
    // To avoid this, we pass in the dimensionality to init, which tells init it is 2D/3D data.
    this.init(this._data, this._dimensionality);
  }

  private initEventListeners(container: HTMLElement) {
		// listen for the user grabbing the bottom right corver of the container to resize
		const resizer = document.getElementById(
			"scatter-thumbnail-resizer"
		) as HTMLElement;
		const header = document.getElementById(
			"scatter-thumbnail-header"
		) as HTMLElement;

    // dont let the thumbnail get smaller than its original size
		const minWidth =  this._width
		const minHeight = this._height

		let startX = 0;
		let startY = 0;
		let startWidth = 0;
		let startHeight = 0;

		function resize(e: MouseEvent) {
			const width = startWidth + e.clientX - startX;
			const height = startHeight + e.clientY - startY;
			if (width > minWidth) {
				container.style.width = width + "px";
				const headerPadding = 0;
				header.style.width = width - headerPadding + "px";
			}
			if (height > minHeight) {
				container.style.height = height + "px";
			}
			// Trigger a resize event
			const resizeEvent = new Event("resize");
			container.dispatchEvent(resizeEvent);
		}

		function stopResize() {
			document.removeEventListener("mousemove", resize);
			document.removeEventListener("mouseup", stopResize);
		}

		resizer.addEventListener("mousedown", (e) => {
			e.preventDefault();
			startX = e.clientX;
			startY = e.clientY;
			startWidth = container.offsetWidth;
			startHeight = container.offsetHeight;
			document.addEventListener("mousemove", resize);
			document.addEventListener("mouseup", stopResize);
		});

		container.addEventListener("resize", () => {
			if (!this._renderer) throw new Error("Renderer not initialized");
			if (!this._camera) throw new Error("Camera not initialized");
			if (!this._controls) throw new Error("Controls not initialized");
			this._width = container.clientWidth;
			this._height = container.clientHeight;
			this._renderer.setSize(this._width, this._height);
			this._camera.aspect = this._width / this._height;
			this._camera.updateProjectionMatrix();
			this._controls.update();
		});
	}

	private initConfig(inputConfig: Partial<ScatterThumbnailConfig> | undefined) {
		if (!inputConfig) return;
		this._config = {
			...this._config,
			...inputConfig,
		};
	}

	/**
	 * This function sets this._data, but first validates the data, determines the dimensionality, and makes necessary adjustments based on the dimensionality.
   * @param inputData
   * @param dimensionality If the dimensionality of the data is already known (perhaps from a prior render of the thumbnail), it can be passed in here to avoid recalculating it.
	 */
	private initData(inputData: ScatterThumbnailPoint[], dimensionality: 2 | 3 = undefined) {
		if (inputData.length === 0) return;

		// check validity of input data dimensions
		const firstPointDim = inputData[0].position.length;
		if (inputData.some((d) => d.position.length !== firstPointDim)) {
			throw new Error(
				"ScatterThumbnail: All points must have the same dimensionality"
			);
		}
		if (firstPointDim !== 2 && firstPointDim !== 3) {
			throw new Error("ScatterThumbnail: Only 2D and 3D data is supported");
		}

		// determine if the data is 2D or 3D
    if (dimensionality) {
      this._dimensionality = dimensionality;
    } else {
		  this._dimensionality = this.determineDimensionality(inputData);
    }

		this._data = this.adjustForDimensionality(inputData);
		this._center = this.calculateCenter();
	}

	private determineDimensionality(inputData: ScatterThumbnailPoint[]): 2 | 3 {
		if (inputData[0].position.length === 2) {
			return 2;
		} else {
			// if all z values are the same, we can treat this as a 2D plot
			const zValues = inputData.map((d) => d.position[2]);
			if (zValues.every((z) => z === zValues[0])) {
				return 2;
			}
		}
		return 3;
	}

	private initHeader() {
		const header = document.getElementById("scatter-thumbnail-header")!;
		const headerX = document.getElementById("scatter-thumbnail-header-x")!;
		const container = document.getElementById(this._containerId) as HTMLElement;

		let pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;

		headerX.addEventListener("click", () => {
			container.style.display = "none";
      this.close.emit();
		});

		header.addEventListener("mouseover", () => {
			header.style.cursor = "grab";
		});

		header.addEventListener("mousedown", (e) => {
			header.style.cursor = "grabbing";
			e.preventDefault();
			// get the mouse cursor position at startup:
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			// call a function whenever the cursor moves:
			document.onmousemove = elementDrag;
		});

		header.addEventListener("mouseup", () => {
			header.style.cursor = "grab";
		});

		function elementDrag(e: any) {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// set the element's new position:
			container.style.top = container.offsetTop - pos2 + "px";
			container.style.left = container.offsetLeft - pos1 + "px";
		}

		function closeDragElement() {
			// stop moving when mouse button is released:
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	/**
	 * Adjust for dimensionality, returning the adjusted data
	 */
	private adjustForDimensionality(
		inputData: ScatterThumbnailPoint[]
	): ScatterThumbnailPoint[] {
		// make adjustments based on the dimensionality of the data
		if (this._dimensionality === 2) {
			document.getElementById("scatter-thumbnail-header-text")!.innerText =
				"DEA Inputs (2D)";
			return inputData.map((d) => {
				const z = d.color ? 0 : -0.01;
				return {
          ...d,
					position: [d.position[0], d.position[1], z]
				};
			});
		}

		const container = document.getElementById(this._containerId) as HTMLElement;

		document.getElementById("scatter-thumbnail-header-text")!.innerText =
			"DEA Inputs (3D)";
		container.addEventListener("mouseover", () => {
			container.style.cursor = "grab";
		});
		container.addEventListener("mousedown", () => {
			container.style.cursor = "grabbing";
		});
		container.addEventListener("mouseup", () => {
			container.style.cursor = "grab";
		});

		return inputData;
	}

	private calculateCenter(): THREE.Vector3 {
		const center = new THREE.Vector3();
		for (const point of this._data) {
			center.add(new THREE.Vector3().fromArray(point.position));
		}
		center.divideScalar(this._data.length);
		return center;
	}

	private initScene() {

		this._scene = new THREE.Scene();
	}

	private initPoints() {
		if (!this._scene) throw new Error("Scene not initialized");
		const positions = this._data.map((d) => d.position);
		const colors = this._data.map((d) => d.color || this._config.defaultColor);
		const opacitys = this._data.map((d) =>
			d.color ? this._config.opacity : this._config.noColorOpacity
		);

		const pointsGeometry = new THREE.BufferGeometry();

		pointsGeometry.setAttribute(
			"position",
			new THREE.Float32BufferAttribute(this.flat(positions), 3)
		);
		pointsGeometry.setAttribute(
			"size",
			new THREE.Float32BufferAttribute(
				new Array(positions.length).fill(this._config.baseSize),
				1
			)
		);
		pointsGeometry.setAttribute(
			"color",
			new THREE.Float32BufferAttribute(
				this.flat(colors
					.map((threeColor, idx) => [...threeColor.toArray(), opacitys[idx]])
        ),
				4,
				true
			)
		);

		const vertexShader = `
      attribute float size;
      attribute vec4 color;
      varying vec4 vColor;
      void main() {
          vColor = color;
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
          gl_PointSize = size * ( 300.0 / -mvPosition.z );
          gl_Position = projectionMatrix * mvPosition;
      }
    `;

		const fragmentShader = `
      varying vec4 vColor;
      void main() {
          float dist = distance(gl_PointCoord, vec2(0.5, 0.5));
          if (dist > 0.5) discard;
          gl_FragColor = vColor;
      }
    `;

		const pointsMaterial = new THREE.ShaderMaterial({
			uniforms: {},
			vertexShader,
			fragmentShader,
			transparent: true,
		});

		this._points = new THREE.Points(pointsGeometry, pointsMaterial);
		this._scene.add(this._points);
	}

	private initCamera() {
		if (!this._scene) throw new Error("Scene not initialized");

		// Calculate the distance from the camera to fit all points in view
		let maxDistance = 0;
		for (const point of this._data) {
			const distance = new THREE.Vector3()
				.fromArray(point.position)
				.distanceTo(this._center);
			maxDistance = Math.max(maxDistance, distance);
		}
		const distance = maxDistance * 2;

     // if orbit controls are already initialized, use the current camera position
    if (this._controls) {
      this._camera = this._controls.object as THREE.PerspectiveCamera;
    } else {
		// Set camera position and target using the precalculated center
		this._camera = new THREE.PerspectiveCamera(
			70,
			this._width / this._height,
			0.01,
			distance * 10
		);
		this._camera.position.set(
			this._center.x,
			this._center.y,
			this._center.z + distance
		);
		this._camera.lookAt(this._center);
    }



		// multiply the point sizes by the distance to make them appear the same size
		// regardless of the camera position
		if (!this._points) throw new Error("Points not initialized");
		(this._points.geometry as THREE.BufferGeometry).setAttribute(
			"size",
			new THREE.Float32BufferAttribute(
				new Array(this._data.length).fill(
					this._config.baseSize * (distance / 2)
				),
				1
			)
		);
		(this._points.geometry as THREE.BufferGeometry).attributes.size.needsUpdate = true;
	}

	private initControls() {
		if (!this._camera) throw new Error("Camera not initialized");
		if (!this._renderer) throw new Error("Renderer not initialized");

		// Initialize OrbitControls with the camera and renderer
		this._controls = new OrbitControls(this._camera, this._renderer.domElement);
		const dimensionControls = {
			2: {
				enableZoom: false,
				enablePan: false,
				enableRotate: false,
			},
			3: {
				enableZoom: false,
				enablePan: false,
				enableRotate: true,
			},
		};
		this._controls.enableZoom =
			dimensionControls[this._dimensionality].enableZoom;
		this._controls.enablePan =
			dimensionControls[this._dimensionality].enablePan;
		this._controls.enableRotate =
			dimensionControls[this._dimensionality].enableRotate;

		// Set the target of the OrbitControls to the precalculated center
		this._controls.target.copy(this._center);
	}

	private initRenderer() {
		const container = document.getElementById(this._containerId);
		if (!container) throw new Error("ScatterThumbnail: Container not found");
    const plotDiv = document.getElementById("scatter-thumbnail-plot");
    if (!plotDiv) throw new Error("ScatterThumbnail: Plot div not found");

    // if (this._renderer) {
    //   // cleanup webgl context from previous renderer
    //   this._renderer.forceContextLoss();
    //   this._renderer.context = null;
    //   this._renderer.domElement = null;
    //   this._renderer = null;
    // }

		this._renderer = new THREE.WebGLRenderer({ antialias: true });
		this._renderer.setSize(this._width, this._height);
		this._renderer.setClearColor(0xffffff);
		plotDiv.appendChild(this._renderer.domElement);
	}

  private initThumbnailPosition() {

    const thumbnailContainer = document.getElementById(this._containerId);
    if (!thumbnailContainer) throw new Error("Thumbnail container not found");
    const diffExpPanel = document.getElementById("differential-expression-modal-panel")!;

    if (this._lastThumbnailPosition) {

      const scrollY = diffExpPanel.scrollTop;
      const scrollX = diffExpPanel.scrollLeft;
      thumbnailContainer.style.top = this._lastThumbnailPosition.top + this._lastThumbnailPosition.scrollY + scrollY + "px";
      thumbnailContainer.style.right = this._lastThumbnailPosition.right + this._lastThumbnailPosition.scrollX + scrollX + "px";
      return;
    }


    // find the volcano plot svg and set the thumbnail container to the top right corner of the svg
    const initialMargins = 20;
    const volcanoSvg = document.getElementById(this.volcanoSvgId);
    if (!volcanoSvg) throw new Error("Volcano plot SVG not found");
    const rect = volcanoSvg.getBoundingClientRect();
    const scrollY = diffExpPanel.scrollTop;
    const scrollX = diffExpPanel.scrollLeft;
    thumbnailContainer.style.top = rect.top + scrollY + initialMargins + "px";
    thumbnailContainer.style.right = rect.right + scrollX - initialMargins + "px";
    this._lastThumbnailPosition = {...rect, scrollX, scrollY};
  }

  private flat(data: number[][]): number[] {
    return data.reduce((acc, val) => acc.concat(val), []);
  }
}
