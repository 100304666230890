import { Injectable } from "@angular/core";
import {
  MatSnackBar,
} from "@angular/material";
import { WebworkerJobManagerService } from "./webworker.jobManager.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "app/reducer/index.reducer";
import { DataService } from "../../data.service";

export type DiffExpPayloads =
  | {
      cmd: "install";
      data: {
        deps: string[];
        method: "pyodide" | "micropip";
      };
    }
  | {
      cmd: "run";
      data: {
        fp: string;
        data: Object;
      };
    };

@Injectable({
  providedIn: "root",
})
export class PythonWebworkerJMService extends WebworkerJobManagerService {
  protected workerScript = "assets/scripts/pyodide-webworker.js";

  public static instance: PythonWebworkerJMService;
  constructor(_snackbar: MatSnackBar, store: Store<fromRoot.State>, ds: DataService) {
    super(_snackbar, store, ds);

    PythonWebworkerJMService.instance = this;

    window["reachablePythonWebworkerJMService"] = this;
  }
}
