<div class='panel'>
  <div>
    <div class='modalClose' (click)='closeClick()'>
    </div>
    <h1 class='panel-header'>{{method}}</h1>
    <div fxLayout.xs='column'>
      <div fxFlex.sm='1 1 80%' fxFlex.md='1 1 80%' fxFlex.lg='1 1 80%' fxFlex.xl='1 1 80%'>
        <p class='help-intro' [innerHTML]='desc'></p>
        <p [innerHTML]='learn'></p>
        <p class='help-link'>
          <a class='os-link' href='{{url}}' target='_blank'>{{urlparagraph}}</a>
        </p>
        <div *ngIf='params.length > 0'>
          <h1 class='panel-header' style='padding-bottom: 25px; padding-top: 25px '>Method Settings</h1>
          <div *ngFor=' let attr of params; let i=index '>
            <p class='help-name '>{{ attr.name }}</p>
            <p>{{ attr.desc }}</p>
          </div>
        </div>

        <div *ngIf='attrs.length > 0'>
          <h1 class='panel-header ' style='padding-bottom: 25px; padding-top: 25px '>Returned Values
            <span class='file-span '> (as viewed in chart legend)</span>
          </h1>
          <div *ngFor='let attr of attrs; let i=i ndex '>
            <p class='help-name '>{{ attr.name }}</p>
            <p>{{ attr.desc }}</p>
          </div>
        </div>
        <div *ngIf='citations.length > 0'>
          <h1 class='panel-header ' style='padding-bottom: 25px; padding-top: 25px '>Method Citations</h1>
          <div *ngFor='let attr of citations; let i=i ndex '>
            <p class='help-name ' style='padding-top: 10px; '>{{ attr.name }}</p>
            <p>{{ attr.desc }}</p>
            <a class='os-link ' href='{{ attr.url }} ' target='_blank '>Link to Pub</a>
          </div>
        </div>
      </div>
    </div>
