import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Renderer2
} from '@angular/core';
import * as d3 from 'd3';

import * as _ from 'lodash';

import { ComputeWorkerUtil } from 'app/service/compute.worker.util';
import { OncoData } from 'app/oncoData';
import { WidgetModel } from './widgetmodel';
import { Vector2 } from 'three';

import { TooltipController, ComplexTooltipData } from '../../../controller/tooltip/tooltip.controller';
import { TooltipOptions } from '../../../controller/tooltip/tooltip.controller';
import { TooltipContextObject } from './tooltipContextObject';
import { WorkspaceComponent } from "app/component/workspace/workspace.component";

import { CommonSidePanelModel } from './commonSidePanelModel';

export function combineSettings<T>(defaultSettings: T, settings: Partial<T>, depth = Infinity): T {
  if (depth <= 0 || typeof settings !== 'object' || settings === null || Array.isArray(settings)) {
    return settings as T;
  }

  const combined = { ...defaultSettings };

  for (const key in settings) {
    if (settings.hasOwnProperty(key)) {
      if (typeof settings[key] === 'object' && settings[key] !== null && !Array.isArray(settings[key])) {
        // Recursively combine nested objects with reduced depth
        combined[key] = combineSettings(defaultSettings[key], settings[key], depth - 1);
      } else {

        // if undefined, use default
        if (settings[key] === undefined) {
          combined[key] = defaultSettings[key];
        } else {
          // Override with user options
          combined[key] = settings[key];
        }
      }
    }
  }

  return combined;
}

@Component({
  selector: 'widget',
  templateUrl: './widget.html',
  styleUrls: ['./common-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class WidgetComponent<Settings extends Object = any> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myId', { static: false }) myId: ElementRef;
  @ViewChild('parentOfSvgDiv', { static: true }) parentOfSvgDiv: ElementRef;


  protected wutil  = new ComputeWorkerUtil();  // Not for remote cpu calls, just for data access utility functions.

  public model = new WidgetModel<Settings>();
  public showSettings = false;
  public tooltips: HTMLElement;
  public tooltip: string | ComplexTooltipData;
  public tooltipColor: string;
  protected tooltipOptions: TooltipOptions;
  protected tooltipController: TooltipController;
  public usesSvg: boolean;

  public commonSidePanelModel:CommonSidePanelModel = null;

  constructor(
    public renderer: Renderer2
  ) {
    this.usesSvg = true;
    this.tooltipOptions = new TooltipOptions();
    this.tooltip = '';
    this.tooltips = <HTMLDivElement>document.createElement('div');
    this.tooltips.id = 'visTooltipsDiv' + this.getContainerName();
    this.tooltips.className = 'xtooltiptext' ;
    let self = this;
    Khonsole.warn('WIDGET constructor.');
    //////this.commonSidePanel = CommonSidePanelComponent.instance; // MJ
    this.tooltips.style.visibility="hidden";

  }

  public onHideTooltip(): void {
    this.tooltips.style.display = "none";
    console.log("hide tooltip widget");
    WorkspaceComponent.instance.pinnabletoolbar.setContent("");
    //visibility="hidden";
    //(this.tooltips.children[0] as HTMLElement).style.visibility="hidden";
  }

  public processConfigChange(config:any): void {
    Khonsole.log('processConfigChange for ' + this.getContainerName());
    // Use  this.commonSidePanelModel.graphConfig
  }

  showOrHideTooltip(currentEventMousedOver, shouldShow:boolean){
    Khonsole.log('WIDGET showOrHideTooltip.');
    if(currentEventMousedOver && shouldShow) {
      // Contains:
      // tooltipContextObject: what we want tooltip to talk about,
      // mouseEvent: the mouse move event
      let contextObject = currentEventMousedOver.tooltipContextObject;
      let mouseMoveEvent = currentEventMousedOver.mouseEvent;

      this.tooltip= this.complexTooltipFromContextObject(contextObject);
      this.tooltipColor = contextObject.color;
      let yFudge = 20;  // Don't know why tooltip is too low, so fudge factor.
      let newTooltipHTML = TooltipController.instance.generateHtml(
        null,
        this,
        {
          position: new Vector2(
            mouseMoveEvent.x + 15,
            mouseMoveEvent.y - yFudge
          ), //x + 15, e.event.clientY - 20, 0),
          userData: { tooltip: this.tooltip, color: this.tooltipColor },
        },
        this.tooltipOptions
      );

      if (WorkspaceComponent.instance.pinnabletoolbar.isPinned) {
        WorkspaceComponent.instance.forcePinnedTooltipContent(
          newTooltipHTML
        );
      } else {
        this.tooltips.innerHTML = newTooltipHTML;
      }

      this.tooltips.style.display = "block";
    } else {
      this.onHideTooltip();
    }
  }

  complexTooltipFromContextObject(contextObject: TooltipContextObject): ComplexTooltipData {
    let shmooltip = '<div>Placeholder</dive>';
    let complexTooltip = new ComplexTooltipData(
      contextObject.entityType,
      contextObject.entityId,
      null, // related entityType?
      null,
      event,
      shmooltip
    );
    return (complexTooltip);
  }

  clearSvg(){
    Khonsole.log('clearSvg '+ this.model.name );
    d3.select(this.parentOfSvgDiv.nativeElement)
    .selectAll("svg > *").remove();
  }

  clearContents(){
    this.clearSvg();
  }

  getName() {
    return this.model.name;
  }

  getContainerName() {
    return "svgContainer_" + this.model.name.replace(' ','_');
  }

  private _hidden = false;
  toggleHidden() {
    this._hidden = !(this._hidden);
    //let svg =     d3.select(this.svgdiv.nativeElement).select("svg").;
    let widgetName = this.model.name.replace(" ", "_")
    let el = document.querySelector('#svgContainer_'+widgetName) as HTMLElement;
    // let svg = d3.select(el.getElementsByTagName('svg')[0]);
    if (this._hidden) {
      el.style.display = "none";
      // hide settings
      this.showSettings = false;
    } else {
      el.style.display = "block";
    }
  }

  clickSettings() {
    this.showSettings = !this.showSettings;
  }

  getExpanded() {
    return (this._hidden == false);
  }

  svgNode:HTMLElement;
  svgD3Selection = null;

  setupSvg(thisWidget){
    if(thisWidget.usesSvg==false) {
      return;
    }
    function handleMouseEnterGeneralSvg(this: HTMLElement) {
      thisWidget.tooltips.style.display = "none";
    }

    let parentOfSvg = (this.parentOfSvgDiv.nativeElement as HTMLDivElement);
    Khonsole.log("=== "+ this.model.name + " setupSvg")
    this.svgD3Selection = d3
        .select(parentOfSvg)
        .append('svg')
        .attr('width',  OncoData.instance.currentCommonSidePanel.commonSidePanelModel.width)
        .attr('height', this.model.preferredHeight)
        ;
    this.svgNode = this.svgD3Selection.node() as HTMLElement;
    parentOfSvg.getElementsByTagName("svg")[0].addEventListener("mouseenter", handleMouseEnterGeneralSvg);
  }

  ngOnInit() {
    let self = this;

    if(this.model.preferredHeight != null) {
      this.renderer.setStyle(this.parentOfSvgDiv.nativeElement, 'height', this.model.preferredHeight);
    }
    this.setupSvg(self);

    this.tooltips.id = 'visTooltipsDiv' + this.getContainerName();
    document.body.appendChild(this.tooltips);

    const button = document.querySelector("button");
    this.tooltips.addEventListener("mouseenter", handleMouseenter);

    function handleMouseenter(this: HTMLElement) {
      Khonsole.log("MOUSEENTER tooltip!");
      self.tooltips.style.display = "block";
      // let tt = self.tooltips.getElementsByClassName("xtooltiptext");
      // if(tt.length>0) {
      //   tt[0].addEventListener("mouseout", handleMouseout);
      //   Khonsole.log('added mouseout handler tooltip.');
      // }
    }
    // function handleMouseout(this: HTMLElement) {
    //   Khonsole.log("MOUSEOUT tooltip!");
    //   //self.tooltips.style.display = "none";
    // }


  }

  ngAfterViewInit() {
    //this.svgdiv.nativeElement.value
    Khonsole.log('WDGET about to run...')
    //Khonsole.log('WDGET== ' +  this.myId.nativeElement);
  }

  ngAfterViewChecked(){
  }

  ngOnDestroy() {}

}
