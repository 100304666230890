import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  ElementRef
} from '@angular/core';

import * as d3 from 'd3';
import { GraphConfig } from './../../../model/graph-config.model';
import { DataService } from './../../../service/data.service';
import { StatFactory } from './../../../service/stat.factory';
import { StatVegaFactory } from './../../../service/stat.vega.factory';
import { ChartTypeEnum } from '../../../model/enum.model';
import {
  Cohort
} from './../../../model/cohort.model';

import { MatProgressBarModule } from '@angular/material'
import { DataTable } from './../../../model/data-field.model';
import { CollectionTypeEnum } from 'app/model/enum.model';
import { DiffexpWidgetComponent } from '../common-side-panel/diffexp-widget.component';
import { DiffexpResults } from '../common-side-panel/diffexpResults';
import { OncoData, LoadedTable } from 'app/oncoData';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { AppComponent } from 'app/app.component';

declare const $: any;

// declare const vega: any;
// declare const vegaTooltip: any;

@Component({
  selector: 'app-workspace-dashboard-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss']
})
export class DashboardPanelComponent implements AfterViewInit, OnDestroy {
  private statFactory: StatFactory;
  private container: any;

  cohortA: Cohort; // for A/B comparisons of two cohorts.
  cohortB: Cohort;
  selectedCompareTable: DataTable;

  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;
  @Output() hide = new EventEmitter<any>();
  private _config: GraphConfig;
  get config(): GraphConfig {
    return this._config;
  }
  @Input() set config(value: GraphConfig) {
    this._config = value;
    if (this.container === undefined) {
      return;
    }
    this.drawStats();
  }

  @Input()
  cohorts: Array<Cohort> = [];

  cohortsAndNotA: Array<Cohort> = [];

  cohort: Cohort; // main cohort for graph
  cohortSecondary: Cohort;  // acohort for comparison

  ngAfterViewInit(): void {
    this.statFactory = StatFactory.getInstance(this.dataService);
    let noneCohort: Cohort = {
      n: 'Not In Cohort A',
      pids: [],
      sids: [],
      conditions: [],
      assignedColor: 'gray'
    }
    this.cohortsAndNotA = [...this.cohorts];
    this.cohortsAndNotA.unshift(noneCohort);

    if (window["cohortRequestingDashboard"]) {
      let cohort = window["cohortRequestingDashboard"]
      this.cohortA = cohort;
      this.cohortB = noneCohort;
      window["cohortRequestingDashboard"] = null
    }

    this.drawStats();
  }

  onCohortChange(event) {
    Khonsole.log('cohort change...')
    Khonsole.dir(event)
  }

  onCohortSecondaryChange(event) {
    Khonsole.log('cohortSecondary change...')
    Khonsole.dir(event)
  }


  drawStats(): void {
    Khonsole.log("drawStats");
    Promise.all([this.statFactory.getCohortsStats(this.config)]).then(results => {
      Khonsole.warn("ALL COHORT STATS");
      Khonsole.dir(results)
      window["allCohortStats"] = results

      const allResults = results.reduce((p, c) => p.concat(...c), []);
      const c = this.chartContainer.nativeElement as any;
      const defaultWidth = 500;
      const defaultHeight = 400;

      Khonsole.log("==== drawstats, results... ===")
      Khonsole.dir(results);
      Khonsole.log("==== drawstats, allResults... ===")
      Khonsole.dir(allResults);

      c.insertAdjacentHTML(
        'beforeend',
        '<div id="chartdiv" foo="goo" style="{width:'+(defaultWidth*1.75)+'px; height:'+(defaultHeight*1.5)+'px;}"></div>'
      );

    //   // $.jqplot('chartdiv',  [[[1, 2],[3,5.12],[5,13.1],[7,33.6],[9,85.9],[11,219.9]]]);
    //   $(document).ready(function(){
    //     GetChart(allResults[0].stats[0]);
    //  });


    //   function GetChart(stat){
    //     // stat.data as Array<{ mylabel: string; myvalue: number; color?: any }>;
    //     $.jqplot.config.enablePlugins = true;

    //     var ticks = stat.data.map(v => v.mylabel);
    //     var series1 = stat.data.map(v => v.myvalue);

    //     let plot1 = $.jqplot('chartdiv', [series1], {
    //       title: stat.name.toUpperCase(),
    //       height: defaultHeight*1.5,
    //       width: 600, //defaultWidth*1.6,


    //       // Provide a custom seriesColors array to override the default colors.
    //       // Same length as stat.data.
    //       seriesColors: stat.data.map(v => "#1e88e5"),
    //       grid:{ borderWidth:1.0, background:"#FFFFFF"},
    //       animate: false , //!$.jqplot.use_excanvas,
    //       seriesDefaults:{
    //           renderer:$.jqplot.BarRenderer,
    //           pointLabels: { show: true }
    //       },
    //       axes: {
    //           xaxis: {
    //               renderer: $.jqplot.CategoryAxisRenderer,
    //               ticks: ticks
    //           }
    //       },
    //       highlighter: { show: false }
    //     });

    //     $('#chartdiv').bind('jqplotDataClick',
    //         function (ev, seriesIndex, pointIndex, data) {
    //             // WOULD put data in div infor1... $('#info1').html('series: '+seriesIndex+', point: '+pointIndex+', data: '+data);
    //         }
    //     );
    //   }

      // this.drawSingleStatChart(allResults[0].stats[0], defaultWidth *1.75, defaultHeight*1.5)
      c.insertAdjacentHTML(
        'beforeend',
        '<hr />'
      );

      allResults.forEach(result => {
        // grab cohort name NEED break at cohort loop
        // tslint:disable-next-line:max-line-length
        // this.chartContainer.nativeElement.apply('<div style="font-size:1.2rem; font-weight: 300; margin-bottom:50px; margin-top:50px; text-transform:uppercase; letter-spacing: 1px; color: #1e88e5;">'
        // + result.cohort.n + '</div>');
        // tslint:disable-next-line:max-line-length
        //     this.chartContainer.nativeElement.innerHTML += '<div style="font-size:1.2rem; font-weight: 300; margin-bottom:50px; margin-top:50px; text-transform:uppercase; letter-spacing: 1px; color: #1e88e5;">'
        // + result.cohort.n + '</div>';
        // d3
        // .select(this.chartContainer.nativeElement)
        // .append('<div>hi</div>')
        c.insertAdjacentHTML(
          'beforeend',
          '<div style="font-size:1.2rem; font-weight: 300; margin-bottom:50px; margin-top:50px; text-transform:uppercase; letter-spacing: 1px; color: #1e88e5;">' +
            result.cohort.n +
            '</div>'
        );

        result.stats.forEach(stat => {
          this.drawSingleStatChart(stat, defaultWidth, defaultHeight, this.chartContainer.nativeElement)
        });
      }, this);
    });
  }

  drawSingleStatChart(stat, w, h, el){
    let svg: d3.Selection<d3.BaseType, unknown, null, undefined> = d3
      .select(el)
      .append('svg')
    this.statFactory.drawStatChartFromLibrary(stat, w, h, svg)
  }


  closeClick(): void {
    this.hide.emit();
  }

  ngOnDestroy(): void {}

  compareCohortsById(f1: Cohort, f2: Cohort): boolean {
    return f1 && f2 && f1.n === f2.n;
  }

  constructor(private cd: ChangeDetectorRef, private dataService: DataService) {

  }
}
