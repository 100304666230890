<div class="common-widget">
  <!-- widget -->
  <table border="0" width="100%">
    <tr>
      <td cdkDragHandle>
        <mat-icon
          (click)="toggleHidden()"
          class="widget-icon"
          *ngIf="getExpanded()"
          >expand_less</mat-icon
        >
        <mat-icon
          (click)="toggleHidden()"
          class="widget-icon"
          *ngIf="!getExpanded()"
          >expand_more</mat-icon
        >
        <!--</button>-->
        <b>{{ getName() }}</b>
        <!-- </td><td align="right"><mat-icon (click)='clickSettings()' class="widget-icon" >settings</mat-icon></td> -->
      </td>
    </tr>
  </table>
  <div
    [id]="getContainerName()"
    #parentOfSvgDiv
    class="common-widget common-widget-svgdiv"
  >
    <div #cohortControls class="commonSideItem mat-form-field-onco-wide-panel">
      <form class="mat-form-field-onco-wide-panel">
        <mat-form-field class="mat-form-field-onco-wide-panel no-gutter" [ngClass]="{'quiet-disabled': !canTurnSelectionIntoNewCohort}">
          <span class="legendHeader">Selected Cohort</span>
          <table
            width="100%"
            style="border-collapse: collapse; border: 1px solid lightgray"
          >
            <tr>
              <td
                id="colorCellForSelectedCohort"
                width="10"
                [style.backgroundColor]="selectedCohort ? selectedCohort.assignedColor : 'blue'"
                #selectedCohortColorCell
              >
                &nbsp;
              </td>
              <td id="spacerColorCellForSelectedCohort">&nbsp;</td>
              <td width="100%" height="39.2">
                <input
                  #selectedCohortTextField
                  [value]="selectedDisplayName"
                  matInput
                  [matTooltip]="!canTurnSelectionIntoNewCohort ? 'Renaming saved cohorts is currently disabled' : null"
                  [disabled]="!canTurnSelectionIntoNewCohort"
                  [ngClass]="{'quiet-disabled': !canTurnSelectionIntoNewCohort}"
                  (keyup)="
                    handleSelectionNameKeyUp($event.target.value);
                  "
                />
              </td>
              <td id="cellForAddingCohort">
                <button
                  *ngIf="canTurnSelectionIntoNewCohort"
                  (click)="handleAddCohortClick()"
                  mat-button
                  class="button-next-to-input"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </mat-form-field>

        <!-- Actions for the current selection -->
        <div style="cursor: pointer; margin-top: 5px">
          <mat-icon
            matTooltip="Copy the selected cohort's patient IDs to the clipboard"
            class="material-icons"
            style="transform: scale(0.75)"
            (click)="copySelectedToClipboard()"
            role="img"
            aria-hidden="true"
            >content_copy</mat-icon
          >
        </div>

        <span class="legendHeader">Saved Cohorts</span>

        <div class="mat-form-field-onco-wide-panel">
          <table
            id="savedCohortsTable"
            width="100%"
            border="1"
            frame="hsides"
            rules="rows"
            style="border-collapse: collapse; border: 1px solid lightgray"
          >
            <tbody>
              <tr *ngFor="let cohort of cohorts; index as i">
                <td width="10" [style.backgroundColor]="getCohortColor(i)">
                  &nbsp;
                </td>
                <td>&nbsp;</td>
                <td
                  width="100%"
                  height="23"
                  class="common-side-cohort-list-item"
                  (click)="handleSavedCohortClick($event, cohort)"
                >
                  {{ getCohortDisplayName(cohort) }}
                </td>
                <td nowrap style="font-size: 12px; vertical-align: middle">
                  <mat-icon
                    matTooltip="Compare this cohort to others"
                    class="material-icons common-side-cohort-list-item"
                    style="font-size: 16px"
                    title="Compare this cohort to others"
                    (click)="handleStatsCohortClick(cohort)"
                    role="img"
                    aria-hidden="true"
                    >bar_chart</mat-icon
                  >
                  <mat-icon
                    *ngIf="canDeleteCohort(cohort)"
                    matTooltip="Delete this cohort"
                    class="material-icons common-side-cohort-list-item"
                    style="font-size: 16px"
                    title="Delete cohort"
                    (click)="handleDeleteCohortClick(cohort)"
                    role="img"
                    aria-hidden="true"
                    >delete</mat-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
