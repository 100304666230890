import { MatSnackBar, } from "@angular/material";
import { WebworkerJobManagerService } from "./webworker.jobManager.service";
import { Store } from "@ngrx/store";
import { DataService } from "../../data.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@ngrx/store";
import * as i3 from "../../data.service";
export class PythonWebworkerJMService extends WebworkerJobManagerService {
    constructor(_snackbar, store, ds) {
        super(_snackbar, store, ds);
        this.workerScript = "assets/scripts/pyodide-webworker.js";
        PythonWebworkerJMService.instance = this;
        window["reachablePythonWebworkerJMService"] = this;
    }
}
PythonWebworkerJMService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PythonWebworkerJMService_Factory() { return new PythonWebworkerJMService(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.DataService)); }, token: PythonWebworkerJMService, providedIn: "root" });
