/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./volcano-scatter-thumbnail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../workspace/common-side-panel/widget-settings/widget-settings.component.ngfactory";
import * as i3 from "../../../workspace/common-side-panel/widget-settings/widget-settings.component";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/common";
import * as i7 from "./volcano-scatter-thumbnail.component";
var styles_VolcanoScatterThumbnailComponent = [i0.styles];
var RenderType_VolcanoScatterThumbnailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VolcanoScatterThumbnailComponent, data: {} });
export { RenderType_VolcanoScatterThumbnailComponent as RenderType_VolcanoScatterThumbnailComponent };
function View_VolcanoScatterThumbnailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-settings", [["id", "scatter-thumbnail-settings"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = ((_co.showSettings = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_WidgetSettingsComponent_0, i2.RenderType_WidgetSettingsComponent)), i1.ɵdid(1, 49152, null, 0, i3.WidgetSettingsComponent, [], { model: [0, "model"] }, { onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VolcanoScatterThumbnailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["draggable", "true"], ["id", "scatter-thumbnail-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["id", "scatter-thumbnail-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["style", "display: flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header-btn"], ["id", "scatter-thumbnail-header-x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["id", "scatter-thumbnail-header-text"], ["style", "margin-left: 4px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "widget-icon mat-icon notranslate"], ["id", "settings-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["settings"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["id", "scatter-thumbnail-resizer"], ["title", "Drag to resize"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["id", "scatter-thumbnail-tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["id", "scatter-thumbnail-plot"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VolcanoScatterThumbnailComponent_1)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_2 = _co.showSettings; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).inline; var currVal_1 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_VolcanoScatterThumbnailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-volcano-scatter-thumbnail", [], null, null, null, View_VolcanoScatterThumbnailComponent_0, RenderType_VolcanoScatterThumbnailComponent)), i1.ɵdid(1, 4308992, null, 0, i7.VolcanoScatterThumbnailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VolcanoScatterThumbnailComponentNgFactory = i1.ɵccf("app-volcano-scatter-thumbnail", i7.VolcanoScatterThumbnailComponent, View_VolcanoScatterThumbnailComponent_Host_0, { data: "data", volcanoSvgId: "volcanoSvgId", width: "width", height: "height", config: "config", visible: "visible" }, { close: "close" }, []);
export { VolcanoScatterThumbnailComponentNgFactory as VolcanoScatterThumbnailComponentNgFactory };
