import { Khonsole } from 'app/khonsole';
import { Vector3 } from 'three';
import { OncoData } from 'app/oncoData';
import { AppComponent } from '../../../../app/app.component';
import { EntityTypeEnum } from '../../../model/enum.model';
import { AbstractVisualization } from '../visualization.abstract.component';
import { TooltipController, ComplexTooltipData } from '../../../controller/tooltip/tooltip.controller';
import * as d3 from 'd3';
import define from "./new_timeline/index.js";
import { Runtime, Library, Inspector } from "./new_timeline/runtime.js";
import { TimelineSelectionController } from 'app/controller/selection/timeline.selection.controller';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { CommonSidePanelComponent } from 'app/component/workspace/common-side-panel/common-side-panel.component';
import { ComputeWorkerUtil } from 'app/service/compute.worker.util';
export class axisDataForGrouping {
    constructor() {
        this.minY = 0;
        this.maxY = 0;
        this.numPatients = 0;
    }
}
export class SvgTimelinesGraph extends AbstractVisualization {
    constructor() {
        super(...arguments);
        this.clipPlanes = [];
        this.axisDataForGroups = [];
        this.runtime = null;
        this.leftPanelOffset = 292;
        // mark true once we've seen this once.
        this.sawFirstRowSelectionChangedCounter = false;
        this.wutil = new ComputeWorkerUtil(); // Not for remote cpu calls, just for data access utility functions.
        this.hiddenOffsetY = 3;
    }
    set data(data) {
        this._data = data;
    }
    get data() {
        return this._data;
    }
    set config(config) {
        this._config = config;
    }
    get config() {
        return this._config;
    }
    recreate() {
        this.removeObjects();
        this.addObjects(this.config.entity);
    }
    // Create - Initialize Mesh Arrays
    create(entity, html, events, view) {
        super.create(entity, html, events, view);
        let self = this;
        this.selectionController = new TimelineSelectionController(this.entity, view, events);
        this.selectionController.enable = true;
        Khonsole.log("created TimelineSelectionController");
        this.selectSubscription = this.selectionController.onSelect.subscribe((data) => {
            let ids = data.ids; // ids are 3 times bigger than real index. We'll divide by 3.
            let source = data.source; // we EXPECT this isalways "Selection", not "Cohort".
            // const values: Array<DataDecoratorValue> = ids
            //   .map(v => v / 3)
            //   .map(v => {
            //     return {
            //       pid: this._data.pid[v],
            //       sid: this._data.sid[v],
            //       mid: null,
            //       key: EntityTypeEnum.SAMPLE,
            //       value: true,
            //       label: ''
            //     };
            //   });
            // const dataDecorator: DataDecorator = {
            //   type: DataDecoratorTypeEnum.SELECT,
            //   values: values,
            //   field: null,
            //   legend: null,
            //   pidsByLabel: null
            // };
            // WorkspaceComponent.addDecorator(this._config, dataDecorator);
            // this._lastSelectionPatientIds = ids.map(v => self._data.pid[v/3]);
            // this.recalculateLegendTotals();
            // window.setTimeout(() => self.signalCommonSidePanel(this._lastSelectionPatientIds, source, EntityTypeEnum.SAMPLE), 50);
        });
        //let leftPanelOffset = document.getElementsByClassName('graphPanel')[0].getBoundingClientRect().width;
        Khonsole.warn("GUESS for leftPanelOffset=" + this.leftPanelOffset);
        this.setupRuntime();
        return this;
    }
    setupRuntime() {
        let self = this;
        if (this.runtime) {
            this.runtime.dispose();
        }
        // First remove any existing timeline
        var timelines = document.getElementsByClassName('timeline-svg-div');
        while (timelines[0]) {
            timelines[0].parentNode.removeChild(timelines[0]);
        }
        self.timelineSvgDiv = document.createElement('div');
        self.timelineSvgDiv.className = 'timeline-svg-div'; // NYI
        let wrapperTableHtml = `
    <table border="0"><tr>
    <tr><td id="oncoSortTools"></td></tr>
    <tr><td id="oncoSvgTable"></td></tr>

    <tr></td><td><div style="display:none;" id="onco3"></div></td></tr>
    <tr><td >

    <div>
      <div style="float:left" >&nbsp;Zoom:&nbsp;</div>
      <div style="float:left" id="divStretcher"></div>
      <div style="float:left"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Track Height:&nbsp;</div>
      <div style="float:left" id="divTrackHeight"></div>
      <div style="float:left"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log:&nbsp;</div>
      <div style="float:left" id="divUseLogScale"></div>
    </div>
    </td></tr>

    <tr><td><div id="oncoMore" style="max-height:32px; overflow-y:auto"></div></td></tr>
    </table>`;
        self.timelineSvgDiv.innerHTML = wrapperTableHtml;
        self.html.appendChild(self.timelineSvgDiv);
        this.runtime = new Runtime(Object.assign(new Library, { width: 640 }));
        self.observableRuntimeModule = this.runtime.module(define, name => {
            Khonsole.log('log ' + name);
            if (name == "svgTable" || name == "viewof svgTable") {
                return Inspector.into("#oncoSvgTable")();
            }
            if (name == "viewof sortTools") { // name == "tooltip" ||
                return Inspector.into("#oncoSortTools")();
            }
            if (name == "viewof dynamicTrackHeight") {
                return Inspector.into("#divTrackHeight")();
            }
            if (name == "viewof stretcher") {
                return Inspector.into("#divStretcher")();
            }
            if (name == "viewof use_logscale") {
                return Inspector.into("#divUseLogScale")();
            }
            if (name == "rowSelectionChangedCounter") {
                Khonsole.log('adding handlerfor rowSelectionChangedCounter ');
                return { fulfilled(value) {
                        if (self.sawFirstRowSelectionChangedCounter) {
                            self.handleManualSelectionChange();
                        }
                        else {
                            self.sawFirstRowSelectionChangedCounter = true;
                        }
                    } };
            }
            if (name == "currentEventMousedOver") {
                Khonsole.log('adding handlerfor currentEventMousedOver ');
                return { fulfilled(value) { self.showOrHideTooltip(value); } };
            }
            if (name == "somethingYouMustSeeForDebugging") { // (name == "eventTypes" ||   name == "theAttrs") {
                return Inspector.into("#oncoMore")();
            }
            return Inspector.into("#onco3")();
        });
        this.observableRuntimeModule.redefine("dataLoadedAction", function () {
            return OncoData.instance.dataLoadedAction;
        });
        // vb3 holds height of main timeline area
        let desiredMainSvgHeight = Math.max(100, window.innerHeight - 290);
        let desiredMainSvgWidth = Math.max(100, window.innerWidth - (this.leftPanelOffset + 160));
        Khonsole.log();
        this.observableRuntimeModule.redefine("vb3", desiredMainSvgHeight);
        this.observableRuntimeModule.redefine("rightSideWidth", desiredMainSvgWidth);
        this.observableRuntimeModule.redefine("onOncoscapeEventClicked", function () {
            return (self.handleOncoscapeEventClicked);
        });
        this.observableRuntimeModule.redefine("onOncoscapeRowSelectionChanged", function () {
            return (self.handleOncoscapeRowSelectionChanged);
        });
        this.observableRuntimeModule.redefine("onOncoscapeIdGroupClicked", function () {
            return (self.handleOncoscapeIdGroupClicked);
        });
        this.observableRuntimeModule.redefine("onOncoscapeCreateCohortFromTimelineSelection", function () {
            return (self.handleOncoscapeCreateCohortFromTimelineSelection);
        });
        //
    }
    handleOncoscapeEventClicked(event) {
        Khonsole.log('ONCOSCAPE, handleOncoscapeEventClicked for...');
        Khonsole.log(event.detail.pid);
        Khonsole.dir(event.detail.report);
        let title = 'Event ' + event.detail.pid;
        let guts = event.detail.report;
        AppComponent.myApp.showContentDialog(title, guts);
    }
    handleOncoscapeCreateCohortFromTimelineSelection(event) {
        Khonsole.log('ONCOSCAPE, onOncoscapeCreateCohortFromTimelineSelection for...');
        Khonsole.log(event.detail);
        CommonSidePanelComponent.instance.createCohortFromPids(event.detail.ids);
    }
    handleOncoscapeRowSelectionChanged(event) {
        Khonsole.log('ONCOSCAPE, onOncoscapeRowSelectionChanged for...');
        Khonsole.log(event.detail);
    }
    handleOncoscapeIdGroupClicked(event) {
        Khonsole.log('ONCOSCAPE, onOncoscapeIdGroupClicked for...');
        //Khonsole.warn(event.detail.pid);
        Khonsole.log(event.detail.pid);
    }
    // in order to SET row selection,
    // must redefine "arrayIdsToSelect"".
    // Give array of  IDs, or __all_true or __all_false;
    externallySelectArrayOfIDs(ids) {
        this.observableRuntimeModule.redefine("arrayIdsToSelect", ids);
    }
    externallyDeselectAllRows() {
        this.observableRuntimeModule.redefine("arrayIdsToSelect", ["__all_false"]);
    }
    currentSelectionLinkedToCounter() {
        // let rr = mutable rowSelectionChangedCounter;
        let selection = [];
        d3.selectAll(".timeline-svg-id-rect.timeline-svg-id-rect-selected")
            .each(function (d) {
            selection.push(d["id"]);
        });
        selection.sort();
        return selection;
    }
    handleManualSelectionChange() {
        let self = this;
        if (this.observableRuntimeModule) {
            Khonsole.log('FIND currentSelectionLinkedToCounter');
            let selectedPids = this.currentSelectionLinkedToCounter();
            Khonsole.dir(selectedPids);
            window.setTimeout(() => self.signalCommonSidePanel(selectedPids, 'Selection', EntityTypeEnum.PATIENT), 50);
        }
        else {
            Khonsole.log('module not ready for currentSelectionLinkedToCounter');
        }
    }
    signalCommonSidePanel(patientIdsForCommonSurvival, selectionSource, entityType) {
        if (OncoData.instance.currentCommonSidePanel) {
            OncoData.instance.currentCommonSidePanel.setSelectionPatientIds({
                patientIds: patientIdsForCommonSurvival, existingCohort: selectionSource == "Cohort" ? "Cohort" :
                    (selectionSource == "Legend" ? "Legend" : null), selectionModifiers: null, graphConfig: this._config
            });
            OncoData.instance.currentCommonSidePanel.drawWidgets();
        }
    }
    showOrHideTooltip(currentEventMousedOver) {
        if (currentEventMousedOver) {
            //  contains:
            // timelineEvent:thisEvent,
            // mouseEvent: the mouse move event
            let event = currentEventMousedOver.timelineEvent;
            let mouseMoveEvent = currentEventMousedOver.mouseEvent;
            this.tooltip = this.complexTooltipFromEvent(event);
            this.tooltipColor = event.color;
            let yFudge = 20; // Don't know why tooltip is around 30px too low, so fudge factor.
            let newTooltipHTML = TooltipController.instance.generateHtml(this.view, this.entity, {
                position: new Vector3(mouseMoveEvent.x + 15, mouseMoveEvent.y - yFudge, 0),
                userData: { tooltip: this.tooltip, color: this.tooltipColor },
            }, this.tooltipOptions);
            if (WorkspaceComponent.instance.pinnabletoolbar.isPinned) {
                WorkspaceComponent.instance.forcePinnedTooltipContent(newTooltipHTML);
            }
            else {
                this.tooltips.innerHTML = newTooltipHTML;
            }
        }
        else {
            //hide
            this.onHideTooltip();
        }
    }
    destroy() {
        super.destroy();
        this.removeObjects();
    }
    enable(truthy) {
        if (this.isEnabled === truthy) {
            return;
        }
        this.isEnabled = truthy;
        //this.labelController.enable = this.isEnabled;
        this.tooltipController.enable = this.isEnabled;
        this.view.controls.enabled = false; // No 3D
        if (truthy) {
            this.$MouseMove = this.events.chartMouseMove.subscribe(this.onMouseMove.bind(this));
            this.$MouseDown = this.events.chartMouseDown.subscribe(this.onMouseDown.bind(this));
            this.$MouseUp = this.events.chartMouseUp.subscribe(this.onMouseUp.bind(this));
            this.$KeyPress = this.events.chartKeyPress.subscribe(this.onKeyPress.bind(this));
            this.$KeyDown = this.events.chartKeyDown.subscribe(this.onKeyDown.bind(this));
            this.$KeyUp = this.events.chartKeyUp.subscribe(this.onKeyUp.bind(this));
        }
        else {
            this.$MouseMove.unsubscribe();
            this.$MouseDown.unsubscribe();
            this.$MouseUp.unsubscribe();
            this.$KeyPress.unsubscribe();
            this.$KeyDown.unsubscribe();
            this.$KeyUp.unsubscribe();
            this.labels.innerHTML = '';
            this.tooltips.innerHTML = '';
        }
    }
    onMouseDown(e) {
        Khonsole.log('TIMELINE mouse down');
    }
    onMouseUp(e) {
        Khonsole.log('TIMELINE mouse up');
    }
    onMouseMouse(e) {
        Khonsole.log('TIMELINE mouse move');
    }
    updateDecorator(config, decorators) {
        super.updateDecorator(config, decorators);
        /*
        ChartFactory.decorateDataGroups(this.objs, this.decorators);
        this.tooltipController.targets = this.objs;
        */
    }
    updateObservableBitsIfReady(config, data) {
        let self = this;
        let ready = OncoData.instance.currentCommonSidePanel && OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientData;
        if (ready) {
            self.observableRuntimeModule.redefine("dataLoadedAction", function () {
                return OncoData.instance.dataLoadedAction;
            });
            let oldDataResult = Object.assign({}, data.result);
            if (oldDataResult.patients.length > 2000) {
                oldDataResult.patients = oldDataResult.patients.slice(0, 2000);
                let notifiedUserOfTimelineSizeLimit = sessionStorage.getItem('notifiedUserOfTimelineSizeLimit');
                if (notifiedUserOfTimelineSizeLimit == null) {
                    sessionStorage.setItem('notifiedUserOfTimelineSizeLimit', 'notified');
                    alert('NOTE: Timeline view is currently limited to the first 2,000 patients in the cohort.');
                }
            }
            oldDataResult.patients = oldDataResult.patients.map(v => v.events);
            let pidsInOrder = data.result.patients.map(v => v.pid); //  OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientData.map(v => v.p);
            self.observableRuntimeModule.redefine("tlConfig", self.config);
            self.observableRuntimeModule.redefine("rawPatientsFromJson", OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientData);
            self.observableRuntimeModule.redefine("loadedRawData", oldDataResult); // New data.result went from straight evnets to {events:x, pid:y}, so we revert here.
            self.observableRuntimeModule.redefine("eventTypes", OncoData.instance.dataLoadedAction.events.map(v => `${v.type}:${v.subtype}`).sort());
            Khonsole.log("== updateObservableBitsIfReady done.");
            Khonsole.log('>> In updateObservableBitsIfReady, build clickedPidsPerEvent');
            let clickedPidsPerEvent = {};
            window['clickedPidsPerEvent'] = null;
            let pidIndex = 0;
            oldDataResult.patients.map(eventsThisPatient => {
                // For each event, add it to entry type:subtype set, create set if  doesn't exist first.
                eventsThisPatient.map(e => {
                    let typeSubtype = (e.type + ":" + e.subtype).toLowerCase();
                    if (clickedPidsPerEvent[typeSubtype] == null) {
                        clickedPidsPerEvent[typeSubtype] = new Set();
                    }
                    let currentSet = clickedPidsPerEvent[typeSubtype];
                    currentSet.add(pidsInOrder[pidIndex]);
                });
                pidIndex = pidIndex + 1;
            });
            window['clickedPidsPerEvent'] = clickedPidsPerEvent;
            // Khonsole.log('BUILT:')
            // Khonsole.dir(clickedPidsPerEvent)
        }
        else {
            window.setTimeout(() => {
                self.updateObservableBitsIfReady(config, data);
            }, 250);
        }
    }
    updateData(config, data) {
        super.updateData(config, data);
        this.setupRuntime();
        // Update patients, events, and config.
        if (this.observableRuntimeModule) {
            if (config) {
                this.updateObservableBitsIfReady(config, data);
            }
            else {
                Khonsole.log('== config not ready');
            }
        }
        else {
            Khonsole.warn("==  obsv Module not ready for data==");
        }
        window['computedFeedbackForForm'][config.graph.toString() + '_128'] = data.computedFeedbackForForm;
        config['firmColors'] = data.computedFeedbackForForm.firmColors;
        this.removeObjects();
        this.addObjects(this.config.entity);
        this.view.controls.enabled = false; // Get rid of 3D behavior
        Khonsole.log('TBD: get rid of view altogether for svg timeline');
    }
    removeObjects() {
        // DO NOT CALL THIS. NEED TO MOVE SVG CODE INTO CreateObjects FIRST.
        /*
        if(this.runtime){
          Khonsole.log("== Remove objects in timeline.");
          this.runtime.dispose();
          this.runtime = null;
          this.observableRuntimeModule = null;
        }
        */
    }
    addObjects(entity) {
        Khonsole.log('addObjects TBD!');
    }
    complexTooltipFromEvent(event) {
        const data = event.data;
        let keysForReduce = Object.keys(data);
        keysForReduce.unshift('');
        let shmooltip = '<div>' +
            keysForReduce.reduce((p, c, idx, srcArray) => {
                if (p == 'event_type' || p == 'event_date (stop_date)' || p == 'event_date_diff' || p == 'rel_date (start_date)') {
                    return '';
                }
                if (c !== 'type') {
                    if (data[c].toString().trim().length > 0) {
                        if (c === 'id') {
                            let evtIndex = ', event#' + event.i; // wasevent.originalIndex
                            p += `<nobr>${c}: ${data[c].toString().toLowerCase()}${evtIndex}</nobr><br />`;
                        }
                        else {
                            p += `<nobr>${c}: ${data[c].toString().toLowerCase()}</nobr><br />`;
                        }
                    }
                }
                return p;
            });
        ;
        if (event.end == null || event.start == event.end) {
            shmooltip += `<hr><nobr>start/end: ${event.start}</nobr><br />`;
        }
        else {
            shmooltip += `<hr><nobr>start: ${event.start} end: ${event.end}</nobr><br />`;
        }
        //shmooltip += `patient row: ${event.barLayoutRowNumber}<br />` ;
        // Put patient vitals here.... tooltip += `<hr><nobr>start: ${event.originalStart} end: ${event.originalEnd}</nobr><br />` ;
        shmooltip += '</div>';
        let complexTooltip = new ComplexTooltipData(EntityTypeEnum.EVENT, event.originalIndex, EntityTypeEnum.PATIENT, event.p, event, shmooltip);
        complexTooltip.id = event.i; /// MJ: Should change from index per patient to unique event id.
        return (complexTooltip);
    }
    formatAttrTooltip(attr, pidIndex, pid) {
        return `Patient:&nbsp;${pid}<br />` +
            attr.prop + ':&nbsp;' + attr.values[pidIndex].label;
    }
    onShowLabels() {
    }
}
