<div class="common-widget">
  <!-- bar for widget, including collapse toggle, title, and settings cog-->
  <table border="0" width="100%">
    <tr>
      <td cdkDragHandle>
        <mat-icon
          (click)="toggleHidden()"
          class="widget-icon"
          *ngIf="getExpanded()"
          >expand_less</mat-icon
        >
        <mat-icon
          (click)="toggleHidden()"
          class="widget-icon"
          *ngIf="!getExpanded()"
          >expand_more</mat-icon
        >
        <!--</button>-->
        <b class="widget-title">{{ getName() }}</b>
      </td>
      <td align="right" *ngIf="model.settings.length > 0">
        <mat-icon
          (click)="clickSettings()"
          class="widget-icon"
          id="settings-icon"
          >settings</mat-icon
        >
      </td>
    </tr>
  </table>

  <!-- Main content -->
  <div
    [id]="getContainerName()"
    #parentOfSvgDiv
    class="common-widget common-widget-svgdiv"
  ></div>

  <app-widget-settings
  *ngIf="showSettings"
  [model]="model"
  (onClose)="showSettings = false"
  ></app-widget-settings>
</div>


