var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { EnrichrBackgroundType, EnrichrGOBackground, EnrichrPathwaysBackground, availableEnrichrBackgrounds, } from "./enrichment-analysis.service.types";
import { Subject, of } from "rxjs";
import { finalize, map, takeUntil } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/snack-bar";
export var EA_API;
(function (EA_API) {
    EA_API["GENE_ONTOLOGY"] = "geneOntology";
})(EA_API || (EA_API = {}));
export class EnrichmentAnalysisService {
    constructor(http, snackbar) {
        this.http = http;
        this.snackbar = snackbar;
        /** key is background. value is a map of term to genes list */
        this.cachedBackgrounds = [
            ...Object.keys(EnrichrGOBackground),
            ...Object.keys(EnrichrPathwaysBackground),
        ].reduce((acc, cur) => (Object.assign({}, acc, { [cur]: new Map() })), {});
        /** key is gene names concatted with a + and the background dataset name */
        this.cachedResults = {};
        this.snackbarConfig = {
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 3000,
        };
    }
    /** Given a list of genes and background Type, get cached results, if any */
    getCachedResult(genes, backgroundType, regulation) {
        const sortedGenesToCache = [...genes].sort();
        const cacheKey = [...sortedGenesToCache, backgroundType, regulation].join("+");
        return this.cachedResults[cacheKey];
    }
    loadAllBackgroundDatasetMappings(backgroundType, cancel = new Subject(), onCanceled = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            // determine which background type to use
            const allBackground = backgroundType === EnrichrBackgroundType.GO
                ? EnrichrGOBackground.ALL
                : EnrichrPathwaysBackground.ALL;
            // get all associated backgrounds of the given type, except for the "all" background
            const backgrounds = availableEnrichrBackgrounds.filter((b) => b.type === backgroundType && b.value !== allBackground);
            // load all mappings in parallel
            const allMappings = yield Promise.all(backgrounds.map((b) => this.loadBackgroundDatasetMapping(b.value, cancel, onCanceled)));
            // combine all mappings into a single flat map
            const flat = new Map();
            allMappings.forEach((mapping) => {
                mapping.forEach((value, key) => {
                    flat.set(key, value);
                });
            });
            return flat;
        });
    }
    loadBackgroundDatasetMapping(dataset, cancel = new Subject(), onCanceled = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (dataset in this.cachedBackgrounds) {
                return this.cachedBackgrounds[dataset];
            }
            if (dataset === EnrichrGOBackground.ALL) {
                return this.loadAllBackgroundDatasetMappings(EnrichrBackgroundType.GO, cancel, onCanceled);
            }
            else if (dataset === EnrichrPathwaysBackground.ALL) {
                return this.loadAllBackgroundDatasetMappings(EnrichrBackgroundType.PATHWAY, cancel, onCanceled);
            }
            const url = `https://maayanlab.cloud/Enrichr/geneSetLibrary?mode=text&libraryName=${dataset}`;
            return this.http
                .get(url, { responseType: "text" })
                .pipe(takeUntil(cancel), finalize(() => {
                EnrichmentAnalysisService.apiAvailability[EA_API.GENE_ONTOLOGY] =
                    true;
                onCanceled();
            }))
                .toPromise()
                .then((tsvData) => {
                // Split the TSV data into rows
                const rows = tsvData.split("\n");
                const termToGenesMapping = new Map();
                rows.map((row) => {
                    const columns = row.split("\t");
                    termToGenesMapping.set(columns[0], columns.slice(1));
                });
                this.cachedBackgrounds[dataset] = termToGenesMapping;
                return termToGenesMapping;
            })
                .catch((error) => {
                console.error("Error fetching data:", error);
                throw error;
            });
        });
    }
    getGenesByTerm(dataset, term, cancel = new Subject(), onCanceled = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const termToGenesMapping = yield this.loadBackgroundDatasetMapping(dataset, cancel, onCanceled);
            const genes = termToGenesMapping.get(term);
            if (!genes) {
                throw new Error(`Term ${term} not found in dataset ${dataset}`);
            }
            return genes;
        });
    }
    /**
     * @description Register a list of genes in the Enrichr database.
     * @param genes list of genes
     * @returns {Observable<{userListId: number, shortId: string}>} the user list id and short id of the newly created list
     *
     */
    createEnrichrGeneList(genes) {
        const ENDPOINT = "https://maayanlab.cloud/Enrichr/addList";
        const description = "Example gene list";
        const formData = new FormData();
        formData.append("list", genes.join("\n"));
        formData.append("description", description);
        // Define headers to specify content type
        const headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(ENDPOINT, formData, { headers: headers });
    }
    /**
     * Special case for when the background type is "All_GO_Terms" or "All_Pathways". This calls `runEnrichrGSEA` for each available background of the requested type
     */
    runEnrichrGSEAAll(genes, backgroundType, regulation) {
        return __awaiter(this, void 0, void 0, function* () {
            const allBackground = backgroundType === EnrichrBackgroundType.GO
                ? EnrichrGOBackground.ALL
                : EnrichrPathwaysBackground.ALL;
            const promises = availableEnrichrBackgrounds
                .filter((b) => b.type === backgroundType && b.value !== allBackground)
                .map((b) => __awaiter(this, void 0, void 0, function* () {
                return this.runEnrichrGSEA(genes, b.value, regulation).then((obs) => obs.toPromise());
            }));
            const allResults = yield Promise.all(promises);
            return of(allResults.reduce((acc, val) => acc.concat(val), []));
        });
    }
    /**
     *
     * @param genes list of genes
     * @param backgroundType Background Dataset type (e.g. "KEGG_2019_Human" or "GO_Biological_Process_2018")
     * @param regulation "up" or "down" for upregulated or downregulated genes (not used in calculation, just for caching lookup purposes)
     * @returns
     */
    runEnrichrGSEA(genes, backgroundType, regulation = "up") {
        return __awaiter(this, void 0, void 0, function* () {
            // create a key for the cache
            const sortedGenesToCache = [...genes].sort();
            const cacheKey = [...sortedGenesToCache, backgroundType, regulation].join("+");
            // see if we have the results cached
            if (cacheKey in this.cachedResults) {
                return of(this.cachedResults[cacheKey]);
            }
            if (backgroundType === EnrichrGOBackground.ALL) {
                return this.runEnrichrGSEAAll(genes, EnrichrBackgroundType.GO, regulation);
            }
            else if (backgroundType === EnrichrPathwaysBackground.ALL) {
                return this.runEnrichrGSEAAll(genes, EnrichrBackgroundType.PATHWAY, regulation);
            }
            // otherwise create a new list and run the analysis
            const { userListId, shortId } = yield this.createEnrichrGeneList(genes).toPromise();
            const ENDPOINT = "https://maayanlab.cloud/Enrichr/enrich";
            const urlParams = {
                userListId: userListId.toString(),
                backgroundType: backgroundType.toString(),
            };
            const full_url = ENDPOINT + "?" + new URLSearchParams(urlParams).toString();
            return this.http.get(full_url).pipe(map((res) => {
                const data = res[backgroundType];
                const results = data.map((d) => {
                    return {
                        index: d[0],
                        term: d[1],
                        pValue: d[2],
                        oddsRatio: d[3],
                        combinedScore: d[4],
                        overlappingGenes: d[5],
                        adjPValue: d[6],
                    };
                });
                // cache the results
                this.cachedResults[cacheKey] = results;
                return results;
            }));
        });
    }
}
// keep track of if a request is being made for each api, for rate limiting
EnrichmentAnalysisService.apiAvailability = {
    [EA_API.GENE_ONTOLOGY]: true,
};
EnrichmentAnalysisService.AVAILABLE_BACKGROUNDS = availableEnrichrBackgrounds;
EnrichmentAnalysisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnrichmentAnalysisService_Factory() { return new EnrichmentAnalysisService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatSnackBar)); }, token: EnrichmentAnalysisService, providedIn: "root" });
