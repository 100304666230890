import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import {
  StepResultType,
  Status,
  StepResponse,
  Job,
  Step,
  JobType,
  StepInput,
  JobOptions,
  LogLevel,
} from "../job.types";
import { v4 as uuidv4 } from "uuid";
import { WorkspaceComponent } from "app/component/workspace/workspace.component";
import { Store } from "@ngrx/store";
import * as fromRoot from "app/reducer/index.reducer";
import { Observable as DexieObservable } from "dexie";
import Dexie, { liveQuery } from "dexie";
import { Khonsole } from "app/khonsole";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { prepareJobForDatabase } from "../jobManager.utils";

export enum DEAStep {
  DESEQ2 = "DESeq2",
}

export type ServerJMPayload = {
  method: string,
  endpoint: string,
  body: any,
  headers: any | null,
  parseJSON: any,
  resultType: StepResultType
}

export type DESeq2PipelinePayload = {
  cohortA: any;
  cohortB: any;
  map: any;
  data: any;
};

// export type LoadStepPayload = {
//   cohortA: any;
//   cohortB: any;
//   map: any;
//   data: any;
// };

// export type PreprocessStepPayload = {
//   expression_data: string;
//   cohort_A: string;
//   cohort_B: string;
// };

// export type Deseq2StepPayload = {
//   counts: string;
//   cohort_data: string;
// };

@Injectable({
  providedIn: "root",
})
export class ServerJobManagerService {
  public static DEFAULT_OPTIONS: JobOptions = {
    type: "generic",
    showSnackbarOnStart: true,
    showSnackbarOnSuccess: true,
    onSuccessSnackbarClick: null,
    showSnackbarOnFail: true,
    onFailSnackbarClick: null,
    saveToDatabase: true,
    freeWorkerOnFinish: true,
    destroyWorkerOnFinish: true, // TODO: reuse workers that have a consistent use (for example, python deseq) by default instead of destroying them
  };

  public static instance: ServerJobManagerService;

  public sessionJobs$: Subject<Job[]> = new BehaviorSubject([]);

  private jobs: Job[] = [];
  private snackbarConfig: MatSnackBarConfig<any> = {
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private _snackbar: MatSnackBar) {
    ServerJobManagerService.instance = this;
    window["reachableServerJMService"] = this;
  }

  async runJob<Payload extends ServerJMPayload>(
    identifier: any,
    name: string,
    initialPayload: Payload,
    steps: StepInput<Payload>[],
    options: Partial<JobOptions> = {}
  ): Promise<StepResponse[]> {
    const stepResponses: StepResponse[] = [];

    const config = await this.store
      .select(fromRoot.getGraphAConfig)
      .pipe(first())
      .toPromise();
    if (config === undefined) {
      throw new Error("runJob failed. Could not find graph config");
    }

    let payloadOrPrevStepResult: Payload | StepResponse["result"] =
      initialPayload;
    const jobId = uuidv4();

    const finalOptions: JobOptions = {
      ...ServerJobManagerService.DEFAULT_OPTIONS,
      ...options,
    };

    Khonsole.log(
      `Running job "${name}" (${jobId}) with options:`,
      finalOptions
    );

    const finalSteps: Step<Payload>[] = steps.map((step, i) => ({
      ...step,
      id: i,
      status: Status.Queued,
      logs: [],
      creationTime: new Date(),
    }));

    this.jobs.push({
      backendIdentifier: identifier,
      id: jobId,
      name: name,
      status: Status.Queued,
      type: finalOptions.type,
      steps: finalSteps,
      creationTime: new Date(),
      databaseName: config.database,
    });

    const job = this.jobs.find(j => j.id === jobId)

    // Show the startup snackbar
    if (finalOptions.showSnackbarOnStart) {
      this._snackbar.open(`Running job: ${name}`, "", {
        duration: 5000,
        ...this.snackbarConfig,
      });
    }

    for (let i = 0; i < finalSteps.length; i++) {
      const step = finalSteps[i];

      // run the step
      const response = await this.runStep<Payload>(
        jobId,
        step,
        payloadOrPrevStepResult
      );
      stepResponses.push(response);

      // if the step failed, do not continue with the rest of the steps
      if (!response.success) {
        if (finalOptions.showSnackbarOnFail) {
          this._snackbar.open(
            `Error running job "${name}". Failed on step "${step.name}"`,
            "Close",
            {
              duration: 5000,
              ...this.snackbarConfig,
            }
          );
        }

        this.finishJob(job.id, Status.Error, finalOptions);
        return stepResponses;
      }

      // if the step succeeded, put the result in the payload for the next step
      payloadOrPrevStepResult = response.result;
    }

    if (finalOptions.showSnackbarOnSuccess) {
      this._snackbar.open(`Finished job "${name}"`, "Close", {
        duration: 5000,
        ...this.snackbarConfig,
      });
    }

    this.finishJob(job.id, Status.Success, finalOptions);

    return stepResponses;
  }

  /**
   *
   * @param workerId The ID of the worker to run the step on
   * @param jobId The ID of the job to run the step on
   * @param step The step to run
   * @param payloadOrPrevStepResult The payload to run the step with. If the step has a `prevResultToPayload` function, this will be ignored.
   * @returns
   */
  private async runStep<Payload extends ServerJMPayload>(
    jobId: number,
    step: Step<Payload>,
    payloadOrPrevStepResult: Payload | StepResponse["result"]
  ): Promise<StepResponse> {
    const job = this.jobs.find(j => j.id === jobId)
    if (job === undefined) {
      throw new Error(`Could not find job with id ${jobId}`)
    }
    // mark the step as running
    step.status = Status.Running;

    if (step.payload) {
      payloadOrPrevStepResult = step.payload;
    } else {
      payloadOrPrevStepResult = step.prevResultToPayload
        ? step.prevResultToPayload(
            payloadOrPrevStepResult as StepResponse["result"]
          )
        : (payloadOrPrevStepResult as Payload);
    }

    let response: StepResponse;
    switch (payloadOrPrevStepResult.method) {
      case 'GET':
        response = await this.get(
          job.backendIdentifier,
          payloadOrPrevStepResult.endpoint,
          payloadOrPrevStepResult.headers,
          payloadOrPrevStepResult.resultType,
          payloadOrPrevStepResult.parseJSON
        ).toPromise()
        break
      case 'POST':
        response = await this.post(
          job.backendIdentifier,
          payloadOrPrevStepResult.endpoint,
          payloadOrPrevStepResult.body,
          payloadOrPrevStepResult.headers,
          payloadOrPrevStepResult.resultType,
          payloadOrPrevStepResult.parseJSON
        ).toPromise()
        break
      default:
        throw new Error(`Unknown/Not implemented method: ${payloadOrPrevStepResult.method}`)
    }

    this.finishStep(step, response);

    return response;
  }

  private finishStep(step: Step<ServerJMPayload>, response: StepResponse) {
    step.finishTime = new Date();
    if (!response.success) {
      step.status = Status.Error;
      step.result = response.result;
      return;
    }

    step.status = Status.Success;

    if (response.result) {
      const typesToParse = [
        StepResultType.Table,
        StepResultType.JSON,
        StepResultType.VOLCANO_DATA,
      ];

      step.result = {
        type: response.result.type,
        // Parse the data if it is a table
        data: typesToParse.includes(response.result.type)
          ? JSON.parse(response.result.data)
          : response.result.data,
      };
    }

    step.logs.push({
      msg: "Success",
      level: LogLevel.Info,
    });

    this.sessionJobs$.next(this.jobs)
  }

  // /**
  //  *
  //  * @param payload The payload to kick off the Deseq2 job with
  //  * @param databaseName The database to save the finished job to
  //  * @param analysisName a human-readable name of the analysis.
  //  * @returns The ID of the job that was kicked off
  //  */
  // public runDiffExpJob(
  //   payload: DESeq2PipelinePayload,
  //   databaseName: string,
  //   analysisName: string
  // ): string {
  //   const jobId = uuidv4();
  //   this.jobs.push({
  //     workerId: jobId,
  //     id: jobId,
  //     name: analysisName,
  //     status: Status.Queued,
  //     type: "differentialExpression",
  //     steps: [],
  //     creationTime: new Date(),
  //     databaseName,
  //   });

  //   // queue up the steps
  //   const stepId = this.createStep(jobId, DEAStep.DESEQ2);
  //   // const preprocessStepId = this.createStep(jobId, DEAStep.PREPROCESS);
  //   // const deseq2StepId = this.createStep(jobId, DEAStep.DESEQ2);

  //   /** @returns should we continue (job was not cancelled and the step didn't fail) */
  //   const stepCleanup = (stepId: number, response: StepResponse): boolean => {
  //     // early exit if the job was cancelled
  //     if (this.getJobStatus(jobId) === Status.Cancelled) {
  //       return false;
  //     }

  //     // Finish the step. This will handle if the step failed
  //     this.finishStep(jobId, stepId, response);
  //     return response.success;
  //   };

  //   const runStep = <T>(
  //     step: (payload: T) => Observable<StepResponse>,
  //     stepId: number,
  //     payload: T
  //   ): Observable<{
  //     moveToNextStep: boolean;
  //     response: StepResponse;
  //   }> => {
  //     this.updateStepStatus(jobId, stepId, Status.Running);
  //     return step(payload).pipe(
  //       map((res: StepResponse) => {
  //         const moveToNextStep = stepCleanup(stepId, res);
  //         return {
  //           moveToNextStep,
  //           response: res,
  //         };
  //       })
  //     );
  //   };

  //   // mark the job as running
  //   this.updateJob(jobId, {
  //     ...this.jobs.find((j) => j.id === jobId),
  //     status: Status.Running,
  //   });

  //   // run the steps
  //   runStep(this.pipeline.bind(this), stepId, payload).subscribe(
  //     ({ moveToNextStep, response }) => {
  //       if (!moveToNextStep) {
  //         return;
  //       }
  //       this.finishJob(jobId, Status.Success);
  //     }
  //   );
  //   // runStep(this.load.bind(this), loadStepId, payload).subscribe(
  //   //   ({ moveToNextStep, response }) => {
  //   //     if (!moveToNextStep) {
  //   //       return;
  //   //     }
  //   //     runStep(
  //   //       this.preprocess.bind(this),
  //   //       preprocessStepId,
  //   //       response.result.data
  //   //     ).subscribe(({ moveToNextStep, response }) => {
  //   //       if (!moveToNextStep) {
  //   //         return;
  //   //       }
  //   //       runStep(this._deseq2.bind(this), deseq2StepId, response.result.data).subscribe(
  //   //         ({ moveToNextStep, response }) => {
  //   //           if (!moveToNextStep) {
  //   //             return;
  //   //           }
  //   //           this.finishJob(jobId, Status.Success)
  //   //         }
  //   //       );
  //   //     });
  //   //   }
  //   // );
  //   return jobId;
  // }

  public cancelJob(job: Job) {
    if (job) {
      // mark all steps as cancelled
      job.steps.forEach((step) => {
        step.status = Status.Cancelled;
      });

      // mark the job as cancelled
      this.finishJob(job.id, Status.Cancelled);
      return { success: true };
    }

    return {
      success: false,
      error: `Could not cancel job with id ${job.id}. Not found.`,
    };
  }

  public deleteJob(job: Job) {
    if (this.jobs.find((job) => job.id === job.id) !== undefined) {
      this.jobs = this.jobs.filter((job) => job.id !== job.id);
      this.sessionJobs$.next(this.jobs);
      return { success: true };
    }

    // If the job is in the database, delete it from the database
    let found = false;
    this.store
      .select(fromRoot.getGraphAConfig)
      .pipe(first())
      .subscribe((graphAConfig) => {
        console.log("deleting job from database", job, graphAConfig);
        WorkspaceComponent.instance.delJob({
          database: graphAConfig.database,
          job: job,
        });
        Khonsole.log(`Deleted job ${job.id} from database`);
        found = true;
        return;
      });
    if (found) {
      return { success: true };
    }

    return {
      success: false,
      error: `Could not delete Job ${job.id}. Not found`,
    };
  }

  private finishJob(jobId: number, status: Status, options: JobOptions = ServerJobManagerService.DEFAULT_OPTIONS) {

    const job = this.jobs.find((j) => j.id === jobId);
    if (!job) {
      console.error("Could not find job with ID", jobId);
      return;
    }
    this.updateJob(jobId, {
      ...job,
      status: status,
      finishTime: new Date(),
    });

    let finalJob = this.jobs.find((j) => j.id === jobId);

    if (options.saveToDatabase) {
      finalJob = prepareJobForDatabase(finalJob);
      WorkspaceComponent.instance.addJob({
        database: finalJob.databaseName,
        job: finalJob,
      });

      // remove the job from the session
      this.jobs = this.jobs.filter((job) => job.id !== jobId);
      this.sessionJobs$.next(this.jobs);
    }
  }

  /**
   * Get jobs from the database. These are jobs that were run in past sessions.
   * @param type The type of job to get
   * @param datasetName The name of the dataset to get jobs from
   * @returns Jobs of the given type from the database for this dataset
   */
  dbJobsOfType$(type: JobType): DexieObservable<Job[]> {
    return liveQuery(async () => {
      const config = await this.store
        .select(fromRoot.getGraphAConfig)
        .pipe(first())
        .toPromise();

      const db = await new Dexie("notitia-" + config.database).open();

      return (
        await db
          .table("jobs")
          .where("type")
          .equals(type)
          .and((job: Job) => job.databaseName === config.database)
          .toArray()
      ).map((j) => ({ ...j, workerId: undefined }));
    });
  }

  async sessionJobsOfType$(type: JobType): Promise<Observable<Job[]>> {
    const config = await this.store
        .select(fromRoot.getGraphAConfig)
        .pipe(first())
        .toPromise();


    return this.sessionJobs$.pipe(
      map(jobs => jobs.filter(j => (j.type === type) && (j.databaseName === config.database)))
    );
  }

  /**
   * Updates the list of jobs with the given job, replacing any existing job with the same ID. This will also cause an emission of jobs$.
   * @param jobId the ID of the job to update
   * @param job the updated job information
   */
  private updateJob(jobId: number, job: Job) {
    this.jobs = this.jobs.map((j) => {
      if (j.id === jobId) {
        return job;
      }
      return j;
    });

    this.sessionJobs$.next(this.jobs);
  }

  private updateStepStatus(jobId: number, stepId: number, status: Status) {
    const currJob = this.jobs.find((j) => j.id === jobId);
    if (!currJob) {
      console.error("Could not find job with ID", jobId);
      return;
    }
    const currStep = currJob.steps.find((s) => s.id === stepId);
    if (!currStep) {
      console.error("Could not find step with ID", stepId);
      return;
    }
    this.updateJob(jobId, {
      ...currJob,
      steps: currJob.steps.map((s) => {
        if (s.id === stepId) {
          return {
            ...s,
            status: status,
          };
        }
        return s;
      }),
    });
  }

  private getJobStatus(jobId: number): Status {
    const job = this.jobs.find((j) => j.id === jobId);
    if (!job) {
      console.error("Could not find job with ID", jobId);
      return Status.Error;
    }
    return job.status;
  }

  private createStep(jobId: number, type: DEAStep): number {
    const stepId = uuidv4();
    this.jobs = this.jobs.map((j) => {
      if (j.id !== jobId) {
        return j;
      }
      return {
        ...j,
        steps: [
          ...j.steps,
          {
            id: stepId,
            name: type.toString(),
            logs: [],
            status: Status.Queued,
            type: type,
            creationTime: new Date(),
          } as Step<any>,
        ],
      };
    });
    return stepId;
  }

  // private finishStep(jobId: number, stepId: number, response: StepResponse) {
  //   const currJob = this.jobs.find((j) => j.id === jobId);
  //   if (!currJob) {
  //     console.error("Could not find job with ID", jobId);
  //     return;
  //   }
  //   const currStep = currJob.steps.find((s) => s.id === stepId);
  //   if (!currStep) {
  //     console.error("Could not find step with ID", stepId);
  //     return;
  //   }
  //   this.updateJob(jobId, {
  //     ...currJob,
  //     steps: [
  //       ...currJob.steps.filter((s) => s.id !== stepId),
  //       {
  //         ...currStep,
  //         status: response.success ? Status.Success : Status.Error,
  //         finishTime: new Date(),
  //         result: response.result,
  //       },

  //       // sort the steps by their finish time
  //     ].sort((a, b) => {
  //       const aFinishTime =
  //         a.finishTime !== undefined ? a.finishTime.getTime() : -1;
  //       const bFinishTime =
  //         b.finishTime !== undefined ? b.finishTime.getTime() : -1;
  //       return aFinishTime - bFinishTime;
  //     }),
  //   });

  //   if (!response.success) {
  //     this.finishJob(jobId, Status.Error);
  //   }
  // }

  private post(baseUrl: string, endpoint: string, body: any, headers: any = {}, resultType: StepResultType, parseJSON = true) {
    return this.http.post(`${baseUrl}${endpoint}`, body, headers).pipe(
      map((response: any) => {
        const res: StepResponse = {
          success: true,
          result: {
            type: resultType,
            data: parseJSON ? JSON.parse(response.data) : response.data,
          },
        };
        return res;
      }),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }

  private get(baseUrl: string, endpoint: string, headers: any = {}, resultType: StepResultType, parseJSON = true) {
    return this.http.get(`${baseUrl}${endpoint}`, headers).pipe(
      map((response: any) => {
        const res: StepResponse = {
          success: true,
          result: {
            type: resultType,
            data: parseJSON ? JSON.parse(response.data) : response.data,
          },
        };
        return res;
      }),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }

  // private pipeline(payload: DESeq2PipelinePayload): Observable<StepResponse> {
  //   return this.http.post(`${this.baseUrl}/pipeline`, payload).pipe(
  //     map((response: any) => {
  //       const res: StepResponse = {
  //         success: true,
  //         result: {
  //           type: StepResultType.VOLCANO_DATA,
  //           data: JSON.parse(response.data),
  //         },
  //       };
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return this.handleError(error);
  //     })
  //   );
  // }

  // private load(payload: LoadStepPayload): Observable<StepResponse> {
  //   return this.http.post(`${this.baseUrl}/load`, payload).pipe(
  //     map((response: any) => {
  //       const res: StepResponse = {
  //         success: true,
  //         result: {
  //           type: StepResultType.JSON,
  //           data: response.data,
  //         },
  //       };
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return this.handleError(error);
  //     })
  //   );
  // }

  // private preprocess(payload: PreprocessStepPayload): Observable<StepResponse> {
  //   return this.http.post(`${this.baseUrl}/preprocess`, payload).pipe(
  //     map((response: any) => {
  //       const res: StepResponse = {
  //         success: true,
  //         result: {
  //           type: StepResultType.JSON,
  //           data: response.data,
  //         },
  //       };
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return this.handleError(error);
  //     })
  //   );
  // }

  // private _deseq2(payload: Deseq2StepPayload): Observable<StepResponse> {
  //   return this.http.post(`${this.baseUrl}/deseq2`, payload).pipe(
  //     map((response: any) => {
  //       const res: StepResponse = {
  //         success: true,
  //         result: {
  //           type: StepResultType.VOLCANO_DATA,
  //           // need to parse the final step, since it is not being passed back to the server,
  //           // but rather being used as JSON in the Angular app
  //           data: JSON.parse(response.data),
  //         },
  //       };
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return this.handleError(error);
  //     })
  //   );
  // }

  private handleError(error: any): Observable<StepResponse> {
    return new Observable((observer) => {
      observer.next({
        success: false,
        result: {
          type: StepResultType.Error,
          data: error,
        },
      });
      observer.complete();
    });
  }
}
