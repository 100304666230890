/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./common-side-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/menu";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./menu-item.component.ngfactory";
import * as i12 from "./menu-item.component";
import * as i13 from "@angular/router";
import * as i14 from "@angular/common";
import * as i15 from "./cohorts-widget.component.ngfactory";
import * as i16 from "./cohorts-widget.component";
import * as i17 from "../../../service/data.service";
import * as i18 from "@angular/material/snack-bar";
import * as i19 from "./survival-widget.component.ngfactory";
import * as i20 from "./survival-widget.component";
import * as i21 from "./copynumber-widget.component.ngfactory";
import * as i22 from "./copynumber-widget.component";
import * as i23 from "./diffexp-widget.component.ngfactory";
import * as i24 from "./diffexp-widget.component";
import * as i25 from "./common-side-panel.component";
var styles_CommonSidePanelComponent = [i0.styles];
var RenderType_CommonSidePanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CommonSidePanelComponent, data: {} });
export { RenderType_CommonSidePanelComponent as RenderType_CommonSidePanelComponent };
function View_CommonSidePanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 6, "button", [["aria-haspopup", "true"], ["class", "mat-menu-trigger"], ["color", "primary"], ["mat-raised-button", ""], ["style", "background-color: red"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(3, 1196032, null, 0, i6.MatMenuTrigger, [i7.Overlay, i1.ElementRef, i1.ViewContainerRef, i6.MAT_MENU_SCROLL_STRATEGY, [2, i6.MatMenu], [8, null], [2, i8.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["expand_more"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-menu-item", [], null, null, null, i11.View_MenuItemComponent_0, i11.RenderType_MenuItemComponent)), i1.ɵdid(9, 49152, [["menu", 4]], 0, i12.MenuItemComponent, [i13.Router], { items: [0, "items"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 9).childMenu; _ck(_v, 3, 0, currVal_4); _ck(_v, 6, 0); var currVal_8 = _v.parent.context.$implicit.children; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 3).menuOpen || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.parent.context.$implicit.displayName; _ck(_v, 4, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 6).inline; var currVal_7 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_6, currVal_7); }); }
function View_CommonSidePanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_3)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.children && (_v.context.$implicit.children.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CommonSidePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "basic-container"], ["style", "display:flex; justify-content:center; padding: 8px 0;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_2)), i1.ɵdid(3, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getNavItems(); _ck(_v, 3, 0, currVal_0); }, null); }
function View_CommonSidePanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["HIDDEN_cdkDrag", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cohorts-widget", [["id", "myId4"]], null, null, null, i15.View_CohortsWidgetComponent_0, i15.RenderType_CohortsWidgetComponent)), i1.ɵdid(2, 12828672, [[5, 4], ["svgContainer_Html", 4], ["myId", 4]], 0, i16.CohortsWidgetComponent, [i1.Renderer2, i1.ChangeDetectorRef, i17.DataService, i18.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CommonSidePanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["HIDDEN_cdkDrag", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "survival-widget", [["id", "myId1"]], null, null, null, i19.View_SurvivalWidgetComponent_0, i19.RenderType_SurvivalWidgetComponent)), i1.ɵdid(2, 12828672, [[2, 4], ["svgContainer_Survival", 4], ["myId", 4]], 0, i20.SurvivalWidgetComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CommonSidePanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["HIDDEN_cdkDrag", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "copynumber-widget", [["id", "myId2"]], null, null, null, i21.View_CopynumberWidgetComponent_0, i21.RenderType_CopynumberWidgetComponent)), i1.ɵdid(2, 12828672, [[3, 4], ["svgContainer_Copy_Number", 4], ["myId", 4]], 0, i22.CopynumberWidgetComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CommonSidePanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["HIDDEN_cdkDrag", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "diffexp-widget", [["id", "myId3"]], null, null, null, i23.View_DiffexpWidgetComponent_0, i23.RenderType_DiffexpWidgetComponent)), i1.ɵdid(2, 12828672, [[4, 4], ["svgContainer_Differential_Expression", 4], ["myId", 4]], 0, i24.DiffexpWidgetComponent, [i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_CommonSidePanelComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { survivalSvgContainer: 0 }), i1.ɵqud(671088640, 2, { newSurvivalWidget: 0 }), i1.ɵqud(671088640, 3, { newCopynumberWidget: 0 }), i1.ɵqud(671088640, 4, { newDiffexpWidget: 0 }), i1.ɵqud(671088640, 5, { newCohortsWidget: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["id", "commonSidePanelContainerHeader"], ["style", "display:none"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_1)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["HIDDEN_cdkDropList", ""], ["class", "example-list"]], null, [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.widgetDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_4)), i1.ɵdid(11, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_5)), i1.ɵdid(13, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_6)), i1.ɵdid(15, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonSidePanelComponent_7)), i1.ɵdid(17, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasMenuItems() && (_co.navItems.length > 0)); _ck(_v, 8, 0, currVal_0); var currVal_1 = true; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.datasetDescription.hasSurvival; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.datasetDescription.hasCopyNumber; _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.shouldUseDiffExp(); _ck(_v, 17, 0, currVal_4); }, null); }
export function View_CommonSidePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-common-side-panel", [], null, null, null, View_CommonSidePanelComponent_0, RenderType_CommonSidePanelComponent)), i1.ɵdid(1, 4898816, null, 0, i25.CommonSidePanelComponent, [i1.ChangeDetectorRef, i17.DataService], null, null)], null, null); }
var CommonSidePanelComponentNgFactory = i1.ɵccf("app-workspace-common-side-panel", i25.CommonSidePanelComponent, View_CommonSidePanelComponent_Host_0, { datasetDescription: "datasetDescription", config: "config", legends: "legends" }, { hide: "hide" }, []);
export { CommonSidePanelComponentNgFactory as CommonSidePanelComponentNgFactory };
