import { Khonsole } from 'app/khonsole';
import { DataService } from 'app/service/data.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ChangeDetectorRef
} from '@angular/core';
import { PanelEnum } from '../../../model/enum.model';
import { DataHubService } from './../../../service/datahub.service';
import { MatTabChangeEvent } from '@angular/material';
import tippy from 'tippy.js';
import introJs from 'intro.js';
import { WorkspaceComponent } from '../workspace.component';
import { OncoData } from 'app/oncoData';
import { PublicDataset } from 'app/model/public-dataset.model';

@Component({
  selector: 'app-workspace-file-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './file-panel.component.html',
  styleUrls: ['./file-panel.component.scss']
})
export class FilePanelComponent {
  // todo: This needs to be revisited post launch.  Should be doing this in redux state.
  myDatasets: Array<any> = [];
  @Output()
  uploadExcel = new EventEmitter<any>();
  @Output()
  loadPublic = new EventEmitter<PublicDataset>();
  @Output()
  loadPrivate = new EventEmitter<{ bucket: string; env: string }>();
  @Output()
  hide = new EventEmitter<any>();
  @Output()
  showPanel = new EventEmitter<PanelEnum>();
  datasets = [];
  filter = '';



  public goToFeaturedDataset() {
    let project = "bulkrnaseqbrainumap";
    WorkspaceComponent.instance.redirectToDataset(project)
  }

  public onSelectedTabChange(e: MatTabChangeEvent): void {
    switch (e.tab.textLabel) {
      case 'Genomic Data Commons':
        this.setDatasetsGDC();
        return;
      case 'HIDDEN Memorial Sloan Kettering':
        this.setDatasetsCBio();
        return;
      // tslint:disable-next-line:quotemark
      case "HIDDEN Children's Hospital of Philadelphia":
        this.setDatasetsChop();
        return;
      case 'Private Datasets':
        this.setDatasetsPrivate();
        setTimeout(v => {
          tippy('.private-dataset-list-item', {
            theme: 'light-border',
            arrow: true,
            placement: "right" //,
            // appendTo: document.body,
            // interactive: true
          });
          }, 100);
        return;
        break;
    }
  }

  ngAfterViewInit(): void {
    // Khonsole.log('going to look for id dataset_link_Glioma');
    // let gliomaEl:HTMLElement = (document.getElementById("dataset_link_Glioma") as unknown) as HTMLElement;
    // gliomaEl.setAttribute("data-hint", "This interesting data set combines Single Nucleotide Variant and Copy Number Variant, which nicely clusters the gliomas by diagnosis. Click it to explore.");
    // gliomaEl.setAttribute("data-hintPosition", "bottom-right");
    // window.setTimeout(this.setHints, 300);
  }

  setHints() {
    introJs().addHints();

  }

  loadTinySet() {
    let project = "tinytest";
    WorkspaceComponent.instance.redirectToDataset(project)

    // let dataset = {
    //   img: "DSbreast",
    //   name: "TinyTest",
    //   prefix: "tcga_tinytest_",
    //   src: "tcga",
    //   uid: "tcga_tinytest"
    // }
    // this.loadPublic.emit(dataset)
  }

  setDatasetsGDC(): void {
    this.datasets = OncoData.instance.gdcDatasets;
    this.cd.markForCheck();
  }
  setDatasetsCBio(): void {
    this.datasets = [];
    Khonsole.log('Inside setDatasetsCBio.');
    this.dataService.getPublicDatasets().then(result => {
      this.datasets = result
        .map(v => {
          Khonsole.log('MSK: ' + JSON.stringify(v));
          return { name: v.content.name, img: 'DScancer', src: v.project.split('|')[0], uid: v.project.split('|')[0] };
        })
        .filter(v => v.name.toLowerCase().indexOf('kim') === -1)
        .filter(v => v.name.toLowerCase().indexOf('lab') === -1)
        .filter(v => v.name.toLowerCase().indexOf('private') === -1)
        .filter(v => v.name.toLowerCase().indexOf('chop') === -1);
      this.cd.markForCheck();
    });
  }
  setDatasetsChop(): void {
    this.datasets = [];
    this.cd.markForCheck();
  }

  setDatasetsPrivate(): void {
    this.datasets = [];
    this.cd.markForCheck();

  }

  loadGDCDataset(dataset: PublicDataset): void {
    console.log("Loading gdc dataset", dataset)
    window.location.href = "#gdc_" + dataset.uid;
    this.loadPublic.emit(dataset)
  }

  uploadExcelClick(): void {
    this.uploadExcel.emit();
  }

  addDataset(): void {
    this.showPanel.emit(PanelEnum.UPLOAD);
  }
  // onLogout(): void {
  //   // this.datahubService.logout();
  // }

  closeClick(): void {
    this.hide.emit();
  }
  constructor(public dataService: DataService, public cd: ChangeDetectorRef) {
    this.setDatasetsGDC();
  }
}
