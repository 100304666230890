<div class="panel">
  <div>
    <div class='modalClose' (click)='closeClick()'>
    </div>
    <h1 class='panel-header'>Feedback</h1>
    <h2 class='panel-header-desc'>Contact the Oncoscape Team</h2>
  </div>
  <div class="feedback-container">
    <mat-form-field>
      <input matInput placeholder="Name" autocomplete='off' [(value)]='fbName'>
    </mat-form-field>
    <!-- fix email -->
    <!-- <mat-form-field>
      <input matInput placeholder="Email" autocomplete='off'>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Institution" autocomplete='off'>
    </mat-form-field> -->
    <mat-form-field>
      <mat-select placeholder="Choose One" [(value)]='fbType'>
        <mat-option value="option">General Inquiry</mat-option>
        <mat-option value="option">Developer Collaboration</mat-option>
        <mat-option value="option">Research Collaboration</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Comments" autocomplete='off' [(value)]='fbComments'></textarea>
    </mat-form-field>
  </div>
  <a class='button' mat-raised-button>Send Message!</a>
</div>
