<!-- widget-settings.component.html -->
<div *ngIf="model" class="widget-settings">
  <div style="display: flex; align-items: center; justify-content: stretch;">
    <button mat-button (click)="onClose.emit()" id="collapse-btn">
      <mat-icon style="font-size: 10px">arrow_forward_ios</mat-icon>
    </button>
    <p style="font-weight: bold; margin-left: -10px">Settings</p>
  </div>
  <div *ngFor="let setting of model.settings">
    <label>{{ setting.name }}</label>
    <div [ngSwitch]="setting.fieldType">
      <mat-slider
        *ngSwitchCase="'range'"
        [(ngModel)]="setting.value"
        (change)="onSettingChange(setting, setting.value)"
        [min]="setting.options.min"
        [max]="setting.options.max"
        [step]="setting.options.step"
        [thumbLabel]="setting.options.showThumbLabel"
        [displayWith]="formatRangeThumb"
      >
      </mat-slider>
      <input
        *ngSwitchCase="'number'"
        type="number"
        [(ngModel)]="setting.value"
        (change)="onSettingChange(setting, setting.value)"
      />
      <input
        *ngSwitchCase="'text'"
        type="text"
        [(ngModel)]="setting.value"
        (change)="onSettingChange(setting, setting.value)"
      />
      <input
        *ngSwitchCase="'color'"
        type="color"
        [(ngModel)]="setting.value"
        (change)="onSettingChange(setting, setting.value)"
      />
      <input
        *ngSwitchCase="'boolean'"
        type="checkbox"
        [(ngModel)]="setting.value"
        (change)="onSettingChange(setting, setting.value)"
      />
      <div *ngSwitchDefault>Unknown setting type "{{seting.fieldType}}"</div>
  </div>
</div>
