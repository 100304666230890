// widget-settings.component.ts
import { Component, Input, AfterViewInit, OnInit, Output, EventEmitter } from '@angular/core';
import { WidgetModel, WidgetSetting, WidgetSettingsFieldType } from "../widgetmodel"
import { Khonsole } from 'app/khonsole';

@Component({
  selector: 'app-widget-settings',
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.scss'],
})
export class WidgetSettingsComponent {
  @Input() model: WidgetModel<any>;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public onSettingChange(setting: WidgetSetting<any, any, any>, value: any): void {
    Khonsole.log('Setting changed: ' + setting.name + ' to ' + value);

    // update the value internally
    this.model.settings.find(s => s.name == setting.name).value = value;

    // pass to the onChange callback
    setting.onChange(value);
  }

  // Helper method to check if the setting is of type 'boolean'
  public isBooleanFieldType(fieldType: WidgetSettingsFieldType): boolean {
    return fieldType === WidgetSettingsFieldType.BOOLEAN;
  }

  formatRangeThumb(value: number): string {
    return value.toFixed(0).toString()
  }

}
