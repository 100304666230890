var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Khonsole } from 'app/khonsole';
import { FormBuilder } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { GraphConfig } from 'app/model/graph-config.model';
import { SelectionToolConfig } from 'app/model/selection-config.model';
import { OncoData } from 'app/oncoData';
import { SelectionModifiers } from 'app/component/visualization/visualization.abstract.scatter.component';
export class BehaviorPanelComponent {
    constructor(fb, cd) {
        this.fb = fb;
        this.cd = cd;
        this.clearSelection = new EventEmitter();
        this.cohortSearch = '';
        this.cohortName = '';
        this.selectionTypes = [];
        this.form = this.fb.group({
            navigation: [],
            selection: []
        });
    }
    onClearSelection() {
        this.clearSelection.emit(this._config);
    }
    // onSearch(): void {}
    // onSaveSelection(): void {
    //   const cohortName = this.cohortName;
    //   this.cohortName = '';
    //   this.saveSelection.emit({ config: this._config, name: cohortName });
    // }
    onSelectByIds() {
        let idsText = prompt("Enter patient IDs to select, separated by commas or spaces.");
        if (idsText) {
            idsText = idsText.trim().replace(/[, ]+/g, ",");
            let ids = idsText.split(',').map(v => v.trim());
            let mouseEvent = event;
            let selectionModifiers = new SelectionModifiers();
            selectionModifiers.extend = mouseEvent.shiftKey;
            selectionModifiers.inverse = mouseEvent.altKey;
            let patientIds = ids;
            window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                yield OncoData.instance.currentCommonSidePanel.setSelectionPatientIds({ patientIds, existingCohort: "Legend", selectionModifiers, graphConfig: this._config, ignorePatientIdCase: true });
                Khonsole.log('Was from the select-by-id dialog, not form Legend. Clean up later. TBD. TODO.');
            }), 20);
            OncoData.instance.currentCommonSidePanel.drawWidgets();
        }
    }
    set config(value) {
        if (value === null) {
            return;
        }
        this._config = value;
        this.selectionTypes = SelectionToolConfig.getToolOptions(this._config);
        this.cd.markForCheck();
    }
    set selectionToolConfig(value) {
        this._selectionToolConfig = value;
        this.cd.markForCheck();
    }
    get selectionToolConfig() {
        return this._selectionToolConfig;
    }
    byLbl(p1, p2) {
        if (p2 === null) {
            return false;
        }
        return p1.label === p2.label;
    }
    ngAfterViewInit() { }
}
