var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChangeDetectorRef, OnDestroy, AfterViewInit, ViewRef, } from "@angular/core";
import { MatDialog, MatTable } from "@angular/material";
import { LogLevel } from "app/service/jobManager/job.types";
import { CancelJobDialogComponent } from "./cancel-job-dialog/cancel-job-dialog.component";
import { DeleteJobDialogComponent } from "./delete-job-dialog/delete-job-dialog.component";
import { Subject } from "rxjs";
export class JobTableComponent {
    constructor(cd, dialog) {
        this.cd = cd;
        this.dialog = dialog;
        // list of jobs, along with the workerId that is running them
        this.jobs = [];
        this.columnsToDisplay = ["name", "finishTime", "status", "cancelOrDelete"];
        this.columnsToDisplayWithExpand = [...this.columnsToDisplay, "expand"];
        this.ngUnsubscribe = new Subject();
    }
    onJobCancelOpenDialog(jobId) {
        const job = this.jobs.find((job) => job.id === jobId);
        const dialogRef = this.dialog.open(CancelJobDialogComponent, {
            data: { jobName: job.name },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const res = this.jobManagerService.cancelJob(job);
                // display error message if failed to cancel job
                if (!res.success) {
                    console.error(res.error);
                    alert("Failed to cancel job.");
                    return;
                }
                // post-cancel cleanup
                console.warn(`Job ${jobId} cancelled`);
            }
        });
    }
    onJobDeleteOpenDialog(jobId) {
        const job = this.jobs.find((job) => job.id === jobId);
        const dialogRef = this.dialog.open(DeleteJobDialogComponent, {
            data: { jobName: job.name },
        });
        dialogRef.afterClosed().subscribe((result) => __awaiter(this, void 0, void 0, function* () {
            if (result) {
                // delete the job
                const res = yield this.jobManagerService.deleteJob(job);
                // display error message if failed to delete job
                if (!res.success) {
                    console.error(res.error);
                    alert("Failed to delete job.");
                    return;
                }
                // post-delete cleanup
                console.warn(`Job ${jobId} deleted`);
                // this.defensiveDetectChanges();
            }
        }));
    }
    toggleExpandedRow(rowElement) {
        this.expandedElement = this.elementsAreEqual(rowElement, this.expandedElement)
            ? null
            : rowElement;
    }
    elementsAreEqual(e1, e2) {
        if (e1 === null || e2 === null) {
            return false;
        }
        return (this.expandedElement &&
            e1.id === e2.id &&
            e1.backendIdentifier === e2.backendIdentifier);
    }
    getLogColor(level) {
        switch (level) {
            case LogLevel.Info:
                return "black";
            case LogLevel.Warn:
                return "orange";
            case LogLevel.Error:
                return "red";
            default:
                return "black";
        }
    }
    canCancel(job) {
        return job.status === "running" || job.status === "queued";
    }
    canDelete(job) {
        return (job.status === "success" ||
            job.status === "cancelled" ||
            job.status === "error");
    }
    /**
     * We need to wrap detectChanges in defensive logic to check if the view has been destroyed (the panel has been closed)
     */
    defensiveDetectChanges() {
        if (this.cd !== null &&
            this.cd !== undefined &&
            !this.cd.destroyed) {
            this.cd.detectChanges();
        }
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    updateJobs(updatedJobs, source) {
        // combine the new jobs with the old jobs, replacing jobs with matching ids with the new jobs
        const updatedJobsWithSource = updatedJobs.map((newJob) => (Object.assign({}, newJob, { source })));
        // combine the new jobs with the old jobs, discarding any old jobs of the same source
        this.jobs = [
            ...updatedJobsWithSource,
            ...this.jobs.filter((oldJob) => oldJob.source !== source),
        ]
            // sort jobs by creation time
            .sort((a, b) => {
            if (a.creationTime < b.creationTime) {
                return 1;
            }
            else if (a.creationTime > b.creationTime) {
                return -1;
            }
            else {
                return 0;
            }
        });
        this.defensiveDetectChanges();
    }
    ngAfterViewInit() {
        this.jobManagerService.dbJobsOfType$(this.jobType).subscribe((jobs) => {
            console.log(`Database jobs have updated`, jobs);
            // Khonsole.log(`Database jobs of type ${this.jobType} have updated`, jobs);
            // backend identifiers get lost when a job is stored in the database, so we need to add them back in as undefined
            const jobsWithUndefinedBackend = jobs.map((job) => {
                return Object.assign({}, job, { backendIdentifier: undefined });
            });
            this.updateJobs(jobsWithUndefinedBackend, "db");
        });
        this.jobManagerService
            .sessionJobsOfType$(this.jobType)
            .then((observerable) => {
            observerable.subscribe((jobs) => {
                console.log(`Session jobs have updated`, jobs);
                this.updateJobs(jobs, "session");
            });
        });
    }
}
