var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AfterViewInit, ChangeDetectorRef, OnInit, EventEmitter } from "@angular/core";
// import { OncoData } from "app/oncoData";
import * as d3 from "d3";
import { VolcanoGeneTableComponent } from "./volcano-gene-table/volcano-gene-table.component";
import { VolcanoSelectionType, VolcanoSelectionTrigger, } from "./volcano.component.types";
import { VolcanoInteractivityMode } from "./volcano.component.types";
import { GOTermVolcanoSelectionConfig, createEmptyVolcanoSelection } from "./volcanoSelectionTypesConfig";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { EnrichmentAnalysisService, } from "app/service/enrichment-analysis/enrichment-analysis.service";
import { EnrichmentAnalysisComponent } from "../enrichment-analysis/enrichment-analysis.component";
import { VolcanoLayoutManagerService, VolcanoTab } from 'app/service/volcano-layout-manager.service';
import { DownloadPlotComponent } from '../download-plot/download-plot.component';
import { OncoData } from "app/oncoData";
import { VisualizationEnum } from "app/model/enum.model";
import * as THREE from "three";
export class VolcanoComponent {
    // #endregion
    constructor(cd, ea, layout) {
        this.cd = cd;
        this.ea = ea;
        this.layout = layout;
        this._plotOnly = false;
        this.genesToSelectByDefault = [];
        this.tabs = [VolcanoTab.Table, VolcanoTab.EnrichmentAnalysis];
        this.selectByStatsFormCollapsed = false;
        this._scatterThumbnailVisible = false;
        this.scatterThumbnailData = new EventEmitter();
        /** When the go term selection is only temporary (user hovered over EA but didn't click), we dismiss the buttons */
        this.dismissGOTermSelectionButtons = false;
        this.selections$ = new BehaviorSubject([
            createEmptyVolcanoSelection(VolcanoSelectionType.Standard),
            createEmptyVolcanoSelection(VolcanoSelectionType.GOTerm),
        ]);
        this.selections = [
            createEmptyVolcanoSelection(VolcanoSelectionType.Standard),
            createEmptyVolcanoSelection(VolcanoSelectionType.GOTerm),
        ];
        this._activeSelectionType = VolcanoSelectionType.Standard;
        // Change the default mode here
        this._mode = VolcanoInteractivityMode.SELECT;
        this._scatterThumbnailBtnTooltip = "";
        this.svgId = "";
        this.isDragging = false;
        this.artificallyHoldingShift = false;
        // this is the most recent selected point, if any.
        this.mostRecentSelectedPoint = null;
        this._points = [];
        // Keep track of how points change during a drag, so we can reverse when the rectangle moves off of them
        // These will reset on mouseup
        this.pointsNewToThisDrag = [];
        this.pointsDeletedThisDrag = [];
        this._showPlotControls = true;
        this._showSelectByStatsForm = true;
        this._showSidebar = true;
        this._selectByStatsForm = {
            nlogpadj: 1.301,
            log2FoldChange: 0.58,
            padj: 0.05,
            fc: 1.5,
            downregulatedColor: "#2FCF3F",
            upregulatedColor: "#CF492F",
        };
        // The names of the genes that has a visible tooltip
        this.activeGeneTooltips = [];
        // The zoom transform object, if any. Used for pan and zoom correction
        this.zt = null;
        this.cancelSelectByGOTerm$ = new Subject();
    }
    /** If true, controls and side bar will not be rendered. */
    set plotOnly(value) {
        this._plotOnly = value;
    }
    get plotOnly() {
        return this._plotOnly;
    }
    get mode() {
        return this._mode;
    }
    get points() {
        return this._points;
    }
    get isFullScreen() {
        return this.layout.isFullScreen;
    }
    get scatterThumbnailBtnTooltip() {
        return this._scatterThumbnailBtnTooltip;
    }
    // Used in adjusting axis ranges. Uncomment if we add this back in
    // public dataBoundingBox: {
    //   xMin: number;
    //   xMax: number;
    //   yMin: number;
    //   yMax: number;
    // } = {
    //   xMin: 0,
    //   xMax: 0,
    //   yMin: 0,
    //   yMax: 0,
    // };
    // public axisRanges: {
    //   xMin: number;
    //   xMax: number;
    //   yMin: number;
    //   yMax: number;
    // } = {
    //   xMin: 0,
    //   xMax: 0,
    //   yMin: 0,
    //   yMax: 0,
    // };
    get selectByStatsForm() {
        return this._selectByStatsForm;
    }
    get showSidebar() {
        return this._showSidebar;
    }
    get showSelectByStatsForm() {
        return this._showSelectByStatsForm;
    }
    get showPlotControls() {
        return this._showPlotControls;
    }
    get activeSelectionType() {
        return this._activeSelectionType;
    }
    set activeSelectionType(type) {
        this._activeSelectionType = type;
        const selection = this.getActiveSelection();
        d3.select(`#${this.svgId}`)
            .selectAll(".point")
            .data(this._points, (d) => d.gene)
            .attr("fill", selection.config.colorUnselected)
            .attr("opacity", selection.config.opacity)
            .attr("stroke", selection.config.strokeUnselected)
            .attr("stroke-width", selection.config.strokeUnselected ? 1 : 0)
            .classed("selected", false)
            .classed("upregulated", false)
            .classed("downregulated", false);
        // update selection and labels to match the current selection
        this.selectGenesByName(selection.selectedPoints.map((p) => p.gene));
        this.labelPoints(selection.labelledPoints);
        // adjust for the current zoom
        this.adjustForZoom();
    }
    // #region Public functions
    selectFromTermOverlap() {
        const overlappingPoints = this.getActiveSelection().selectedPoints.filter(p => p.partOfSelectionOverlap);
        const standardSelection = this.selections.find(s => s.type === VolcanoSelectionType.Standard);
        standardSelection.selectPoints(overlappingPoints);
        standardSelection.trigger = VolcanoSelectionTrigger.EnrichmentAnalysisButtons;
        this.exitGOTermSelection();
        this.emitSelectionUpdate();
    }
    downloadPlot(downloadPlotType = null) {
        this.downloadPlotComponent.download(downloadPlotType);
    }
    toggleFullScreen() {
        this.layout.toggleFullScreen();
    }
    /**
     * @description If the user last rendered a scatter plot, we should be able to access the data necessary to render a thumbnail. If not, we should not show the thumbnail.
     * @returns Whether or not the last visualization was a scatter plot, and cohort data is available from the DEA payload (may not be available on older DEA jobs)
     */
    get canAccessScatterThumbnail() {
        if (!OncoData.instance.currentCommonSidePanel._config.isScatterVisualization) {
            this._scatterThumbnailBtnTooltip = `This feature is only available for scatter plots. Select a scatter plot as your "Active Analysis" before opening DEA.`;
            return false;
        }
        try {
            const cohortA = this.deaPayload.data.data.cohortA;
            const cohortB = this.deaPayload.data.data.cohortB;
            if (!cohortA || !cohortB) {
                this._scatterThumbnailBtnTooltip = `Input cohort data is not available for this DEA job. Try a newer DEA job.`;
                return false;
            }
            return true;
        }
        catch (e) {
            this._scatterThumbnailBtnTooltip = `Error getting mini-scatter data. Try a newer DEA job.`;
            return false;
        }
    }
    get scatterThumbnailVisible() {
        return this._scatterThumbnailVisible;
    }
    set scatterThumbnailVisible(value) {
        this._scatterThumbnailVisible = value;
        if (value) {
            this.updateScatterThumbnailData();
        }
    }
    selectByTerm(backgroundDataset, term) {
        const self = this;
        // Wrap the asynchronous operation inside an Observable
        return this.ea
            .getGenesByTerm(backgroundDataset, term, this.cancelSelectByGOTerm$, () => {
            self.eaComponent.loadingBackgroundDatasetMapping = false;
        })
            .then((genes) => {
            // find the GO Term selection
            const goTermSelection = this.selections.find((s) => s.type === VolcanoSelectionType.GOTerm);
            // select the points by gene name in the GO Term selection
            goTermSelection.selectPointsByGeneName(genes);
            // set the active selection type to GO Term
            this.activeSelectionType = VolcanoSelectionType.GOTerm;
            this.cd.detectChanges();
            return genes;
        }, (error) => {
            console.error("Error fetching data:", error);
            throw error;
        });
    }
    shouldShowGOTermSelectionButtons() {
        return !this.dismissGOTermSelectionButtons && (this.activeSelectionType === VolcanoSelectionType.GOTerm);
    }
    handleEAmouseout() {
        this.cancelSelectByGOTerm$.next();
        this.eaComponent.loadingBackgroundDatasetMapping = false;
        this.exitGOTermSelection();
    }
    handleEAmouseclick(data) {
        const self = this;
        if (data) {
            const { point, options } = data;
            // turn on the GO Term selection buttons
            this.dismissGOTermSelectionButtons = false;
            this.handleEAmouseover({ point, options }).then(() => {
                // even though we called hover, this is areally a click, so we want to go into the GO Term selection mode. Show the buttons
                self.dismissGOTermSelectionButtons = false;
            });
        }
        else {
            this.exitGOTermSelection();
        }
    }
    /**
     * @description Handle the mouseover event from the Enrichment Analysis component.
     * This creates a GO Term selection and then does combined styling of points to illustrate the overlap between selections.
     * @param point The point (GO Term or pathway) that was clicked on in the Enrichment Analysis component
     * @returns list of genes in the term
     */
    handleEAmouseover({ point, options }) {
        return __awaiter(this, void 0, void 0, function* () {
            const backgroundDataset = this.eaComponent.currentBackgroundDataset;
            const { numGenesToLabel } = options;
            return this.selectByTerm(backgroundDataset, point.termId).then((genesInTerm) => {
                this.eaComponent.loadingBackgroundDatasetMapping = false;
                this.dismissGOTermSelectionButtons = true;
                const goTermSelection = this.getActiveSelection();
                const standardSelection = this.selections.find(s => s.type === VolcanoSelectionType.Standard);
                const overlappingPoints = standardSelection.intersection(goTermSelection);
                // used later to create a selection from the overlapping points
                goTermSelection.markPointsAsOverlappingWithOtherSelection(overlappingPoints);
                // style the standard selection regularly
                this.stylePointsOnClick(null, standardSelection.selectedPoints, { selectionConfig: standardSelection.config, forceMode: 'selected' });
                // style  overlap with combined styling
                const goTermSelectionConfigWithColorByStats = Object.assign({}, GOTermVolcanoSelectionConfig, { useSelectByStatColorLogic: true });
                this.stylePointsOnClick(null, overlappingPoints, { selectionConfig: goTermSelectionConfigWithColorByStats, forceMode: 'selected' });
                // restyle points in term, not in the overlap. This is so they are brought to the front
                const pointsNotInOverlap = goTermSelection.selectedPoints.filter(p => !p.partOfSelectionOverlap);
                this.stylePointsOnClick(null, pointsNotInOverlap, { selectionConfig: goTermSelection.config, forceMode: 'selected' });
                // label the top n points in the GO Term selection, based on the combination of x and y
                const topN = numGenesToLabel;
                const topNPoints = overlappingPoints.slice().sort((a, b) => {
                    return (b.x + b.y) - (a.x + a.y);
                }).slice(0, topN);
                this.labelPoints(topNPoints);
                const self = this;
                // set standard selection points to use standard selection config
                d3.selectAll(".point")
                    .filter((d) => standardSelection.isPointSelected(d))
                    // @ts-ignore
                    .on("mouseout", (event, d) => self.onPointMouseOut(event, d, standardSelection))
                    // @ts-ignore
                    .on("mouseover", (event, d) => self.onPointMouseOver(event, d, standardSelection));
                // set go term selection points to use go term selection config
                d3.selectAll(".point")
                    .filter((d) => goTermSelection.isPointSelected(d) && !d.partOfSelectionOverlap)
                    // @ts-ignore
                    .on("mouseout", (event, d) => self.onPointMouseOut(event, d, goTermSelection))
                    // @ts-ignore
                    .on("mouseover", (event, d) => self.onPointMouseOver(event, d, goTermSelection));
                const selection = this.getActiveSelection();
                selection.trigger = VolcanoSelectionTrigger.EnrichmentAnalysisTab;
                // adjust for current zoom
                this.adjustForZoom();
                return genesInTerm;
            });
        });
    }
    exitGOTermSelection() {
        // re-render the Enrichment Analysis component, since it this point it will be in a "selected" state
        this.eaComponent.render();
        this.activeSelectionType = VolcanoSelectionType.Standard;
    }
    selectionOfType$(type) {
        return this.selections$.pipe(map((list) => list.find((item) => item.type === type)), // Get the object with the requested type
        filter((object) => object !== undefined) // Filter out undefined objects (if no object with the requested type)th
        );
    }
    selectedTabChange(event) {
        const textLabelToTab = {
            "Enrichment Analysis (GSEA)": VolcanoTab.EnrichmentAnalysis,
            "Table": VolcanoTab.Table,
        };
        this.layout.activeTab = textLabelToTab[event.tab.textLabel];
    }
    togglePlotControls() {
        this._showPlotControls = !this._showPlotControls;
    }
    toggleSelectByStatsForm() {
        this._showSelectByStatsForm = !this._showSelectByStatsForm;
    }
    toggleSidebar() {
        this._showSidebar = !this._showSidebar;
    }
    updateSelectByStatsForm(event) {
        const precision = 3;
        // @ts-ignore
        const field = event.target.name;
        // @ts-ignore
        const value = event.target.value;
        this._selectByStatsForm[field] = value;
        // update the associated log/ non-log field
        switch (field) {
            case "nlogpadj":
                this._selectByStatsForm.padj = Number(Math.pow(10, -value).toPrecision(precision));
                break;
            case "log2FoldChange":
                this._selectByStatsForm.fc = Number(Math.pow(2, value).toPrecision(precision));
                break;
            case "padj":
                this._selectByStatsForm.nlogpadj = Number(-Math.log10(value).toPrecision(precision));
                break;
            case "fc":
                this._selectByStatsForm.log2FoldChange = Number(Math.log2(value).toPrecision(precision));
                break;
            default:
                console.error(`Unknown stats form field: ${field}`);
        }
        this.selectByStats();
    }
    getGeneRegulation(point) {
        if (point.x > this._selectByStatsForm.log2FoldChange &&
            point.y > this._selectByStatsForm.nlogpadj) {
            return "up";
        }
        if (point.x < -this._selectByStatsForm.log2FoldChange &&
            point.y > this._selectByStatsForm.nlogpadj) {
            return "down";
        }
        return "none";
    }
    updateRegulationColor(color, regulation) {
        // regulation color only applies in standard selection type
        this.activeSelectionType = VolcanoSelectionType.Standard;
        if (regulation === "up") {
            this._selectByStatsForm.upregulatedColor = color;
            // select all points that are upregulated and currently selected
            d3.selectAll(".point.upregulated.selected").attr("fill", color);
        }
        else {
            this._selectByStatsForm.downregulatedColor = color;
            d3.selectAll(".point.downregulated.selected").attr("fill", color);
        }
        this.updateScatterThumbnailData();
        this.cd.detectChanges();
    }
    selectGenesByName(genes, options = { label: false }) {
        const pointsToClick = this._points.filter((p) => genes.includes(p.gene));
        const event = new MouseEvent("selectGenesByName");
        this.stylePointsOnClick(event, pointsToClick, {
            tooltip: false,
            fill: options.fill,
        });
        const selection = this.getActiveSelection();
        selection.selectPoints(pointsToClick);
        if (options.label || selection.config.labelOnSelection) {
            this.labelPoints(this.getActiveSelection().selectedPoints);
        }
    }
    clearSelection(type = this.activeSelectionType) {
        const selection = this.selections.find((s) => s.type === type);
        // clear out the selected cohort subsets
        selection.selectPoints([]);
        // reset the points to unselected style
        d3.selectAll(".point")
            .attr("fill", selection.config.colorUnselected)
            .attr("opacity", function (d, i, nodes) {
            const outOfView = d3.select(this).classed("out-of-view");
            return outOfView ? 0 : selection.config.opacity;
        })
            .classed("selected", false);
        // remove all tooltips
        d3.selectAll(".volcano-tooltip").remove();
        this.activeGeneTooltips.length = 0;
        // remove all labels
        this.labelPoints([]);
        // emit the new cleared selection so other elements on the page can respond
        this.emitSelectionUpdate();
    }
    selectByStats() {
        // select by stats should be used within a standard selection context
        this.activeSelectionType = VolcanoSelectionType.Standard;
        const selection = this.getActiveSelection();
        selection.trigger = VolcanoSelectionTrigger.SelectByStats;
        // find all points that are above the -log10(padj) line and greater than the absolute value of log2FoldChange
        const upregulatedPoints = this._points.filter((point) => this.getGeneRegulation(point) === "up");
        const downregulatedPoints = this._points.filter((point) => this.getGeneRegulation(point) === "down");
        this.clearSelection();
        this.selectGenesByName([...downregulatedPoints, ...upregulatedPoints].map((p) => p.gene));
        // draw dashed lines to show the thresholds
        this.drawThresholdLines();
        this.emitSelectionUpdate();
    }
    labelPoints(points) {
        // clear out any existing labels
        d3.selectAll(`.volcano-label`).remove();
        // update the label flag in the selection
        this.getActiveSelection().labelPoints(points);
        let inverseScale = 1;
        if (this.zt) {
            inverseScale = 1 / this.zt.k;
        }
        const xOffset = (VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING) * inverseScale;
        const yOffset = (VolcanoComponent.MARGIN.top + VolcanoComponent.TITLE_PADDING) * inverseScale;
        // calculate the x and y positions of the labels, increasing the offset by the log of the inverse scale
        const x = (d) => this.xScale(d.x) + VolcanoComponent.LABEL_OFFSET.x * inverseScale + xOffset;
        const y = (d) => this.yScale(d.y) + VolcanoComponent.LABEL_OFFSET.y * inverseScale + yOffset;
        this.plot
            .selectAll(".volcano-label")
            .data(points, (d) => d.gene)
            .enter()
            .append("text")
            .attr("name", d => d.gene)
            .attr("class", "volcano-label")
            .attr("x", d => x(d))
            .attr("y", d => y(d))
            .attr("font-size", VolcanoComponent.LABEL_FONT_SIZE * inverseScale)
            .attr("font-weight", "bold")
            .text(d => d.gene);
        // // add a rectangle behind the text
        // d3.select(`#${this.svgId}`).append("rect")
        //   .attr("class", "volcano-label")
        //   .attr("x", x + VolcanoComponent.LABEL_OFFSET.x - 2)
        //   .attr("y", y + VolcanoComponent.LABEL_OFFSET.y - 12)
        //   .attr("width", 10)
        //   .attr("height", 10)
        //   .attr("fill", "white");
        // draw a line from the center left of the text to the point
        // d3.select(`#${this.svgId}`)
        // .selectAll(".volcano-label-line")
        // .data(points)
        // .enter()
        // .append("line")
        //   .attr("class", "volcano-label-line")
        //   .attr("x1", d => x(d) + VolcanoComponent.LABEL_OFFSET.x - 2)
        //   .attr("y1", d => y(d) + VolcanoComponent.LABEL_OFFSET.y - 2)
        //   .attr("x2", d => x(d))
        //   .attr("y2", d => y(d))
        //   .attr("stroke", "black")
        // adjust for zoom, which will hide labels outside of the view
        this.adjustForZoom();
    }
    resetView() {
        const svg = d3.select(`#${this.svgId}`);
        // @ts-ignore
        const defaultMode = this._mode;
        this.setMode(VolcanoInteractivityMode.PAN_ZOOM);
        // @ts-ignore
        svg.call(this.zoom.transform, d3.zoomIdentity);
        this.setMode(defaultMode);
    }
    setMode(mode) {
        const svg = d3.select(`#${this.svgId}`);
        this._mode = mode;
        const modeToggles = {
            [VolcanoInteractivityMode.PAN_ZOOM]: {
                enable: () => {
                    svg.call(this.zoom);
                },
                disable: () => {
                    svg.on(".zoom", null);
                },
            },
            [VolcanoInteractivityMode.SELECT]: {
                enable: () => {
                    d3.select(`#${this.svgId}`)
                        .on("mousedown", this.onMouseDown.bind(this))
                        .on("mousemove", this.onMouseMove.bind(this))
                        .on("mouseup", this.onMouseUp.bind(this));
                    // if the mouse is outside the svg, stop dragging
                    document.addEventListener("mousemove", (e) => {
                        if (this.isMouseOutsideSvg(e) && this.isDragging) {
                            this.onMouseUp();
                        }
                    });
                },
                disable: () => {
                    d3.select(`#${this.svgId}`)
                        .on("mousedown", null)
                        .on("mousemove", null)
                        .on("mouseup", null);
                },
            },
        };
        Object.keys(modeToggles).forEach((mode) => {
            if (mode === this._mode) {
                modeToggles[mode].enable();
            }
            else {
                modeToggles[mode].disable();
            }
        });
    }
    // #endregion
    // #region Event Listeners
    onMouseDown(event) {
        // return on right click
        if (event.button == 2) {
            return;
        }
        const activeSelectionConfig = this.getActiveSelection().config;
        if (activeSelectionConfig.disableMouseSelection)
            return;
        const shiftKeyPressed = event.shiftKey || this.artificallyHoldingShift;
        const altKeyPressed = event.altKey;
        // if both shift and alt are pressed, do nothing
        if (shiftKeyPressed && altKeyPressed)
            return;
        // If the mouse is over a point, do nothing. Let the point's click event handle it.
        if (this.hovered)
            return;
        const anyModifierKeyPressed = shiftKeyPressed || altKeyPressed;
        if (!anyModifierKeyPressed) {
            // if no modifier keys are pressed, clear the selection
            this.clearSelection();
        }
        this.isDragging = true;
        this.eventCoords = this.getEventCoords(event);
        // remove any existing rectangle
        d3.select(`#${this.svgId}`).selectAll(".drag-rectangle").remove();
        this.drawTooltipText(event);
    }
    onMouseMove(event) {
        const shiftKeyPressed = event.shiftKey || this.artificallyHoldingShift;
        const altKeyPressed = event.altKey;
        // if both shift and alt are pressed, do nothing
        if (shiftKeyPressed && altKeyPressed) {
            return;
        }
        const selection = this.getActiveSelection();
        const selectionConfig = selection.config;
        if (selectionConfig.disableMouseSelection) {
            return;
        }
        if (this.isDragging) {
            // remove any existing rectangle
            d3.select(`#${this.svgId}`).selectAll(".drag-rectangle").remove();
            d3.select(`#${this.svgId}`)
                .selectAll(".drag-rectangle-coordinates")
                .remove();
            selection.trigger = VolcanoSelectionTrigger.Drag;
            let color = altKeyPressed ? "#d16666" : "lightblue";
            // get start and current coordinates
            const startDomain = this.eventCoords.domain;
            const currentDomain = this.getEventCoords(event).domain;
            const startDraw = this.eventCoords.draw;
            const currentDraw = this.getEventCoords(event).draw;
            const svgElement = document.getElementById(this.svgId);
            const svgRect = svgElement.getBoundingClientRect();
            // draw the rectangle
            d3.select(`#${this.svgId}`)
                .append("rect")
                .data([
                {
                    start: startDraw,
                    current: currentDraw,
                },
            ])
                .attr("class", "drag-rectangle")
                .attr("x", (d) => Math.min(d.start.x, d.current.x))
                .attr("y", (d) => Math.min(d.start.y, d.current.y))
                .attr("width", (d) => Math.abs(d.current.x - d.start.x))
                .attr("height", (d) => Math.abs(d.current.y - d.start.y))
                .attr("fill", color)
                .attr("opacity", 0.2);
            this.drawTooltipText(event);
            // if current is beyond the domain, set it to the domain boundary
            if (currentDomain.x > this.domain.x[1]) {
                currentDomain.x = this.domain.x[1];
            }
            if (currentDomain.x < this.domain.x[0]) {
                currentDomain.x = this.domain.x[0];
            }
            if (currentDomain.y > this.domain.y[1]) {
                currentDomain.y = this.domain.y[1];
            }
            if (currentDomain.y < this.domain.y[0]) {
                currentDomain.y = this.domain.y[0];
            }
            // calculate the rectangle coordinates
            const rectCoords = [
                {
                    x: Math.min(startDomain.x, currentDomain.x),
                    y: Math.min(startDomain.y, currentDomain.y),
                },
                {
                    x: Math.max(startDomain.x, currentDomain.x),
                    y: Math.max(startDomain.y, currentDomain.y),
                },
            ];
            const toClick = [];
            const deselectPoint = (p) => {
                selection.deselectSinglePoint(p);
                toClick.push(p);
            };
            const selectPoint = (p) => {
                selection.selectSinglePoint(p);
                toClick.push(p);
            };
            this._points.forEach((point) => {
                const inRect = this.pointInRect(point, rectCoords) &&
                    !d3.select(`circle[name=${point.gene}]`).classed("out-of-view");
                const wasAlreadySelected = selection.isPointSelected(point);
                const newToThisDrag = this.pointsNewToThisDrag.includes(point);
                const deletedThisDrag = this.pointsDeletedThisDrag.includes(point);
                if (!inRect && wasAlreadySelected) {
                    if (!shiftKeyPressed && !altKeyPressed) {
                        // selected point not in rectangle and no modifier keys pressed
                        // Expected behavior is to deselect the point
                        deselectPoint(point);
                        return;
                    }
                    if (shiftKeyPressed && !newToThisDrag) {
                        // selected point not in rectangle and shift is pressed, and not new to this drag
                        // Expected behavior is to keep the point (do nothing)
                        return;
                    }
                    if (shiftKeyPressed && newToThisDrag) {
                        // selected point not in rectangle and shift is pressed, and new to this drag
                        // Expected behavior is to deselect the point
                        deselectPoint(point);
                        return;
                    }
                }
                if (!inRect && deletedThisDrag && altKeyPressed) {
                    // selected point not in rectangle and alt is pressed, and deleted this drag
                    // Expected behavior is to select the point
                    selectPoint(point);
                    this.pointsDeletedThisDrag.splice(this.pointsDeletedThisDrag.indexOf(point), 1);
                    return;
                }
                if (inRect) {
                    if (wasAlreadySelected) {
                        if (altKeyPressed) {
                            // point previously selected, alt is pressed, point is in rectangle
                            // Expected behavior is to deselect the point
                            deselectPoint(point);
                            this.pointsDeletedThisDrag.push(point);
                            return;
                        }
                    }
                    if (!wasAlreadySelected) {
                        if (!altKeyPressed) {
                            // point not previously selected, alt is not pressed, and point is in rectangle
                            // Expected behavior is to select the point
                            selectPoint(point);
                            this.pointsNewToThisDrag.push(point);
                            return;
                        }
                    }
                }
            });
            // bulk style the points
            this.stylePointsOnClick(event, toClick, { tooltip: false });
        }
    }
    onMouseUp() {
        const selection = this.getActiveSelection();
        if (selection.config.disableMouseSelection)
            return;
        // end the drag
        if (this.isDragging) {
            this.isDragging = false;
            this.pointsNewToThisDrag.length = 0;
            this.pointsDeletedThisDrag.length = 0;
            d3.select(".drag-rectangle-text").remove();
            d3.select(".drag-rectangle").remove();
            d3.select(".drag-rectangle-coordinates").remove();
        }
        this.emitSelectionUpdate();
    }
    onTooltipMouseOver(event, point) {
        this.activeGeneTooltips.push(point.gene);
    }
    onTooltipMouseOut(event, point) {
        this.onPointMouseOut(event, point);
    }
    /** Returns a promise once the event hander is done. */
    onPointMouseOut(event, point, selection = this.getActiveSelection()) {
        return __awaiter(this, void 0, void 0, function* () {
            // immediately remove the tooltip from the list of active tooltips when the mouse leaves. If the tooltip's mouseover event is called, it will be added back to the list before the timeout
            this.activeGeneTooltips.splice(this.activeGeneTooltips.indexOf(point.gene), 1);
            return new Promise((resolve) => {
                setTimeout(() => {
                    // After the timeout, if the tooltip is still active, then that means the user has hovered over the tooltip
                    if (this.activeGeneTooltips.includes(point.gene)) {
                        resolve();
                        return;
                    }
                    this.hovered = null;
                    if (this.mostRecentSelectedPoint === point) {
                        this.mostRecentSelectedPoint = null;
                        resolve();
                        return;
                    }
                    // remove the tooltip
                    d3.select(`.volcano-tooltip[name=${point.gene}]`).remove();
                    this.activeGeneTooltips.splice(this.activeGeneTooltips.indexOf(point.gene), 1);
                    if (selection.isPointSelected(point)) {
                        resolve();
                        return;
                    }
                    // see if the point has the "selected" class
                    const isSelected = d3.select(`.point[name="${point.gene}"]`).classed("selected");
                    d3.select(`.point[name="${point.gene}"]`)
                        .attr("fill", isSelected
                        ? selection.config.colorSelected
                        : selection.config.colorUnselected)
                        .attr("opacity", isSelected
                        ? selection.config.opacitySelected
                        : selection.config.opacity);
                    resolve();
                }, 1);
            });
        });
    }
    onPointMouseOver(event, point, selection = this.getActiveSelection()) {
        // don't call the same hover event twice
        if (this.hovered == point) {
            return;
        }
        this.hovered = point;
        this.stylePointOnHover(event, point, {
            tooltip: true,
            selectionConfig: selection.config,
        });
    }
    onPointClick(event, point) {
        const shiftPressed = event.shiftKey || this.artificallyHoldingShift;
        const altKeyPressed = event.altKey;
        let selection = this.getActiveSelection();
        if (selection.config.disableMouseSelection)
            return;
        selection.trigger = VolcanoSelectionTrigger.Click;
        const alreadySelected = selection.isPointSelected(point);
        const somethingIsSelected = selection.selectedPoints.length > 0;
        if (somethingIsSelected) {
            if (altKeyPressed) {
                if (selection.isPointSelected(point)) {
                    // the point is already selected with alt pressed
                    // Expected behavior is to remove this point from the selection
                    selection.deselectSinglePoint(point);
                    this.stylePointOnClick(event, point);
                    this.onPointMouseOut(event, point);
                    this.emitSelectionUpdate();
                    return;
                }
                else {
                    // something is already selected and alt is pressed. The point is not already selected
                    // Expected behavior is to do nothing
                    return;
                }
            }
            if (shiftPressed) {
                // shift is pressed and something else is already selected
                if (alreadySelected) {
                    // the point is already selected with shift or alt pressed
                    // Expected behavior is to remove this point from the selection
                    selection.deselectSinglePoint(point);
                    this.stylePointOnClick(event, point);
                    this.onPointMouseOut(event, point);
                    this.emitSelectionUpdate();
                    return;
                }
                else {
                    // the point is not already selected with shift pressed
                    // Expected behavior is to add this point to the selection
                    // clear all tooltips
                    d3.selectAll(".volcano-tooltip").remove();
                    this.activeGeneTooltips.length = 0;
                    selection.selectSinglePoint(point);
                    this.stylePointOnClick(event, point);
                    this.mostRecentSelectedPoint = point;
                    this.emitSelectionUpdate();
                    return;
                }
            }
            else {
                // shift is not pressed and something else is already selected.
                // Expected behavior is to clear the selection and select this point
                this.clearSelection();
                // put this point back in focus
                this.stylePointOnClick(event, point, true);
                selection.selectSinglePoint(point);
                this.mostRecentSelectedPoint = point;
                // because the order of operations for putting the point back in focus, we need to manually call drawTooltip
                this.drawTooltip(event, point);
                this.emitSelectionUpdate();
                return;
            }
        }
        this.mostRecentSelectedPoint = point;
        selection.selectSinglePoint(point);
        this.stylePointOnClick(event, point);
        this.emitSelectionUpdate();
    }
    // #endregion
    // #region Helper functions
    // Function to check if mouse is outside SVG element
    isMouseOutsideSvg(event) {
        const svgElement = document.getElementById(this.svgId); // Get the SVG element
        if (!svgElement)
            return true; // If the SVG element doesn't exist, return true (mouse is outside SVG element
        const svgRect = svgElement.getBoundingClientRect(); // Get bounding rectangle of SVG element
        return (event.clientX < svgRect.left ||
            event.clientX > svgRect.right ||
            event.clientY < svgRect.top ||
            event.clientY > svgRect.bottom);
    }
    getActiveSelection() {
        return this.selections.find((s) => s.type === this.activeSelectionType);
    }
    getSelectedColor(point, selectionConfig = this.getActiveSelection().config) {
        if (!selectionConfig.useSelectByStatColorLogic)
            return selectionConfig.colorSelected;
        switch (this.getGeneRegulation(point)) {
            case "up":
                return this._selectByStatsForm.upregulatedColor;
            case "down":
                return this._selectByStatsForm.downregulatedColor;
            default:
                return selectionConfig.colorSelected;
        }
    }
    /**
     * Regardless of how the data looks coming in, we want to transform it to the following format:
     * [
     * {x: number, y: number, gene: string},
     * ]
     */
    processData(data, genesToSelectByDefault = []) {
        const genes = Object.keys(data["log2FoldChange"]);
        // const firstGene = Object.keys(data)[0];
        // const genes = Object.keys(data[firstGene]);
        const x = Object.keys(data["log2FoldChange"]).map((e) => data["log2FoldChange"][e]); // because our build doesn't support Object.values
        const yValues = Object.keys(data["padj"]).map((e) => data["padj"][e]);
        const y = yValues.map((p) => {
            const res = -Math.log10(p);
            if (Math.abs(res) == Infinity || isNaN(res)) {
                return null;
            }
            return res;
        });
        let finalData = x.map((xValue, i) => {
            return {
                x: xValue,
                y: y[i],
                gene: genes[i],
                labelled: false,
                selected: false,
                partOfSelectionOverlap: false
            };
        });
        // filter out null values
        finalData = finalData.filter((d) => d.x != null && d.y != null);
        return {
            data: finalData,
            genesToSelectByDefault,
            genes,
        };
    }
    emitSelectionUpdate() {
        // emit gets called when we programatically select a bunch of points, but we want to wait until the selection completes to emit
        if (this.artificallyHoldingShift) {
            return;
        }
        // sort each selection's points before emission
        this.selections.forEach((s) => {
            s.sortSelection((a, b) => {
                return Math.abs(b.x) - Math.abs(a.x);
            });
        });
        // we don't want selections to hang around in the table when all points are deselected in the volcano plot
        const standardSelectionPoints = this.selections.find((s) => s.type === VolcanoSelectionType.Standard).selectedPoints;
        if (standardSelectionPoints.length === 0 && this.geneTable) {
            this.geneTable.selection.clear();
        }
        this.selections$.next(this.selections);
        this.cd.detectChanges();
    }
    drawThresholdLines() {
        const svg = d3.select(`#${this.svgId}`);
        svg.selectAll(".threshold-line").remove();
        // Calculate the new positions based on the zoom transform
        const lowerLog2FoldChange = -Math.abs(this._selectByStatsForm.log2FoldChange);
        const upperLog2FoldChange = Math.abs(this._selectByStatsForm.log2FoldChange);
        const inRange = (value, range) => {
            return value >= range[0] && value <= range[1];
        };
        [lowerLog2FoldChange, upperLog2FoldChange].forEach((x, i) => {
            // hide of the lines are beyond the limits of the graph x-axis
            svg
                .append("line")
                .attr("class", "threshold-line")
                .attr("x1", this.zoomXScale(x) +
                VolcanoComponent.MARGIN.left +
                VolcanoComponent.AXIS_LABEL_PADDING)
                .attr("y1", VolcanoComponent.HEIGHT)
                .attr("x2", this.zoomXScale(x) +
                VolcanoComponent.MARGIN.left +
                VolcanoComponent.AXIS_LABEL_PADDING)
                .attr("y2", 0)
                .attr("stroke", "black")
                .attr("stroke-dasharray", "5,5")
                .attr("opacity", Number(inRange(x, this.zoomXScale.domain())));
        });
        // Calculate the y position based on the current zoom transform
        const yThresholdY = this.zoomYScale(this._selectByStatsForm.nlogpadj);
        // Draw the -log10(padj) threshold line
        svg
            .append("line")
            .attr("class", "threshold-line")
            .attr("x1", VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING)
            .attr("y1", yThresholdY +
            VolcanoComponent.MARGIN.top +
            VolcanoComponent.TITLE_PADDING)
            .attr("x2", VolcanoComponent.WIDTH +
            VolcanoComponent.MARGIN.left +
            VolcanoComponent.AXIS_LABEL_PADDING)
            .attr("y2", yThresholdY +
            VolcanoComponent.MARGIN.top +
            VolcanoComponent.TITLE_PADDING)
            .attr("stroke", "black")
            .attr("stroke-dasharray", "5,5")
            .attr("opacity", Number(inRange(this._selectByStatsForm.nlogpadj, this.zoomYScale.domain())));
    }
    /**
     *  Returns true if the given point is within the given rectangle
     * @param { { x: number, y: number } } point
     * @param { { x: number, y: number }[] } rectPoints
     */
    pointInRect(point, rectPoints) {
        const x = point.x;
        const y = point.y;
        const x1 = Math.min(rectPoints[0].x, rectPoints[1].x);
        const x2 = Math.max(rectPoints[0].x, rectPoints[1].x);
        const y1 = Math.min(rectPoints[0].y, rectPoints[1].y);
        const y2 = Math.max(rectPoints[0].y, rectPoints[1].y);
        return x >= x1 && x <= x2 && y >= y1 && y <= y2;
    }
    getEventCoords(event) {
        // Get the position and dimensions of the SVG
        const svgElement = document.getElementById(this.svgId);
        const svgRect = svgElement.getBoundingClientRect();
        return {
            draw: {
                x: event.clientX - svgRect.left,
                y: event.clientY - svgRect.top,
            },
            domain: {
                x: this.zoomXScale.invert(event.clientX -
                    svgRect.left -
                    VolcanoComponent.MARGIN.left -
                    VolcanoComponent.AXIS_LABEL_PADDING),
                y: this.zoomYScale.invert(event.clientY -
                    svgRect.top -
                    VolcanoComponent.MARGIN.top -
                    VolcanoComponent.TITLE_PADDING),
            },
        };
    }
    drawTooltipText(event) {
        const startCoords = this.eventCoords.draw;
        const endCoords = this.getEventCoords(event).draw;
        const startDomain = this.eventCoords.domain;
        const endDomain = this.getEventCoords(event).domain;
        d3.select(`#${this.svgId}`).selectAll(".drag-rectangle-text").remove();
        const shiftKeyPressed = event.shiftKey || this.artificallyHoldingShift;
        const altKeyPressed = event.altKey;
        let color = "lightblue";
        let tooltipText = "Select subset of genes";
        if (altKeyPressed) {
            color = "#d16666";
            tooltipText = "Deselect subset of genes";
        }
        if (shiftKeyPressed) {
            tooltipText = "Add subset of genes to selection";
        }
        // if start and end coords are different, add them to the text
        if (startCoords.x !== endCoords.x || startCoords.y !== endCoords.y) {
            tooltipText += ` from (${startDomain.x.toPrecision(3)}, ${startDomain.y.toPrecision(3)}) to (${endDomain.x.toPrecision(3)}, ${endDomain.y.toPrecision(3)})`;
        }
        // add hint text at the starting point of the rectangle
        d3.select(`#${this.svgId}`)
            .append("text")
            .attr("class", "drag-rectangle-text")
            .attr("x", this.eventCoords.draw.x)
            .attr("y", this.eventCoords.draw.y - 3)
            .attr("fill", color)
            .attr("font-size", "12px")
            .text(tooltipText);
    }
    stylePointOnHover(event, point, options = {
        tooltip: true,
        selectionConfig: this.getActiveSelection().config,
    }) {
        if (this.isDragging) {
            return;
        }
        const activeSelectionConfig = options.selectionConfig;
        // see if the point has the "selected" class
        const isSelected = d3
            .select(`.point[name="${point.gene}"]`)
            .classed("selected");
        const opacity = isSelected
            ? activeSelectionConfig.opacitySelected
            : activeSelectionConfig.opacityHover;
        let fill = "";
        if (options.fill) {
            fill = options.fill;
        }
        else {
            // if the point is selected, keep the fill color the same
            if (!isSelected) {
                fill = activeSelectionConfig.colorUnselected;
            }
            else {
                fill = d3.select(`.point[name="${point.gene}"]`).attr("fill");
            }
        }
        // style the point based on whether it is selected
        d3.select(`.point[name="${point.gene}"]`)
            .attr("fill", fill)
            .attr("opacity", opacity);
        const tooltipAlreadyExists = d3.select(`.tooltip[name=${point.gene}]`).size() > 0;
        if (!tooltipAlreadyExists &&
            options.tooltip &&
            !activeSelectionConfig.disableTooltip) {
            this.drawTooltip(event, point);
        }
    }
    stylePointsOnClick(event, points, options) {
        const DEFAULT_OPTIONS = {
            tooltip: true,
            fill: undefined,
            selectionConfig: this.getActiveSelection().config,
        };
        options = Object.assign({}, DEFAULT_OPTIONS, options);
        const selection = d3
            .select(`#${this.svgId}`)
            .selectAll(".point")
            .data(points, (d) => d.gene);
        const selectionConfig = options.selectionConfig;
        const applySelectedStyling = (selection) => {
            selection
                .attr("fill", (d) => options.fill ? options.fill : this.getSelectedColor(d, options.selectionConfig))
                .attr("opacity", selectionConfig.opacitySelected)
                .attr("stroke", selectionConfig.strokeSelected)
                .attr("stroke-width", selectionConfig.strokeWidthSelected)
                .classed("selected", true)
                .classed("upregulated", (d) => this.getGeneRegulation(d) === "up")
                .classed("downregulated", (d) => this.getGeneRegulation(d) === "down")
                .raise();
        };
        const applyDeselectedStyling = (selection) => {
            selection
                .attr("fill", options.fill ? options.fill : selectionConfig.colorUnselected)
                .attr("opacity", selectionConfig.opacity)
                .attr("stroke", selectionConfig.strokeUnselected)
                .attr("stroke-width", selectionConfig.strokeWidthSelected)
                .classed("selected", false)
                .classed("upregulated", false)
                .classed("downregulated", false);
        };
        if (options.forceMode === 'selected') {
            applySelectedStyling(selection);
            return;
        }
        if (options.forceMode === 'deselected') {
            applyDeselectedStyling(selection);
            return;
        }
        const selectedPoints = selection.filter(".selected");
        const unselectedPoints = selection.filter(":not(.selected)");
        // for each group, toggle into the other styling state
        applyDeselectedStyling(selectedPoints);
        applySelectedStyling(unselectedPoints);
        if (options.tooltip && !selectionConfig.disableTooltip) {
            points.forEach((p) => this.drawTooltip(event, p));
        }
    }
    stylePointOnClick(event, point, tooltip = true) {
        this.stylePointsOnClick(event, [point], { tooltip });
    }
    // updateAxisRange(event: Event, minOrMax: "min" | "max") {
    //   // @ts-ignore
    //   const axis = event.target.name;
    //   // @ts-ignore
    //   const value = event.target.value;
    //   // xMin, xMax, yMin, yMax - axis followed by uppercase minOrMax
    //   const axisRangesAttr =
    //     axis + minOrMax.charAt(0).toUpperCase() + minOrMax.slice(1);
    //   this.axisRanges[axisRangesAttr] = value;
    //   if (axis == "x") {
    //     // adjust negative to positive value range for x axis (LogFC)
    //     this.xScale.domain([
    //       minOrMax === "min" ? value : this.xScale.domain()[0],
    //       minOrMax === "max" ? value : this.xScale.domain()[1],
    //     ]);
    //     this.xAxis.transition().duration(1000).call(d3.axisBottom(this.xScale));
    //   } else if (axis == "y") {
    //     this.yScale.domain([
    //       minOrMax === "min" ? value : this.yScale.domain()[0],
    //       minOrMax === "max" ? value : this.yScale.domain()[1],
    //     ]);
    //     this.yAxis.transition().duration(1000).call(d3.axisLeft(this.yScale));
    //   } else {
    //     throw Error(`Unknown axis ${axis}`);
    //   }
    //   // Filter out data points beyond the updated axis limits
    //   const filteredData = this.points.filter((d) => {
    //     const xWithinLimits =
    //       d.x >= this.xScale.domain()[0] && d.x <= this.xScale.domain()[1];
    //     const yWithinLimits =
    //       d.y >= this.yScale.domain()[0] && d.y <= this.yScale.domain()[1];
    //     return xWithinLimits && yWithinLimits;
    //   });
    //   // Update the circles within the new limits
    //   this.plot
    //     .selectAll("circle")
    //     .data(filteredData, (d: VolcanoPoint) => d.gene) // Use a key function to bind data
    //     .transition()
    //     .duration(1000)
    //     .attr("cx", (d) => this.xScale(d.x))
    //     .attr("cy", (d) => this.yScale(d.y))
    //     .attr("opacity", (d) =>
    //       this.getActiveSelection().points.includes(d)
    //         ? VolcanoComponent.OPACITY_SELECTED
    //         : VolcanoComponent.OPACITY
    //     );
    //   // hide circles outside of axis limits
    //   this.plot
    //     .selectAll("circle")
    //     .data(this.points, (d: VolcanoPoint) => d.gene) // Rebind the data
    //     .filter((d) => !filteredData.includes(d)) // Select circles that are not in filteredData
    //     .attr("opacity", 0); // Set opacity to hide them
    // }
    drawData(xScale = this.xScale, yScale = this.yScale) {
        const activeSelectionConfig = this.getActiveSelection().config;
        this.plot
            .selectAll("circle")
            .data(this._points)
            .enter()
            .append("circle")
            .attr("class", "point")
            .attr("name", (d) => d.gene)
            .attr("cx", (d) => xScale(d.x) +
            VolcanoComponent.MARGIN.left +
            VolcanoComponent.AXIS_LABEL_PADDING)
            .attr("cy", (d) => yScale(d.y) +
            VolcanoComponent.MARGIN.top +
            VolcanoComponent.TITLE_PADDING)
            .attr("r", VolcanoComponent.POINT_RADIUS)
            .attr("fill", activeSelectionConfig.colorUnselected)
            .attr("stroke", "none")
            .attr("opacity", activeSelectionConfig.opacity)
            // @ts-ignore
            .on("mouseover", (e, d) => this.onPointMouseOver(e, d))
            .on("mouseout", (e, d) => this.onPointMouseOut(e, d))
            // @ts-ignore
            .on("click", (e, d) => this.onPointClick(e, d));
    }
    drawTooltip(event, point, opacity = 1) {
        // Remove the tooltip for this point if it already exists
        d3.select(`.volcano-tooltip[name=${point.gene}]`).remove();
        this.activeGeneTooltips.splice(this.activeGeneTooltips.indexOf(point.gene), 1);
        const cnaData = OncoData.instance.currentCommonSidePanel.getCnaDataForGene(point.gene);
        // const cnaData = {
        //   min: "--",
        //   max: "--",
        //   mean: 0,
        // };
        let detailsHtml = `
      <hr />
      <a target="_blank" href="https://www.genecards.org/cgi-bin/carddisp.pl?gene=${point.gene}">GeneCard</a> |
      <a target="_blank" href="https://cancer.sanger.ac.uk/cosmic/gene/analysis?ln=${point.gene}">COSMIC</a>
    `;
        let title = point.gene;
        if (title.length > 40) {
            let shortTitle = title.substring(0, 39) + "…";
            title = `<div class="no-decorate-unhovered-tooltip os-link" onclick="alert('ID: ${title}');">${shortTitle}</div>`;
        }
        // TODO: Is it worth calculating CNA data just for this iterative standalone application?
        if (cnaData) {
            detailsHtml += `
      <hr />
      CNA: Min=${cnaData.min} Max=${cnaData.max} Mean=${cnaData.mean.toPrecision(4)}<br />
      `;
        }
        const html = `
    <span class="xtooltiptext" style="opacity: ${opacity}">
      <span ><img
        style="vertical-align:middle" src="./assets/icons/freepik/dna-chain.png" width="16" height="16" />
        &nbsp;<b>${title}</b>
        <div class="xtooltipexpando">${detailsHtml}</div>
      </span>
    </span>
    `;
        d3.select(`body`)
            .append("div")
            .attr("class", "volcano-tooltip")
            .attr("name", point.gene)
            .style("position", "absolute")
            // the differential-expression-panel has a z-index of 100 since it is an overlay
            .style("z-index", "1000")
            .style("left", event.pageX + 20 + "px")
            .style("top", event.pageY - 20 + "px")
            .html(html)
            .on("mouseover", () => this.onTooltipMouseOver(event, point))
            .on("mouseout", () => this.onTooltipMouseOut(event, point));
        this.activeGeneTooltips.push(point.gene);
    }
    adjustForZoom() {
        // @ts-ignore
        this.handleZoom({ transform: this.zt });
    }
    handleZoom(event) {
        const activeSelectionConfig = this.getActiveSelection().config;
        this.zt = event.transform;
        // Update the x-axis and y-axis based on the zoom transformation
        this.zoomXScale = this.zt.rescaleX(this.xScale);
        this.zoomYScale = this.zt.rescaleY(this.yScale);
        this.xAxis.call(d3.axisBottom(this.zoomXScale));
        this.yAxis.call(d3.axisLeft(this.zoomYScale));
        this.drawThresholdLines();
        //#region Update Point and Label positions, event listeners, and stylings
        const inAxisLimits = (d) => {
            const xWithinLimits = d.x >= this.zoomXScale.domain()[0] &&
                d.x <= this.zoomXScale.domain()[1];
            const yWithinLimits = d.y >= this.zoomYScale.domain()[0] &&
                d.y <= this.zoomYScale.domain()[1];
            return xWithinLimits && yWithinLimits;
        };
        const pointsInAxisLimits = this._points.filter(inAxisLimits);
        const pointsNotInAxisLimits = this._points.filter((d) => !pointsInAxisLimits.includes(d));
        const labelInAxisLimits = (_, i, nodes) => {
            const node = nodes[i];
            const gene = node.attributes.name.value;
            return pointsInAxisLimits.find((p) => p.gene == gene) !== undefined;
        };
        // NOTE: We could just call drawData and labelPoints again here, which would redraw everything with zoom adjustments,
        // but that would inefficient. Instead, we will just update the positions of the points and labels that are in view.
        const inverseScale = 1 / this.zt.k;
        // enable points in axis limits
        const xOffset = (VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING) * inverseScale;
        const yOffset = (VolcanoComponent.MARGIN.top + VolcanoComponent.TITLE_PADDING) * inverseScale;
        this.plot
            .selectAll("circle")
            .data(pointsInAxisLimits, (d) => d.gene)
            // reposition
            .attr("cx", (d) => this.xScale(d.x) + xOffset)
            .attr("cy", (d) => this.yScale(d.y) + yOffset)
            .attr("r", VolcanoComponent.POINT_RADIUS * inverseScale)
            .attr("stroke-width", activeSelectionConfig.strokeWidthSelected * inverseScale)
            // add event listeners back
            // @ts-ignore
            .on("mouseover", (e, d) => this.onPointMouseOver(e, d))
            .on("mouseout", (e, d) => this.onPointMouseOut(e, d))
            //@ts-ignore
            .on("click", (e, d) => this.onPointClick(e, d))
            // make points visible again
            .attr("opacity", (d) => this.getActiveSelection().isPointSelected(d)
            ? activeSelectionConfig.opacitySelected
            : activeSelectionConfig.opacity)
            .classed("out-of-view", false);
        const labelledPointsInAxisLimits = pointsInAxisLimits.filter((d) => this.getActiveSelection().isPointLabelled(d));
        // show labels in axis limits
        this.plot
            .selectAll(".volcano-label")
            .data(labelledPointsInAxisLimits, (d) => d.gene)
            .attr("display", "inherit")
            .attr("x", (d) => this.xScale(d.x) + VolcanoComponent.LABEL_OFFSET.x * inverseScale + xOffset)
            .attr("y", (d) => this.yScale(d.y) + VolcanoComponent.LABEL_OFFSET.y * inverseScale + yOffset)
            .attr("font-size", VolcanoComponent.LABEL_FONT_SIZE * inverseScale);
        // hide points outside axis limits
        this.plot
            .selectAll("circle")
            .data(pointsNotInAxisLimits, (d) => d.gene)
            .attr("opacity", 0) // Set opacity to hide them
            // disable event listeners
            .on("mouseover", null)
            .on("mouseout", null)
            .on("click", null)
            .classed("out-of-view", true);
        // hide labels outside axis limits
        this.plot
            .selectAll(".volcano-label")
            .filter((_, i, nodes) => !labelInAxisLimits(_, i, nodes))
            .attr("display", "none");
        //#endregion
        // Apply the zoom transformation to the plot container
        // @ts-ignore
        this.plot.attr("transform", this.zt);
    }
    render() {
        VolcanoComponent.WIDTH = document.documentElement.clientWidth / 2.2;
        VolcanoComponent.HEIGHT = VolcanoComponent.WIDTH * 0.75;
        this.svgId = `volcano-${this.id}`;
        const { data: processedData, genesToSelectByDefault } = this.processData(this.data, this.genesToSelectByDefault);
        this._points = processedData;
        this.selections.forEach((s) => {
            // clear selection points and add the new data in
            s.resetData(this._points);
        });
        // this.dataBoundingBox = {
        //   xMin: Number(Math.min(...this.points.map((p) => p.x)).toFixed(3)),
        //   xMax: Number(Math.max(...this.points.map((p) => p.x)).toFixed(3)),
        //   yMin: Number(Math.min(...this.points.map((p) => p.y)).toFixed(3)),
        //   yMax: Number(Math.max(...this.points.map((p) => p.y)).toFixed(3)),
        // };
        // clear out the container
        d3.select(`#${this.svgId}`).selectAll("*").remove();
        const width = VolcanoComponent.WIDTH +
            VolcanoComponent.MARGIN.left +
            VolcanoComponent.MARGIN.right +
            VolcanoComponent.AXIS_LABEL_PADDING;
        const height = VolcanoComponent.HEIGHT +
            VolcanoComponent.MARGIN.top +
            VolcanoComponent.MARGIN.bottom +
            VolcanoComponent.TITLE_PADDING +
            VolcanoComponent.AXIS_LABEL_PADDING;
        const svg = d3
            .select(`#${this.svgId}`)
            .attr("width", width)
            .attr("height", height)
            // @ts-ignore
            .attr("viewBox", [0, 0, width, height]);
        // @ts-ignore
        this.plot = svg
            .append("g")
            .attr("transform", `translate(${VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING}, ${VolcanoComponent.MARGIN.top + VolcanoComponent.TITLE_PADDING})`);
        this.domain = {
            x: [d3.min(this._points, (d) => d.x), d3.max(this._points, (d) => d.x)],
            y: [d3.min(this._points, (d) => d.y), d3.max(this._points, (d) => d.y)],
        };
        const paddedDomain = {
            x: [
                this.domain.x[0] - VolcanoComponent.DATA_PADDING.left,
                this.domain.x[1] + VolcanoComponent.DATA_PADDING.right,
            ],
            y: [
                this.domain.y[0] - VolcanoComponent.DATA_PADDING.bottom,
                this.domain.y[1] + VolcanoComponent.DATA_PADDING.top,
            ],
        };
        this.xScale = d3
            .scaleLinear()
            .domain(paddedDomain.x)
            .range([0, VolcanoComponent.WIDTH]);
        this.zoomXScale = this.xScale;
        this.yScale = d3
            .scaleLinear()
            .domain(paddedDomain.y)
            .range([
            VolcanoComponent.HEIGHT -
                VolcanoComponent.MARGIN.top -
                VolcanoComponent.TITLE_PADDING,
            0,
        ]);
        this.zoomYScale = this.yScale;
        // set up zoom
        this.zoom = d3
            .zoom()
            .scaleExtent([0.5, 5])
            .on("zoom", this.handleZoom.bind(this));
        svg.call(this.zoom);
        // for each point in the data draw a circle
        this.drawData();
        // Add x-axis label
        svg
            .append("text")
            .attr("class", "x-axis-label")
            .attr("x", VolcanoComponent.WIDTH / 2 + VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING)
            .attr("y", VolcanoComponent.HEIGHT + VolcanoComponent.MARGIN.bottom)
            .attr("text-anchor", "middle")
            .text(`Log2 Fold Change`);
        // Add y-axis label
        svg
            .append("text")
            .attr("class", "y-axis-label")
            .attr("transform", "rotate(-90)")
            .attr("x", -VolcanoComponent.HEIGHT / 2)
            .attr("y", VolcanoComponent.AXIS_LABEL_PADDING)
            .attr("text-anchor", "middle")
            .text("-log10(p-adjusted)");
        // Add a title
        // svg
        //   .append("text")
        //   .attr("class", "title")
        //   .attr("x", VolcanoComponent.WIDTH / 2)
        //   .attr("y", -VolcanoComponent.MARGIN.top)
        //   .attr("text-anchor", "middle")
        //   .text("Differential Expression Volcano Plot")
        //   .style("font-size", "20px")
        //   .style("font-weight", "bold")
        // Add x-axis ticks
        // @ts-ignore
        this.xAxis = svg
            .append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(${VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING}, ${VolcanoComponent.HEIGHT})`)
            // @ts-ignore
            .call(d3.axisBottom(this.xScale));
        // Add y-axis ticks
        // @ts-ignore
        this.yAxis = svg
            .append("g")
            .attr("class", "y-axis")
            .attr("transform", `translate(${VolcanoComponent.MARGIN.left + VolcanoComponent.AXIS_LABEL_PADDING}, ${VolcanoComponent.MARGIN.top + VolcanoComponent.TITLE_PADDING})`)
            // @ts-ignore
            .call(d3.axisLeft(this.yScale));
        // Some weird stuff happens with point placement if we don't trigger zoom first. To fix this, reset the view, which briefly goes into zoom/pan mode and sets the zoom and origin to 0
        this.resetView();
        this.selectByStats();
        this.emitSelectionUpdate();
    }
    updateScatterThumbnailData() {
        try {
            // Step 1. Determine the last visualization that was rendered
            const visualizationEnumVal = OncoData.instance.currentCommonSidePanel._config.visualization;
            const visualizationName = VisualizationEnum[visualizationEnumVal];
            // Step 2. Get the data associated with the last visualization
            const currScatterData = OncoData.instance.lastData[visualizationName].results.data;
            const sids = currScatterData.sid;
            const positions = currScatterData.resultScaled;
            // Step 3. Get the cohort sids from the deaPayload
            let cohortA, cohortB;
            try {
                cohortA = this.deaPayload.data.data.cohortA;
                cohortB = this.deaPayload.data.data.cohortB;
            }
            catch (e) {
                cohortA = { sids: [], n: "Cohort A" };
                cohortB = { sids: [], n: "Cohort B" };
            }
            // Step 3. Map the data to the ScatterThumbnailPoint type
            this.scatterThumbnailData.emit(positions.map((position, idx) => {
                const sid = sids[idx];
                // determine the color based on the cohort. Cohort A (test) is upregulated, cohort B (ref) is downregulated
                let color = undefined;
                let label = "Rest of data";
                let cohort = null;
                if (cohortA.sids.includes(sid)) {
                    color = new THREE.Color(this.selectByStatsForm.upregulatedColor);
                    label = cohortA.n;
                    cohort = 'A';
                }
                else if (cohortB.sids.includes(sid)) {
                    color = new THREE.Color(this.selectByStatsForm.downregulatedColor);
                    label = cohortB.n;
                    cohort = 'B';
                }
                return {
                    position,
                    color,
                    label,
                    cohort
                };
            }));
        }
        catch (e) {
            console.warn('unable to get scatter thumbnail data. Try a newer DEA job');
            this.scatterThumbnailData.emit([]);
        }
    }
    // #endregion
    // #region lifecycle events
    ngAfterViewInit() {
        this.render();
    }
    ngOnInit() {
        this.selections$.next(this.selections);
        this.layout.setEnabledTabs(this.tabs);
        window.addEventListener("resize", this.render.bind(this));
    }
}
VolcanoComponent.AXIS_LABEL_PADDING = 20;
VolcanoComponent.TITLE_PADDING = 0;
VolcanoComponent.MARGIN = { top: 20, right: 20, bottom: 30, left: 30 };
VolcanoComponent.DATA_PADDING = { top: 5, right: 0.5, bottom: 0, left: 0.5 };
// defined on render
VolcanoComponent.WIDTH = 0;
// defined on render
VolcanoComponent.HEIGHT = 0;
VolcanoComponent.COLOR_UNSELECTED = "#454444";
VolcanoComponent.COLOR_SELECTED = "black";
VolcanoComponent.POINT_RADIUS = 3;
VolcanoComponent.LABEL_FONT_SIZE = 10;
VolcanoComponent.LABEL_OFFSET = {
    x: 4,
    y: -4,
};
