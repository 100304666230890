var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Khonsole } from 'app/khonsole';
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material';
import { GraphConfig } from 'app/model/graph-config.model';
import { DataFieldFactory } from '../../../model/data-field.model';
import { EntityTypeEnum, DataTypeEnum } from '../../../model/enum.model';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { ChartScene } from '../chart/chart.scene';
import { MatDialog } from '@angular/material/dialog';
import { ColorbyForm } from './colorby-form/colorby-form.component';
import { OncoData } from 'app/oncoData';
export class ConfigureLegendPanelComponent {
    constructor(dialog, cd) {
        this.dialog = dialog;
        this.cd = cd;
        this.dragDialogRefs = new Array();
        this.showPanel = new EventEmitter();
        this.threeDOption = new EventEmitter();
        this.decoratorAdd = new EventEmitter();
        this.decoratorDel = new EventEmitter();
        this.legendFilterAdd = new EventEmitter();
        this.legendFilterDel = new EventEmitter();
        this.genesetOptions = [];
        this.nClusters = 3;
        this._decorators = [];
        this._isNewDatasetInConfig = false;
        this.tableName = "CNA";
        this.id = "IDH1";
    }
    ngOnInit() {
        Khonsole.warn("======= ConfigureLegendPanelComponent OnInit");
        Khonsole.log("tables...");
        Khonsole.dir(this.tables);
        Khonsole.log("fields...");
        Khonsole.dir(this.fields);
    }
    addDragDialogRef(dialogRef, datasetScope, visualizationScope) {
        let ddr = {
            dialogRef: dialogRef,
            datasetScope: datasetScope,
            visualizationScope: visualizationScope
        };
        this.dragDialogRefs.push(ddr);
    }
    closeDragDialogByRef(mdr) {
        Khonsole.warn("DragDialog  entered closeDragDialogByRef");
        // use splice to remove the item with that exact MatDialogRef.
        const foundIndex = this.dragDialogRefs.findIndex(element => element.dialogRef === mdr);
        if (foundIndex > -1) {
            Khonsole.warn("DragDialog ... found dialogRef in array...");
            this.dragDialogRefs.splice(foundIndex, 1);
            Khonsole.warn("DragDialog ... after splicing out the ref, array length=" + this.dragDialogRefs.length);
        }
    }
    closeDragDialogsInScope(datasetScope, visualizationScope) {
        Khonsole.warn("CHECKING closeDragDialogs");
        // use splice to remove each item that matches any scope condition.
        this.dragDialogRefs.forEach(function (item, index, object) {
            Khonsole.warn("test ddr...");
            if (item.datasetScope || item.visualizationScope) {
                Khonsole.warn("SCOPE match.");
                object.splice(index, 1);
                try {
                    item.dialogRef.close();
                }
                catch (ex) {
                    Khonsole.warn("dialogRef might already be closed?");
                }
            }
        });
    }
    set content(content) {
        if (content) { // initially setter gets called with undefined
            this.colorbyForm = content;
        }
    }
    customizeGenesets(overlayNum) {
        if (overlayNum == 1) {
            if (this.firstGenesetOverlaySelectComponent.panelOpen) {
                this.firstGenesetOverlaySelectComponent.toggle();
            }
        }
        else {
            if (this.secondGenesetOverlaySelectComponent.panelOpen) {
                this.secondGenesetOverlaySelectComponent.toggle();
            }
        }
        this.showPanel.emit(32 /* GENESET */);
    }
    formatGenesetOverlayForDropdown(gs) {
        if (gs.n == 'None') {
            return 'None';
        }
        else {
            return `${gs.n} (${gs.g.length} genes)`;
        }
    }
    chartFromConfigGraphenum() {
        let viewIndex = 0; // GRAPH_A
        if (this.config.graph == 2 /* GRAPH_B */) {
            viewIndex = 1;
        }
        if (this.config.graph == 4 /* EDGES */) {
            viewIndex = 2;
        }
        let chart = ChartScene.instance.views[viewIndex].chart;
        return chart;
    }
    setGenesetOverlay(position, event) {
        Khonsole.log(`setGenesetOverlay for pos ${position}.`);
        if (position == 1) {
            this._config.firstGenesetOverlay = event.value;
        }
        else {
            this._config.secondGenesetOverlay = event.value;
        }
        Khonsole.log('TBD: ==== Take this view and recreate the objects (genes, w/ colors)');
        ;
        let chart = this.chartFromConfigGraphenum();
        if (chart['recreate']) {
            let c = chart;
            c.recreate();
        }
        ChartScene.instance.render();
    }
    set genesets(v) {
        this.genesetOptions = [{ n: 'None', g: [] }, ...v];
    }
    set decorators(value) {
        if (value === null) {
            return;
        }
        this._decorators = value;
    }
    shouldShowLegendOption(config, option) {
        // Find the option in the list of legendOptions and see if it's enabled.
        const optionInListResult = config.legendOptions.find((e) => e.type.toString() === option);
        const optionInList = optionInListResult ? optionInListResult.enabled : false;
        // support legacy options that may be in an updates.txt
        const capitalizedOption = option.charAt(0).toUpperCase() + option.slice(1);
        const legacyOptionTrue = config[`enable${capitalizedOption}`] && config[`enable${capitalizedOption}`] === true;
        return optionInList || legacyOptionTrue;
    }
    getGenesetOptions() {
    }
    defaultMarkerBaseSize() {
        return this._config.getLegendOptionValue("markerBaseSize" /* MARKER_BASE_SIZE */);
    }
    defaultMarkerOpacity() {
        return this._config.getLegendOptionValue("markerOpacity" /* MARKER_OPACITY */);
    }
    get config() {
        return this._config;
    }
    set config(config) {
        this.closeDragDialogsInScope(true, true);
        this._isNewDatasetInConfig = false;
        if (!this._config) {
            this._config = GraphConfig.cloneFromAny(JSON.parse(JSON.stringify(config)));
            this.updateFields();
            this._isNewDatasetInConfig = true;
        }
        else if (this._config.entity !== config.entity || this._config.visualization != config.visualization || this._config.database != config.database) {
            this._config = GraphConfig.cloneFromAny(JSON.parse(JSON.stringify(config)));
            this.updateFields();
            this._isNewDatasetInConfig = true;
        }
        if (this._config.isLegendOptionEnabled("genesetOverlay" /* GENESET_OVERLAY */)) {
            if (this.genesetOptions.length > 1) {
                if (this._config.firstGenesetOverlay.n != 'None') {
                    this.firstGenesetOverlaySelected = this.genesetOptions.find(e => e.n == this._config.firstGenesetOverlay);
                    if (this.firstGenesetOverlaySelected == null) {
                        Khonsole.error(`ERROR:1 Could not find overlay geneset ${this._config.firstGenesetOverlay} in graph panel data.`);
                        this.firstGenesetOverlaySelected = this.genesetOptions[0]; // 'None'
                    }
                }
                if (this._config.secondGenesetOverlay.n != 'None') {
                    this.secondGenesetOverlaySelected = this.genesetOptions.find(e => e.n == this._config.secondGenesetOverlay);
                    if (this.secondGenesetOverlaySelected == null) {
                        Khonsole.error(`ERROR: 2 Could not find overlay geneset ${this._config.secondGenesetOverlay} in graph panel data.`);
                        this.secondGenesetOverlaySelected = this.genesetOptions[0]; // 'None'
                    }
                }
            }
            else {
                // No genesets passed, so we are forced to use None.
                this.firstGenesetOverlaySelected = this.genesetOptions[0];
                this.secondGenesetOverlaySelected = this.genesetOptions[0];
            }
        }
        if (window["oncoscapeConfigureLegendPanelRefs"] == null) {
            window["oncoscapeConfigureLegendPanelRefs"] = [null, null];
        }
        let viewIndex = 0; // GRAPH_A
        if (this._config.graph == 2 /* GRAPH_B */) {
            viewIndex = 1;
        }
        window["oncoscapeConfigureLegendPanelRefs"][viewIndex] = this;
    }
    set tables(tables) {
        Khonsole.log("hide meningioma CNA molecularColorOptions...");
        let tablesToUse = [...tables];
        if ((this._config.database.startsWith("meningiomaumap") || this._config.database == "version_89.zip_2024-01-02T18-23-39.15_8820")) {
            tablesToUse = tables.filter(t => t.tbl.toUpperCase() != "CNA");
        }
        this.molecularColorOptions = DataFieldFactory.getMolecularColorFields(tablesToUse);
        this.molecularShapeOptions = DataFieldFactory.getMolecularShapeFields(tablesToUse);
        this.molecularSizeOptions = DataFieldFactory.getMolecularSizeFields(tablesToUse);
        this.molecularLabelOptions = DataFieldFactory.getMolecularLabelOptions(tablesToUse);
        this.sampleColorByOptions = DataFieldFactory.getSampleColorByFields(tablesToUse);
        // Don't call this.updateFields() here, only in response to fields setting (below)
    }
    set fields(fields) {
        let fieldsWithoutHiddens = fields;
        let ux = OncoData.instance.dataLoadedAction.datasetTableInfo.ux;
        if (ux && ux.hidden_metadata) {
            //  && ux.hidden_metadata.indexOf("spreadsheet") >= 0
            fieldsWithoutHiddens = fields.filter(f => {
                let key_to_use = f.key;
                return ux.hidden_metadata.indexOf(key_to_use) < 0;
            });
        }
        this.clinicalColorOptions = DataFieldFactory.getSampleColorFields(fieldsWithoutHiddens);
        this.clinicalShapeOptions = DataFieldFactory.getSampleShapeFields(fieldsWithoutHiddens);
        this.clinicalSizeOptions = DataFieldFactory.getSampleSizeFields(fieldsWithoutHiddens);
        this.clinicalLabelOptions = DataFieldFactory.getSampleLabelFields(fieldsWithoutHiddens);
        this.updateFields();
    }
    byKey(p1, p2) {
        if (p2 == null) {
            return false;
        }
        try {
            return p1.label === p2.label;
        }
        catch (ex) {
            Khonsole.error("byKey failure in graph panel vis. p1 and p2 follow...");
            Khonsole.dir(p1);
            Khonsole.dir(p2);
        }
    }
    byN(p1, p2) {
        if (p2 == null) {
            return false;
        }
        return p1.n === p2.n;
    }
    customizeColorOptions() {
        //  Khonsole.log('MJ customizeColorOptions is empty in graph-panel-visualization');
    }
    updateFields() {
        if (!this._config || !this.molecularColorOptions || !this.clinicalColorOptions) {
            return;
        }
        Khonsole.log("==updateFields");
        if (this.config.entity === EntityTypeEnum.GENE) {
            this.colorOptions = [...this.molecularColorOptions];
            this.shapeOptions = [...this.molecularShapeOptions];
            this.sizeOptions = [...this.molecularSizeOptions];
            this.labelOptions = [...this.molecularLabelOptions];
        }
        else {
            this.colorOptions = [...this.clinicalColorOptions];
            this.shapeOptions = [...this.clinicalShapeOptions];
            this.sizeOptions = [...this.clinicalSizeOptions];
            this.labelOptions = [...this.clinicalLabelOptions];
            if (this.sampleColorByOptions.length > 0 && (this.config.entity === EntityTypeEnum.SAMPLE)) {
                this.colorOptions = this.colorOptions.concat(this.sampleColorByOptions);
            }
        }
        this.colorDataFieldSelected = DataFieldFactory.getUndefined();
        this.shapeSelected = DataFieldFactory.getUndefined();
        this.sizeSelected = DataFieldFactory.getUndefined();
        this.labelSelected = DataFieldFactory.getUndefined();
        this.baseSizeSelected = DataFieldFactory.getUndefined();
        this.opacitySelected = DataFieldFactory.getUndefined();
        this.setDefaultColorDecoratorIfNeeded();
    }
    findColorDecorator() {
        let dec = ChartScene.instance.views[0].chart.decorators
            .filter(v => v.type == 0 /* COLOR */);
        if (dec.length > 0) {
            return dec[0];
        }
        else {
            return null;
        }
    }
    colorDecoratorHasInvisibles() {
        let dec = this.findColorDecorator();
        if (dec) {
            if (dec.legend.visibility) {
                return (dec.legend.visibility.filter(v => v < 0.5).length > 0);
            }
            else {
                Khonsole.warn('dec.legend.visibility is null');
                return false;
            }
        }
        else {
            return false;
        }
    }
    applyColorbyFormChoice(event) {
        Khonsole.warn("In GPVC applyColorbyFormChoice...");
        Khonsole.dir(event);
        this.processSetColorOption(event);
        // self.id = result;
        // let marker= result; // prompt("Enter a marker name with which to color the points.", lastMarker)
        // if(marker != null && marker!= ""){
        //   Khonsole.warn("== nmeed to process color ==");
        //   // event.value.label = labelParts[0] + "=" + marker;
        //   // self.processSetColorOption(event);
        // } else{
        //   return; // cancel color change?
        // }
    }
    // openColorByDialog(event: MatSelectChange): void {
    //   let labelParts = event.value.label.split("="); // ColorBy:gistic=BRCA2
    //   this.tableName = labelParts[0].split(":")[1]; // e.g. gistic
    //   this.id = labelParts.length>1 ? labelParts[1] : "";
    //   Khonsole.log('openColorByDialog in ... GPVC');
    //   let database = OncoData.instance.dataLoadedAction.dataset;
    //   Khonsole.time("loadIds");
    //   const db = new Dexie('notitia-' + database);
    //   let self = this;
    //   db.open().then(v => {
    //     Khonsole.log("DB ready to get keys for colorby tbl " + self.tableName +".")
    //     // v._allTables["cna"].toCollection().keys()
    //     v.table(self.tableName).toCollection().keys(function (identifiers) {
    //       Khonsole.timeEnd("loadIds");
    //       // We have the keys for this table, so ready to pop up dialog.
    //       const dialogRef = self.dialog.open(DialogColorby, {
    //         hasBackdrop: false,
    //         autoFocus: true,
    //         disableClose: true,
    //         data: {tableName: self.tableName, id: self.id, ids: identifiers, event: event},
    //       });
    //       self.addDragDialogRef(dialogRef, true, true);
    //       const subOnApply = dialogRef.componentInstance.onApply.subscribe((data) => {
    //         Khonsole.warn('DIALOG subscriber sees: ' + (data ? data : "null").toString());
    //         let result = data;
    //         if (result) {
    //           self.id = result;
    //           let marker= result; // prompt("Enter a marker name with which to color the points.", lastMarker)
    //           if(marker != null && marker!= ""){
    //             event.value.label = labelParts[0] + "=" + marker;
    //             self.processSetColorOption(event);
    //           } else{
    //             return; // cancel color change?
    //           }
    //         }
    //       });
    //       dialogRef.keydownEvents().subscribe(event => {
    //         Khonsole.log('DragDialog keydownEvents.');
    //         Khonsole.log(event.key)
    //       });
    //       dialogRef.afterOpened().subscribe(result => {
    //         Khonsole.log('DragDialog afterOpened.');
    //       });
    //       dialogRef.beforeClosed().subscribe(result => {
    //         Khonsole.log('DragDialog beforeClosed.');
    //       });
    //       dialogRef.afterClosed().subscribe(result => {
    //         subOnApply.unsubscribe();
    //         self.closeDragDialogByRef(dialogRef);
    //         // self.dialogRef = undefined
    //         Khonsole.log('DragDialog afterClosed.');
    //       });
    //     });
    //   });
    // }
    _updatesHasDecorators() {
        let updates = WorkspaceComponent.instance.updatesIncomingData;
        if (updates && updates.decorators && updates.decorators.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
    bestDefaultColoringField() {
        Khonsole.warn("==== bestDefaultColoringField ==");
        let found = 1;
        found = this.colorOptions.findIndex(x => x.key.toLocaleLowerCase() == "who_grade");
        if (found >= 0) {
            return found;
        }
        found = this.colorOptions.findIndex(x => x.key.toLocaleLowerCase() == "dataset");
        if (found >= 0) {
            return found;
        }
        found = this.colorOptions.findIndex(x => x.key.toLocaleLowerCase() == "diagnosis");
        if (found >= 0) {
            return found;
        }
        found = this.colorOptions.findIndex(x => x.key.toLocaleLowerCase() == "vital_status");
        if (found >= 0) {
            return found;
        }
        if (this.colorOptions.length > 1) {
            return 1; // First option after "None"
        }
        else {
            return 0; // None
        }
    }
    // Call this only if it's a new dataset.
    setDefaultColorDecoratorIfNeeded() {
        Khonsole.warn("== setDefaultColorDecoratorIfNeeded");
        let self = this;
        if (this._isNewDatasetInConfig && this._decorators.length == 0 && this._updatesHasDecorators() == false) {
            if (this.colorOptions.length > 1) {
                let fieldIndexToUse = this.bestDefaultColoringField();
                if (fieldIndexToUse == -1) {
                    Khonsole.warn("No bestDefaultColoringField found.");
                }
                else {
                    let dataFieldtoUse = this.colorOptions[fieldIndexToUse]; // Just skip the [0]"None" DataField for now. TBD: Add smarts to look for best field, like survival status.
                    let clonedDataField = __rest(dataFieldtoUse, []);
                    let matSelectChange = new MatSelectChange(null, clonedDataField);
                    this.processSetColorOption(matSelectChange);
                }
            }
        }
        this._isNewDatasetInConfig = false;
    }
    processSetColorOption(event) {
        Khonsole.log("processSetColorOption");
        Khonsole.dir(event);
        this.colorDataFieldSelected = event.value;
        if (this.colorDataFieldSelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 0 /* COLOR */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 0 /* COLOR */,
                    field: this.colorDataFieldSelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    // setColorOption(event: MatSelectChange): void {
    //   Khonsole.log('setColorOption. If has inviz, check old colorDec.');
    //   let colorDec = this.findColorDecorator();
    //   Khonsole.dir(colorDec);
    //   // Save a LegendFilter if desired. These add/stack,
    //   // so we don't delete currrent one before adding a new one.
    //   if (this.colorDecoratorHasInvisibles()) {
    //     let p = window.confirm(`Do you want to save your "${colorDec.field.label}" filter?" `)
    //     if(p){
    //       let lf:LegendFilter = {
    //       legend: null,
    //       excludedValues: [],
    //       excludedItemIndexes: []
    //       }
    //       this.legendFilterAdd.emit({
    //         config: this.config,
    //         legendFilter: lf
    //       });
    //     }
    //   }
    //   if(event.value.key == "ColorBy"){
    //     this.openColorByDialog(event);
    //     return; // async processing will eventually hit processSetColorOption in afteClosed() of openColorByDialog.
    //   }
    //   // If we don't need async dialog, just emit the change now.
    //   this.processSetColorOption(event);
    // }
    setShapeOption(event) {
        this.shapeSelected = event.value;
        if (this.shapeSelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 2 /* SHAPE */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 2 /* SHAPE */,
                    field: this.shapeSelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    setBaseSizeOption(event) {
        this.baseSizeSelected = {
            key: event.value.toString(),
            label: event.value.toString(),
            type: DataTypeEnum.NUMBER,
            tbl: 'patient',
            values: [event.value],
            ctype: 0 /* UNDEFINED */
        };
        if (this.baseSizeSelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 4096 /* BASE_SIZE */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 4096 /* BASE_SIZE */,
                    field: this.baseSizeSelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    setOpacityOption(event) {
        this.opacitySelected = {
            key: event.value.toString(),
            label: event.value.toString(),
            type: DataTypeEnum.NUMBER,
            tbl: 'patient',
            values: [event.value],
            ctype: 0 /* UNDEFINED */
        };
        if (this.opacitySelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 8192 /* OPACITY */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 8192 /* OPACITY */,
                    field: this.opacitySelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    setSizeOption(event) {
        this.sizeSelected = event.value;
        if (this.sizeSelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 1 /* SIZE */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 1 /* SIZE */,
                    field: this.sizeSelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    setLabelOption(event) {
        this.labelSelected = event.value;
        if (this.labelSelected.key === 'None') {
            this.decoratorDel.emit({
                config: this.config,
                decorator: {
                    type: 16 /* LABEL */,
                    values: null,
                    field: null,
                    legend: null
                }
            });
        }
        else {
            this.decoratorAdd.emit({
                config: this.config,
                decorator: {
                    type: 16 /* LABEL */,
                    field: this.labelSelected,
                    legend: null,
                    values: null
                }
            });
        }
    }
    setNClusters(num) {
        this.nClusters = num;
    }
    applyCluster(type) {
        this.decoratorAdd.emit({
            config: this.config,
            decorator: {
                type: 512 /* CLUSTER_MINIBATCHKMEANS */,
                field: {
                    key: this.nClusters.toString(),
                    label: this.nClusters.toString(),
                    type: DataTypeEnum.FUNCTION,
                    tbl: 'na',
                    values: 'na',
                    ctype: 0 /* UNDEFINED */
                },
                legend: null,
                values: null
            }
        });
    }
}
