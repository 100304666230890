import { GraphConfig } from 'app/model/graph-config.model';
import { DataDecorator } from 'app/model/data-map.model';
import { TooltipConfig } from 'ngx-bootstrap';
import { TooltipOverride, DatasetTour, TableIdList } from './dataset-table-info.model';
import { NavItem } from 'app/component/workspace/common-side-panel/nav-item'

/** DatasetKeyMetric is used to let us sort or filter rows of a table. 
An example use case is STTR-428:
  "Each FUSIONS color by option should have an associated value 
  of how many samples have a value of 1 / Yes / 1 To 1. 
  Then, sort the drop - down by this, descending.
  Show it in parenthesis next to the fusion label."
  **/
export class TableKeyMetric {
  metricName: string; // unique name for this metric
  metricTable: string; // Possible for single table to have multiple metrics
  metricDescription?: string;
  metricValues: number[]; // value for this metric of each row in table, in table's original order
} 

export class DatasetUpdates {
  public codeBuild: number = 0;
  public version: number = 0;
  public title: string;
  public savedPoints = [];
  public defaultVizConfig:GraphConfig = null;
  public decorators:Array<DataDecorator> = [];
  public tooltips:Array<TooltipOverride> = [];
  public customColors = {}; // dictionary-like object, entries of form: "Sample group!gtex": "\"#9ACD32\"".  Note the "!" between legend and item names.
  public tour?:DatasetTour = null;
  public table_ids?: Array<TableIdList> = [];
  public navItems?: NavItem[] = [];
  public ux?: {
    disabled?: String[];
    hidden?: String[];
  };
  public tableKeyMetrics?: TableKeyMetric[] = [];
}
