<div class='infoPanel'>
  <div>
    <span *ngFor='let item of message'>
      <span class='infoPanelKey'>{{item.key}}</span><span class='infoPanelValue'>{{item.value}}</span>
    </span>
  </div>
</div>
<div class="chart-view-controls-wrapper" *ngIf="ConfigAIsSavedPointsVisualization() && DataLoadedActionHasMultipleSavedPoints()">

  <!-- <pre class="card card-block card-header">{{radioModel || 'null'}}</pre>
  <div class="form-inline d-flex align-items-center"> -->
    <!--
    <div class="btn-group" btnRadioGroup [(ngModel)]="radioModel" [disabled]="modelGroupDisabled">
      <label class="btn btn-success mb-0" btnRadio="Left">Left</label>
      <label class="btn btn-success mb-0" btnRadio="Middle">Middle</label>
      <label class="btn btn-success mb-0" btnRadio="Right">Right</label>
    </div>
  -->

  <div  class="chart-view-controls">View:&nbsp;&nbsp;

    <div class="btn-group" role="group" aria-label="Basic radio toggle button group" *ngFor="let enum of savedpoints_view_details">
            <input type="radio" class="btn-check btn-sm" name="btnradio" id="btnradio_{{enum.id}}" autocomplete="off" (click)="viewButtonsClick(enum)" [checked]="isCurrentButton(enum)" >
            <label class="btn btn-outline-primary btn-sm" for="btnradio_{{enum.id}}">{{enum.name}}</label>
    </div>

  </div>
</div>


