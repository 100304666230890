<div class="volcano-container" [ngClass]="{ 'full-screen': layout.isFullScreen }">
  <div>
    <div id="top-bar" *ngIf="showPlotControls && !plotOnly">
      <div id="top-bar-left">
        <button
          mat-icon-button
          matTooltip="Toggle Fullscreen"
          (click)="layout.toggleFullScreen()"
          id="toggle-fullscreen-button"
        >
          <mat-icon *ngIf="layout.isFullScreen">fullscreen_exit</mat-icon>
          <mat-icon *ngIf="!layout.isFullScreen">fullscreen</mat-icon>
        </button>
        <!-- save button -->

        <app-download-plot [plotSvgId]="svgId" plotId="volcano"></app-download-plot>
        <button
        id="scatter-thumbnail-toggle"
        class="skinny-button"
        style="font-size: 10px; max-height: 22px"
        mat-flat-button
        color="primary"
        [disabled]="!canAccessScatterThumbnail"
        [matTooltip]="scatterThumbnailBtnTooltip"
        [matTooltipDisabled]="canAccessScatterThumbnail"
        (click)="scatterThumbnailVisible = !scatterThumbnailVisible"
      >
        {{scatterThumbnailVisible ? 'hide' : 'show'}} mini-scatter
    </button>
      </div>

      <div id="top-bar-right">
        <div id="mode" class="tiny-btn-group">
          <p>Mode</p>
            <mat-button-toggle-group  aria-label="Mode"  style="height: 30px" [value]="mode">
              <mat-button-toggle value="select" (click)="setMode('select')">
                <mat-icon style="font-size: small" matTooltip="Select"
                  >touch_app</mat-icon
                >
              </mat-button-toggle>

              <mat-button-toggle value="panZoom" (click)="setMode('panZoom')">
                <mat-icon style="font-size: small" matTooltip="Pan/Zoom"
                  >open_with</mat-icon
                >
              </mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle-group  aria-label="Mode"  style="height: 30px; margin-left: 10px" class="t-flip-flop">
              <mat-button-toggle (click)="resetView()">
                <mat-icon style="font-size: small" matTooltip="Reset View"
                  >home</mat-icon
                >
              </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div id="regulation-colors">
          <mat-form-field appearance="none">
            <input
              matInput
              type="color"
              [value]="selectByStatsForm.downregulatedColor"
              (change)="updateRegulationColor($event.target.value, 'down')"
            />
            <span class="label">Downregulated gene color</span>
          </mat-form-field>
          <br />
          <mat-form-field appearance="none">
            <input
              matInput
              type="color"
              [value]="selectByStatsForm.upregulatedColor"
              (change)="updateRegulationColor($event.target.value, 'up')"
              style="display: inline-block"
            />
            <span class="label">Upregulated gene color</span>
          </mat-form-field>
        </div>
      </div>

    </div>

    <svg [id]="'volcano-' + id"></svg>

    <!--  *ngIf="shouldShowGOTermSelectionButtons()" -->

    <div id="go-term-selection-buttons" class="tiny-btn-group" *ngIf="shouldShowGOTermSelectionButtons()">
      <p>Enrichment Analysis actions</p>
        <mat-button-toggle-group  aria-label="Mode"  style="height: 30px">
          <mat-button-toggle (click)="exitGOTermSelection()">
            Exit Enrichment Analysis highlight mode
          </mat-button-toggle>

          <mat-button-toggle (click)="selectFromTermOverlap()">
            Create selection from overlap
          </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
  </div>

  <div id="side-bar" *ngIf="showSidebar && !plotOnly">
    <!-- Select By Statistical Thresholds -->
    <div *ngIf="showSelectByStatsForm">
      <mat-expansion-panel
      [hideToggle]="false"
      [expanded]="true"
      (afterCollapse)="layout.setPanelCollapsed('selectByStats', true)"
      (afterExpand)="layout.setPanelCollapsed('selectByStats', false)"
      id="select-by-stats-options-panel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title style="text-transform: none"
          >Select by Statistical Thresholds</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div id="select-by-stats">
        <div id="p-adj-input-container">
          <p id="title">P-Adjusted</p>
          <mat-form-field style="width: 100px">
            <mat-label>-log<sub>10</sub>(p-adjusted)</mat-label>
            <input
              matInput
              type="number"
              id="nlogpadj"
              name="nlogpadj"
              step="0.05"
              [value]="selectByStatsForm.nlogpadj"
              (change)="updateSelectByStatsForm($event)"
            />
          </mat-form-field>

          <span class="slash"></span>

          <mat-form-field style="width: 100px">
            <mat-label>p-adjusted</mat-label>
            <input
              matInput
              type="number"
              id="padj"
              name="padj"
              step="0.001"
              [value]="selectByStatsForm.padj"
              (change)="updateSelectByStatsForm($event)"
            />
          </mat-form-field>
        </div>

        <div id="fc-input-container">
          <p id="title">Fold Change</p>
          <mat-form-field style="width: 100px">
            <mat-label>Log2 Fold Change</mat-label>
            <input
              matInput
              type="number"
              id="log2FoldChange"
              name="log2FoldChange"
              step="0.05"
              [value]="selectByStatsForm.log2FoldChange"
              (change)="updateSelectByStatsForm($event)"
            />
          </mat-form-field>

          <span class="slash"></span>

          <mat-form-field style="width: 100px">
            <mat-label>Fold Change</mat-label>
            <input
              matInput
              type="number"
              id="foldChange"
              name="fc"
              step="0.05"
              [value]="selectByStatsForm.fc"
              (change)="updateSelectByStatsForm($event)"
            />
          </mat-form-field>
        </div>
        <div>
          <button mat-flat-button class="skinny-button" color="primary" (click)="selectByStats()">
            Reselect
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    </div>

    <div style="flex: 1; display: flex; flex-direction: column;" id="tabs-container">
      <mat-tab-group preserveContent style="display: flex; flex-direction: column; min-height: 100%" (selectedTabChange)="selectedTabChange($event)">

        <mat-tab label="Table" id="table-tab" *ngIf="layout.isTabEnabled('table')">
          <app-volcano-gene-table
        [upregulatedColor]="selectByStatsForm.upregulatedColor"
        [downregulatedColor]="selectByStatsForm.downregulatedColor"
        [getGeneRegulation]="getGeneRegulation.bind(this)"
        [points]="points"
        [selectionObservable]="selectionOfType$('Standard')"
        (selectionChanged)="labelPoints($event)"
      ></app-volcano-gene-table>
        </mat-tab>
        <mat-tab label="Enrichment Analysis (GSEA)" id="enrichment-dotplot-tab" *ngIf="layout.isTabEnabled('enrichmentAnalysis')">
          <app-enrichment-analysis
          [id]="id"
          [selectionObservable]="selectionOfType$('Standard')"
          [downregulatedColor]="selectByStatsForm.downregulatedColor"
          [upregulatedColor]="selectByStatsForm.upregulatedColor"
          [getGeneRegulation]="getGeneRegulation.bind(this)"
          (onmouseclick)="handleEAmouseclick($event)"
          (onmouseover)="handleEAmouseover($event)"
          (onmouseout)="handleEAmouseout()"
          [active]="layout.activeTab === 'enrichmentAnalysis'"></app-enrichment-analysis>
        </mat-tab>

      </mat-tab-group>
    </div>



    <!-- <div *ngIf="emittedPoints.length === 0" id="no-genes">
      <h4>Interactivity Tips</h4>
      <p>- Click on a point to select a gene</p>
      <p>- Hold down the shift key to select multiple points</p>
      <p>- Drag to select a region</p>
      <p>- Drag while holding shift to add a region to the selection</p>
      <p>- Drag while holding alt to deselect a region</p>
    </div>
  </div> -->
  </div>
</div>
<app-volcano-scatter-thumbnail
[volcanoSvgId]="'volcano-' + id"
[data]="scatterThumbnailData"
[visible]="scatterThumbnailVisible"
(close)="scatterThumbnailVisible = false"
[width]="150"
[height]="150"
></app-volcano-scatter-thumbnail>
