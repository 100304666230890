<div>
  <!-- Sign In -->
  <div *ngIf="activeForm === 'SIGN_IN'">
    <div class="user-panel-intro">
      The following datasets are private. Sign in or sign up to upload or view datasets.
    </div>
    <form [formGroup]="formGroupSignIn" (submit)="signInIfNeeded()">
      <div>
        <mat-error class="user-panel-warning">{{ errorMessage }}</mat-error>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input class="sign-in-margin" matInput placeholder="Email" formControlName="email" autocomplete="email" />
          <mat-error *ngIf="formGroupSignUp.controls['email'].hasError('required')">Valid Email Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" formControlName="password" type="password" autocomplete="off" />
          <mat-error *ngIf="formGroupSignUp.controls['password'].hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="user-panel-button">
        <button mat-raised-button type="submit" color="primary" style="vertical-align: text-bottom;margin-right:10px;">
          Sign In
        </button>
        <button (click)="setForm('SIGN_UP')" mat-raised-button type="submit" color="primary"
          style="vertical-align: text-bottom;margin-right:10px;">
          Sign Up
        </button>
      </div>
      <div class="user-panel-links">
        <div class="user-panel-link">
          <div class="os-link" (click)="setForm('FORGOT_PASSWORD')">Forgot Password</div>
        </div>
      </div>
    </form>
  </div>
  <!-- Sign Up -->
  <div *ngIf="activeForm === 'SIGN_UP'">
    <div class="user-panel-intro">
      When signing up for an account please be aware that you are agreeing to our
      <a class="os-link" href="/assets/terms.html">Terms and Conditions</a>.
      <div>
        Passwords must be a minimum of eight (8) characters and contain at least one (1) character from the following
        categories: uppercase and lowercase letter, digit, special character.
      </div>
    </div>
    <form [formGroup]="formGroupSignUp" (submit)="signUp()">
      <mat-error class="user-panel-warning">{{ errorMessage }}</mat-error>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="First Name" formControlName="firstName" autocomplete="given-name" />
          <mat-error *ngIf="formGroupSignUp.controls['firstName'].hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Last Name" formControlName="lastName" autocomplete="family-name" />
          <mat-error *ngIf="formGroupSignUp.controls['lastName'].hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" type="email" autocomplete="email" />
          <mat-error *ngIf="formGroupSignUp.controls['email'].hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" formControlName="password" type="password" autocomplete="off" />
          <mat-error *ngIf="formGroupSignUp.controls['password'].hasError('pattern')">See Password Requirements
          </mat-error>
        </mat-form-field>
      </div>
      <div class="user-panel-button">
        <mat-checkbox color="primary" class="uploadPanelCbx" formControlName="mailinglist">Join Oncoscape Mailing List
        </mat-checkbox>
      </div>
      <div class="user-panel-button">
        <button mat-raised-button type="submit" color="primary" style="margin-right:10px;">Sign Up</button>
      </div>
      <div class="user-panel-links">
        <div class="user-panel-link">
          <div class="os-link"(click)="setForm('SIGN_IN')">Sign In</div> |
          <!-- <div class="os-link" (click)="setForm('RESEND_CODE')">Resend Verification Code</div> -->
          <div class="os-link"(click)="setForm('FORGOT_PASSWORD')">Reset Password</div>
        </div>
      </div>
    </form>
  </div>
  <!-- Forgot Password -->
  <div *ngIf="activeForm === 'FORGOT_PASSWORD'">
    <!--
      <div>Password reset code has been sent. If you do not receive the email, please reach out to contact contact@oncoscape.org for assistance.</div>
    -->
    <div class="user-panel-intro">
      Please enter your email address below. An account verification code will be sent via email.
    </div>
    <mat-error class="user-panel-warning">{{ errorMessage }}</mat-error>
    <form [formGroup]="formGroupForgotPassword" (submit)="forgotPassword()">
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" autocomplete="email" />
          <mat-error *ngIf="formGroupForgotPassword.controls['email'].hasError('required')">Valid Email Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="user-panel-button">
        <button mat-raised-button type="submit" color="primary" style="margin-right:10px;">Reset Password</button>
      </div>
      <div class="user-panel-links">
        <div class="user-panel-link">
          <div class="os-link" (click)="setForm('SIGN_IN')">Return to Sign In</div>
          <!-- <div class="os-link"(click)="setForm('UPDATE_PASSWORD')">Verify Code</div> -->
        </div>
      </div>
    </form>
  </div>
  <!-- Sign Up Update Password -->
  <div *ngIf="activeForm === 'UPDATE_PASSWORD'">
    <form [formGroup]="formGroupUpdatePassword" (submit)="updatePassword()">
      <div class="user-panel-intro">Please check your email and enter the code below.</div>
      <mat-error class="user-panel-warning"> {{ errorMessage }}</mat-error>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" autocomplete="email" />
          <mat-error *ngIf="formGroupUpdatePassword.controls['email'].hasError('required')">Valid Email Required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width"><input matInput placeholder="Code" formControlName="code"
            autocomplete="off" />
          <mat-error *ngIf="formGroupUpdatePassword.controls['code'].hasError('required')">Required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="New Password" formControlName="password" type="password" autocomplete="off" />
          <mat-error *ngIf="formGroupUpdatePassword.controls['password'].hasError('pattern')">See Password Requirements
          </mat-error>
        </mat-form-field>
      </div>
      <div class="user-panel-button">
        <button mat-raised-button type="submit" color="primary">Update Password</button>
      </div>
      <div class="user-panel-links">
        <div class="user-panel-link">
          <div class="os-link" (click)="setForm('RESEND_CODE')">I didn't receive a code</div>
        </div>
      </div>
    </form>
  </div>

  <!-- IS THIS BEING USED?? -->
  <!-- Sign Up Confirmation -->
  <div *ngIf="activeForm === 'SIGN_UP_CONFIRM'">
    <div>Please check your email and enter the code below.</div>
    <form [formGroup]="formGroupSignUpConfirm" (submit)="signUpConfirm()">
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" autocomplete="email" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width"> <input matInput placeholder="Code" formControlName="code"
            autocomplete="off" />
        </mat-form-field>
      </div>
      <div>
        <button mat-raised-button type="submit" color="primary">Verify Code</button>
      </div>
      <div class="user-panel-links">
        <div class="user-panel-link"><a class="os-link" href="#" (click)="setForm('FORGOT_PASSWORD')">Forgot
            Password</a></div>
      </div>
    </form>
  </div>


  <!-- Resend Code -->
  <div *ngIf="activeForm === 'RESEND_CODE'">
    <form [formGroup]="formGroupResendCode" (submit)="resendCode()">
      <div>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" autocomplete="email" />
        </mat-form-field>
      </div>
      <div class="user-panel-button">
        <button mat-raised-button type="submit" color="primary" style="margin-right:10px;">Resend Code</button>
      </div>
      <!--
        <div class="user-panel-links">
          <div class="user-panel-link">
            <a class="os-link" href="#" (click)="setForm('SIGN_IN')">Sign In</a> |
            <a class="os-link" href="#" (click)="setForm('UPDATE_PASSWORD')">Verify Code</a>
          </div>
        </div>
      -->
    </form>
  </div>

  <!-- Add Data Set -->
  <div id="privateDataProjectListId" *ngIf="activeForm === 'PROJECT_LIST'">
    <div id="sharedProjectListId" *ngIf="hasSharedDatasets()">
      <h2>Datasets Shared With Me</h2>
      <div *ngFor="let option of sharedDatasets" class="panel-config-row-del-dataset-list">
        <i class="private-dataset-delete-icon material-icons md-18" attr.data-tippy-content="Delete?"
          (click)="deleteDataset(option)">delete_forever</i>
        <i class="private-dataset-hastip material-icons md-18"
          attr.data-tippy-content="{{composeDatasetTooltip(option)}}" (click)="showInfoDataset(option)">info_outline</i>
        <div class="private-dataset-hastip private-dataset-list-item"
          attr.data-tippy-content="{{composeDatasetTooltip(option)}}" (click)="loadDataset(option)">{{
          option.content.name
          }}</div>
      </div>
      <hr /><br />
    </div>

    <h2>My Datasets</h2>
    <div *ngFor="let option of myDatasets" class="panel-config-row-del-dataset-list">
      <i class="private-dataset-delete-icon material-icons md-18" attr.data-tippy-content="Delete?"
        (click)="deleteDataset(option)">delete_forever</i>
      <i class="private-dataset-hastip material-icons md-18" attr.data-tippy-content="{{composeDatasetTooltip(option)}}"
        (click)="showInfoDataset(option)">info_outline</i>
      <div class="private-dataset-hastip private-dataset-list-item"
        attr.data-tippy-content="{{composeDatasetTooltip(option)}}" (click)="loadDataset(option)">{{
        option.content.name }}</div>
    </div>

    <button mat-raised-button type="submit" color="primary" (click)="addDataSet()" style="margin-bottom:20px;">
      Add My Own Data Set
    </button>
  </div>
  <br /><br />
  Once you have an account and are signed in, you can upload your own private data set. See this video for a
  walkthrough...<br />
  <br /><br />
  <video height="300" controls>
    <source src="/assets/videos/upload_dataset.mp4" type="video/mp4">
  </video>
  <br /><br />
  Tip: a useful tool to help clean datasets is <a target="_blank" href="https://openrefine.org/">OpenRefine</a>.
  <br />
</div>
