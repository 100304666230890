import {
  Component,
  HostListener,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  OnInit,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Khonsole } from "app/khonsole";

@Component({
  selector: "app-pinnable-toolbar",
  templateUrl: "./pinnable-toolbar.component.html",
  styleUrls: ["./pinnable-toolbar.component.css"],
})
export class PinnableToolbarComponent implements OnInit {
  isPinned = true;
  content = "";

  constructor(
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.updateWindowSize();
  }

  ngOnInit() {
    this.updateWindowSize();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.updateWindowSize();
  }

  private updateWindowSize() {
    console.log(
      "Window width:",
      window.innerWidth,
      "Window height:",
      window.innerHeight
    );
    const toolbarElement =
      this.el.nativeElement.querySelector(".floating-toolbar");
    if (toolbarElement) {
      // Calculate the initial top position to be at least 50 and at most a certain height up from the bottom of the viewport
      const currentTop = window.innerHeight - toolbarElement.offsetHeight;
      const maxTop = window.innerHeight - 120;
      const safeTop = Math.min(currentTop, maxTop);
      this.renderer.setStyle(toolbarElement, "top", `${safeTop}px`);
      console.log("Top: ", currentTop, maxTop, safeTop);

      // Calculate left to be at least 30 and at most a certain distance from the right edge of the viewport
      const currentLeft = window.innerWidth - toolbarElement.offsetWidth;
      const maxLeft = Math.min(10, window.innerWidth - 350);
      const safeLeft = Math.min(currentLeft, maxLeft);
      this.renderer.setStyle(toolbarElement, "left", `${safeLeft}px`);
    }
  }

  togglePin() {
    this.isPinned = !this.isPinned;
    console.log("Pinned: ", this.isPinned);
    this.cdr.detectChanges();
  }

  setContent(content: string) {
    this.content = content;
    this.cdr.detectChanges();
  }

  private presentContent(): SafeHtml {
    let rawHtml = "<i>(hover over data to see tooltip)</i>";
    if (this.content != null && this.content != "") {
      rawHtml = this.content;
    }
    let sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    return sanitizedHtml;

  }
}
