import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import {
  JobOptions,
  JobType,
  Step,
  StepInput,
  StepResponse,
  Job,
  Status,
  StepResultType,
  LogLevel,
} from "../job.types";
import { IWorkerService, IWorker, WebworkerJMPayload } from "./webworker.jobManager.service.types";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { Observable as DexieObservable } from "dexie";
import { Khonsole } from "app/khonsole";
import { v4 as uuidv4 } from "uuid";
import { WorkspaceComponent } from "app/component/workspace/workspace.component";
import { Store } from "@ngrx/store";
import * as fromRoot from "app/reducer/index.reducer";
import { first, map } from "rxjs/operators";
import { DataService } from "../../data.service";
import Dexie, { liveQuery } from "dexie";
import { prepareJobForDatabase } from "../jobManager.utils";

type JobWithWorkerId = Job & { workerId: number };

@Injectable({
  providedIn: "root",
})
export class WebworkerJobManagerService implements IWorkerService, OnDestroy {
  public static DEFAULT_OPTIONS: JobOptions = {
    type: "generic",
    showSnackbarOnStart: true,
    showSnackbarOnSuccess: true,
    onSuccessSnackbarClick: null,
    showSnackbarOnFail: true,
    onFailSnackbarClick: null,
    saveToDatabase: true,
    freeWorkerOnFinish: true,
    destroyWorkerOnFinish: true, // TODO: reuse workers that have a consistent use (for example, python deseq) by default instead of destroying them
  };

  public sessionJobs$: Subject<JobWithWorkerId[]> = new BehaviorSubject([]);

  protected _workers: IWorker[] = [];

  /**
   * Former completed jobs from the IndexedDB. These are jobs that have been run in the past and are saved in the database.
   */
  protected _dbJobs: Job[] = [];

  /**
   * The script to run in the worker. The worker must be able to handle messages of the form:
   * ```ts
   * {
   *  cmd: string;
   *  data: any;
   * }
   * ```
   *
   * It should respond with a message of the form:
   * ```ts
   * {
   * status: "success" | "error";
   * data: any | null;
   * }
   * ```
   *
   * If a step does not return any data, it should return `null` as the data, as the data needs to be JSON serializable.
   *
   */
  protected workerScript: string;

  private snackbarConfig: MatSnackBarConfig<any> = {
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  newWorker(): {
    success: boolean;
    workerId: number;
    error?: string;
  } {
    try {
      const worker = new Worker(this.workerScript);
      const workerId = uuidv4();
      this._workers.push({
        id: workerId,
        worker,
        busy: false,
        jobs: [],
      });
      return {
        success: true,
        workerId: workerId,
      };
    } catch (e) {
      return {
        success: false,
        workerId: -1,
        error: e,
      };
    }
  }

  isWorkerBusy$(workerId: number): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const interval = setInterval(() => {
        observer.next(this.getWorker(workerId).busy);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    });
  }

  workerJobs$(workerId: number): Observable<Job[]> {
    return new Observable<Job[]>((observer) => {
      const interval = setInterval(() => {
        observer.next(this.getWorker(workerId).jobs);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    });
  }

  /**
   * Get jobs from the database. These are jobs that were run in past sessions.
   * @param type The type of job to get
   * @returns Jobs of the given type from the database for this dataset
   */
  dbJobsOfType$(type: JobType): DexieObservable<Job[]> {
    return liveQuery(async () => {
      const config = await this.store
        .select(fromRoot.getGraphAConfig)
        .pipe(first())
        .toPromise();

      const db = await new Dexie("notitia-" + config.database).open();

      return (
        await db
          .table("jobs")
          .where("type")
          .equals(type)
          .and((job: Job) => job.databaseName === config.database)
          .toArray()
      ).map((j) => ({ ...j, workerId: undefined }));
    });
  }

  /**
   * Get jobs from the current session.
   * @param type The type of job to get
   * @param databaseName The name of the database that the session jobs with eventually be assigned to (se we only get the session jobs that are with the current dataset)
   * @returns Jobs of the given type from the session for this dataset
   */
  async sessionJobsOfType$(
    type: JobType
  ): Promise<Observable<JobWithWorkerId[]>> {
    const config = await this.store
      .select(fromRoot.getGraphAConfig)
      .pipe(first())
      .toPromise();

    return this.sessionJobs$.pipe(
      map((jobs) =>
        jobs.filter(
          (j) => j.type === type && j.databaseName === config.database
        )
      )
    );
  }

  terminateWorker(workerId: any): { success: boolean; error?: any } {
    try {
      const worker = this.getWorker(workerId);
      worker.worker.terminate();
      worker.busy = false;

      // Try to cancel running jobs in the worker
      let errorMsg = "";
      let i = 0;
      while (i < worker.jobs.length) {
        const j = worker.jobs[i];
        const cancelJobRes = this.cancelJob(j, false);
        if (!cancelJobRes.success) {
          errorMsg = `While attempting to terminate worker ${workerId}, failed to cancel job. Internal cancelJob error: ${cancelJobRes.error}`;
          break;
        }
        i++;
      }
      if (errorMsg !== "") {
        return { success: false, error: errorMsg };
      }

      this.sessionJobs$.next(this.jobsWithWorkerId());
      return { success: true };
    } catch (e) {
      return { success: false, error: e };
    }
  }

  cancelJob(
    job: Job,
    terminateWorker = true
  ): { success: boolean; error?: any } {
    try {
      let jobFound = false;
      let i = 0;
      while (i < this._workers.length && !jobFound) {
        const worker = this._workers[i];
        if (worker.jobs.some((j) => j.id === job.id)) {
          jobFound = true;
          const jobsToCancel = worker.jobs.filter((j) => j.id === job.id);
          for (const j of jobsToCancel) {
            if (j.status === Status.Queued || j.status === Status.Running) {
              j.status = Status.Cancelled;
              j.finishTime = new Date();
              j.steps.forEach((step) => {
                if (
                  step.status === Status.Queued ||
                  step.status === Status.Running
                ) {
                  step.status = Status.Cancelled;
                  step.finishTime = new Date();
                }
              });
            }
          }
          if (terminateWorker) {
            const termWorkerRes = this.terminateWorker(worker.id);
            if (!termWorkerRes.success) {
              return {
                success: false,
                error: `Failed to terminate parent worker while canceling job ${job.id}. Internal terminateWorker error: ${termWorkerRes.error}`,
              };
            }
          } else {
            // Since we didn't call terminate waorker, we still need to emit the updated jobs
            this.sessionJobs$.next(this.jobsWithWorkerId());
          }
          return { success: true };
        }
        i++;
      }
      return {
        success: false,
        error: `Could not find job in any worker with jobId ${job.id}`,
      };
    } catch (e) {
      return { success: false, error: e };
    }
  }

  deleteJob(job: Job): { success: boolean; error?: any } {
    try {
      // If the job is in the current session, delete it from the session
      for (let i = 0; i < this._workers.length; i++) {
        const index = this._workers[i].jobs.findIndex((j) => j.id === job.id);
        if (index !== -1) {
          this._workers[i].jobs.splice(index, 1);
          Khonsole.log(`Deleted job ${job.id} from session`);
          this.sessionJobs$.next(this.jobsWithWorkerId());
          return { success: true };
        }
      }

      // If the job is in the database, delete it from the database
      let found = false;
      this.store
        .select(fromRoot.getGraphAConfig)
        .pipe(first())
        .subscribe((graphAConfig) => {
          console.log("deleting job from database", job, graphAConfig);
          WorkspaceComponent.instance.delJob({
            database: graphAConfig.database,
            job: job,
          });
          Khonsole.log(`Deleted job ${job.id} from database`);
          found = true;
          return;
        });
      if (found) {
        return { success: true };
      }

      return {
        success: false,
        error: `Could not delete Job ${job.id}. Not found`,
      };
    } catch (e) {
      return { success: false, error: e };
    }
  }

  getJob(workerId: number, jobId: number): Job {
    return this.getWorker(workerId).jobs.find((job) => job.id === jobId);
  }

  getWorker(workerId: number): IWorker {
    return this._workers.find((worker) => worker.id === workerId);
  }

  async runJob(
    workerId: number,
    name: string,
    initialPayload: WebworkerJMPayload,
    steps: StepInput<WebworkerJMPayload>[],
    options: Partial<JobOptions> = WebworkerJobManagerService.DEFAULT_OPTIONS
  ): Promise<StepResponse[]> {
    const stepResponses: StepResponse[] = [];

    const config = await this.store
      .select(fromRoot.getGraphAConfig)
      .pipe(first())
      .toPromise();
    if (config === undefined) {
      throw new Error("runJob failed. Could not find graph config");
    }

    let payloadOrPrevStepResult: WebworkerJMPayload | StepResponse["result"] =
      initialPayload;
    const jobId = uuidv4();
    const worker = this.getWorker(workerId);

    const finalOptions: JobOptions = {
      ...WebworkerJobManagerService.DEFAULT_OPTIONS,
      ...options,
    };

    Khonsole.log(
      `Running job "${name}" (${jobId}) on worker ${workerId} with options:`,
      finalOptions
    );

    const finalSteps: Step<WebworkerJMPayload>[] = steps.map((step, i) => ({
      ...step,
      id: i,
      status: Status.Queued,
      logs: [],
      creationTime: new Date(),
    }));

    // add the job to the history,
    worker.jobs.push({
      id: jobId,
      backendIdentifier: workerId,
      name,
      steps: finalSteps,
      status: Status.Running,
      type: finalOptions.type,
      creationTime: new Date(),
      databaseName: config.database,
    });
    this.sessionJobs$.next(this.jobsWithWorkerId());

    const job = this.getJob(workerId, jobId);

    // Show the startup snackbar
    if (finalOptions.showSnackbarOnStart) {
      this._snackbar.open(`Running job: ${name}`, "", {
        duration: 5000,
        ...this.snackbarConfig,
      });
    }

    for (let i = 0; i < finalSteps.length; i++) {
      const step = finalSteps[i];

      // run the step
      const response = await this.runStep<WebworkerJMPayload>(
        workerId,
        step,
        payloadOrPrevStepResult
      );
      stepResponses.push(response);

      // if the step failed, do not continue with the rest of the steps
      if (!response.success) {
        if (finalOptions.showSnackbarOnFail) {
          this._snackbar.open(
            `Error running job "${name}". Failed on step "${step.name}"`,
            "Close",
            {
              duration: 5000,
              ...this.snackbarConfig,
            }
          );
        }

        this.finishJob(worker, job, Status.Error, finalOptions);
        return stepResponses;
      }

      // if the step succeeded, put the result in the payload for the next step
      payloadOrPrevStepResult = response.result;
    }

    if (finalOptions.showSnackbarOnSuccess) {
      this._snackbar.open(`Finished job "${name}"`, "Close", {
        duration: 5000,
        ...this.snackbarConfig,
      });
    }

    this.finishJob(worker, job, Status.Success, finalOptions);

    return stepResponses;
  }

  /**
   *
   * @param workerId The ID of the worker to run the step on
   * @param jobId The ID of the job to run the step on
   * @param step The step to run
   * @param payloadOrPrevStepResult The payload to run the step with. If the step has a `prevResultToPayload` function, this will be ignored.
   * @returns
   */
  private async runStep<Payload extends WebworkerJMPayload>(
    workerId: number,
    step: Step<Payload>,
    payloadOrPrevStepResult: Payload | StepResponse["result"]
  ): Promise<StepResponse> {
    const worker = this.getWorker(workerId).worker;

    // mark the step as running
    step.status = Status.Running;

    const response = await new Promise<StepResponse>((resolve, _) => {
      // set the payload, either from the provided payload or from the previous step's result
      if (step.payload) {
        payloadOrPrevStepResult = step.payload;
      } else {
        payloadOrPrevStepResult = step.prevResultToPayload
          ? step.prevResultToPayload(
              payloadOrPrevStepResult as StepResponse["result"]
            )
          : (payloadOrPrevStepResult as Payload);
      }

      // Run the step
      worker.postMessage(payloadOrPrevStepResult);

      // Handle the response
      worker.onmessage = (
        // event: MessageEvent<WorkerResponse> // Ideally we would use this type, but it doesn't work for some reason, even though MessageEvent is generic
        event: MessageEvent
      ) => {
        const response = event.data;

        // the status the worker responded with
        const status: Status = response.status;

        // The result of the step
        const dataOrErrorMsg: any = response.data;

        // The type of the result of the step
        const dataType: StepResultType = response.type;

        switch (status) {
          case Status.Success:
            resolve({
              success: true,
              result: {
                type: dataType,
                data: dataOrErrorMsg,
              },
            });
            break;
          case Status.Error:
            console.error(dataOrErrorMsg);
            resolve({
              success: false,
              result: {
                type: StepResultType.Error,
                data: dataOrErrorMsg,
              },
            });
            break;

          case Status.Log:
            const data = dataOrErrorMsg as {
              msg: string;
              level: LogLevel;
            };
            step.logs.push(data);
            this.sessionJobs$.next(this.jobsWithWorkerId());
            break;

          default:
            console.error(
              `Unknown status from worker "${status}". Must be "success" or "error"`
            );
            resolve({
              success: false,
              result: {
                type: StepResultType.Error,
                data: `Unknown status from worker "${status}". Must be "success" or "error"`,
              },
            });
            break;
        }
      };
    });

    this.finishStep(step, response);

    return response;
  }

  private jobsWithWorkerId(): JobWithWorkerId[] {
    return this._workers.reduce((acc, worker) => {
      return acc.concat(
        worker.jobs.map((job) => ({ ...job, workerId: worker.id }))
      );
    }, []);
  }

  private finishStep(step: Step<WebworkerJMPayload>, response: StepResponse) {
    step.finishTime = new Date();
    if (!response.success) {
      step.status = Status.Error;
      step.result = response.result;
      return;
    }

    step.status = Status.Success;

    if (response.result) {
      const typesToParse = [
        StepResultType.Table,
        StepResultType.JSON,
        StepResultType.VOLCANO_DATA,
      ];

      step.result = {
        type: response.result.type,
        // Parse the data if it is a table
        data: typesToParse.includes(response.result.type)
          ? JSON.parse(response.result.data)
          : response.result.data,
      };
    }

    step.logs.push({
      msg: "Success",
      level: LogLevel.Info,
    });

    this.sessionJobs$.next(this.jobsWithWorkerId());
  }

  private async finishJob(
    worker: IWorker,
    job: Job,
    status: Status,
    options: JobOptions = WebworkerJobManagerService.DEFAULT_OPTIONS
  ) {
    job.status = status;
    job.finishTime = new Date();

    Khonsole.log(
      `Job "${job.name}" (${job.id}) finished with status "${status}"`
    );

    this.sessionJobs$.next(this.jobsWithWorkerId());

    if (options.saveToDatabase) {
      job = prepareJobForDatabase(job)
      console.log("saving job to database", job, job.databaseName);
      WorkspaceComponent.instance.addJob({
        database: job.databaseName,
        job: job,
      });

      // remove the job from the session
      const index = worker.jobs.findIndex((j) => j.id === job.id);
      if (index !== -1) {
        worker.jobs.splice(index, 1);
      }
      this.sessionJobs$.next(this.jobsWithWorkerId());

      if (options.freeWorkerOnFinish) {
        worker.busy = false;
      }
      return;
    }
    if (options.freeWorkerOnFinish) {
      worker.busy = false;
    }

    if (options.destroyWorkerOnFinish) {
      worker.worker.terminate();
      const index = this._workers.findIndex((w) => w.id === worker.id);
      if (index !== -1) {
        this._workers.splice(index, 1);
      }
    }
  }

  ngOnDestroy(): void {}

  constructor(
    private _snackbar: MatSnackBar,
    private store: Store<fromRoot.State>,
    private ds: DataService
  ) {}
}
