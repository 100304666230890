<!-- NOTE: draggable="true" does not enable any functionality, but rather indicates draggability to accessability tools -->
<div id="scatter-thumbnail-container" draggable="true">
  <div id="scatter-thumbnail-header">
    <div style="display: flex">
      <div id="scatter-thumbnail-header-x" class="header-btn">X</div>
      <div style="margin-left: 4px" id="scatter-thumbnail-header-text"></div>
    </div>

    <mat-icon
      (click)="toggleSettings()"
      class="widget-icon"
      id="settings-icon"
      >settings</mat-icon
    >
  </div>
  <div id="scatter-thumbnail-resizer" title="Drag to resize"></div>
  <div id="scatter-thumbnail-tooltip"></div>
  <div id="scatter-thumbnail-plot">

  </div>
  <app-widget-settings
  id="scatter-thumbnail-settings"
  *ngIf="showSettings"
  [model]="model"
  (onClose)="showSettings = false"
></app-widget-settings>
</div>


