<div [id]="'enrichment-dot-plot-' + id" class="ea-container">
  <div *ngIf="genes.length > 0" id="enrichment-dot-plot-controls">
    <mat-expansion-panel
      [hideToggle]="false"
      [expanded]="true"
      (afterCollapse)="
        layout.setPanelCollapsed('enrichmentAnalysisOptions', true)
      "
      (afterExpand)="
        layout.setPanelCollapsed('enrichmentAnalysisOptions', false)
      "
      id="enrichment-analysis-options-panel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title style="text-transform: none">
          Controls
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>

      <!-- <div class="control-wrapper">
        <mat-form-field id="select-color-by">
          <mat-label>Color By</mat-label>
          <mat-select
            [value]="options.plotting.colorBy"
            id="colorBy"
            (selectionChange)="updatePlottingOption($event)"
          >
            <mat-option
              *ngFor="let option of colorByOptions"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field id="select-size-by">
          <mat-label>Scale By</mat-label>
          <mat-select
            [value]="options.plotting.sizeBy"
            id="sizeBy"
            (selectionChange)="updatePlottingOption($event)"
          >
            <mat-option *ngFor="let option of sizeByOptions" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field id="select-sort-by">
          <mat-label>Sort By</mat-label>
          <mat-select
            [value]="options.plotting.sortBy"
            id="sortBy"
            (selectionChange)="updatePlottingOption($event)"
          >
            <mat-option *ngFor="let option of sortByOptions" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field id="select-x-axis">
          <mat-label>X Axis</mat-label>
          <mat-select
            [value]="options.plotting.x"
            id="x"
            (selectionChange)="updatePlottingOption($event)"
          >
            <mat-option *ngFor="let option of xAxisOptions" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="control-wrapper">
        <app-download-plot
          plotSvgId="ea-svg"
          plotId="GSEA"
          [disabled]="!plotReady()"
        ></app-download-plot>

        <div style="display: flex; gap: 5px">
          <mat-form-field id="select-ann-dataset">
            <mat-label>Background dataset</mat-label>
            <mat-select
              [(ngModel)]="currentBackgroundDataset"
              [disabled]="!plotReady()"
            >
              <mat-option
                [value]="bkg.value"
                *ngFor="let bkg of availableBackgrounds"
                >{{ bkg.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field id="n-input" style="width: 75px">
            <mat-label># GO Terms</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="options.plotting.n"
              (change)="render()"
              [disabled]="!plotReady()"
              [min]="5"
              [max]="50"
            />
          </mat-form-field>

          <form
            [formGroup]="regulationForm"
            style="font-size: 12px"
            id="regulation-form"
          >
            <div>
              <div style="text-align: left; color: rgba(0, 0, 0, 0.6); font-size: 10px; margin-top: -3px;">Regulation filter</div>
              <mat-button-toggle-group
                formControlName="regulation"
                aria-label="Regulation"
                id="regulation"
              >
                <mat-button-toggle value="down" [disabled]="downregulatedGenes.length <= 0">
                  <span>
                    <mat-icon
                      style="font-size: small"
                      [style.color]="downregulatedColor"
                      matTooltip="downregulated"
                      >arrow_downward</mat-icon
                    >
                  </span>
                  Downregulated
                </mat-button-toggle>
                <mat-button-toggle value="up" [disabled]="upregulatedGenes.length <= 0">
                  <span>
                    <mat-icon
                      style="font-size: small"
                      [style.color]="upregulatedColor"
                      matTooltip="upregulated"
                      >arrow_upward</mat-icon
                    >
                  </span>
                  Upregulated
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </form>
        </div>

        <!-- <div class="toggle-container">
          <span class="toggle-label">Shorten GO Term labels</span>
          <mat-slide-toggle
            [(ngModel)]="useIdsForTermLabels"
          ></mat-slide-toggle>
        </div> -->
      </div>
    </mat-expansion-panel>
  </div>
  <div id="ea-svg-container">
    <!-- If we are loading the background dataset we put it in place of any hints -->
    <div class="hints" *ngIf="loadingBackgroundDatasetMapping">
      <img src="assets/icons/spinner.gif" width="15px" height="15px" />
      <p>Getting background dataset information</p>
    </div>

    <!-- show hint text at the top of the viz -->
    <ng-container *ngIf="plotReady()">
      <div class="hints">
        <p>
          Hover over a point to highlight the term's genes on the volcano plot.
          Overlapping genes from your selection will be a
          <span class="icon">&#8902;</span>
        </p>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="genes.length === 0"
    style="
      width: 100%;
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <p id="no-genes-hint">
      Select genes on the volcano plot to view Enrichment Analysis
    </p>
  </div>

  <div
    *ngIf="loading"
    style="
      width: 100%;
      height: 40vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <mat-spinner></mat-spinner>
    <p style="margin-left: 10px">Running Enrichment Analysis</p>
  </div>
</div>
