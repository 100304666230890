import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Renderer2,
  Input,
  ChangeDetectorRef,
} from "@angular/core";

import { HtmlWidgetComponent } from "./html-widget.component";
import * as _ from "lodash";
import { OncoData } from "app/oncoData";
import { ViewChild, ElementRef } from "@angular/core";
import { Cohort } from "app/model/cohort.model";
import { SelectionModifiers } from "app/component/visualization/visualization.abstract.scatter.component";
import { Khonsole } from "app/khonsole";
import { CommonSidePanelComponent } from "./common-side-panel.component";
import { WorkspaceComponent } from "../workspace.component";
import {PanelEnum} from 'app/model/enum.model';
import { liveQuery } from "dexie";
import { DataService } from "app/service/data.service";
import { MatInput, MatSnackBar } from "@angular/material";

@Component({
  selector: "cohorts-widget",
  templateUrl: "./cohorts-widget.component.html",
  styleUrls: ["./common-side-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class CohortsWidgetComponent extends HtmlWidgetComponent {

  @Input() cohorts: Cohort[] = []
  @ViewChild("selectedCohortTextField", {static: false}) selectedCohortTextField: MatInput;

  selectedCohort: Cohort;
  selectedDisplayName: string = "All Patients + Samples";
  canTurnSelectionIntoNewCohort: boolean;

  constructor(renderer: Renderer2, private cd: ChangeDetectorRef,  private dataService: DataService, private _snackbar: MatSnackBar) {
    super(renderer);
    this.model.name = "Cohorts";
    this.model.preferredHeight = null; // Autogrow, unlike forced height like "130px";
  }

  /** Read-only shorthand to the OncoData.instance.currentCommonSidePanel */
  get currentCommonSidePanel(): CommonSidePanelComponent {
    return OncoData.instance.currentCommonSidePanel;
  }

  /** Read-only shorthand to cohort colors */
  get cohortColors(): Array<string> {
    return OncoData.instance.cohortColors;
  }

  /**
   * @param cohort: The cohort to select
   * @param canTurnIntoNewCohort: Whether the selected cohort can be turned into a new cohort
   * @param optionalDisplayName: Optional display name for the cohort. Otherwise, the cohort's name will be used.
   * @param useSizeSuffix: Whether to append the cohort size to the display name. For example, "My Cohort (123)"
   */
  setSelection({ cohort, canTurnIntoNewCohort, optionalDisplayName = null, useSizeSuffix = true }: { cohort: Cohort; canTurnIntoNewCohort: boolean; optionalDisplayName?: string; useSizeSuffix?: boolean }): void {
    // Other widgets, like the survival widget, will need to know the color of the selected cohort, which we provide in the commonSidePanelModel context
    this.commonSidePanelModel.selectedCohort = cohort;
    this.selectedCohort = cohort;
    this.canTurnSelectionIntoNewCohort = canTurnIntoNewCohort;
    this.selectedDisplayName = optionalDisplayName ? `${optionalDisplayName} (${cohort.pids.length})` : this.getCohortDisplayName(cohort, useSizeSuffix);
    this.cd.detectChanges();
  }

  handleSelectionNameKeyUp(value: string): void {
    // if the selection is not read-only (ie can be used to write a new cohort), allow the user to change the name
    if (this.canTurnSelectionIntoNewCohort) {
      this.selectedDisplayName = value;
      this.selectedCohort.n = value;
    }
  }


  parentApplySelectedCohortNameFilter(e): void {
    this.currentCommonSidePanel.applySelectedCohortNameFilter(e);
  }

  copySelectedToClipboard(): void {
    if (!this.selectedCohort) {
      this._snackbar.open(`⚠️ Make a selection first.`, '', {
        duration: 5000,
        horizontalPosition: "right",
        verticalPosition: "top"
      });
    }
    this.copyCohortPidsToClipboard(this.selectedCohort);
  }

  private copyCohortPidsToClipboard(cohort: Cohort) {
    const pids = cohort.pids.join(",");
    navigator.clipboard.writeText(pids).then(() => {
      this._snackbar.open(`Copied patient IDs for ${cohort.n} to clipboard!`, '', {
        duration: 5000,
        horizontalPosition: "right",
        verticalPosition: "top"
      });
    }).catch((err) => {
      console.error('Failed to copy to clipboard', err);
      this._snackbar.open('Failed to copy to clipboard', '', {
        duration: 5000,
        horizontalPosition: "right",
        verticalPosition: "top"
    });
    });
  }

  getCohortColor(cohortIdx: number): string {
    return this.cohorts[cohortIdx].assignedColor || this.cohortColors[cohortIdx];
  }

  getCohortDisplayName(cohort: Cohort, useSizeSuffix = true): string {
    let nameDescription = cohort.n;
    if (nameDescription != "All Patients") {
      nameDescription = `${nameDescription}`;
      if (useSizeSuffix) nameDescription += ` (${cohort.pids.length})`;
    }
    return nameDescription;
  }

  handleSavedCohortClick(event: MouseEvent, cohort: Cohort): void {
    this.currentCommonSidePanel.setSelectionPatientIds({
      patientIds: cohort.pids,
      existingCohort: cohort,
      selectionModifiers: SelectionModifiers.fromEvent(event),
      graphConfig: null,
    });
  }

  handleAddCohortClick(): void {
    let self = this;

    let newCohort: Cohort = this.currentCommonSidePanel.createCohortFromPatientSelectionIds({
      selectionIds: this.selectedCohort.pids
    });
    newCohort.n = this.selectedCohort.n;
    newCohort.assignedColor = this.selectedCohort.assignedColor;
    newCohort.preferredColor = this.selectedCohort.preferredColor;

    WorkspaceComponent.instance.addCohort({
      database: self.currentCommonSidePanel.config.database,
      cohort: newCohort,
    });
  }

  handleDeleteCohortClick(cohort: Cohort) {
    if (
      window.confirm(
        "Are you sure you want to DELETE this cohort? This cannot be undone."
      )
    ) {
      this.commonSidePanelModel.lastSelectedDefinedCohort = null;
      WorkspaceComponent.instance.delCohort({
        database: this.currentCommonSidePanel.config.database,
        cohort: cohort,
      });
    }
  }

  handleStatsCohortClick(cohort: Cohort) {
    window["cohortRequestingDashboard"] = cohort;
    WorkspaceComponent.instance.setPanel(PanelEnum.DASHBOARD);
  }

  canDeleteCohort(cohort: Cohort): boolean {
    return cohort.n !== "All Patients";
  }

  ngOnInit(): void {
    super.ngOnInit();
    const self = this;
    liveQuery(() => this.dataService.getCustomCohorts(this.currentCommonSidePanel.config.database)).subscribe((cohorts: Cohort[]) => {
      self.cohorts = cohorts;
      self.cd.detectChanges();
    });
  }
}
