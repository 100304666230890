<div   >

  <div id="commonSidePanelContainerHeader" style="display:none"></div>

  <div *ngIf="hasMenuItems() && navItems.length > 0">

    <!-- Shortcuts to Figures -->
    <div class="basic-container" style="display:flex; justify-content:center; padding: 8px 0;" >
      <span *ngFor="let item of getNavItems()">
        <!-- Handle branch node buttons here -->
        <span *ngIf="item.children && item.children.length > 0">
          <button mat-raised-button color="primary" style="background-color: red" [matMenuTriggerFor]="menu.childMenu">
            {{item.displayName}}
            <mat-icon>expand_more</mat-icon>
          </button>
          <app-menu-item #menu [items]="item.children"></app-menu-item>
        </span>
      </span>
    </div>
  </div>

  <div HIDDEN_cdkDropList class="example-list" (cdkDropListDropped)="widgetDrop($event)">

    <div HIDDEN_cdkDrag *ngIf="true">
      <cohorts-widget
      #svgContainer_Html
      id="common-cohorts-widget"
      id="myId4"
      #myId>
      </cohorts-widget>
    </div>
    <div HIDDEN_cdkDrag *ngIf="datasetDescription.hasSurvival"><survival-widget  #svgContainer_Survival  id="common-survival-widget"
      id ="myId1" #myId>
    </survival-widget></div>
    <div HIDDEN_cdkDrag *ngIf="datasetDescription.hasCopyNumber"><copynumber-widget  #svgContainer_Copy_Number   id="common-copynumber-widget"
      id ="myId2" #myId>
    </copynumber-widget></div>
    <div HIDDEN_cdkDrag *ngIf="shouldUseDiffExp()"><diffexp-widget  #svgContainer_Differential_Expression  id="common-diffexp-widget"
      id ="myId3" #myId>
    </diffexp-widget></div>

  </div>

</div>
