import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import * as d3 from 'd3';
import { GraphConfig } from './../../../model/graph-config.model';
import { DataService } from './../../../service/data.service';
import { StatFactory } from './../../../service/stat.factory';
import {
  Cohort
} from './../../../model/cohort.model';

import { DataTable } from './../../../model/data-field.model';
import { WorkspaceComponent } from '../workspace.component';
import { LoadedTable } from 'app/oncoData';
import { PythonWebworkerJMService } from 'app/service/jobManager/webworker/python.webworker.jobManager.service';
import { CollectionTypeEnum } from 'app/model/enum.model';
import { MatSnackBar } from '@angular/material';
import { ServerJobManagerService } from 'app/service/jobManager/server/server.jobManager.service';

@Component({
  selector: 'app-workspace-differential-expression-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './differential-expression-panel.component.html',
  styleUrls: ['./differential-expression-panel.component.scss']
})
export class DifferentialExpressionPanelComponent implements AfterViewInit {

  public JobManagerService: PythonWebworkerJMService | ServerJobManagerService = PythonWebworkerJMService.instance;

  private _cohortA: Cohort; // for A/B comparisons of two cohorts.
  set cohortA(value: Cohort) {
    this._cohortA = value;
    this.updateAnalysisName(this._cohortA, this._cohortB);
  }
  get cohortA(): Cohort {
    return this._cohortA;
  }
  private _cohortB: Cohort;
  set cohortB(value: Cohort) {
    this._cohortB = value;
    this.updateAnalysisName(this._cohortA, this._cohortB);
  }
  get cohortB(): Cohort {
    return this._cohortB;
  }
  selectedCompareTable: DataTable;
  analysisName: string = "Differential Expression Analysis";

  @Output() hide = new EventEmitter<any>();
  private _config: GraphConfig;
  get config(): GraphConfig {
    return this._config;
  }
  @Input() set config(value: GraphConfig) {
    this._config = value;
  }

  @Input() cohorts: Array<Cohort> = [];

  // tables: Array<DataTable> = [];

  ngAfterViewInit(): void {
    // Load tables.
    let molecularTableFlag = CollectionTypeEnum.MOLECULAR;
    let self = this;
    this.dataService.getDatasetTables(this._config.database)
    .then(result => {
      Khonsole.log('info here');
      let tableArray:Array<DataTable> = result;
      self.selectedCompareTable = tableArray.find(t => t.tbl == "rnacounts")
      // let molecularTables = tableArray.filter(table => table.ctype & molecularTableFlag);
      // self.tables = molecularTables;
      self.cd.detectChanges();
    });
  }

  closeClick(): void {
    this.hide.emit();
  }

  private updateAnalysisName(cohortA: Cohort, cohortB: Cohort): void {

    const cohortAName = cohortA == null ? 'Cohort A' : cohortA.n;
    const cohortBName = cohortB == null ? 'Cohort B' : cohortB.n;

    this.analysisName = `PyDESeq2 of ${cohortAName} vs. ${cohortBName}`;
  }

  diffExpClick() {
    // if(this.tables == null || this.tables.length == 0) {
    //   alert('There are no molecular tables in this data set which can be used here for a comparison of the cohorts.');
    //   return;
    // }

    if (this.cohortA == null || this.cohorts.filter(c => c.n == this.cohortA.n).length == 0) {
      alert('Please choose a first cohort for the comparison.');
      return;
    } else {
      if (this.cohortB == null || this.cohorts.filter(c => c.n == this.cohortB.n).length == 0) {
        alert('Please choose a second cohort for the comparison.');
        return;
      } else {
        if (this.selectedCompareTable == null) {
          alert('PLease select a table from the "Use Table" list.');
          return;
        }
      }
    }

    // Put a limit on the size of samples that can be processed.
    // TODO: remove this limit when we have a better way to handle large data sets.
    const maxSamples = 450;
    const numSamples = this.cohortA.sids.length + this.cohortB.sids.length;
    if (numSamples > maxSamples) {
      alert(`Sorry, Differential Expression Analysis is currently limited to a combined total of ${maxSamples} samples. Your selected cohorts have a combined ${numSamples} samples. Use smaller cohorts and try again.`);
      return;
    }

    if (this._snackbar) {
      this._snackbar.open('Starting Differential Expression Analysis...', 'Dismiss', {duration: 2000,
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })
    }

    Khonsole.log(`Starting comparison of "${this.cohortA.n}" and "${this.cohortB.n}, using table ${this.selectedCompareTable.tbl}.".`);
    this.calculateNaiveDiffExp(this._config, this.selectedCompareTable);
  }

  private runDiffExpJob(diffExpData: any) {

    switch(this.JobManagerService) {
      case (PythonWebworkerJMService.instance):
        const pyService = this.JobManagerService as PythonWebworkerJMService

        const workerRes = pyService.newWorker()
        if (workerRes.success) {
          pyService.runJob(
            workerRes.workerId,
            this.analysisName,
            {
              cmd: "install",
              data: {
                deps: [
                  // for some reason micropip cant find some pypi packages, so we have to use the explicit urls to the wheels
                  "https://files.pythonhosted.org/packages/8a/87/201514af3bf08db52e11b7d94e6129f0a75503194b81614ff48883101c4c/anndata-0.10.3-py3-none-any.whl",
                  "numpy",
                  "pandas",
                  "scikit-learn",
                  "scipy",
                  "statsmodels",
                  "matplotlib",
                  "h5py",
                  "https://files.pythonhosted.org/packages/ef/82/7a9d0550484a62c6da82858ee9419f3dd1ccc9aa1c26a1e43da3ecd20b0d/natsort-8.4.0-py3-none-any.whl",
                  "https://files.pythonhosted.org/packages/c0/b0/cb1fbd419050d35de852005594b0d5f1c9183f0d319c02ec34764faead92/pydeseq2-0.4.4-py3-none-any.whl",
                ],
                method: "pyodide",
              },
            },
            [
              {
                name: "Install Dependencies",
              },
              {
                name: "Run Differential Expression",
                payload: {
                  cmd: "run",
                  data: {
                    fp: "differential_expression.py",
                    data: diffExpData,
                  },
                },
              }
            ],
            {
              type: "differentialExpression",
              showSnackbarOnStart: false
            }
          );
        } else {
          throw new Error(workerRes.error);
        }
      break;
      case (ServerJobManagerService.instance):
        // const deaService = this.DiffExpService as ServerJobManager;
        // deaService.runJob
        break;
      default:
        throw new Error(`Unknown Differential Expresssion Service type: "${this.JobManagerService.constructor.name}"`)
      }

  }

  calculateNaiveDiffExp(config: GraphConfig, table:DataTable)  {

    let tableName = table.tbl;
    let self = this;
    let storedMapData;
    let dataNeedsLoading = WorkspaceComponent.instance.hasLoadedTable(tableName) == false;
    if(!dataNeedsLoading){
      let loadedTable = WorkspaceComponent.instance.getLoadedTable(tableName);

      console.log("calling python with payload")
      this.runDiffExpJob({
        map: loadedTable.map,
        data: loadedTable.data,
        cohortA: self.cohortA,
        cohortB: self.cohortB
      });
    } else {
      this.dataService.getTable(config.database, tableName+'Map' ).then(mapResult => {
        mapResult.toArray().then(mapData => {
          storedMapData = mapData;
          this.dataService.getTable(config.database, tableName ).then(result => {
            result.toArray().then((expressionData) => {
              let thisLoadedTable:LoadedTable = {
                map: storedMapData,
                data: expressionData
              }
              WorkspaceComponent.instance.setLoadedTable(tableName, thisLoadedTable);
              window.setTimeout(function(){self.cd.detectChanges();}, 50);
              console.log("calling python with payload")
              this.runDiffExpJob({
                map: storedMapData,
                data: expressionData,
                cohortA: self.cohortA,
                cohortB: self.cohortB
              });
              self.cd.detectChanges();
            });
          });
            });
      });
    }
  }

  constructor(private cd: ChangeDetectorRef, private dataService: DataService, private _snackbar: MatSnackBar) {
    this.cohortA = null;
    this.cohortB = null;
  }
}
