import { Job, StepResultType } from "./job.types";

export const prepareJobForDatabase = (job: Job) => {

  job.steps.forEach((step) => {
    step.prevResultToPayload = null;
  })

  switch (job.type) {
    case 'differentialExpression':
      job.steps.forEach((step) => {
        if (step.result.type === StepResultType.VOLCANO_DATA) {
          // Drill down into the data attribute of the diffExpData and set it to null
          // In big data scenarios, this will prevent lots of data from being stored in the database after the job is complete
          // payload: {
          //   cmd: "run",
          //   data: {
          //     fp: "differential_expression.py",
          //     data: diffExpData,
          //   },
          // },
          step.payload.data.data.data = null;
        }
      });
      break;
    default:
      break;
  }

  return job;
}
