<div style="height: auto !important">
  <!-- Sometimes we hide legend elements, and way want to let the user know how many are hidden -->
  <div
    *ngFor="let legendFilter of _legendFilters"
    style="border-bottom-width: 1px"
  >
    <div class="legend-header">
      Hidden:
      {{ countAttempt(legendFilter) + legendFilter.excludedValues.length }}
    </div>
  </div>

  <mat-expansion-panel
    *ngFor="
      let legend of allLegendsExceptDataPointsPlaceholder();
      index as i;
      trackBy: trackLegendByFn
    "
    [expanded]="true"
  >
    <!-- Legend Header with an info icon that shows the whole name (useful when truncated for longer ones) -->
    <mat-expansion-panel-header *ngIf="isntDataPointsPlaceholder(legend)">
      <mat-panel-title
        class="legend-header"
        [ngClass]="{ 'color-legend-header': isColorLegend(legend) }"
        style="padding-top: 4px !important; padding-bottom: 4px !important"
      >
        <span>
          {{ smarterName(legend.name) }}
          <i
            matTooltip="{{ smarterName(legend.name) }}"
            matTooltipPosition="right"
            [matTooltipDisabled]="true"
            #legendTooltip="matTooltip"
            matTooltipClass="custom-tooltip"
            class="fa fa-info-circle"
            (mouseenter)="displayTooltip(legendTooltip)"
            (mouseleave)="hideTooltip(legendTooltip)"
          ></i>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Shape Items -->
    <div *ngIf="legend.type === 'SHAPE' && isntDataPointsPlaceholder(legend)">
      <div
        *ngFor="let label of legend.labels; let i = index"
        class="legend-item"
      >
        <img class="swatch" src="{{ legend.values[i] }}" />
        <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ label }}</span>
      </div>
    </div>

    <!-- Color Items -->
    <div *ngIf="legend.type === 'COLOR'">
      <div
        *ngFor="let label of legend.labels; let i = index"
        class="legend-item"
      >
        <!-- Eye to toggle visibility -->
        <app-workspace-legend-eye
          *ngIf="config.isScatterVisualization"
          (onClick)="onEyeClick($event)"
          [legend]="legend"
          [i]="i"
        ></app-workspace-legend-eye>

        <!-- Color Box with Color Picker -->
        <input
          class="swatch"
          matInput
          type="color"
          [value]="legend.values[i]"
          (change)="updateLegendItemColor(legend, i, $event.target.value)"
        />

        <!-- Legend Item Text -->
        <div
          class="clickable"
          [ngClass]="{ selected: legendItemHasSelectedPoints(legend, i), hidden: legendItemIsHidden(legend, i) }"
          id="ocLegendItem_{{ label }}"
          (click)="onLegendItemTextClick($event, legend, i)"
        >
          {{ formattedLegendItemText(legend, i) }}
        </div>
      </div>
    </div>

    <!-- Bar chart showing the distribution of the variable behind the legend -->

    <div class="legend-bar-chart" [id]="'legend-bar-chart-' + i"></div>
    <div id="legend-bar-chart-settings" *ngIf="isBarChartVisible(legend)">
      <mat-checkbox
        [checked]="barChartConfig.showEntireDataset"
        (change)="toggleBarChartConfigShowEntireDataset()"
        [disabled]="barChartConfig.yAxisType === 'ratio'"
        >Show Population Distribution</mat-checkbox
      >
      <div style="display: flex; gap: 5px; margin-top: 5px">
        <mat-radio-group aria-label="Select an option" [value]="barChartConfig.yAxisType" (change)="setBarChartConfigYAxisType($event)">
          <mat-radio-button value="percent" class="radio">%</mat-radio-button>
          <mat-radio-button value="count" class="radio">Counts</mat-radio-button>
          <mat-radio-button value="ratio" class="radio">Population Ratio</mat-radio-button>
        </mat-radio-group>
      </div>

    </div>
  </mat-expansion-panel>
</div>
