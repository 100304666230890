import { ChartScene } from './component/workspace/chart/chart.scene';
import { GraphEnum, VisualizationEnum, SelectionTypeEnum } from 'app/model/enum.model';
import { CommonSidePanelComponent } from 'app/component/workspace/common-side-panel/common-side-panel.component';
import { SelectionController } from 'app/controller/selection/selection.controller';
import { DataLoadedAction } from 'app/action/data.action';
import { VariantCheckbox } from 'app/model/data-map.model'
import { PublicDataset } from './model/public-dataset.model';



export class LoadedTable {
  public map: Object;
  public data: Object;
}
export class OncoData {
  public static spacey_legend_name_regex: RegExp = /[\_\(\)\-\,\.]/gi;

  public inHistoryUndoRedo:boolean = false;

  public currentCommonSidePanel:CommonSidePanelComponent;
  public currentSelectionController:SelectionController; //ScatterSelectionLassoController;
  public dataLoadedAction:DataLoadedAction;
  public mutationRecords:Array<any>;
  public cnaRecords:Array<any>;
  public variantsEdgeArray:Array<any>;
  public variantCountsPerPatient:Map<string, any>
  public lastData = {};
  public visSettings:Array<any>;

  public edgeMainVariantCheckbox: VariantCheckbox;

  /** This gets awfully verbose with OncoData.instance.currentCommonSidePanelComponent.commonSidePanelModel.cohortColors */
  get cohortColors(): Array<string> {
    return this.currentCommonSidePanel.commonSidePanelModel.cohortColors;
  }

  public gdcDatasets: PublicDataset[] = [
    {
      name: 'Adrenocortical carcinoma',
      prefix: 'tcga_acc_',
      img: 'DSadrenal',
      src: 'tcga',
      uid: 'tcga_acc'
    },
    {
      name: 'Bladder urothelial carcinoma',
      prefix: 'tcga_blca_',
      img: 'DSbladder',
      src: 'tcga',
      uid: 'tcga_blca'
    },
    {
      name: 'Breast',
      prefix: 'tcga_brca_',
      img: 'DSbreast',
      src: 'tcga',
      uid: 'tcga_brca'
    },
    {
      name: 'Cervical',
      prefix: 'tcga_cesc_',
      img: 'DSuterine',
      src: 'tcga',
      uid: 'tcga_cesc'
    },
    {
      name: 'Cholangiocarcinoma',
      prefix: 'tcga_chol_',
      img: 'DSbile',
      src: 'tcga',
      uid: 'tcga_chol'
    },
    {
      name: 'Colon',
      prefix: 'tcga_coad_',
      img: 'DScoadread',
      src: 'tcga',
      uid: 'tcga_coad'
    },
    // {
    //   name: 'Colorectal',
    //   prefix: 'tcga_coadread_',
    //   img: 'DScoadread',
    //   src: 'tcga',
    //   uid: 'tcga_coadread'
    // },
    {
      name: 'Diffuse large B Cell',
      prefix: 'tcga_dlbc_',
      img: 'DSblood',
      src: 'tcga',
      uid: 'tcga_dlbc'
    },
    {
      name: 'Esophageal',
      prefix: 'tcga_esca_',
      img: 'DSheadneck',
      src: 'tcga',
      uid: 'tcga_esca'
    },
    {
      name: 'Glioblastoma',
      prefix: 'tcga_gbm_',
      img: 'DSbrain',
      src: 'tcga',
      uid: 'tcga_gbm'
    },
    {
      name: 'Glioma',
      prefix: 'tcga_brain_',
      img: 'DSbrain',
      src: 'tcga',
      uid: 'tcga_brain'
    },
    {
      name: 'Head and Neck',
      prefix: 'tcga_hnsc_',
      img: 'DSheadneck',
      src: 'tcga',
      uid: 'tcga_hnsc'
    },
    {
      name: 'Kidney chromophobe',
      prefix: 'tcga_kich_',
      img: 'DSkidney',
      src: 'tcga',
      uid: 'tcga_kich'
    },
    {
      name: 'Kidney renal clear cell',
      prefix: 'tcga_kirc_',
      img: 'DSkidney',
      src: 'tcga',
      uid: 'tcga_kirc'
    },
    {
      name: 'Kidney renal papillary cell',
      prefix: 'tcga_kirp_',
      img: 'DSkidney',
      src: 'tcga',
      uid: 'tcga_kirp'
    },
    {
      name: 'Lower grade glioma',
      prefix: 'tcga_lgg_',
      img: 'DSbrain',
      src: 'tcga',
      uid: 'tcga_lgg'
    },
    {
      name: 'Liver',
      prefix: 'tcga_lihc_',
      img: 'DSliver',
      src: 'tcga',
      uid: 'tcga_lihc'
    },
    {
      name: 'Lung adenocarcinoma',
      prefix: 'tcga_luad_',
      img: 'DSlung',
      src: 'tcga',
      uid: 'tcga_luad'
    },
    {
      name: 'Lung squamous cell',
      prefix: 'tcga_lusc_',
      img: 'DSlung',
      src: 'tcga',
      uid: 'tcga_lusc'
    },
    {
      name: 'Mesothelioma',
      prefix: 'tcga_meso_',
      img: 'DSlung',
      src: 'tcga',
      uid: 'tcga_meso'
    },
    {
      name: 'Ovarian',
      prefix: 'tcga_ov_',
      img: 'DSovary',
      src: 'tcga',
      uid: 'tcga_ov'
    },
    {
      name: 'Pancreas',
      prefix: 'tcga_paad_',
      img: 'DSpancreas',
      src: 'tcga',
      uid: 'tcga_paad'
    },
    {
      name: 'Prostate',
      prefix: 'tcga_prad_',
      img: 'DSprostate',
      src: 'tcga',
      uid: 'tcga_prad'
    },
    {
      name: 'Pheochromocytoma + Paraganglioma',
      prefix: 'tcga_pcpg_',
      img: 'DSadrenal',
      src: 'tcga',
      uid: 'tcga_pcpg'
    },
    {
      name: 'Rectal',
      prefix: 'tcga_read_',
      img: 'DScoadread',
      src: 'tcga',
      uid: 'tcga_read'
    },
    {
      name: 'Sarcoma',
      prefix: 'tcga_sarc_',
      img: 'DSsarcoma',
      src: 'tcga',
      uid: 'tcga_sarc'
    },
    {
      name: 'Stomach',
      prefix: 'tcga_stad_',
      img: 'DSstomach',
      src: 'tcga',
      uid: 'tcga_stad'
    },
    {
      name: 'Testicular germ cell',
      prefix: 'tcga_tgct_',
      img: 'DStesticules',
      src: 'tcga',
      uid: 'tcga_tgct'
    },
    {
      name: 'Thyroid carcinoma',
      prefix: 'tcga_thca_',
      img: 'DSthyroid',
      src: 'tcga',
      uid: 'tcga_thca'
    },
    {
      name: 'Thymoma',
      prefix: 'tcga_thym_',
      img: 'DSthymus',
      src: 'tcga',
      uid: 'tcga_thym'
    },
    {
      name: 'Uterine corpus endometrial',
      prefix: 'tcga_ucec_',
      img: 'DSuterine',
      src: 'tcga',
      uid: 'tcga_ucec'
    },
    {
      name: 'Uterine carcinosarcoma',
      prefix: 'tcga_ucs_',
      img: 'DSuterine',
      src: 'tcga',
      uid: 'tcga_ucs'
    },
    {
      name: 'Uveal melanoma',
      prefix: 'tcga_uvm_',
      img: 'DSeye',
      src: 'tcga',
      uid: 'tcga_uvm'
    }
  ]
    .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
    .sort((a: any, b: any) => (a.img < b.img ? -1 : 1));

  public static instance: OncoData;

  constructor() {
    OncoData.instance = this;

    window['reachableOncoData'] = this;
  }
}
