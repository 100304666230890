import { GraphConfig } from 'app/model/graph-config.model';
import { DataDecorator } from 'app/model/data-map.model';
import { TableKeyMetric } from 'app/model/dataset-updates.model';
import { EntityType } from 'aws-sdk/clients/iam';
import { CollectionTypeEnum, EntityTypeEnum } from './enum.model';
import { DatasetTableMetadata } from "../dataInterfacesAndEnums";
import { NavItem } from 'app/component/workspace/common-side-panel/nav-item'

export class TooltipFieldRule {
  public name: string;
  public title: string;
  public originEntity: string = null;
  public excludedValues: Array<string> = [];
}

export class TooltipOverride {
  public entity: EntityTypeEnum;
  public fields: Array<TooltipFieldRule> = [];
}

// TableIdList lets us store all the (interesting) values of a large table,
// so we don't have to read it from the whole table each time we load the dataset.
// As of January 2023 these are being stored in the dataset table, and can
// appear in the updates.txt file.
export class TableIdList {
  public name: string;
  public descriptor: string;
  public ids: Array<string> = [];

  constructor(name:string, descriptor:string, ids:Array<string>){
    this.name = name;
    this.descriptor = descriptor;
    this.ids = ids
  }
}


export class DatasetTourTab {
  public title: string;
  public content: string;
}
export class DatasetTourStep {
  public html: string;
  public escapedHtml?: string;
  public tabs?: Array<DatasetTourTab>;
  public title: string;

}
export class DatasetTour {
  public steps: Array<DatasetTourStep> = [];
  public tourName: string;
  public width?: any;
  public skipUsualSteps?: boolean;
}

/* e.g.,
  "tour":{
  "format": "video",
  "source": "https://oncoscape-data.s3.us-west-2.amazonaws.com/data/public/bulkrnaseqbrainumap/brainumap_tour.mp4",
  "title": "How to Color By Genes or GO Terms"
},
*/  


// The record that gets saved in "dataset" table.
export class DatasetTableInfo {
  public name: string; // databse name
  public title: string; // human friendly data set title
  //public codeBuild: number = 0;
  public version: number = 0;
  //public savedPoints = [];
  public events:Array<any> =[];
  public fields:Array<any> =[];
  public tables: Array<DatasetTableMetadata> =[];

  public updateVersion:number = 0;
  public defaultVizConfig:GraphConfig = null;
  public decorators:Array<DataDecorator> = [];
  public tooltips:Array<TooltipOverride> = [];
  public tour?: DatasetTour = null;
  public table_ids?: Array<TableIdList> = [];
  public navItems?: NavItem[] = [];
  public ux?: {
    disabled?: String[];
    hidden?: String[];
    hidden_metadata?: String[];
  };
  public tableKeyMetrics?: TableKeyMetric[] = [];
}
