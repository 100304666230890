<div class="panel">
  <div>
    <div class="modalClose" (click)="closeClick()"> </div>
    <h1 class="panel-header">Cohorts</h1>
    <h2 class="panel-header-desc">
      Create, manage and apply custom cohorts to your visualizations
    </h2>
  </div>
  <!-- My Cohorts -->
  <div fxLayout.xs="column">
    <div
      fxFlex.sm="1 1 30%"
      fxFlex.md="1 1 20%"
      fxFlex.lg="1 1 25%"
      fxFlex.xl="1 1 20%"
    >
      <h2 class="panel-column-title">My Cohorts</h2>
      <div
        *ngFor="let option of cohorts"
        (click)="deleteClick(option)"
        class="panel-config-row-del"
      >
        <i class="material-icons md-18">remove_circle_outline</i>
        <div>
          {{ option.n }}
          <span *ngIf="option.pids.length > 0"
            >({{ option.pids.length }} patients)</span
          >
        </div>
      </div>
    </div>
    <!-- Create Cohorts -->
    <div
      fxFlex.sm="1 1 70%"
      fxFlex.md="1 1 80%"
      fxFlex.lg="1 1 75%"
      fxFlex.xl="1 1 80%"
    >
      <h2 class="panel-column-title" style="margin-bottom: 10px">
        Build A Cohort
      </h2>

      <label class="panel-builder-label">create</label>
      <mat-form-field>
        <input
          placeholder="Enter Cohort Name"
          matInput
          type="text"
          autocomplete="off"
          [(ngModel)]="activeCohort.n"
        />
      </mat-form-field>
      <div
        class="panel-builder-criteria-row"
        [class.cohort-field-or]="condition.condition === 'or'"
        *ngFor="let condition of activeCohort.conditions"
      >
        <label class="panel-builder-label">{{ condition.condition }}</label>
        <mat-form-field>
          <mat-select placeholder="Field" [(value)]="condition.field">
            <mat-option *ngFor="let option of fields" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngIf="condition.field.type === 'category'">
          <mat-form-field style="width: 170px; margin-left: 5px">
            <mat-select [(value)]="condition.value" placeholder="Equals">
              <mat-option
                *ngFor="let option of condition.field.options"
                [value]="option.value"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
        <span *ngIf="condition.field.type === 'number'">
          <mat-form-field style="width: 75px; margin-left: 5px">
            <input
              matInput
              type="number"
              [(ngModel)]="condition.min"
              autocomplete="off"
              placeholder="Min"
            />
          </mat-form-field>
          <mat-form-field style="width: 75px; margin-left: 5px">
            <input
              matInput
              type="number"
              [(ngModel)]="condition.max"
              autocomplete="off"
              placeholder="Max"
            />
          </mat-form-field>
        </span>
        <span class="panel-builder-buttons">
          <div class="os-link" (click)="fieldAnd(condition)">And</div> |
          <div class="os-link" (click)="fieldOr(condition)">Or</div>
          <span *ngIf="condition.condition !== 'where'">
            |
            <div class="os-link" (click)="fieldDel(condition)">Remove</div>
          </span>
        </span>
      </div>
      <button class="button" (click)="saveClick()" mat-raised-button>
        Save
      </button>
    </div>
  </div>

</div>
