<div class='panel'>
    <div>
      <div class='modalClose' (click)='closeClick()'>
      </div>
        <h1 class='panel-header'>Pathways</h1>
        <h2 class='panel-header-desc'>Select from thousands of curated pathways</h2>
    </div>
    <!-- My Cohorts -->
    <div fxLayout.xs='column'>
        <div fxFlex.sm='1 1 30%' fxFlex.md='1 1 20%' fxFlex.lg='1 1 25%' fxFlex.xl='1 1 20%'>
            <h2 class='panel-column-title'>My Pathways</h2>
            <div *ngFor='let option of pathways' (click)='pathwayDel(option)' class='panel-config-row-del'>
                <i class='material-icons md-18'>remove_circle_outline</i>
                <div>{{option.n}}</div>
            </div>
        </div>
        <!-- Create Cohorts -->
        <div fxFlex.sm='1 1 70%' fxFlex.md='1 1 80%' fxFlex.lg='1 1 75%' fxFlex.xl='1 1 80%'>
            <h2 class='panel-column-title'>Select / Build a Gene Set</h2>
            <mat-form-field>
                <mat-select placeholder='Cateogy' [value]='pathwayCategory' (selectionChange)='pathwayCategoryChange($event)'>
                    <mat-option *ngFor='let option of pathwayCategories' [value]='option'>{{option.n}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style='margin-left:5px;'>
                <input autocomplete='off' matInput placeholder="Filter (e.g Cancer, Biosythesis)" (keyup)='$pathwayFilter.next($event.target.value)'>
            </mat-form-field>
            <div *ngFor='let option of pathwayOptionsFilter' (click)='pathwayAdd(option)' class='panel-config-row-add'>
                <i class='material-icons md-18'>add_circle_outline</i>
                <div>{{option.name}}
                    <br />{{option.summary}}
                </div>
            </div>
        </div>
    </div>
</div>
