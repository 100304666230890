<div class="floating-toolbar" [class.expanded]="isPinned" cdkDrag>
  <div class="title-bar" cdkDragHandle>
    <mat-icon class="material-symbols-outlined ">drag_handle</mat-icon>
  </div>
  <button mat-icon-button class="small-toolbar-button"  (click)="togglePin()">
    <mat-icon class="material-symbols-outlined small-icon">{{ isPinned ? "keep" : "keep_off" }}</mat-icon>
  </button>&nbsp;&nbsp;


  <div class="content" style="margin-top: 4px;" *ngIf="isPinned" [innerHTML]="presentContent()"></div>
</div>
