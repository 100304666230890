import { Khonsole } from 'app/khonsole';
import { DatasetService } from './../../service/dataset.service';
import Dexie from 'dexie';
import { GraphEnum } from 'app/model/enum.model';
import { ChartScene } from 'app/component/workspace/chart/chart.scene';
import { Preprocessing } from './../../model/preprocessing.model';
import { SelectionToolConfig } from './../../model/selection-config.model';
import { ScatterConfigModel } from './../visualization/scatter/scatter.model';
import { getTipVisible, getTipEnabled } from './../../reducer/index.reducer';
import { DataService } from 'app/service/data.service';
import { SelectSaveSamplesAction, SelectSaveMarkersAction } from './../../action/compute.action';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as compute from 'app/action/compute.action';
import * as data from 'app/action/data.action';
import { PlsSvdConfigModel } from 'app/component/visualization/pls-svd/pls-svd.model';
import { PlsRegressionConfigModel } from 'app/component/visualization/plsregression/plsregression.model';
// tslint:disable-next-line:max-line-length
import { LinearDiscriminantAnalysisConfigModel } from 'app/component/visualization/lineardiscriminantanalysis/lineardiscriminantanalysis.model';
// tslint:disable-next-line:max-line-length
import { MiniBatchDictionaryLearningConfigModel } from 'app/component/visualization/minibatchdictionarylearning/minibatchdictionarylearning.model';
import { MiniBatchSparsePcaConfigModel } from 'app/component/visualization/minibatchsparsepca/minibatchsparsepca.model';
import { PathwaysConfigModel } from 'app/component/visualization/pathways/pathways.model';
// tslint:disable-next-line:max-line-length
import { QuadradicDiscriminantAnalysisConfigModel } from 'app/component/visualization/quadradicdiscriminantanalysis/quadradicdiscriminantanalysis.model';
import { TimelinesConfigModel } from 'app/component/visualization/timelines/timelines.model';
import { DataField } from 'app/model/data-field.model';
import * as enums from 'app/model/enum.model';
import { Legend } from 'app/model/legend.model';
import * as fromRoot from 'app/reducer/index.reducer';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import {
  DataAddCohortAction,
  DataAddGenesetAction,
  DataUpdateGenesetAction,
  DataAddPreprocessingAction,
  DataAddPathwayAction,
  DataDelCohortAction,
  DataDelGenesetAction,
  DataDelPreprocessingAction,
  DataDelPathwayAction,
  DataAddJobAction,
  DataDelJobAction
} from './../../action/data.action';
import {
  DataDecoratorCreateAction,
  DataDecoratorDelAction,
  DataDecoratorDelAllAction,
  LegendFilterCreateAction,
  LegendFilterAddAction,
  LegendFilterDelAction,
  LegendFilterDelAllAction,
  WorkspaceConfigAction,
  SelectionToolChangeAction,
  ThreeDRenderOptionAction
} from './../../action/graph.action';
import { HelpSetConfigAction } from './../../action/help.action';
import { GraphPanelToggleAction, LoaderShowAction, ModalPanelAction } from './../../action/layout.action';
import { ChartSelection } from './../../model/chart-selection.model';
import { Cohort } from './../../model/cohort.model';
import { DataTable } from './../../model/data-field.model';
import { DataDecorator, LegendFilter } from './../../model/data-map.model';
import { EntityTypeEnum } from './../../model/enum.model';
import { GeneSet } from './../../model/gene-set.model';
import { GraphConfig } from './../../model/graph-config.model';
import { Pathway } from './../../model/pathway.model';
import { WorkspaceConfigModel } from './../../model/workspace.model';
import { BoxWhiskersConfigModel } from './../visualization/boxwhiskers/boxwhiskers.model';
import { ChromosomeConfigModel } from './../visualization/chromosome/chromosome.model';
import { DendogramConfigModel } from './../visualization/dendogram/dendogram.model';
import { DictionaryLearningConfigModel } from './../visualization/dictionarylearning/dictionarylearning.model';
import { EdgeConfigModel } from './../visualization/edges/edges.model';
import { FaConfigModel } from './../visualization/fa/fa.model';
import { FastIcaConfigModel } from './../visualization/fastica/fastica.model';
import { GenomeConfigModel } from './../visualization/genome/genome.model';
import { HazardConfigModel } from './../visualization/hazard/hazard.model';
import { HeatmapConfigModel } from './../visualization/heatmap/heatmap.model';
import { HicConfigModel } from './../visualization/hic/hic.model';
import { HistogramConfigModel } from './../visualization/histogram/histogram.model';
import { IsoMapConfigModel } from './../visualization/isomap/isomap.model';
import { LdaConfigModel } from './../visualization/lda/lda.model';
import { LinkedGeneConfigModel } from './../visualization/linkedgenes/linkedgenes.model';
import { LocalLinearEmbeddingConfigModel } from './../visualization/locallinearembedding/locallinearembedding.model';
import { MdsConfigModel } from './../visualization/mds/mds.model';
import { SavedPointsConfigModel } from './../visualization/savedpoints/savedpoints.model';
import { NmfConfigModel } from './../visualization/nmf/nmf.model';
import { ParallelCoordsConfigModel } from './../visualization/parallelcoords/parallelcoords.model';
import { PcaConfigModel } from './../visualization/pca/pca.model';
import { PcaIncrementalConfigModel } from './../visualization/pcaincremental/pcaincremental.model';
import { PcaKernalConfigModel } from './../visualization/pcakernal/pcakernal.model';
import { PcaSparseConfigModel } from './../visualization/pcasparse/pcasparse.model';
import { SomConfigModel } from './../visualization/som/som.model';
import { SpectralEmbeddingConfigModel } from './../visualization/spectralembedding/spectralembedding.model';
import { SurvivalConfigModel } from './../visualization/survival/survival.model';
import { TruncatedSvdConfigModel } from './../visualization/truncatedsvd/truncatedsvd.model';
import { TsneConfigModel } from './../visualization/tsne/tsne.model';
import { PlsCanonicalConfigModel } from './../visualization/plscanonical/plscanonical.model';
import { CCAConfigModel } from './../visualization/cca/cca.model';
import { LinearSVCConfigModel } from './../visualization/linearsvc/linearsvc.model';
import { LinearSVRConfigModel } from './../visualization/linearsvr/linearsvr.model';
import { NuSVRConfigModel } from './../visualization/nusvr/nusvr.model';
import { NuSVCConfigModel } from './../visualization/nusvc/nusvc.model';
import { OneClassSVMConfigModel } from './../visualization/oneclasssvm/oneclasssvm.model';
import { SVRConfigModel } from './../visualization/svr/svr.model';
import { TipSetVisualizationAction, TipSetEnabledAction, TipSetVisibleAction } from '../../action/tip.action';
import { UmapConfigModel } from '../visualization/umap/umap.model';
import { DatasetDescription } from 'app/model/dataset-description.model';
import { ProteinConfigModel } from '../visualization/protein/protein.model';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { OncoData, LoadedTable } from 'app/oncoData';
import { TableLoaderConfigModel } from '../visualization/tableLoader/tableLoader';
import { DatasetUpdates } from 'app/model/dataset-updates.model';
import { AbstractScatterVisualization } from '../visualization/visualization.abstract.scatter.component';
import { GlobalGuiControls } from 'app/globalGuiControls';
import { Job } from 'app/service/jobManager/job.types';
import { PublicDataset } from 'app/model/public-dataset.model';

import { PinnableToolbarComponent } from "./pinnable-toolbar/pinnable-toolbar.component";

@Component({
  selector: "app-workspace",
  templateUrl: "./workspace.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./workspace.component.scss"],
})
export class WorkspaceComponent implements OnInit {
  private static _instance: WorkspaceComponent;
  public static get instance(): WorkspaceComponent {
    return this._instance;
  }

  // Components
  @ViewChild("leftPanelContainer", { static: true })
  public leftPanelContainer: ElementRef;

  @ViewChild("pinnabletoolbar", { static: true })
  pinnabletoolbar!: PinnableToolbarComponent;

  requestContent(content: string) {
    if (this.pinnabletoolbar.isPinned) {
      this.pinnabletoolbar.setContent(content);
    }
  }

  public forcePinnedTooltipContent(txt: string) {
    this.pinnabletoolbar.setContent(txt);
  }

  // Version last saved in local database.
  updateVersion: number = 0;

  // If non-null, the new changes we want to save in local database.
  updatesIncomingData: DatasetUpdates = null;

  overrideShowPanel = true;

  pathways: Observable<Array<any>>;
  genesets: Observable<Array<any>>;
  cohorts: Observable<Array<any>>;
  jobs: Observable<Array<Job>>;
  preprocessings: Observable<Array<Preprocessing>>;
  loader: Observable<boolean>;
  graphALegend: Observable<Array<Legend>>;
  graphBLegend: Observable<Array<Legend>>;
  graphAConfig: Observable<GraphConfig>;
  graphBConfig: Observable<GraphConfig>;
  helpPanelConfig: Observable<GraphConfig>;
  workspaceConfig: Observable<WorkspaceConfigModel>;
  graphAData: Observable<any>;
  graphBData: Observable<any>;
  graphADecorators: Observable<Array<DataDecorator>>;
  graphBDecorators: Observable<Array<DataDecorator>>;

  graphALegendFilters: Observable<Array<LegendFilter>>;

  graphASelectionToolConfig: Observable<SelectionToolConfig>;
  graphBSelectionToolConfig: Observable<SelectionToolConfig>;
  edgeDecorators: Observable<Array<DataDecorator>>;
  datasetDescription: Observable<DatasetDescription>;
  selectVisible: Observable<boolean>;
  selectSelection: Observable<ChartSelection>;
  selectStats: Observable<Array<any>>;

  edgeConfig: Observable<EdgeConfigModel>;
  edgeLegend: Observable<Array<Legend>>;
  $configChangeA: Subscription;
  $configChangeB: Subscription;
  $configChangeE: Subscription;

  graphPanelATab: Observable<enums.GraphPanelEnum>;
  graphPanelBTab: Observable<enums.GraphPanelEnum>;
  genesetPanelTab: Observable<enums.SinglePanelEnum>;
  modalPanel: Observable<enums.PanelEnum>;
  modalPanelNumber: number;

  selectedTool: Observable<enums.ToolEnum>;
  selectedGraph: Observable<enums.GraphEnum>;
  fields: Observable<Array<DataField>>;
  tables: Observable<Array<DataTable>>;
  events: Observable<Array<{ type: string; subtype: string }>>;
  queryData: Observable<any>;
  _selectedGraph: enums.GraphEnum; // This is super wrong

  tip: Observable<any>;
  tipVisible: Observable<boolean>;
  tipEnabled: Observable<boolean>;
  tipVisualization: Observable<enums.VisualizationEnum>;

  public static addDecorator(
    config: GraphConfig,
    decorator: DataDecorator
  ): void {
    if (decorator.config && decorator.config.entity != config.entity) {
      Khonsole.error("addDecorator entity mismatch.");
    }
    this._instance.graphPanelAddDecorator({
      config: config,
      decorator: decorator,
    });
  }

  datasetName: string = null;
  datasetSrc: "project" | "gdc" = null;
  public datGui: GlobalGuiControls;

  public redirectToDataset(project: String) {
    let href = this.urlToRedirectToProject(project);
    window.location.href = href;
  }

  public urlToRedirectToProject(project: String) {
    let protocol = window.location.protocol.replace(":", "");
    let host = window.location.hostname;
    let port = window.location.port == "80" ? "" : ":" + window.location.port;
    host = host + port;
    let href = `https://oncoscape.v3.sttrcancer.org/redirect4.html?protocol=${protocol}&server=${host}&project=${project}`;
    return href;
  }

  constructor(private store: Store<fromRoot.State>, public ds: DataService) {
    try {
      WorkspaceComponent._instance = this;
      window["reachableWorkspaceComponent"] = this;
      this.pathways = store.select(fromRoot.getPathways);
      this.genesets = store.select(fromRoot.getGenesets);
      this.cohorts = store.select(fromRoot.getCohorts);
      this.jobs = store.select(fromRoot.getJobs);
      this.preprocessings = store.select(fromRoot.getPreprocessing);
      this.graphPanelATab = store.select(fromRoot.getLayoutGraphPanelAState);
      this.graphPanelBTab = store.select(fromRoot.getLayoutGraphPanelBState);
      this.modalPanel = store.select(fromRoot.getLayoutModalPanelState);
      this.loader = store.select(fromRoot.getLayoutLoaderState);
      this.helpPanelConfig = store.select(fromRoot.getHelpConfigState);

      this.edgeConfig = store.select(fromRoot.getEdgesConfig);
      this.graphAConfig = store.select(fromRoot.getGraphAConfig);
      this.graphBConfig = store.select(fromRoot.getGraphBConfig);
      this.workspaceConfig = store.select(fromRoot.getWorkspaceConfig);

      this.graphAData = store.select(fromRoot.getGraphAData);
      this.graphBData = store.select(fromRoot.getGraphBData);

      this.graphASelectionToolConfig = store.select(
        fromRoot.getGraphASelectionToolConfig
      );
      this.graphBSelectionToolConfig = store.select(
        fromRoot.getGraphASelectionToolConfig
      );
      this.graphADecorators = store.select(fromRoot.getGraphADecorators);
      this.graphBDecorators = store.select(fromRoot.getGraphBDecorators);
      this.edgeDecorators = store.select(fromRoot.getEdgeDecorators);
      this.datasetDescription = store.select(fromRoot.getDatasetDescription);

      this.graphALegendFilters = store.select(fromRoot.getGraphALegendFilters);

      this.selectVisible = store.select(fromRoot.getSelectVisible);
      this.selectSelection = store.select(fromRoot.getSelectSelection);
      this.selectStats = store.select(fromRoot.getSelectStats);

      this.tables = store.select(fromRoot.getTables);
      this.fields = store.select(fromRoot.getFields);
      this.events = store.select(fromRoot.getEvents);

      this.tip = store.select(fromRoot.getTip);
      this.tipVisible = store.select(fromRoot.getTipVisible);
      this.tipEnabled = store.select(fromRoot.getTipEnabled);
      this.tipVisualization = store.select(fromRoot.getTipVisualization);

      Khonsole.warn("Workspace.instance making dat.gui");
      this.datGui = new GlobalGuiControls();
    } catch (e) {
      console.error("ERROR in constructor of WorkspaceComponent.");
    }
  }

  selectedId: string;
  ngOnInit() {
    Khonsole.log("ngOnInit of WorkspaceComponent");
    this.datasetName = null;
    if (window.document.URL.includes("#")) {
      const url = new URL(window.document.URL);
      if (
        url.hash.startsWith("#project_") ||
        url.hash.startsWith("#project-")
      ) {
        // parse the url to get the project
        const projectName = url.hash.substring(1 + 8); // remove '#project_'
        if (projectName != "" && projectName != null) {
          Khonsole.log("ngOnInit project = " + projectName);
          this.datasetName = projectName;
          this.datasetSrc = "project";
        }
        return;
      }
      if (url.hash.startsWith("#gdc_")) {
        // parse the url to get the project
        const gdcDatasetUID = url.hash.substring(1 + 4); // remove '#gdc_'
        if (gdcDatasetUID != "" && gdcDatasetUID != null) {
          Khonsole.log("ngOnInit project = " + gdcDatasetUID);
          this.datasetName = gdcDatasetUID;
          this.datasetSrc = "gdc";
        }
        return;
      }
    }
  }

  ngAfterViewInit(): void {
    Khonsole.log("ngAfterViewInit of WorkspaceComponent");
    if (this.datasetName != null && this.datasetName != "") {
      Khonsole.log("NOW OPEN " + this.datasetName);
      switch (this.datasetSrc) {
        case "project":
          this.fileLoadPublicShared(this.datasetName);
          break;
        case "gdc":
          const publicDatasetPayload = OncoData.instance.gdcDatasets.find(
            (v) => v.uid == this.datasetName
          );
          if (!publicDatasetPayload) {
            Khonsole.error("Public dataset not found: " + this.datasetName);
            alert(
              'Genomic Data Commons dataset not found: "' +
                this.datasetName +
                '". Check your spelling.'
            );
            window.location.href = "";

            return;
          }
          this.fileLoadPublic(publicDatasetPayload);
          break;
      }
    }
  }

  private loadedTables: Map<string, LoadedTable> = new Map<
    string,
    LoadedTable
  >();

  public getLoadedTable(key: string) {
    return this.loadedTables.get(key);
  }

  public hasLoadedTable(key: string) {
    return this.loadedTables.has(key);
  }

  public setLoadedTable(key: string, value: LoadedTable) {
    this.loadedTables.set(key, value);
  }

  public clearLoadedTables() {
    this.loadedTables.clear();
    this.tableRetrievalInProgress = false;
  }

  private tableRetrievalInProgress = false;

  public requestLoadedTable(key: string) {
    if (this.hasLoadedTable(key) == false) {
      if (this.tableRetrievalInProgress == false) {
        this.tableRetrievalInProgress = true;
        this.loadTableAndMap(key);
      }
    }
  }

  select(selection: ChartSelection): void {
    switch (selection.type) {
      case EntityTypeEnum.SAMPLE:
        this.store.dispatch(
          new compute.SelectSamplesAction({ samples: selection.ids })
        );
        break;
      case EntityTypeEnum.GENE:
        this.store.dispatch(
          new compute.SelectMarkersAction({ markers: selection.ids })
        );
        break;
    }
  }

  saveSelection(event: { name: string; selection: ChartSelection }) {
    if (event.selection.type === EntityTypeEnum.SAMPLE) {
      this.store.dispatch(new compute.SelectSaveSamplesAction(event));
    } else if (event.selection.type === EntityTypeEnum.GENE) {
      this.store.dispatch(new compute.SelectSaveMarkersAction(event));
    }
  }

  edgeConfigChange(value: EdgeConfigModel): void {
    this.store.dispatch(new compute.EdgesAction({ config: value }));
  }

  loadTableAndMap(tableName: string): void {
    Khonsole.log(`loadTableAndMap ${Date.now()}.`);
    // TBD !!!!!! first do map
    let config: TableLoaderConfigModel = new TableLoaderConfigModel();
    config.tableName = tableName;
    config.graph = GraphEnum.GRAPH_A;
    config.database = OncoData.instance.dataLoadedAction.dataset;
    config.datasetName = OncoData.instance.dataLoadedAction.datasetName;
    config.table = OncoData.instance.dataLoadedAction.tables.find(
      (v) => v.tbl == tableName
    );

    this.ds.getTable(config.database, tableName + "Map").then((mapResult) => {
      Khonsole.log(`Got map for loadTableAndMap of${tableName}.`);
      mapResult.toArray().then((mapData) => {
        config.mapData = mapData;
        this.store.dispatch(new compute.TableLoaderAction({ config }));
      });
    });
  }

  graphPanelSetConfig(graphConfigValue: GraphConfig): void {
    // TEMPNOTE: Here is our chance to override default config settings
    // with values generated for the specific dataset, such as
    // Timeline's config bars. As we start to store user prefs for a
    // visualization, we can apply them too.
    // These values come from the visSettings Dexie table.

    // Now find setting overrides in visSettings Dexie table.
    // Note that we might only override one or two settings values.
    // When we process them, loop through each settings key value,
    // and write it into the gc.
    try {
      Khonsole.log(`TEMPNOTE: ==== Entering graphPanelSetConfig ...`);
      //      DatasetService.db.table('visSettings').get({visEnum: graphConfigValue.visualization}).then(v => {

      if (graphConfigValue.hasAppliedMetafileOverrides == false) {
        let v: any = OncoData.instance.visSettings.find(
          (v) => v.visEnum == graphConfigValue.visualization
        );
        if (v) {
          let settings = JSON.parse(v.settings);
          if (settings) {
            Khonsole.log(
              `TEMPNOTE: Found settings for visEnum=${graphConfigValue.visualization}...`
            );
            Khonsole.log(`TEMPNOTE: settings = ${v.settings}!`);
            let overrideKeys = Object.keys(settings);
            overrideKeys.map((ok) => {
              Khonsole.log(
                `TEMPNOTE: override "${ok}" with "${JSON.stringify(
                  settings[ok]
                )}"!`
              );
              graphConfigValue[ok] = settings[ok];
            });
            // Khonsole.log('MJ settings overridden.');
            graphConfigValue.hasAppliedMetafileOverrides = true;
            Khonsole.log(
              `TEMPNOTE: New config = ${JSON.stringify(graphConfigValue)}!`
            );
          }
        }
        // Khonsole.log('MJ Now continue on with dispatching the vis.');
      }
      // Khonsole.log('MJ No more override: Has already applied meta overrides to this GraphConfig.');

      this.store.dispatch(new LoaderShowAction());
      // MJ Hide tooltips for visualizations. 2020-03-19. this.store.dispatch(new TipSetVisualizationAction(graphConfigValue.visualization));
      switch (graphConfigValue.visualization) {
        case enums.VisualizationEnum.NONE:
          this.store.dispatch(
            new compute.NoneAction({
              config: graphConfigValue as GraphConfig,
            })
          );
          break;
        case enums.VisualizationEnum.EDGES:
          this.store.dispatch(
            new compute.EdgesAction({
              config: graphConfigValue as EdgeConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PCA:
          this.store.dispatch(
            new compute.PcaAction({
              config: graphConfigValue as PcaConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PATHWAYS:
          this.store.dispatch(
            new compute.PathwaysAction({
              config: graphConfigValue as PathwaysConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PROTEINS:
          this.store.dispatch(
            new compute.ProteinAction({
              config: graphConfigValue as ProteinConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.CHROMOSOME:
          this.store.dispatch(
            new compute.ChromosomeAction({
              config: graphConfigValue as ChromosomeConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.GENOME:
          this.store.dispatch(
            new compute.GenomeAction({
              config: graphConfigValue as GenomeConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.TSNE:
          this.store.dispatch(
            new compute.TsneAction({
              config: graphConfigValue as TsneConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.UMAP:
          this.store.dispatch(
            new compute.UmapAction({
              config: graphConfigValue as UmapConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SCATTER:
          this.store.dispatch(
            new compute.ScatterAction({
              config: graphConfigValue as ScatterConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.TIMELINES:
          this.store.dispatch(
            new compute.TimelinesAction({
              config: graphConfigValue as TimelinesConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.HEATMAP:
          this.store.dispatch(
            new compute.HeatmapAction({
              config: graphConfigValue as HeatmapConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.DENDOGRAM:
          this.store.dispatch(
            new compute.DendogramAction({
              config: graphConfigValue as DendogramConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PARALLEL_COORDS:
          this.store.dispatch(
            new compute.ParallelCoordsAction({
              config: graphConfigValue as ParallelCoordsConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.BOX_WHISKERS:
          this.store.dispatch(
            new compute.BoxWhiskersAction({
              config: graphConfigValue as BoxWhiskersConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LINKED_GENE:
          this.store.dispatch(
            new compute.LinkedGeneAction({
              config: graphConfigValue as LinkedGeneConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.HIC:
          this.store.dispatch(
            new compute.HicAction({
              config: graphConfigValue as HicConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SOM:
          this.store.dispatch(
            new compute.SomAction({
              config: graphConfigValue as SomConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.MDS:
          this.store.dispatch(
            new compute.MdsAction({
              config: graphConfigValue as MdsConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SAVED_POINTS:
          this.store.dispatch(
            new compute.SavedPointsAction({
              config: graphConfigValue as SavedPointsConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.FA:
          this.store.dispatch(
            new compute.FaAction({
              config: graphConfigValue as FaConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LDA:
          this.store.dispatch(
            new compute.LdaAction({
              config: graphConfigValue as LdaConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.FAST_ICA:
          this.store.dispatch(
            new compute.FastIcaAction({
              config: graphConfigValue as FastIcaConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.DICTIONARY_LEARNING:
          this.store.dispatch(
            new compute.DictionaryLearningAction({
              config: graphConfigValue as DictionaryLearningConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.NMF:
          this.store.dispatch(
            new compute.NmfAction({
              config: graphConfigValue as NmfConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.TRUNCATED_SVD:
          this.store.dispatch(
            new compute.TruncatedSvdAction({
              config: graphConfigValue as TruncatedSvdConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.ISOMAP:
          this.store.dispatch(
            new compute.IsoMapAction({
              config: graphConfigValue as IsoMapConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LOCALLY_LINEAR_EMBEDDING:
          this.store.dispatch(
            new compute.LocalLinearEmbeddingAction({
              config: graphConfigValue as LocalLinearEmbeddingConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SPECTRAL_EMBEDDING:
          this.store.dispatch(
            new compute.SpectralEmbeddingAction({
              config: graphConfigValue as SpectralEmbeddingConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.INCREMENTAL_PCA:
          this.store.dispatch(
            new compute.PcaIncrementalAction({
              config: graphConfigValue as PcaIncrementalConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.KERNAL_PCA:
          this.store.dispatch(
            new compute.PcaKernalAction({
              config: graphConfigValue as PcaKernalConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SPARSE_PCA:
          this.store.dispatch(
            new compute.PcaSparseAction({
              config: graphConfigValue as PcaSparseConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.MINI_BATCH_DICTIONARY_LEARNING:
          this.store.dispatch(
            new compute.MiniBatchDictionaryLearningAction({
              config:
                graphConfigValue as MiniBatchDictionaryLearningConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.MINI_BATCH_SPARSE_PCA:
          this.store.dispatch(
            new compute.MiniBatchSparsePcaAction({
              config: graphConfigValue as MiniBatchSparsePcaConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LINEAR_DISCRIMINANT_ANALYSIS:
          this.store.dispatch(
            new compute.LinearDiscriminantAnalysisAction({
              config: graphConfigValue as LinearDiscriminantAnalysisConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SURVIVAL:
          this.store.dispatch(
            new compute.SurvivalAction({
              config: graphConfigValue as SurvivalConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.HAZARD:
          this.store.dispatch(
            new compute.HazardAction({
              config: graphConfigValue as HazardConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.HISTOGRAM:
          this.store.dispatch(
            new compute.HistogramAction({
              config: graphConfigValue as HistogramConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PLSSVD:
          this.store.dispatch(
            new compute.PlsSvdAction({
              config: graphConfigValue as PlsSvdConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PLSREGRESSION:
          this.store.dispatch(
            new compute.PlsRegressionAction({
              config: graphConfigValue as PlsRegressionConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.PLSCANONICAL:
          this.store.dispatch(
            new compute.PlsCanonicalAction({
              config: graphConfigValue as PlsCanonicalConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.QUADRATIC_DISCRIMINANT_ANALYSIS:
          this.store.dispatch(
            new compute.QuadraticDiscriminantAnalysisAction({
              config:
                graphConfigValue as QuadradicDiscriminantAnalysisConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.CCA:
          this.store.dispatch(
            new compute.CCAAction({
              config: graphConfigValue as CCAConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LINEAR_SVC:
          this.store.dispatch(
            new compute.LinearSVCAction({
              config: graphConfigValue as LinearSVCConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.LINEAR_SVR:
          this.store.dispatch(
            new compute.LinearSVRAction({
              config: graphConfigValue as LinearSVRConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.NU_SVR:
          this.store.dispatch(
            new compute.NuSVRAction({
              config: graphConfigValue as NuSVRConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.NU_SVC:
          this.store.dispatch(
            new compute.NuSVCAction({
              config: graphConfigValue as NuSVCConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.ONE_CLASS_SVM:
          this.store.dispatch(
            new compute.OneClassSVMAction({
              config: graphConfigValue as OneClassSVMConfigModel,
            })
          );
          break;
        case enums.VisualizationEnum.SVR:
          this.store.dispatch(
            new compute.SVRAction({
              config: graphConfigValue as SVRConfigModel,
            })
          );
          break;
      }
    } catch (eee) {
      Khonsole.log(
        `TEMPNOTE: Caught failure to look at visSettings. ${JSON.stringify(
          eee
        )}.`
      );
    }
  }

  tipHide(): void {
    this.store.dispatch(new TipSetVisibleAction(false));
  }
  graphPanelSetSelectionToolConfig(e: {
    config: GraphConfig;
    selectionTool: SelectionToolConfig;
  }): void {
    Khonsole.warn("==== graphPanelSetSelectionToolConfig...");
    Khonsole.dir(e.selectionTool);
    this.store.dispatch(
      new SelectionToolChangeAction({
        config: e.config,
        selectionTool: e.selectionTool,
      })
    );
  }

  //ThreeDRenderOptionAction
  setThreeDOptions(e: {
    config: GraphConfig;
    option: string;
    value: any;
  }): void {
    this.store.dispatch(
      new ThreeDRenderOptionAction({
        config: e.config,
        option: e.option,
        value: e.value,
      })
    );
  }

  edgeAddDecorator(e: {
    config: EdgeConfigModel;
    decorator: DataDecorator;
  }): void {
    this.store.dispatch(
      new DataDecoratorCreateAction({
        config: e.config,
        decorator: e.decorator,
      })
    );
  }
  edgeDelDecorator(e: {
    config: EdgeConfigModel;
    decorator: DataDecorator;
  }): void {
    this.store.dispatch(
      new DataDecoratorDelAction({ config: e.config, decorator: e.decorator })
    );
  }
  graphPanelAddDecorator(e: {
    config: GraphConfig;
    decorator: DataDecorator;
  }): void {
    this.store.dispatch(
      new DataDecoratorCreateAction({
        config: e.config,
        decorator: e.decorator,
      })
    );
  }
  graphPanelDelDecorator(e: {
    config: GraphConfig;
    decorator: DataDecorator;
  }): void {
    this.store.dispatch(
      new DataDecoratorDelAction({ config: e.config, decorator: e.decorator })
    );
  }
  graphPanelDelAllDecorators(e: { config: GraphConfig }): void {
    this.store.dispatch(new DataDecoratorDelAllAction({ config: e.config }));
  }

  graphPanelAddLegendFilter(e: {
    config: GraphConfig;
    legendFilter: LegendFilter;
  }): void {
    Khonsole.log("in graphPanelAddLegendFilter");
    let scatterChart = ChartScene.instance.views[0]
      .chart as AbstractScatterVisualization;
    if (scatterChart) {
      Khonsole.dir(ChartScene.instance.views[0].chart);

      scatterChart.savePreviousPointVisibilities();
    } else {
      Khonsole.log("NOT scatter");
    }

    //Create legend filter based on current settings? Of which legend?

    this.store.dispatch(
      new LegendFilterCreateAction({
        config: e.config,
        legendFilter: e.legendFilter,
      })
    );
  }
  graphPanelDelLegendFilter(e: {
    config: GraphConfig;
    legendFilter: LegendFilter;
  }): void {
    this.store.dispatch(
      new LegendFilterDelAction({
        config: e.config,
        legendFilter: e.legendFilter,
      })
    );
  }
  graphPanelDelAllLegendFilters(e: { config: GraphConfig }): void {
    this.store.dispatch(new LegendFilterDelAllAction({ config: e.config }));
  }

  addPathway(value: { database: string; pathway: Pathway }): void {
    this.store.dispatch(new DataAddPathwayAction(value));
  }
  delPathway(value: { database: string; pathway: Pathway }): void {
    this.store.dispatch(new DataDelPathwayAction(value));
  }
  addGeneset(value: { database: string; geneset: GeneSet }): void {
    this.store.dispatch(new DataAddGenesetAction(value));
  }
  delGeneset(value: { database: string; geneset: GeneSet }): void {
    this.store.dispatch(new DataDelGenesetAction(value));
  }
  updateGeneset(value: { database: string; geneset: GeneSet }): void {
    this.store.dispatch(new DataUpdateGenesetAction(value));
  }
  addPreprocessing(value: {
    database: string;
    preprocessing: Preprocessing;
  }): void {
    this.store.dispatch(new DataAddPreprocessingAction(value));
  }
  delPreprocessing(value: {
    database: string;
    preprocessing: Preprocessing;
  }): void {
    this.store.dispatch(new DataDelPreprocessingAction(value));
  }
  addCohort(value: { database: string; cohort: Cohort }): void {
    this.store.dispatch(new DataAddCohortAction(value));
  }
  addJob(value: { database: string; job: Job }): void {
    this.store.dispatch(new DataAddJobAction(value));
  }
  delJob(value: { database: string; job: Job }): void {
    this.store.dispatch(new DataDelJobAction(value));
  }
  delCohort(value: { database: string; cohort: Cohort }): void {
    this.store.dispatch(new DataDelCohortAction(value));
  }
  helpPanelToggle(config: GraphConfig): void {
    this.store.dispatch(new HelpSetConfigAction(config));
    this.store.dispatch(new ModalPanelAction(enums.PanelEnum.HELP));
  }
  splitScreenChange(value: boolean): void {
    const model = new WorkspaceConfigModel();
    model.layout = value
      ? enums.WorkspaceLayoutEnum.HORIZONTAL
      : enums.WorkspaceLayoutEnum.SINGLE;
    this.store.dispatch(new WorkspaceConfigAction(model));
    this.store.dispatch(
      new GraphPanelToggleAction(enums.GraphPanelEnum.GRAPH_B)
    );
  }
  setPanel(value: enums.PanelEnum): void {
    if (value === enums.PanelEnum.NONE && this.overrideShowPanel) {
      value = enums.PanelEnum.DATA;
    }
    this.store.dispatch(new ModalPanelAction(value));
  }

  workspacePanelSetConfig(value: WorkspaceConfigModel) {
    this.store.dispatch(new WorkspaceConfigAction(value));
  }

  fileLoadPrivate(value: { bucket: string; env: string }) {
    Khonsole.log(`fileLoadPrivate, input is ${JSON.stringify(value)}`);
    this.overrideShowPanel = false;
    //value.token = '';
    this.store.dispatch(new data.DataLoadFromPrivate(value));
    this.store.dispatch(new ModalPanelAction(enums.PanelEnum.NONE));
    this.store.dispatch(new LoaderShowAction());
  }

  // Convert the project param string, after "!" in "#project_abc!fig=f2,foo=123...",  into a dictionary
  projectParamstringToDict(input: string): { [key: string]: string } {
    const pairs = input.split(",");
    const dictionary: { [key: string]: string } = {};

    for (const pair of pairs) {
      const [key, valueStr] = pair.split("=");
      dictionary[key] = valueStr;
    }

    return dictionary;
  }

  // For loading a publicly-shared private-upload set, like site.com?project=myproject
  fileLoadPublicShared(publicName: string) {
    Khonsole.log("Starting fileLoadPublicShared");
    let value = {
      img: "DSbreast",
      name: "Breast",
      prefix: "tcga_brca_",
      src: "tcga",
      uid: "tcga_brca",
      fig: null,
    };

    console.log("split publicName to check for fig");
    let publicNameAndParts = publicName.split("!");
    let justPublicName = publicNameAndParts[0];
    if (publicNameAndParts.length > 1) {
      const paramDict = this.projectParamstringToDict(publicNameAndParts[1]);
      console.log(paramDict);
      if (paramDict["fig"]) {
        value.fig = paramDict["fig"];
      }
    }
    value.src = "public";
    value.img = justPublicName;
    value.name = justPublicName;
    value.prefix = "";
    value.uid = justPublicName;

    this.fileLoadPublic(value);
    Khonsole.log("Completed fileLoadPublicShared");
  }

  fileLoadPublic(value: any) {
    Khonsole.warn("fileLoadPublic =====");
    this.ds.resolveGeneSymbols();
    if (value.hasOwnProperty("content")) {
      // Khonsole.log('MJ == Should not be trying zbd ==');
      alert(
        "These semi-private datasets are not currently supported. Contact Matt Jensen with questions (mnjensen@fredhutch.org)."
      ); // MJ
      // const v = {
      //   bucket: 'zbd' + value.project.split('|')[0],
      //   token: '',
      //   name: value.content.name
      // };
      // this.overrideShowPanel = false;
      // this.store.dispatch(new data.DataLoadFromPrivate(v));
      // this.store.dispatch(new ModalPanelAction(enums.PanelEnum.NONE));
      // this.store.dispatch(new LoaderShowAction());
    } else {
      Khonsole.log('MJ == In fileLoadPublic, no "content" property.');
      value["goToSplashOnError"] = true;
      this.overrideShowPanel = false;
      this.store.dispatch(new data.DataLoadFromPublic(value));
      this.store.dispatch(new ModalPanelAction(enums.PanelEnum.NONE));
      this.store.dispatch(new LoaderShowAction());
    }
  }
}
