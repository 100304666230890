
<div>
  <mat-expansion-panel
  *ngIf="points.length > 0"
  [hideToggle]="false"
  [expanded]="true"
  (afterCollapse)="layout.setPanelCollapsed('tableOptions', true)"
  (afterExpand)="layout.setPanelCollapsed('tableOptions', false)"
  id="table-options-panel"
  style="margin-bottom: 10px"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Controls
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>


  </mat-expansion-panel-header>
  <form [formGroup]="filterForm" style="font-size: 12px" id="filter-form">
    <div id="selection">
      <div id="box">
        <mat-checkbox formControlName="onlySelection" color="primary" (change)="applyFilter()">Only show selection</mat-checkbox>
        <br/>
        <button
            mat-icon-button
            aria-label="Copy gene list to clipboard"
            class="stat-descr-btn"
            (click)="copyToClipboard($event)"
          >
            <mat-icon>file_copy</mat-icon>Copy {{dataSource.filteredData.length}} genes to clipboard
          </button>
          <br/>
          <button
            mat-icon-button
            aria-label="Open in Enrichr"
            class="stat-descr-btn"
            (click)="openInEnrichr($event)"
          >
            <mat-icon>link</mat-icon>Open {{dataSource.filteredData.length}} genes in Enrichr
            <ng-container *ngIf="processingEnrichrRequest">
              <br/>
              <div  id="enrichr-processing">Processing...</div>
            </ng-container>
          </button>
      </div>

    </div>

    <div>
      <div style="text-align: left">Search genes</div>
      <mat-form-field appearance="outline" id="search-genes">
        <input
          type="text"
          aria-label="Enter gene name from table"
          matInput
          formControlName="geneName"
          (keyup)="applyFilter()"
          placeholder="Enter gene name from table"
        />
      </mat-form-field>
    </div>

    <div>
      <div style="text-align: left">Regulation filter</div>
      <mat-button-toggle-group
      formControlName="regulation"
      aria-label="Regulation"
      id="regulation"
    >
      <mat-button-toggle value="all" (click)="applyFilter()"
        >All</mat-button-toggle
      >
      <mat-button-toggle value="down" (click)="applyFilter()">
        <span>
          <mat-icon
            style="font-size: small"
            [style.color]="downregulatedColor"
            matTooltip="downregulated"
            >arrow_downward</mat-icon
          >
        </span>
        Downregulated
        </mat-button-toggle
      >
      <mat-button-toggle value="up" (click)="applyFilter()">
        <span>
          <mat-icon
            style="font-size: small"
            [style.color]="upregulatedColor"
            matTooltip="upregulated"
            >arrow_upward</mat-icon
          >
        </span>
        Upregulated
        </mat-button-toggle
      >
    </mat-button-toggle-group>
    </div>



  </form>
</mat-expansion-panel>
</div>



<!-- Import MatTableDataSource and MatSort from @angular/material -->
<mat-table
  [dataSource]="dataSource"
  *ngIf="points.length > 0"
  matSort
  id="selected-genes"

>
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      Show label
      <!-- <br />
      <mat-checkbox
        #masterToggle
        (change)="$event ? handleMasterCheckboxClick() : null"
        [checked]="selection.hasValue() && allRenderedRowsSelected()"
        [indeterminate]="selection.hasValue() && !allRenderedRowsSelected()"
      >
      </mat-checkbox> -->
      <!-- Show label -->
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        [id]="'label-checkbox-' + row.gene"
        (click)="$event.stopPropagation(); handleShiftSelection($event, row)"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
        <!-- This is to adjust for the "Show label" label on the checkbox column header -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Define the columns for the MatTable -->
  <ng-container matColumnDef="rank">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Rank</mat-header-cell>
    <mat-cell *matCellDef="let point">{{
      dataSource.data.indexOf(point) + 1
    }}</mat-cell>
  </ng-container>

  <!-- Define the columns for the MatTable -->
  <ng-container matColumnDef="x">
    <mat-header-cell *matHeaderCellDef mat-sort-header>LogFC</mat-header-cell>
    <mat-cell *matCellDef="let point">
      <span *ngIf="getGeneRegulation(point) == 'up'">
        <mat-icon
          style="font-size: small; padding-top: 50%"
          [style.color]="upregulatedColor"
          matTooltip="upregulated"
          >arrow_upward</mat-icon
        >
      </span>
      <span *ngIf="getGeneRegulation(point) == 'down'">
        <mat-icon
          style="font-size: small; padding-top: 50%"
          [style.color]="downregulatedColor"
          matTooltip="downregulated"
          >arrow_downward</mat-icon
        >
      </span>
      <span *ngIf="getGeneRegulation(point) == 'none'">
        <mat-icon
          style="color: #000000; font-size: small; padding-top: 50%"
          matTooltip="no regulation"
          >remove</mat-icon
        >
      </span>

      {{ point.x | number : "1.5" }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="y">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >-log<sub>10</sub>(p-adjusted)</mat-header-cell
    >
    <mat-cell *matCellDef="let point">{{
      point.y | scientific : "5"
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="padj">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >p-adjusted</mat-header-cell
    >
    <mat-cell *matCellDef="let point">{{
      reverseLog(10, -point.y) | scientific : "5"
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="gene">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Gene</mat-header-cell>
    <mat-cell *matCellDef="let point" [style.fontWeight]="getFontWeight(point)">{{ point.gene }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="fc">
    <mat-header-cell *matHeaderCellDef mat-sort-header
      >Fold Change</mat-header-cell
    >
    <mat-cell *matCellDef="let point">{{
      reverseLog(2, point.x) | scientific : "5"
    }}</mat-cell>
  </ng-container>

  <!-- Set up MatTable's header row and columns -->
  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[25, 50, 100]" aria-label="Select page of users" id="paginator"></mat-paginator>
