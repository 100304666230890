<div class="dashboard-modal-panel overlay">
    <div>
        <div class='modalClose' (click)='closeClick()'>
        </div>

        <table><tbody><tr>
            <td><h1 class='panel-header' style='display:inline-block'>Spreadsheet</h1></td>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>

            <td><button title="Create cohort from selection" mat-icon-button style="color:rgb(30, 136, 229)" (click)="onCreateCohort()"><mat-icon>group_add</mat-icon></button></td>
            <td><button title="Move selected rows to top"  mat-icon-button style="color:rgb(30, 136, 229)" (click)="onSelectedToTop()"><mat-icon>unarchive</mat-icon></button></td>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>

                <td><mat-form-field class='form-field'>
                <mat-select placeholder='Data Table' (selectionChange)='tableChange($event);' [(value)]="_table">
                    <mat-option *ngFor='let table of _tables' [value]='table'>
                        {{ table.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field></td>
            <td><button mat-icon-button style="color:rgb(30, 136, 229)" (click)="onSaveFile()"><mat-icon>save</mat-icon></button></td>
        </tr></tbody></table>

    </div>
    <!-- <div>
        <a href='#' class='modalClose' (click)='closeClick()'>
        </a>
        <h1 class='panel-header' style='display: inline-block;'>Speadsheet</h1>
        <h2 class='panel-header-desc' style='width:180px;display: inline-block;padding-left:20px;'>
            <mat-form-field class='form-field'>
                <mat-select placeholder='Data Table' (selectionChange)='tableChange($event);' [(value)]="_table">
                    <mat-option *ngFor='let table of _tables' [value]='table'>
                        {{ table.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </h2>

    </div> -->
    <div style='position: relative; left:-30px; top:120px;'>
        <hot-table class="hot"  id="spreadsheetHotTable" [columns]="columns" [hotId]="spreadsheetHotTable" [settings]='settings' [colHeaders]='colHeaders' [rowHeaders]='rowHeaders' [data]='dataSource'>

        </hot-table>
    </div>
</div>
