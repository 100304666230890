import { Khonsole } from 'app/khonsole';
import { DatasetDescription } from 'app/model/dataset-description.model';
import { DataService } from 'app/service/data.service';
import { ChartScene } from 'app/component/workspace/chart/chart.scene';
import { OncoData } from 'app/oncoData';
import { GraphConfig } from './../../../model/graph-config.model';
import { Cohort } from 'app/model/cohort.model';

export class CommonSidePanelModel {
  public static instance: CommonSidePanelModel;

  //wire up to parent panel
  datasetDescription: DatasetDescription;
  dataService: DataService;

  public genesData:Array<Array<any>> = null;
  public cnaData:Array<any> = null;
  public cnaSampleMapData = null;
  public patientMap = null;
  public sampleMap = null;
  public patientData:Array<any> = null;
  // These are things in config which trigger reloading or reoptimizing data for copynumber widget.
  public lastCopynumberProcessedMarkerFilterAsString:string = null;
  public lastCopynumberProcessedDatabase:string = null;
  public width = 268; //was 262. full sidebar width is 275.
  public tableNameUsedForCopynumber:string = null;

  public selectedCohort: Cohort = {
    n: "",
    pids: [],
    sids: [],
    conditions: []
  }
  public lastSelectedDefinedCohort = null; // if null, selected points are a manualselection.
  public graphConfig:GraphConfig;

  // public cohortColors = [
  //   "#039CE5",
  //   "#E91E63",
  //   "#673AB7",
  //   "#4CAF50",
  //   "#CDDC39",
  //   "#FFC107",
  //   "orange"
  // ];


  // cohortColors is brewerColros SEt1, with bits of dark2 added.
  public cohortColors = [
    "#E41A1C", // (red)
    "#377EB8", // (blue)
    "#4DAF4A", // (green)
    "#984EA3", // (purple)
    "#FF7F00", // (orange)
    "#0000FF", // (yellow)
    "#A65628", // (brown)
    "#F781BF", // (pink)
    "#999999", // (gray)
    // Now Dark2...
    // Skip #1B9E77(dark green)
    "#D95F02", // (dark orange)
    "#7570B3", // (dark purple - blue)
    "#E7298A", // (dark pink)
    "#66A61E", // (olive green)
    "#E6AB02", // (dark yellow)
    "#A6761D", // (brown)
    "#66666", // 6(dark gray)
];

  public notifyGraphsOfVariantChanges(reason:string){
    Khonsole.log(`notifyGraphsOfVariantChanges because ${reason}.`);
    OncoData.instance.variantsEdgeArray = null;
    // TODO: check each view, send update.
    ChartScene.instance.views.map( v=> {
      if(v.chart){
        v.chart.notifiedOfVariantChanges(reason);
      }
    });
  }

  constructor() {
    CommonSidePanelModel.instance = this;
  }
}
