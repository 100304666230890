<div class="panel">
  <div>
    <div class="modalClose" (click)="closeClick()"> </div>
    <h1 class="panel-header">Data Pipeline</h1>
    <h2 class="panel-header-desc">Create custom pipelines to transform your data</h2>
  </div>
  <!-- My Cohorts -->
  <div fxLayout.xs="column">
    <div fxFlex.sm="1 1 30%" fxFlex.md="1 1 20%" fxFlex.lg="1 1 25%" fxFlex.xl="1 1 20%">
      <h2 class="panel-column-title">My Pipelines</h2>
      <div *ngFor="let option of preprocessings" (click)="deleteClick(option)" class="panel-config-row-del">
        <i class="material-icons md-18">remove_circle_outline</i>
        <div>{{ option.n }}</div>
      </div>
    </div>

    <!-- Create Pipeline -->
    <div fxFlex.sm="1 1 70%" fxFlex.md="1 1 80%" fxFlex.lg="1 1 75%" fxFlex.xl="1 1 80%">
      <h2 class="panel-column-title" style="margin-bottom:10px;">Build A Pipeline</h2>

      <label class="panel-builder-label">create</label>
      <mat-form-field>
        <input
          placeholder="Enter Pipeline Name"
          matInput
          type="text"
          autocomplete="off"
          [(ngModel)]="preprocessing.n"
        />
      </mat-form-field>
      <div class="panel-builder-criteria-row" *ngFor="let step of preprocessing.steps; let i = index">
        <label class="panel-builder-label">Step</label>
        <mat-form-field>
          <mat-select
            placeholder="Transform"
            [value]="step"
            [compareWith]="stepCompareFn"
            (selectionChange)="selectionChange(step, i, $event)"
          >
            <mat-option *ngFor="let option of possibleSteps" [value]="option"> {{ option.method }} </mat-option>
          </mat-select>
        </mat-form-field>
        <span *ngFor="let param of step.params">
          <span *ngIf="param.dataType === 'float' || param.dataType === 'int'">
            <mat-form-field style="width:75px;margin-left:5px;" matTooltip="{{param.desc}}">
              <input
                matInput
                type="number"
                [(ngModel)]="param.value"
                autocomplete="off"
                placeholder="{{param.name}}"
                min="param.values[0]==='-1' ? -Infinity :param.values[0] "
                max="param.values[1]==='-1' ? Infinity : param.values[1] "
                step="{{param.dataType === 'float' ? 0.1 : 1}}"
              />
            </mat-form-field>
          </span>
          <span *ngIf="param.dataType === 'set' || param.dataType === 'boolean'">
            <mat-form-field style="width:170px;margin-left:5px;" matTooltip="{{param.desc}}">
              <mat-select [(value)]="param.value" placeholder="{{param.name}}">
                <mat-option *ngFor="let option of param.values" [value]="option"> {{ option }} </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </span>
        <span class="panel-builder-buttons">
          <div class="os-link" (click)="stepAdd(0)">Add</div> |
          <div class="os-link" (click)="stepDel(0)">Remove</div> |
          <!-- FIXME: Which href should this go to? -->
          <a class="os-link" href="{{step.url}}" target="_blank" href="#">Info</a>
        </span>
      </div>
      <button class="button" (click)="saveClick()" mat-raised-button>Save</button>
    </div>
  </div>
</div>
