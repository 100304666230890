<div class="overlay" id="differential-expression-modal-panel">
  <a href="#" class="modalClose" (click)="closeClick()"></a>
  <h1 class="dashboard-h1">Differential Expression Analysis</h1>

  <div id="compareCohorts">
    <h4>
      This Differential Expression Analysis uses the PyDESeq2 implementation,
      based on Bioconductor's DESeq2 R package.
    </h4>

    <div
      style="display: flex; align-items: center; gap: 20px;"
    >
      <mat-form-field>
        <mat-select placeholder="Choose a test cohort" [(ngModel)]="cohortA">
          <mat-option *ngFor="let option of cohorts" [value]="option">
            {{ option.n }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          placeholder="Choose a reference cohort"
          [(ngModel)]="cohortB"
        >
          <mat-option *ngFor="let option of cohorts" [value]="option">
            {{ option.n }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>(using RNA counts table)</div>
      <!-- TODO: Add metadata to the tables so we know which ones are safe to show in this list (for example, yes to rnacounts, no to rna or cna) -->
<!--
      <mat-form-field>
        <mat-select
          placeholder="Choose a table"
          [(ngModel)]="selectedCompareTable"
        >
          <mat-option *ngFor="let option of tables" [value]="option">
            {{ option.tbl }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>

    <mat-form-field style="min-width: 40%">
      <mat-label>Analysis Label</mat-label>
      <input matInput [(ngModel)]="analysisName" autocomplete="off" />
    </mat-form-field>
  </div>

  <button
    class="button"
    id="diff-exp-btn"
    (click)="diffExpClick()"
    mat-raised-button
  >
    Run Analysis
  </button>

  <app-job-table [jobType]="'differentialExpression'" [jobManagerService]="JobManagerService"></app-job-table>
</div>
