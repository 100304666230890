var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Khonsole } from "app/khonsole";
import { AfterViewInit, OnChanges, ChangeDetectorRef, SimpleChanges, QueryList, EventEmitter, } from "@angular/core";
import * as _ from "lodash";
import { GraphConfig } from "../../../model/graph-config.model";
import { ChartFactory } from "../chart/chart.factory";
import * as THREE from "three";
import { SelectionModifiers } from "app/component/visualization/visualization.abstract.scatter.component";
import { OncoData } from "app/oncoData";
import { ChartScene } from "../chart/chart.scene";
import { LegendEyeComponent } from "./legend-eye/legend-eye.component";
import { VisualizationEnum } from "app/model/enum.model";
import { MatTooltip } from "@angular/material/tooltip";
export class LegendPanelComponent {
    constructor(cd) {
        this.cd = cd;
        // Array to hold MatTooltip instances
        this.legendTooltips = [];
        this.autoUpdate = true;
        this.allLegends = [];
        this.updateLegend = _.debounce(this.update, 600);
        this.barChartConfig = {
            yAxisType: "percent",
            showEntireDataset: false,
        };
        /**
         * @description Map of whether the bar chart is visible for each legend item type.
         */
        this._isBarChartVisible = new Map();
        this._decorators = [];
        this._legends = [];
        this._legendFilters = [];
        this.preventRecursiveUpdate = false;
        LegendPanelComponent.setLegends.subscribe(this.onSetLegends.bind(this));
    }
    isBarChartVisible(legend) {
        if (!this._isBarChartVisible.has(legend.type)) {
            return false;
        }
        return this._isBarChartVisible.get(legend.type);
    }
    get config() {
        return this._config;
    }
    set config(value) {
        if (value === null) {
            return;
        }
        console.warn("LegendPanelComponent.config set");
        if (this.legendEyes) {
            this.legendEyes.forEach((eye, i) => {
                this.setLegendItemVisibility(eye, i, eye.legend.visibility[i] === 1); // was "true);". This is a hack to fix the eyes not being reset when the decorator changes."
            });
        }
        this._config = value;
        this.updateLegend();
    }
    set decorators(value) {
        if (value === null) {
            return;
        }
        if (this._decorators != value) {
            this._decorators = value;
            this.updateLegend();
        }
    }
    set legends(value) {
        if (value === null) {
            Khonsole.log(`TEMPNOTE: Input for legend-panel was null.`);
            return;
        }
        this._legends = value;
        this.updateLegend();
    }
    set legendFilters(value) {
        if (value === null) {
            Khonsole.log(`TEMPNOTE: Input legendFilters for legend-panel was null.`);
            return;
        }
        this._legendFilters = value;
        this.updateLegendFilters();
    }
    updateLegendFilters() {
        Khonsole.warn("## updateLegendFilters NYI ##");
    }
    ngAfterViewInit() {
        this.legendTooltips = this.legendTooltipsQuery.toArray();
    }
    updateLegendItemColor(legend, itemIdx, color) {
        if (color) {
            // update the color in local storage
            ChartFactory.writeCustomValueToLocalStorage(this._config.database, "legendColors", legend.name +
                "!" +
                ChartFactory.cleanForLocalStorage(legend.labels[itemIdx]), color);
            // tell the chart to update the decorators, which will query the local storage for the new color
            ChartScene.instance.views[0].chart.updateDecorator(this._config, this._decorators);
        }
    }
    setLegendItemVisibility(eye, i, visible) {
        eye.visible = visible;
        eye.legend.visibility[i] = Number(visible);
    }
    isntDataPointsPlaceholder(legend) {
        let result = true;
        try {
            if (legend.type === "SHAPE") {
                result = legend.name != "Data Points" && legend.values.length > 1;
            }
        }
        catch (e) {
            Khonsole.error(`Error in isntDataPointsPlaceholder: ${e}`);
        }
        return result;
    }
    allLegendsExceptDataPointsPlaceholder() {
        let self = this;
        let result = this.allLegends.filter((l) => self.isntDataPointsPlaceholder(l));
        return result;
    }
    onEyeClick(activity) {
        if (!this.config.isScatterVisualization) {
            Khonsole.warn("In onEyeClick, clicking non-scatter vis. Not yet supported..");
            return;
        }
        // Get current vis of clicked item.
        let currentEyeVis = true;
        if (this.legendEyes) {
            this.legendEyes.forEach((eye, i) => {
                if (i == activity.i) {
                    currentEyeVis = eye.visible;
                }
            });
            this.legendEyes.forEach((eye, i) => {
                if (i == activity.i) {
                    this.setLegendItemVisibility(eye, i, !currentEyeVis);
                }
                else {
                    if (activity.event.altKey) {
                        this.setLegendItemVisibility(eye, i, currentEyeVis); // e.g., if item was true, all others now become true.
                    }
                }
            });
        }
        Khonsole.warn("== Assuming view 0 in onEyeClick ==");
        let view = ChartScene.instance.views[0];
        let thisScatterGraph = view.chart;
        if (thisScatterGraph && thisScatterGraph.isBasedOnAbstractScatter) {
            thisScatterGraph.removeInvisiblesFromSelection(view.config, view.chart.decorators);
        }
        else {
            Khonsole.warn("This vis does not support removeInvisiblesFromSelection.");
        }
        ChartScene.instance.render();
        OncoData.instance.currentCommonSidePanel.drawWidgets();
    }
    countAttempt(lf) {
        Khonsole.warn("in countAttempt");
        Khonsole.dir(lf);
        return 0;
    }
    formattedLegendItemText(legend, i) {
        //    {{label}}{{legend.counts && legend.counts[i]?'&nbsp;&nbsp;&nbsp;('+ selectionOf(legend,i)+ legend.counts[i]+')':''
        const itemCountsExist = legend.counts && legend.counts[i];
        let txt = `${legend.labels[i]}${itemCountsExist
            ? ` (${this.selectionOf(legend, i)}${legend.counts[i]})${this.selectionPercent(legend, i)}`
            : ""}`;
        return txt;
    }
    legendItemHasSelectedPoints(legend, i) {
        return (legend.selectionCounts &&
            legend.selectionCounts[i] &&
            legend.selectionCounts[i] > 0);
    }
    legendItemIsHidden(legend, i) {
        let hiddenTest = false;
        if (this.legendEyes) {
            this.legendEyes.forEach((eye, idx) => {
                if (i == idx) {
                    hiddenTest = eye.visible == false;
                }
            });
        }
        return hiddenTest;
    }
    selectionOf(legend, i) {
        // Returns "3 of " part of "3 of 5".
        if (legend.selectionCounts) {
            let count = legend.selectionCounts[i];
            if (count == 0) {
                // none selected
                return "";
            }
            else {
                return `${count} of `;
            }
        }
        else {
            return "";
        }
    }
    selectionPercent(legend, i) {
        if (legend.selectionCounts) {
            let count = legend.selectionCounts[i];
            if (count == 0) {
                // none selected
                return "";
            }
            let total = legend.counts[i];
            let percent = (count / total) * 100;
            return ` (${percent.toFixed(1)}%)`;
        }
        else {
            return "";
        }
    }
    smarterName(name) {
        if (name) {
            if (name.startsWith("Color By ColorBy:")) {
                name = name.replace("ColorBy:", "");
                let equalStart = name.indexOf("=");
                name =
                    "Color By " +
                        name.substring("color By ".length, equalStart).toUpperCase() +
                        name.substring(equalStart);
                return name;
            }
            else {
                return name;
            }
        }
        else {
            // name is null
            Khonsole.warn("Unnamed Legend in smarterName()");
            return "Unnamed Legend";
        }
    }
    getClickedPidsFromLegendItem(legend, i) {
        let dec = ChartScene.instance.views[0].chart.decorators.find((d) => d.field && d.field.label == legend.name);
        if (dec == null) {
            dec = ChartScene.instance.views[0].chart.decorators.find((d) => d.field && "Color By " + d.field.label == legend.name);
        }
        if (dec == null) {
            Khonsole.warn("Null decorator in clickedPidsFromLegendItem.");
            return;
        }
        if (dec.pidsByLabel == null)
            return null;
        const legendItemLabel = legend.labels[i];
        let clickedPids = dec.pidsByLabel.find((v) => v.label == legendItemLabel);
        if (!clickedPids)
            return null;
        if (clickedPids.pids) {
            Khonsole.log(`clickedPids.length = ${clickedPids.pids.length}.`);
        }
        else {
            Khonsole.warn("clickedPids is undefined in clickedPidsFromLegendItem.");
        }
        return clickedPids.pids;
    }
    setBarChartConfigYAxisType(event) {
        this.barChartConfig.yAxisType = event.value;
        // the "Entire Dataset" bars have no meaning in ratio mode.
        if (event.value === "ratio") {
            this.barChartConfig.showEntireDataset = false;
        }
        this.updateLegend();
    }
    toggleBarChartConfigShowEntireDataset() {
        this.barChartConfig.showEntireDataset =
            !this.barChartConfig.showEntireDataset;
        this.updateLegend();
    }
    getClickedPidsPerEvent(legend, i) {
        if (ChartScene.instance.views[0].config.visualization !=
            VisualizationEnum.TIMELINES ||
            !window["clickedPidsPerEvent"]) {
            return null;
        }
        let basekey = legend.name; // legend.name.startsWith("ROW // ") ? legend.name.substring("ROW // ".length) : legend.name;
        Khonsole.log(`basekey=${basekey}.`);
        let subtype = legend.labels[i];
        let typeSubtype = (basekey + ":" + subtype).toLowerCase();
        let typeSubtypeArray = window["clickedPidsPerEvent"][typeSubtype];
        if (typeSubtypeArray == null) {
            typeSubtypeArray = window["clickedPidsPerEvent"][typeSubtype + ":"];
        }
        if (typeSubtypeArray == null) {
            typeSubtypeArray = window["clickedPidsPerEvent"]["row // " + typeSubtype];
        }
        if (typeSubtypeArray == null) {
            typeSubtypeArray =
                window["clickedPidsPerEvent"]["row // " + typeSubtype + ":"];
        }
        if (typeSubtypeArray == null) {
            // Try removing "row // " from front.
            if (typeSubtype.startsWith("row // ")) {
                let typeSubtypeWithoutRow = typeSubtype.substring(7);
                typeSubtypeArray = window["clickedPidsPerEvent"][typeSubtypeWithoutRow];
                if (typeSubtypeArray == null) {
                    typeSubtypeArray =
                        window["clickedPidsPerEvent"][typeSubtypeWithoutRow + ":"];
                }
            }
        }
        if (typeSubtypeArray) {
            return Array.from(typeSubtypeArray);
        }
        return null;
    }
    onLegendItemTextClick(event, legend, i) {
        const color = legend.values[i];
        // build list of matching patient IDs, then
        let patientIds = this.getClickedPidsFromLegendItem(legend, i);
        // if we didn't find a match, then try to get it from the event (happens for timelines)
        if (patientIds == null) {
            patientIds = this.getClickedPidsPerEvent(legend, i);
        }
        if (patientIds != null) {
            let selectionModifiers = SelectionModifiers.fromEvent(event);
            // pass it off to commonSidePanel.setSelectionPatientIds.
            // patientIds length should match legend.counts[i].
            window.setTimeout(() => {
                OncoData.instance.currentCommonSidePanel.setSelectionPatientIds({
                    patientIds,
                    existingCohort: "Legend",
                    preferredCohortColor: color,
                    selectionModifiers,
                    graphConfig: null,
                });
                OncoData.instance.currentCommonSidePanel.drawWidgets();
            }, 20);
        }
        else {
            Khonsole.log("Click on label did not resolve by color.");
        }
        window.setTimeout(this.update, 100);
    }
    /**
     * @description Format the legend values to be displayed in the legend panel. If there is an assocaited data field, pass it in to get the key.
     */
    legendFormatter(legend, field) {
        if (legend == null) {
            Khonsole.error("Expected legend in legendFormatter().");
            return null;
        }
        else {
            if (legend.values == null) {
                Khonsole.error("Expected legend.values in legendFormatter().");
                return legend;
            }
            const rv = Object.assign({}, legend);
            if (rv.values == null) {
                Khonsole.error("NOT valid legend in legendFormatter. 363732");
                alert("Not valid legend in legendFormatter");
                return legend;
            }
            if (rv.type === "COLOR") {
                for (let i = 0; i < rv.values.length; i++) {
                    if (!isNaN(rv.values[i])) {
                        legend.values[i] =
                            "#" + (0xffffff + legend.values[i] + 1).toString(16).substr(1);
                    }
                }
            }
            else if (legend.type === "SHAPE") {
                for (let i = 0; i < rv.values.length; i++) {
                    if (!isNaN(rv.values[i])) {
                        legend.values[i] =
                            "https://oncoscape.v3.sttrcancer.org/assets/shapes/shape-" +
                                legend.values[i] +
                                "-solid-legend.png";
                    }
                }
            }
            if (field) {
                rv.tbl = field.tbl;
                if (field.tbl !== "patient" && field.key === "ColorBy") {
                    // we are trying to color by molecular data
                    // To get the proper key
                }
                rv.key = field.key;
            }
            return rv;
        }
    }
    isColorLegend(legend) {
        return legend.type === "COLOR";
    }
    update() {
        let self = this;
        if (!self.autoUpdate) {
            return;
        }
        if (self.preventRecursiveUpdate) {
            self.preventRecursiveUpdate = false;
            return;
        }
        // // Since update gets called a lot, and it triggers a ton of sub-systems, double check if the decorators changed
        // if (_.isEqual(self.lastUpdateDecorators, self._decorators)) {
        //   return;
        // }
        const legendsFromDecorators = self._decorators.map((decorator) => self.legendFormatter(decorator.legend, decorator.field));
        try {
            const coreLegends = self._legends.map((legend) => self.legendFormatter(legend));
            this.allLegends = [].concat(...legendsFromDecorators, ...coreLegends);
            let colorLegend = this.allLegends.find((l) => l.type == "COLOR");
            if (colorLegend) {
                let smartName = self.smarterName(colorLegend.name);
                // Khonsole.warn(`smartName = ${smartName}.`)
                let header = document.querySelector(".color-legend-header");
                if (header) {
                    let cleanHeader = header.innerText
                        .trim()
                        .toLowerCase()
                        .replace(/ /g, "_");
                    let cleanSmartName = smartName
                        .trim()
                        .toLowerCase()
                        .replace(/ /g, "_");
                    if (cleanHeader != cleanSmartName) {
                        header.classList.remove("fade-text-animation");
                        // Trigger a reflow in between removing and adding the class
                        const forceReflow = header.offsetWidth;
                        header.scrollTop = header.scrollTop;
                        header.classList.add("fade-text-animation");
                    }
                }
                else {
                    Khonsole.error("Could not find color-legend-header.");
                }
            }
            // substitute custom colors for legend items, if they exist.
            this.allLegends.map((legend) => {
                if (legend.type == "COLOR" && legend.labels) {
                    for (let label in legend.labels) {
                        let cleanLabel = ChartFactory.cleanForLocalStorage(legend.labels[label]);
                        let customColor = ChartFactory.readCustomValueFromLocalStorage(this._config.database, "legendColors", legend.name + "!" + cleanLabel);
                        if (customColor) {
                            // create a threejs color object to get the hex value
                            legend.values[label] =
                                "#" + new THREE.Color(customColor).getHexString();
                        }
                    }
                }
            });
            this.allLegends.forEach((legend, index) => this.drawGoogleSingleStatChart(legend, index));
            this.cd.detectChanges();
        }
        catch (err) {
            Khonsole.error(`TEMPNOTE: error in legend update, probably bad _legends. ${err}`);
        }
    }
    ngOnChanges(changes) {
        // console.warn("LegendPanelComponent.ngOnChanges");
        // console.dir(changes);
        // if (changes["decorators"]) {
        //   let sc: SimpleChange = changes["decorators"];
        //   // Assume for now we only care about the first decorator. TBD
        //   let dec: DataDecorator = sc.currentValue[0];
        //   let oldDec: DataDecorator = sc.previousValue[0];
        //   if (dec && oldDec && dec.field !== oldDec.field) {
        //     console.warn("Decorator Field changed. Updating eyes.");
        //     // Somehow the eyeballs are not being reset, so we need to do it here.
        //     if (this.legendEyes) {
        //       let eyes = this.legendEyes.toArray();
        //       eyes.forEach((eye, i) => {
        //         console.log(`ngOnChanges eye[${i}].visible = ${eye.visible}`);
        //       });
        //     }
        //   }
        // }
        // this.update();
    }
    /**
     * @description Display the tooltip for the legend item.
     * @param i The index of the legend item.
     */
    displayTooltip(tooltip) {
        tooltip.disabled = false;
        tooltip.show();
    }
    /**
     * @description Hide the tooltip for the legend item.
     * @param i The index of the legend item.
     */
    hideTooltip(tooltip) {
        tooltip.disabled = true;
        tooltip.hide();
    }
    trackLegendByFn(index, legend) {
        return legend.type; // color, shape, etc., because we only have one of each type max.
    }
    drawGoogleSingleStatChart(legend, index) {
        return __awaiter(this, void 0, void 0, function* () {
            const el = document.getElementById("legend-bar-chart-" + index);
            if (!el)
                return;
            // If not color, or no selection counts are available, clear the chart. This happens on non-color legends.
            if (legend.type !== "COLOR" ||
                legend.selectionCounts === null ||
                legend.selectionCounts === undefined) {
                el.innerHTML = "";
                return;
            }
            // right now, legends are always discrete.
            const numSamplesInSelection = legend.selectionCounts.reduce((a, b) => {
                let temp1 = a === null || a === undefined ? 0 : a;
                let temp2 = b === null || b === undefined ? 0 : b;
                return temp1 + temp2;
            }, 0);
            // If the legend is a color type, use its values for the bar colors. Otherwise, use blue.
            // FIXME: Right now only COLOR legends get through to here, due to other legend types not having selectionCounts.
            const legendItemColors = legend.type === "COLOR"
                ? legend.values
                : new Array(legend.labels.length).fill("blue");
            if (numSamplesInSelection == 0) {
                this._isBarChartVisible.set(legend.type, false);
                // clear chart
                el.innerHTML = "<i>No samples selected.</i>";
                return;
            }
            try {
                // Populate chart
                el.innerHTML = "";
                const rows = legend.selectionCounts.map((selectionCount, i) => {
                    // const key = legend.key;
                    // const tbl = legend.tbl;
                    const metric = legend.labels[i];
                    let entireDataset = [];
                    let datasetCount = 0;
                    if (legend.tbl === "patient") {
                        entireDataset =
                            OncoData.instance.currentCommonSidePanel.commonSidePanelModel
                                .patientData;
                        datasetCount = entireDataset.filter((p) => p[legend.key] === metric).length;
                    }
                    // clean up null or undefined values.
                    selectionCount =
                        selectionCount === null || selectionCount === undefined
                            ? 0
                            : selectionCount;
                    let selectionValue = 0;
                    let datasetValue = 0;
                    if (this.barChartConfig.yAxisType === "percent") {
                        selectionValue = (selectionCount / numSamplesInSelection) * 100;
                        datasetValue = (datasetCount / entireDataset.length) * 100;
                    }
                    else if (this.barChartConfig.yAxisType === "count") {
                        selectionValue = selectionCount;
                        datasetValue = datasetCount;
                    }
                    else if (this.barChartConfig.yAxisType === "ratio") {
                        // ratio of selection vs dataset
                        const selectionPcnt = (selectionCount / numSamplesInSelection) * 100;
                        const datasetPcnt = (datasetCount / entireDataset.length) * 100;
                        selectionValue = selectionPcnt / datasetPcnt;
                    }
                    return { metric, selectionValue, datasetValue };
                });
                const preferredEntireDatasetColor = "black";
                const entireDatasetColor = yield OncoData.instance.currentCommonSidePanel.calculateAssignedCohortColort({ preferredColor: preferredEntireDatasetColor });
                const dt = new google.visualization.DataTable();
                dt.addColumn("string", "Metric");
                dt.addColumn("number", this.barChartConfig.yAxisType === "percent"
                    ? "% Selection"
                    : "Selection Count");
                dt.addColumn({ type: "string", role: "style" });
                dt.addColumn({ type: "boolean", role: "certainty" });
                if (this.barChartConfig.showEntireDataset) {
                    dt.addColumn("number", this.barChartConfig.yAxisType === "percent"
                        ? "% Entire Dataset"
                        : "Entire Dataset Count");
                    dt.addColumn({ type: "string", role: "style" });
                    dt.addColumn({ type: "boolean", role: "certainty" });
                }
                dt.addRows(legend.selectionCounts.length);
                rows.forEach((r, i) => {
                    dt.setCell(i, 0, r.metric);
                    dt.setCell(i, 1, r.selectionValue);
                    dt.setCell(i, 2, legendItemColors[i]);
                    dt.setCell(i, 3, true);
                    if (!this.barChartConfig.showEntireDataset) {
                        return;
                    }
                    dt.setCell(i, 4, r.datasetValue);
                    dt.setCell(i, 5, `color: ${entireDatasetColor}; opacity: 0.5`);
                    dt.setCell(i, 6, false);
                });
                const chart = new google.visualization.ColumnChart(el);
                chart.draw(dt, {
                    legend: "none",
                    title: "",
                    vAxis: {
                        title: null,
                        viewWindow: {
                            min: 0,
                        },
                        format: this.barChartConfig.yAxisType === "percent" ? "#'%'" : "#",
                    },
                });
                this._isBarChartVisible.set(legend.type, true);
            }
            catch (error) {
                console.error("Error drawing chart.", error);
                el.innerHTML = "<i>Error drawing chart.</i>";
            }
            this.cd.detectChanges();
            return "";
        });
    }
    onSetLegends(e) {
        if (this.config.graph !== e.graph) {
            return;
        }
        this.autoUpdate = false;
        this.allLegends = [].concat(...e.legends); //e.legends;
        this.allLegends.forEach((legend, index) => this.drawGoogleSingleStatChart(legend, index));
        this.cd.detectChanges();
    }
}
LegendPanelComponent.setLegends = new EventEmitter();
