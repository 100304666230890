import { combineReducers } from '@ngrx/store';
import { createSelector } from 'reselect';
import * as fromData from './data.reducer';
import * as fromEdges from './edges.reducer';
import * as fromGraph from './graph.reducer';
import * as fromHelp from './help.reducer';
import * as fromLayout from './layout.reducer';
import * as fromSelect from './select.reducer';
import * as fromSpreadsheet from './spreadsheet.reducer';
import * as fromUser from './user.reducer';
import * as fromTip from './tip.reducer';
const graphAReducer = fromGraph.graphReducerA;
const graphBReducer = fromGraph.graphReducerB;
const ɵ0 = fromData.reducer;
export let reducers = {
    help: fromHelp.reducer,
    tip: fromTip.reducer,
    layout: fromLayout.reducer,
    graphA: graphAReducer,
    graphB: graphBReducer,
    edges: fromEdges.reducer,
    select: fromSelect.reducer,
    data: ɵ0,
    spreadsheet: fromSpreadsheet.reducer,
    user: fromUser.reducer
};
// const developmentReducer: ActionReducer<State> = compose(storeFreeze, combineReducers)(reducers);
const productionReducer = combineReducers(reducers);
export function reducer(state, action) {
    return productionReducer(state, action);
    // if (environment.production) {
    // } else {
    //   return developmentReducer(state, action);
    // }
}
/**
 * Layout Reducer
 */
export const getLayoutState = (state) => state.layout;
export const getLayoutGraphPanelAState = createSelector(getLayoutState, fromLayout.getGraphPanelAState);
export const getLayoutGraphPanelBState = createSelector(getLayoutState, fromLayout.getGraphPanelBState);
export const getLayoutModalPanelState = createSelector(getLayoutState, fromLayout.getModalPanelState);
export const getLayoutLoaderState = createSelector(getLayoutState, fromLayout.getLoaderState);
const ɵ1 = (state) => state.workspaceConfig;
export const getWorkspaceConfig = createSelector(getLayoutState, ɵ1);
/**
 * Graph Reducers
 */
export const getGraphAState = (state) => state.graphA;
const ɵ2 = (state) => state.visibility;
export const getGraphAVisibility = createSelector(getGraphAState, ɵ2);
const ɵ3 = (state) => state.depth;
export const getGraphADepth = createSelector(getGraphAState, ɵ3);
const ɵ4 = (state) => state.config;
export const getGraphAConfig = createSelector(getGraphAState, ɵ4);
const ɵ5 = (state) => state.selectionToolConfig;
export const getGraphASelectionToolConfig = createSelector(getGraphAState, ɵ5);
const ɵ6 = (state) => state.data;
export const getGraphAData = createSelector(getGraphAState, ɵ6);
const ɵ7 = (state) => state.decorators;
export const getGraphADecorators = createSelector(getGraphAState, ɵ7);
const ɵ8 = (state) => state.legendFilters;
export const getGraphALegendFilters = createSelector(getGraphAState, ɵ8);
export const getGraphBState = (state) => state.graphB;
const ɵ9 = (state) => state.visibility;
export const getGraphBVisibility = createSelector(getGraphBState, ɵ9);
const ɵ10 = (state) => state.depth;
export const getGraphBDepth = createSelector(getGraphBState, ɵ10);
const ɵ11 = (state) => state.config;
export const getGraphBConfig = createSelector(getGraphBState, ɵ11);
const ɵ12 = (state) => state.selectionToolConfig;
export const getGraphBSelectionToolConfig = createSelector(getGraphBState, ɵ12);
const ɵ13 = (state) => state.data;
export const getGraphBData = createSelector(getGraphBState, ɵ13);
const ɵ14 = (state) => state.decorators;
export const getGraphBDecorators = createSelector(getGraphBState, ɵ14);
// Edges Reducer
export const getEdgesState = (state) => state.edges;
const ɵ15 = (state) => state.config;
export const getEdgesConfig = createSelector(getEdgesState, ɵ15);
const ɵ16 = (state) => state.data;
export const getEdgesData = createSelector(getEdgesState, ɵ16);
const ɵ17 = (state) => state.decorators;
export const getEdgeDecorators = createSelector(getEdgesState, ɵ17);
// Data Reducer
export const getDataState = (state) => state.data;
export const getFields = createSelector(getDataState, fromData.getFields);
export const getTables = createSelector(getDataState, fromData.getTables);
export const getEvents = createSelector(getDataState, fromData.getEvents);
export const getPathways = createSelector(getDataState, fromData.getPathways);
export const getGenesets = createSelector(getDataState, fromData.getGenesets);
export const getCohorts = createSelector(getDataState, fromData.getCohorts);
export const getJobs = createSelector(getDataState, fromData.getJobs);
export const getPreprocessing = createSelector(getDataState, fromData.getPreprocessing);
export const getDatasetDescription = createSelector(getDataState, fromData.getDatasetDescription);
// Select Reducer
export const getSelectState = (state) => state.select;
export const getSelectVisible = createSelector(getSelectState, fromSelect.getVisible);
export const getSelectSelection = createSelector(getSelectState, fromSelect.getSelection);
export const getSelectStats = createSelector(getSelectState, fromSelect.getStats);
// Spreadsheet Reducer
export const getSpreadsheetState = (state) => state.spreadsheet;
export const getDataTable = createSelector(getSpreadsheetState, fromSpreadsheet.getDataTable);
// Help Reducer
export const getHelpState = (state) => state.help;
export const getHelpConfigState = createSelector(getHelpState, fromHelp.getHelpConfigState);
// User Reducer
export const getUserState = (state) => state.user;
export const getUserData = createSelector(getUserState, fromUser.getUserData);
export const getUserDataSets = createSelector(getUserState, fromUser.getUserDataSets);
// Tip Reducer
export const getTipState = (state) => state.tip;
export const getTipVisualization = createSelector(getTipState, fromTip.getTipVisualization);
export const getTipEnabled = createSelector(getTipState, fromTip.getTipEnabled);
export const getTipVisible = createSelector(getTipState, fromTip.getTipVisible);
export const getTip = createSelector(getTipState, fromTip.getTip);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
