export enum WidgetSettingsFieldType {
  RANGE = 'range',
  NUMBER = 'number',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  COLOR = 'color',
}

export type WidgetSettingsFieldTypeOptions = {
  [WidgetSettingsFieldType.RANGE]: {
    min: number;
    max: number;
    step: number;
    showThumbLabel?: boolean;
  };
  [WidgetSettingsFieldType.NUMBER]: {
    min: number;
    max: number;
  };
  [WidgetSettingsFieldType.TEXT]: {
    placeholder: string;
  };
  [WidgetSettingsFieldType.BOOLEAN]: {};
  [WidgetSettingsFieldType.COLOR]: {};
  [key: string]: any;
};

export interface WidgetSetting<ParentSettings, T, FieldType extends WidgetSettingsFieldType = WidgetSettingsFieldType> {
  name: string;
  onChange: (value: T) => void;
  fieldType: FieldType;
  options: WidgetSettingsFieldTypeOptions[FieldType];
  value?: T;
  key: keyof ParentSettings
}

type GenericSettings = {
  [key: string]: any
}


export class WidgetModel<Settings extends GenericSettings> {
  name: string;
  preferredHeight: string;
  settings: WidgetSetting<Settings, any, any>[];

  constructor() {
    this.name = "Untitled";
    this.preferredHeight = "155px";
    this.settings = [];
  }

  getSetting<T>(key: keyof Settings): T {
    return this.settings.find(s => s.key === key).value as T;
  }

  /**
   * @description Get an object of key-value pairs with the current setting values
   */
  getCurrentSettings(): {
    [key: string]: any
  } {
    return this.settings.reduce((curr, ws) => {
      curr[ws.key as string] = ws.value;
      return curr;
    }, {})
  }
}
