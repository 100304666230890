import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
// volcano-layout-manager.service.ts
export var VolcanoPanel;
(function (VolcanoPanel) {
    VolcanoPanel["SelectByStats"] = "selectByStats";
    VolcanoPanel["TableOptions"] = "tableOptions";
    VolcanoPanel["EnrichmentAnalysisOptions"] = "enrichmentAnalysisOptions";
})(VolcanoPanel || (VolcanoPanel = {}));
export var VolcanoTab;
(function (VolcanoTab) {
    VolcanoTab["Table"] = "table";
    VolcanoTab["EnrichmentAnalysis"] = "enrichmentAnalysis";
})(VolcanoTab || (VolcanoTab = {}));
export class VolcanoLayoutManagerService {
    constructor() {
        this.activeTab = VolcanoTab.Table;
        this.panelStates = new Map();
        this._enabledTabs = [VolcanoTab.Table, VolcanoTab.EnrichmentAnalysis];
        this.panelStates$ = new Subject();
        this._isFullScreen = false;
    }
    get isFullScreen() {
        return this._isFullScreen;
    }
    setPanelCollapsed(panel, collapsed) {
        this.panelStates.set(panel, collapsed);
        this.panelStates$.next(this.panelStates);
    }
    isPanelCollapsed(panel) {
        return this.panelStates.get(panel);
    }
    setEnabledTabs(tabs) {
        this._enabledTabs = tabs;
    }
    getPanelClientHeights() {
        const getHeightOrZero = (id) => {
            const el = document.getElementById(id);
            return el ? el.clientHeight : 0;
        };
        return {
            [VolcanoPanel.SelectByStats]: getHeightOrZero("select-by-stats-panel"),
            [VolcanoPanel.TableOptions]: getHeightOrZero("table-options-panel"),
            [VolcanoPanel.EnrichmentAnalysisOptions]: getHeightOrZero("enrichment-analysis-options-panel")
        };
    }
    toggleFullScreen() {
        this._isFullScreen = !this._isFullScreen;
        if (this._isFullScreen) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }
    getAvailableHeightForActiveTab(padding = 0) {
        let maxHeight = document.getElementById("tabs-container").clientHeight;
        // get and subtract the height of the tab header
        const tabHeaderHeight = document.getElementsByTagName("mat-tab-header")[0].clientHeight;
        maxHeight -= tabHeaderHeight;
        // apply padding
        maxHeight -= padding;
        const panelHeights = this.getPanelClientHeights();
        maxHeight -= panelHeights[VolcanoPanel.SelectByStats];
        switch (this.activeTab) {
            case VolcanoTab.Table:
                return maxHeight - panelHeights[VolcanoPanel.TableOptions];
            case VolcanoTab.EnrichmentAnalysis:
                return maxHeight - panelHeights[VolcanoPanel.EnrichmentAnalysisOptions];
            default:
                return maxHeight;
        }
    }
    get enabledTabs() {
        return this._enabledTabs;
    }
    isTabEnabled(tab) {
        return this._enabledTabs.includes(tab);
    }
}
VolcanoLayoutManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VolcanoLayoutManagerService_Factory() { return new VolcanoLayoutManagerService(); }, token: VolcanoLayoutManagerService, providedIn: "root" });
