import { EntityTypeEnum, LegendOptionEnum, VisualizationEnum } from 'app/model/enum.model';
import { GraphConfig } from 'app/model/graph-config.model';
import { GraphData } from 'app/model/graph-data.model';
import { Legend } from './../../../model/legend.model';


export class SurvivalConfigModel extends GraphConfig {
    constructor() {
        super();
        this.entity = EntityTypeEnum.PATIENT;
        this.visualization = VisualizationEnum.SURVIVAL;
        this.label = 'Survival';
        this.enableCohorts = true;
        this.enableGenesets = false;
        this.legendOptions = [{
          type: LegendOptionEnum.SHAPE,
          enabled: false
        },
        {
          type: LegendOptionEnum.COLOR,
          enabled: false
        }]
        this.enableSupplemental = false;
    }
    censorEvent: string;
    cohortsToCompare: Array<string> = [];

    static isPatientDead = (v) => {
        return v.vital_status ==='dead'
        || v.vital_status === '1'
        || v.os_status ==='DECEASED'
        || v.os_status ==='deceased';
    };

    // Clinical data harmonization: Support GDC/TCGA  and cBioPortal fields.
    // See https://wiki.fhcrc.org/display/ON/Clinical+Data+Harmonization -MJ

    static patientHasSurvivalData = (v) => {
        let hasTcgaSurvival:boolean =
        v['vital_status'] &&
        (v['days_to_death'] || v['days_to_last_follow_up'] || v['days_to_last_followup']);


        let hasCbioportalSurvival:boolean =
        (v['os_status'] || v['os_event']) &&
        (v['os_months'] || v['os_years']);

        return hasTcgaSurvival || hasCbioportalSurvival;
    }

    static patientsHaveSurvivalData = (patients) => {
        return patients.filter((p) => SurvivalConfigModel.patientHasSurvivalData(p)).length > 0;
    }

    static survivalTimeIfCbioportalElseNeg1 = (v) => {
        if ((v["os_status"] || v["os_event"])) {
            if (v["os_months"]) {
                return v["os_months"];
            } else if (v["os_years"]) {
                return typeof v["os_years"] === "number" ? v["os_years"] * 12 : -1;
            }
        }
        return -1;
    }

    static survivalTime = (v) => {
        if (
            v['vital_status'] &&
            (v['days_to_death'] || v['days_to_last_follow_up'] || v['days_to_last_followup'])) {
            // Use GDC/TCGA fields
            let result = null
            if (SurvivalConfigModel.isPatientDead(v)) {
                result = v['days_to_death'];
            } else {
                result = (
                  v["days_to_last_follow_up"] === undefined
                    ? v["days_to_last_followup"]
                    : v["days_to_last_follow_up"]
                );
            }
            if (result) {
                return result / 30.0; // turns days into months
            } else {
                return null;
            }
        } else {
            return SurvivalConfigModel.survivalTimeIfCbioportalElseNeg1(v);
        }
    }
}

export interface SurvivalDatumModel {
    line: Array<[number, number]>;
    upper: Array<[number, number]>;
    lower: Array<[number, number]>;
    range: Array<[number, number]>;
    name: string;
    color: number;
}
export interface SurvivalDataModel extends GraphData {
    legends: Array<Legend>;
    survival: Array<SurvivalDatumModel>;
}
