/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pinnable-toolbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./pinnable-toolbar.component";
import * as i12 from "@angular/platform-browser";
var styles_PinnableToolbarComponent = [i0.styles];
var RenderType_PinnableToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PinnableToolbarComponent, data: {} });
export { RenderType_PinnableToolbarComponent as RenderType_PinnableToolbarComponent };
function View_PinnableToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "content"], ["style", "margin-top: 4px;"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.presentContent(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PinnableToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 18, "div", [["cdkDrag", ""], ["class", "floating-toolbar cdk-drag"]], [[2, "expanded", null], [2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵdid(1, 4866048, null, 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _handles: 1 }), i1.ɵqud(603979776, 2, { _previewTemplate: 0 }), i1.ɵqud(603979776, 3, { _placeholderTemplate: 0 }), i1.ɵprd(2048, null, i2.ɵb, null, [i2.CdkDrag]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["cdkDragHandle", ""], ["class", "title-bar cdk-drag-handle"]], null, null, null, null, null)), i1.ɵdid(7, 147456, [[1, 4]], 0, i2.CdkDragHandle, [i1.ElementRef, [2, i2.ɵb]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "material-symbols-outlined  mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["drag_handle"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "small-toolbar-button"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.togglePin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "material-symbols-outlined small-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(15, 0, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PinnableToolbarComponent_1)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); _ck(_v, 14, 0); var currVal_10 = _co.isPinned; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPinned; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = i1.ɵnov(_v, 1)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 9).inline; var currVal_4 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 12).disabled || null); var currVal_6 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 14).inline; var currVal_8 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_9 = (_co.isPinned ? "keep" : "keep_off"); _ck(_v, 15, 0, currVal_9); }); }
export function View_PinnableToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pinnable-toolbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PinnableToolbarComponent_0, RenderType_PinnableToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i11.PinnableToolbarComponent, [i12.DomSanitizer, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PinnableToolbarComponentNgFactory = i1.ɵccf("app-pinnable-toolbar", i11.PinnableToolbarComponent, View_PinnableToolbarComponent_Host_0, {}, {}, []);
export { PinnableToolbarComponentNgFactory as PinnableToolbarComponentNgFactory };
