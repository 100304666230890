/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../user-panel/user-panel.component.ngfactory";
import * as i6 from "../user-panel/user-panel.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../service/data.service";
import * as i9 from "@angular/flex-layout/flex";
import * as i10 from "@angular/flex-layout/core";
import * as i11 from "@angular/common";
import * as i12 from "./file-panel.component";
var styles_FilePanelComponent = [i0.styles];
var RenderType_FilePanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FilePanelComponent, data: {} });
export { RenderType_FilePanelComponent as RenderType_FilePanelComponent };
function View_FilePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "panel-button-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "panel-button-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "clickable-area"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadGDCDataset(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "disease-img disease-img", _v.context.$implicit.img, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", ("dataset_link_" + _v.context.$implicit.name), ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.name == "Glioma") ? "Glioma (MDS w/ SNV + CNV)." : _v.context.$implicit.name); _ck(_v, 5, 0, currVal_2); }); }
export function View_FilePanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "panel-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Data Sets"])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "mat-tab-group", [["animationDuration", "0ms"], ["class", "filePanel mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onSelectedTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(5, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i4.ANIMATION_MODULE_TYPE]], { animationDuration: [0, "animationDuration"] }, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵeld(7, 16777216, null, null, 4, "mat-tab", [["label", "Genomic Data Commons"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(8, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵted(-1, 0, [" The following datasets were downloaded from the Genomic Data Commons (GDC). (verified by the Oncoscape team) "])), (_l()(), i1.ɵeld(12, 16777216, null, null, 5, "mat-tab", [["label", "Private Datasets"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(13, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "app-workspace-user-panel", [], null, [[null, "showPanel"], [null, "loadPrivate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showPanel" === en)) {
        var pd_0 = (_co.showPanel.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("loadPrivate" === en)) {
        var pd_1 = (_co.loadPrivate.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_UserPanelComponent_0, i5.RenderType_UserPanelComponent)), i1.ɵdid(17, 4243456, null, 0, i6.UserPanelComponent, [i7.FormBuilder, i1.ChangeDetectorRef, i8.DataService], null, { showPanel: "showPanel", loadPrivate: "loadPrivate" }), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["fxLayout.xs", "column"]], null, null, null, null, null)), i1.ɵdid(19, 671744, null, 0, i9.DefaultLayoutDirective, [i1.ElementRef, i10.StyleUtils, [2, i9.LayoutStyleBuilder], i10.MediaMarshaller], { "fxLayout.xs": [0, "fxLayout.xs"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePanelComponent_1)), i1.ɵdid(23, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadTinySet() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "span", [["id", "TinySet"], ["style", "color:white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [". ."])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Featured Data Set"])), (_l()(), i1.ɵeld(32, 0, null, null, 10, "table", [["border", "0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToFeaturedDataset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 0, "img", [["src", "/assets/icons/brainumap3.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0"])), (_l()(), i1.ɵeld(39, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RNA seq dataset of normal and neoplastic brain using UMAP. "])), (_l()(), i1.ɵeld(41, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToFeaturedDataset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Explore it"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "0ms"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "Genomic Data Commons"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "Private Datasets"; _ck(_v, 13, 0, currVal_4); var currVal_5 = "column"; _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.datasets; _ck(_v, 23, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 5).headerPosition === "below"); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_FilePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-file-panel", [], null, null, null, View_FilePanelComponent_0, RenderType_FilePanelComponent)), i1.ɵdid(1, 4243456, null, 0, i12.FilePanelComponent, [i8.DataService, i1.ChangeDetectorRef], null, null)], null, null); }
var FilePanelComponentNgFactory = i1.ɵccf("app-workspace-file-panel", i12.FilePanelComponent, View_FilePanelComponent_Host_0, {}, { uploadExcel: "uploadExcel", loadPublic: "loadPublic", loadPrivate: "loadPrivate", hide: "hide", showPanel: "showPanel" }, []);
export { FilePanelComponentNgFactory as FilePanelComponentNgFactory };
