export type JobType = "differentialExpression" | "generic";

export enum Status {
  Running = "running",
  Success = "success",
  Error = "error",
  Queued = "queued",
  Cancelled = "cancelled",
  Log = "log",
}

export enum LogLevel {
  Log = "log",
  Info = "info",
  Warn = "warn",
  Error = "error",
}

export enum StepResultType {

  /**
   * The result is a table. The table will be displayed in a table component.
   * We expect the table to be in the following format:
   * ```ts
   *{
   * "col 1": {
   *     "row 1": "row1col1_value",
   *     "row 2": "row2col1_value"
   * },
   * "col 2": {
   *     "row 1": "row1col2_value",
   *     "row 2": "row2col2_value"
   * }
   * }
   * ```
   */
  Table = "table",
  Text = "text",
  Error = "error",
  JSON = "json",
  VOLCANO_DATA = "volcanoData",
}

export interface StepInput<Payload> {
  name: string;
  /**
   * A function that transforms the result of the previous step into a payload for this step. Should not be provided for the first step.
   */
  prevResultToPayload?: (result: any) => Payload;

  /**
   * You can directly provide a payload for this step instead of deriving it from the previous step.
   */
  payload?: Payload;
}

export interface Step<Payload> {
  id: number;
  name: string;
  status: Status;
  logs: { msg: string; level: LogLevel }[];
  result?: {
    /**
   * Defines the type of the result of the step. Used to determine how to use and display the result. sometimes null if we ahve a special status type that we know what to do with (e.g. log, error)
   */
    type: StepResultType | null;
    data: any;
  } | null;
  creationTime: Date;
  finishTime?: Date;
  /**
    * A function that transforms the result of the previous step into a payload for this step. Should not be provided for the first step.
   */
  prevResultToPayload?: (result: StepResponse["result"]) => Payload;
  /**
   * You can directly provide a payload for this step instead of deriving it from the previous step.
   */
  payload?: Payload;
}

export type Job = {
  id: number;

  /** The name of the IndexedDB database the job is/will be saved to upon completion. */
  databaseName?: string;

  /** Some identifier for the "backend" running the job. In the case of a job running on a webworker, this is the worker ID. In the case of running on a server, this is the URL for the server. */
  backendIdentifier: any;
  name: string;
  status: Status;
  /** Type (category) of job. Useful for querying only jobs of a certain type, such as differentialExpression jobs */
  type: JobType;
  steps: Step<any>[];
  creationTime: Date;
  finishTime?: Date;
}


export type StepResponse = {

  /**
   * Whether the step succeeded.
   */
  success: boolean;


  /**
   * The result of the step. Can be null if no result was returned. If the step failed, this will be the error message.
   */
  result: {
    type: StepResultType;
    data: any;
  } | null;
};

export type JobOptions = {

  /**
   * The type of the job.
   */
  type: JobType;

  /**
   * Whether to show the snackbar when the job starts.
   */
  showSnackbarOnStart?: boolean;

  /**
   * Whether to show the snackbar when the job successfully finishes.
   */
  showSnackbarOnSuccess?: boolean;

  /**
   * Callback to attach to clicking the snackbar that appears when the job successfully finishes.
   */
  onSuccessSnackbarClick?: () => void;

  /**
   * Whether to show the snackbar when the job fails.
   */
  showSnackbarOnFail?: boolean;

  /**
   * Callback to attach to clicking the snackbar that appears when the job fails.
   */
  onFailSnackbarClick?: () => void;

  /**
   * Whether to save the result of the job to the database (will only save on success). Defaults to true.
   */
  saveToDatabase: boolean;

  /**
   * Whether to free the worker when the job finishes (successfully or not). Defaults to true.
   */
  freeWorkerOnFinish: boolean;

  /**
   * Whether to destroy the worker when the job finishes (successfully or not). Defaults to true.
   */
  destroyWorkerOnFinish: boolean;
};
