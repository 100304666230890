/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cancel-job-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./cancel-job-dialog.component";
var styles_CancelJobDialogComponent = [i0.styles];
var RenderType_CancelJobDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelJobDialogComponent, data: {} });
export { RenderType_CancelJobDialogComponent as RenderType_CancelJobDialogComponent };
export function View_CancelJobDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Cancel Job"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to cancel "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["\"", "\"?"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This action cannot be undone."])), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["No"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Yes"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.jobName; _ck(_v, 8, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 15).disabled || null); var currVal_3 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 18).disabled || null); var currVal_5 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_4, currVal_5); }); }
export function View_CancelJobDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancel-job-dialog", [], null, null, null, View_CancelJobDialogComponent_0, RenderType_CancelJobDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.CancelJobDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var CancelJobDialogComponentNgFactory = i1.ɵccf("app-cancel-job-dialog", i7.CancelJobDialogComponent, View_CancelJobDialogComponent_Host_0, {}, {}, []);
export { CancelJobDialogComponentNgFactory as CancelJobDialogComponentNgFactory };
