import { Khonsole } from '../../../../app/khonsole';
import { DataTypeEnum, ShapeEnum, SizeEnum } from './../../../../app/model/enum.model';
import * as scale from 'd3-scale';
import * as THREE from 'three';
import { PerspectiveCamera, Vector3 } from 'three';
import { OrbitControls } from  'three-orbitcontrols-ts';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import {
  DataDecorator,
  DataDecoratorTypeEnum,
  CustomPalette
} from './../../../model/data-map.model';
import {
  EntityTypeEnum,
  SpriteMaterialEnum
} from './../../../model/enum.model';
import { VisualizationView } from './../../../service/chart-view.model';
import { DataField, DataFieldFactory } from './../../../../app/model/data-field.model';
import { OncoData } from 'app/oncoData';
import { CollectionTypeEnum } from 'app/model/enum.model';

export type DataDecoratorRenderer = (
  group: THREE.Group,
  mesh: THREE.Sprite,
  decorators: Array<DataDecorator>,
  index: number,
  count: number
) => void;

export class ChartFactory {
  private static meshPool: Array<THREE.Mesh> = [];
  private static linePool: Array<THREE.Line> = [];
  public static shader = {
    outline: {
      vertex_shader: [
        'uniform float offset;',
        'void main() {',
        'vec4 pos = modelViewMatrix * vec4( position + normal * offset, 1.0 );',
        'gl_Position = projectionMatrix * pos;',
        '}'
      ].join('\n'),
      fragment_shader: [
        'void main(){',
        'gl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );',
        '}'
      ].join('\n')
    }
  };

  public static spriteOpacity: number = 0.7;

  public static cleanForLocalStorage(s): string {
    //TBD: add tolowercase?
    return s.replace(/\./g, '_')
      .replace(/\!/g, '_')
      .replace(/\,/g, '_')
      .replace(/\ /g, '_')
  }

  public static writeCustomValueToLocalStorage(database:string, category:string, key:string, val:any):void  {
    Khonsole.warn(`== write CVFLS,  ${database}, ${category}, ${key}, ${val}`)
    let db:string = this.cleanForLocalStorage(database);  // Turn periods and bangs to underscores.
    let storagePath:string = 'oncoscape' + category + '_' + db;
    let categoryStruct = {};
    if (!localStorage[storagePath]) {
      localStorage[storagePath] = {};
    } else {
      categoryStruct = JSON.parse(localStorage[storagePath]);
    }
    categoryStruct[key] = JSON.stringify(val);
    localStorage[storagePath] = JSON.stringify(categoryStruct);
  }

  public static readCustomValueFromLocalStorage(database:string, category:string, key:string): string {
    let db:string = database.replace(/\./g, '_').replace(/\!/g, '_');  // Turn periods and bangs to underscores.
    let storagePath:string = 'oncoscape' + category + '_' + db;
    let categoryStruct: { [key: string]: any } = {};
    if (!localStorage[storagePath]) {
      return null;
    } else {
      let categoryStructWithPeriods = JSON.parse(localStorage[storagePath]);
      // Turn periods into underscores.
      for (const key in categoryStructWithPeriods) {
        if (categoryStructWithPeriods.hasOwnProperty(key)) {
          const newKey = key.replace(/\./g, "_");
          categoryStruct[newKey] = categoryStructWithPeriods[key];
        }
      }


    }
    let key_to_use = key.replace(/\./g, '_');  // Turn periods to underscores.;
    let result = categoryStruct[key_to_use];
    if (result == null) {
      // Older customcolors in users' LocalStorage may still have "Sample " prefix, so retry.
      result = categoryStruct["Sample " + key_to_use]
    }
    if (result == null) {
      return null;
    } else {
      result = result.replace(/"([^"]+(?="))"/g, '$1'); // trim quotes from start and end
      return result;
    }
  }

  public static sizes = [SizeEnum.S, SizeEnum.M, SizeEnum.L, SizeEnum.XL];
  public static shapes = [
    ShapeEnum.CIRCLE,
    ShapeEnum.BOX,
    ShapeEnum.SQUARE,
    ShapeEnum.CONE
  ];

  // public static colorsContinuous = [
  //   '#e53935',
  //   '#d81b60',
  //   '#8e24aa',
  //   '#5e35b1',
  //   '#3949ab',
  //   '#1e88e5',
  //   '#039be5',
  //   '#00acc1',
  //   '#00897b',
  //   '#43a047'
  // ];

  public static colorsContinuous(scale_type:string, customPalette:CustomPalette) {
    // Original (pre-2023) "ZEROBASED"...
    //   return ['#fff5f5', '#ffe6e6', '#ff9999', '#ff6666', '#ff3333', '#ff0000', '#cc0000', '#990000'];
    // Original (pre-2023) "DEFAULT", divergent...
    //   return ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];

    if (customPalette) {
      // Brewer Spectral is fallback if custom palette is not found.
      let palette =  ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];
      if (customPalette.type == "brewer" || customPalette.type == "onco") {
        switch (customPalette.name) {
          case "RdYlBu": {
            // a brewer palette
            palette = [
              "#d73027",
              "#f46d43",
              "#fdae61",
              "#fee090",
              "#e0f3f8",
              "#abd9e9",
              "#74add1",
              "#4575b4",
            ];
            break;
          }
          case "RdYlBu_reverse": {
            // a brewer palette, reversed.
            palette = [
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
            ];
            break;
          }
          case "RdBu": {
            // a brewer palette
            palette = [
              "#2166ac",
              "#4393c3",
              "#92c5de",
              "#d1e5f0",
              "#fddbc7",
              "#f4a582",
              "#d6604d",
              "#b2182b",
            ];
            break;
          }
          case "RdBu_reverse": {
            // a brewer palette, reversed.
            palette = [
              "#b2182b",
              "#d6604d",
              "#f4a582",
              "#fddbc7",
              "#d1e5f0",
              "#92c5de",
              "#4393c3",
              "#2166ac",
            ];
            break;
          }
          case "OncoYlBu": {
            // equal steps yellow to blue, supports meningioma paper
            palette = [
              "#ece48b",
              "#cdc687",
              "#b4ad84",
              "#999380",
              "#7e787d",
              "#615c7a",
              "#464277",
              "#292674",
            ];
            break;
          }
          case "PurpleWhite": {
            palette = [
              "#410282",
              "#5a2492",
              "#7245a3",
              "#8b67b3",
              "#a488c4",
              "#bdaad4",
              "#d5cbe5",
              "#eeedf5",
            ];
            break;
          }
          case "GreenWhite": {
              palette = [
                "#0f3d1d",
                "#30593c",
                "#517369",
                "#718e7a",
                "#92aa99",
                "#b3c4b8",
                "#d4e0d7",
                "#f5fbf6",
              ];
              break;
          }

        }
      }
      return palette;

    } else {
      // No custom palette, so use the default zerobased or divergent.
      if (scale_type == "ZEROBASED") {
        return ['#ffe8e8', '#ffbbbb', '#ff8080', '#ff4d4d', '#ff2222', '#ee0000', '#cc0000', '#800000'];
      } else {
        // "DEFAULT", divergent. default, blue to white to red
        return ['#3288bd', '#66c2a5', '#abdda4', '#e6f598', '#fee08b', '#fdae61', '#f46d43', '#d53e4f'];
      }
    }
  }

  // public static shapes = ['blast', 'blob', 'circle', 'diamond', 'polygon', 'square', 'star', 'triangle'];
  private static sprites = [
    SpriteMaterialEnum.CIRCLE,
    SpriteMaterialEnum.TRIANGLE,
    SpriteMaterialEnum.DIAMOND,
    SpriteMaterialEnum.POLYGON,
    SpriteMaterialEnum.SQUARE,
    SpriteMaterialEnum.STAR,
    SpriteMaterialEnum.BLAST,
    SpriteMaterialEnum.BLOB
  ];

  public static alphas = {
    circle: new THREE.TextureLoader().load(
      'assets/shapes/shape-circle-solid-alpha.png'
    ),
  };

  public static textures = {
    blast: new THREE.TextureLoader().load(
      'assets/shapes/shape-blast-solid.png'
    ),
    blob: new THREE.TextureLoader().load('assets/shapes/shape-blob-solid.png'),
    circle: new THREE.TextureLoader().load(
      'assets/shapes/shape-circle-solid-border.png' // added border
    ),
    diamond: new THREE.TextureLoader().load(
      'assets/shapes/shape-diamond-solid.png'
    ),
    polygon: new THREE.TextureLoader().load(
      'assets/shapes/shape-polygon-solid.png'
    ),
    square: new THREE.TextureLoader().load(
      'assets/shapes/shape-square-solid.png'
    ),
    star: new THREE.TextureLoader().load('assets/shapes/shape-star-solid.png'),
    triangle: new THREE.TextureLoader().load(
      'assets/shapes/shape-triangle-solid.png'
    ),
    na: new THREE.TextureLoader().load('assets/shapes/shape-na-solid.png')
  };

  public static getScaleSizeOrdinal(values: Array<string>): Function {
    const len = values.length;
    return scale
      .scaleOrdinal()
      .domain(values)
      .range(ChartFactory.sizes.slice(0, values.length));
  }

  public static getScaleSizeLinear(min: number, max: number): Function {
    return scale
      .scaleLog()
      .domain([min, max])
      .range([1, 3])
      .clamp(true);
  }
  public static getScaleShapeOrdinal(values: Array<string>): Function {
    const len = values.length;
    const cols = ChartFactory.sprites.slice(0, values.length);
    return scale
      .scaleOrdinal()
      .domain(values)
      .range(cols);
  }
  public static getScaleShapeLinear(
    min: number,
    max: number,
    bins: number = 0
  ): Function {
    bins = Math.min(bins, 8);
    const range = ChartFactory.sprites.slice(0, bins);
    return scale
      .scaleQuantize<string>()
      .domain([min, max])
      .range(range);
  }
  public static getScaleGroupOrdinal(values: Array<string>): Function {
    const range = Array.from({ length: values.length }, (v, k) =>
      (k + 1).toString()
    );
    return scale
      .scaleOrdinal()
      .domain(values)
      .range(range);
  }
  public static getScaleGroupLinear(
    min: number,
    max: number,
    bins: number = 0
  ): Function {
    bins = Math.min(bins, 8);
    const range = Array.from({ length: bins }, (v, k) => (k + 1).toString());
    return scale
      .scaleQuantize<string>()
      .domain([min, max])
      .range(range);
  }
  public static getScaleColorOrdinal(values: Array<string>, field: DataField): Function {
    const len = values.length;
    Khonsole.log(`getScaleColorOrdinal for ${len} items.`);
    const cols =
      len > 4
        ? DataFieldFactory.dataFieldColors.slice(0, values.length)
        : DataFieldFactory.dataFieldColors.filter((c, i) => i % 2).slice(0, values.length);

    let valuesCopy = JSON.parse(JSON.stringify(values));
    let colsCopy = JSON.parse(JSON.stringify(cols));
    if(field){
      let database = OncoData.instance.dataLoadedAction.dataset;
      let legendKeyOrder_escaped = ChartFactory.readCustomValueFromLocalStorage(database, 'legendColorMisc', 'legendKeyOrder');
      if (legendKeyOrder_escaped) {
        let legendKeyOrder = JSON.parse(legendKeyOrder_escaped.replace(/\\/g, '"'));
        Khonsole.log("legendKeyOrder found in local storage");
        Khonsole.dir(legendKeyOrder);
        let dict = legendKeyOrder[field.key];
        if (dict == null){
          dict = legendKeyOrder["Sample " + field.key];
        }
        if (dict){
          Khonsole.log("legendKeyOrder match in local storage");
          Khonsole.dir(dict);
          // // Make a copy of values swapping spaces for underscores
          // const valuesCopyUnderscore = valuesCopy.map(v => v.replace(/\ /g, '_'));

          // // Get the values from the dictionary in the desired order
          // const orderedValues = Object.keys(dict).map(v => dict[v].key).filter(key => valuesCopyUnderscore.includes(key));

          // // Create a set of values from the dictionary for efficient lookup
          // const dictValuesSet = new Set(orderedValues);

          // // Filter out values that are already in orderedValues
          // const remainingValues = valuesCopy.filter(value => !dictValuesSet.has(value.replace(/\ /g, '_')));

          // // Concatenate the remaining values to the end of orderedValues
          // const finalOrderedValues = orderedValues.concat(remainingValues);

          // // Create new copies of colsCopy following the order in finalOrderedValues
          // const newColsCopy = finalOrderedValues.map(value => colsCopy[valuesCopyUnderscore.indexOf(value)]);



          // Get the values from the dictionary in the desired order
          const orderedValues = Object.keys(dict).map(v => dict[v].key).filter(key => valuesCopy.includes(key));

          // Create a set of values from the dictionary for efficient lookup
          const dictValuesSet = new Set(orderedValues);

          // Filter out values that are already in orderedValues
          const remainingValues = valuesCopy.filter(value => !dictValuesSet.has(value));

          // Concatenate the remaining values to the end of orderedValues
          const finalOrderedValues = orderedValues.concat(remainingValues);

          // Create new copies of colsCopy following the order in finalOrderedValues
          const newColsCopy = finalOrderedValues.map(value => colsCopy[valuesCopy.indexOf(value)]);

          valuesCopy = finalOrderedValues;
          colsCopy = newColsCopy;
        }
      }
    }

    return scale
      .scaleOrdinal()
      .domain(valuesCopy)
      .range(colsCopy);
  }

  public static getScaleColorLinear(
    field: DataField,
    min: number,
    max: number,
    bins: number = 8,
    customPalette: CustomPalette = null
  ): Function {
    Khonsole.warn("getScaleColorLinear  ==========")
    let minToUse = min;
    let maxToUse = max;
    if (customPalette){
      if (customPalette.min != null) {
        minToUse = customPalette.min;
      }
      if (customPalette.max != null) {
        maxToUse = customPalette.max;
      }
    }
    let table_legend_colorscale_type = "DEFAULT" // divergent
    let tbl_info = OncoData.instance.dataLoadedAction.tables.find(x => x.tbl == field.tbl);
    if(tbl_info) {
      if (
        tbl_info.ctype == CollectionTypeEnum.RNA ||
        tbl_info.ctype == CollectionTypeEnum.MRNA ||
        tbl_info.ctype == CollectionTypeEnum.MIRNA)
      {
        table_legend_colorscale_type = "ZEROBASED"

      }
    }
    bins = Math.min(bins, 8);
    const range =
      bins > 4
      // TODO: Our scales have 8 colors. If someone comes in with 10 bins (is that posssible?), we trim off the darkest 2 colors; we should rescale to 10 bins.
        ? ChartFactory.colorsContinuous(table_legend_colorscale_type, customPalette).slice(0, bins)
        : ChartFactory.colorsContinuous(table_legend_colorscale_type, customPalette).filter((c, i) => i % 2).slice(0, bins);
    return scale
      .scaleQuantize<string>()
      .domain([minToUse, maxToUse])
      .range(range);
  }

  // Pools
  public static createDataGroup(
    id: string,
    idType: EntityTypeEnum,
    position: Vector3
  ): THREE.Group {
    const group = new THREE.Group();
    group.position.set(position.x, position.y, position.z);
    group.userData.id = id;
    group.userData.idType = idType;
    group.userData.tooltip = id;
    return group;
  }

  public static decorateDataGroups(
    groups: Array<any>,
    decorators: Array<DataDecorator>,
    renderer: DataDecoratorRenderer = null,
    scaleFactor: number = 3
  ): void {
    // groups: Array<THREE.Group>,
    // Retrieve Id

    if (groups.length === 0) {
      return;
    }
    Khonsole.log('decorateDataGroups');

    const idType = groups[0].userData.idType;
    const idProperty =
      idType === EntityTypeEnum.PATIENT
        ? 'pid'
        : idType === EntityTypeEnum.SAMPLE
          ? 'sid'
          : 'mid';

    // Decorators
    const shapeDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.SHAPE
    );
    const colorDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.COLOR
    );
    const sizeDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.SIZE
    );
    const selectDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.SELECT
    );
    const labelDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.LABEL
    );
    const groupDecorator = decorators.filter(
      decorator => decorator.type === DataDecoratorTypeEnum.GROUP
    );
    // const tooltipDecorator = decorators.filter(decorator => decorator.type === DataDecoratorTypeEnum.TOOLTIP);

    // Maps
    const shapeMap = !shapeDecorator.length
      ? null
      : shapeDecorator[0].values.reduce((p, c) => {
          p[c[idProperty]] = c.value;
          return p;
        }, {});
    const colorMap = !colorDecorator.length
      ? null
      : colorDecorator[0].values.reduce((p, c) => {
          p[c[idProperty]] = c.value;
          return p;
        }, {});
      Khonsole.log("===colorMap=")
      Khonsole.dir(colorMap);

    const labelMap = !labelDecorator.length
      ? null
      : labelDecorator[0].values.reduce((p, c) => {
          p[c[idProperty]] = c.label;
          return p;
        }, {});

    const groupMap = !groupDecorator.length
      ? null
      : groupDecorator[0].values.reduce((p, c) => {
          p[c[idProperty]] = c.value;
          return p;
        }, {});

    const sizeMap = !sizeDecorator.length
      ? null
      : sizeDecorator[0].values.reduce((p, c) => {
          Khonsole.log('find size options');
          p[c[idProperty]] = c.value;
          return p;
        }, {});
    const count = groups.length;
    groups.forEach((item, i) => {
      while (item.children.length) {
        item.remove(item.children[0]);
      }
      const id = item.userData.id;
      let color = colorMap
        ? colorMap[id]
          ? colorMap[id]
          : '#DDDDDD'
        : '#039be5';
      if(item.userData.genesetOverlayColor) {
        color = item.userData.genesetOverlayColor;
      }
      const label = labelMap ? (labelMap[id] ? labelMap[id] : 'NA') : '';
      const shape = shapeMap
        ? shapeMap[id]
          ? shapeMap[id]
          : SpriteMaterialEnum.NA
        : SpriteMaterialEnum.CIRCLE;
      const size = sizeMap
        ? sizeMap[id]
          ? sizeMap[id]
          : scaleFactor
        : scaleFactor;
      const group = groupMap ? (groupMap[id] ? groupMap[id] : 0) : 0;
      // size = 1;
      const spriteMaterial = ChartFactory.getSpriteMaterial(shape, color);

      spriteMaterial.opacity = ChartFactory.spriteOpacity;
      const mesh: THREE.Sprite = new THREE.Sprite(spriteMaterial);
      const tooltipExistsAlready:boolean = item.userData.tooltip && item.userData.tooltip != '';
      item.userData.tooltip = tooltipExistsAlready ? item.userData.tooltip : label;
      item.userData.color = isNaN(color)
        ? parseInt(color.replace(/^#/, ''), 16)
        : color;
      mesh.scale.set(size, size, size);
      mesh.userData.tooltip = label;
      mesh.userData.color = item.userData.color;
      mesh.userData.selectionLocked = false;
      if (renderer) {
        renderer(item, mesh, decorators, i, count);
      }
      item.add(mesh);
    });
  }

  public static getOutlineMaterial(): THREE.ShaderMaterial {
    const uniforms = { offset: { type: 'f', value: 1 } };
    const outShader = ChartFactory.shader.outline;
    return new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: outShader.vertex_shader,
      fragmentShader: outShader.fragment_shader
    });
  }

  // --------------------- Old ------------------------ //

  // Mesh Pool
  public static meshRelease(mesh: THREE.Mesh): void {
    mesh.userData = null;
    this.meshPool.push(mesh);
  }
  public static meshAllocate(
    color: number,
    shape: ShapeEnum,
    size: number,
    position: THREE.Vector3,
    data: any
  ): THREE.Mesh {
    const mesh =
      this.meshPool.length > 0 ? this.meshPool.shift() : new THREE.Mesh();
    mesh.geometry = this.getShape(shape);
    mesh.scale.set(size, size, size);
    mesh.position.set(position.x, position.y, position.z);
    mesh.material = this.getColorPhong(color);
    mesh.userData = data;
    return mesh;
  }
  public static meshDrain(): void {
    this.meshPool.length = 0;
  }

  public static planeAllocate(
    color: number,
    width: number,
    height: number,
    data
  ): THREE.Mesh {
    const geo = new THREE.PlaneGeometry(width, height);
    const material = this.getColorPhong(color);
    return new THREE.Mesh(geo, material);
  }

  public static lineRelease(line: THREE.Line): void {}
  public static lineAllocateCurve(
    color: number,
    pt1: THREE.Vector2,
    pt2: THREE.Vector2,
    pt3: THREE.Vector2,
    data?: any,
    z?: number
  ): THREE.Line {
    const line = new THREE.Line();
    line.material = this.getLineColor(color);
    const curve = new THREE.SplineCurve([pt1, pt3, pt2]);
    const path = new THREE.Path(curve.getPoints(50));
    const pts = path.getPoints().map(v => new Vector3(v.x, v.y, z ? z : 0));
    const geometry = new THREE.BufferGeometry().setFromPoints(pts);
    line.geometry = geometry;
    line.userData = data;
    return line;
  }

  public static meshLineAllocate(
    color: number,
    pt1: THREE.Vector2,
    pt2: THREE.Vector2,
    camera: any,
    data?: any
  ): MeshLine {
    const geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(pt1.x, pt1.y, 0));
    geometry.vertices.push(new THREE.Vector3(pt2.x, pt2.y, 0));
    const line = new MeshLine();
    line.setGeometry(geometry);
    const material = new MeshLineMaterial({
      useMap: false,
      color: new THREE.Color(color),
      opacity: 1,
      // resolution: resolution,
      // sizeAttenuation: !false,
      lineWidth: 2,
      near: camera.near,
      far: camera.far
    });
    return new THREE.Mesh(line.geometry, material);
  }
  public static linesAllocate(
    color: number,
    pts: Array<THREE.Vector2>,
    data: any
  ): THREE.Line {
    const line = new THREE.Line();
    line.material = this.getLineColor(color);
    line.userData = data;
    const geometry = new THREE.Geometry();
    geometry.vertices.push(...pts.map(v => new THREE.Vector3(v.x, v.y, 0)));
    line.geometry = geometry;
    return line;
  }
  public static lineAllocate(
    color: number,
    pt1: THREE.Vector2,
    pt2: THREE.Vector2,
    data?: any,
    z?: number
  ): THREE.Line {
    const line = new THREE.Line();
    line.material = this.getLineColor(color);
    line.userData = data;
    const geometry = new THREE.Geometry();
    geometry.vertices.push(new THREE.Vector3(pt1.x, pt1.y, z ? z : 0));
    geometry.vertices.push(new THREE.Vector3(pt2.x, pt2.y, z ? z : 0));
    line.geometry = geometry;
    return line;
  }
  public static lineDrain(): void {
    this.linePool.length = 0;
  }

  // @memoize
  public static getLineColor(color: number): THREE.LineBasicMaterial {
    return new THREE.LineBasicMaterial({ color: color });
  }
  // @memoize
  public static getMeshLine(
    color: number,
    lineWidth: number = 2
  ): MeshLineMaterial {
    return new MeshLineMaterial({
      color: new THREE.Color(color),
      lineWidth: lineWidth
    });
  }

  // @memoize
  public static getColorMetal(color: number): THREE.Material {
    return new THREE.MeshStandardMaterial({
      color: color,
      emissive: new THREE.Color(0x000000),
      metalness: 0.2,
      roughness: 0.5
    });
  }

  public static getOutlineShader(
    cameraPosition: Vector3,
    color: number = 0xff0000
  ): THREE.ShaderMaterial {
    const shader = {
      glow: {
        vertex_shader: [
          'uniform vec3 viewVector;',
          'uniform float c;',
          'uniform float p;',
          'varying float intensity;',
          'void main() ',
          '{',
          'vec3 vNormal = normalize( normalMatrix * normal );',
          'vec3 vNormel = normalize( normalMatrix * viewVector );',
          'intensity = pow( c - dot(vNormal, vNormel), p );',
          'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
          '}'
        ].join('\n'),
        fragment_shader: [
          'uniform vec3 glowColor;',
          'varying float intensity;',
          'void main() ',
          '{',
          'vec3 glow = glowColor * intensity;',
          'gl_FragColor = vec4( glow, 1.0 );',
          '}'
        ].join('\n')
      }
    };
    const uniforms = {
      c: { type: 'f', value: 1.0 },
      p: { type: 'f', value: 1.4 },
      glowColor: { type: 'c', value: new THREE.Color(color) },
      viewVector: { type: 'v3', value: cameraPosition }
    };

    return new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: shader.glow.vertex_shader,
      fragmentShader: shader.glow.fragment_shader,
      side: THREE.BackSide,
      blending: THREE.NormalBlending,
      transparent: true
    });
  }

  // @memoize
  public static getColor(color: number): THREE.Color {
    return new THREE.Color(color);
  }

  // @memoize
  public static getColorBasic(color: number): THREE.Material {
    return new THREE.MeshBasicMaterial({
      color: color,
      side: THREE.DoubleSide
    });
  }

  // @memoize

  public static getColorPhong(color: number): THREE.MeshBasicMaterial {
    return new THREE.MeshBasicMaterial({ color: color });
    // return new THREE.MeshStandardMaterial(
    //     {
    //         color: color,
    //         roughness: 0.0,
    //         metalness: 0.02,
    //         emissive: new THREE.Color(0x000000)
    //         // color: color, emissive: new THREE.Color(0x000000),
    //         // metalness: 0.2, roughness: .5, shading: THREE.SmoothShading
    //     });
  }

  // @memoize
  public static getSpriteMaterial(
    shape: SpriteMaterialEnum,
    color: number
  ): THREE.SpriteMaterial {
    let result:THREE.SpriteMaterial = null;
    switch (shape) {
      case SpriteMaterialEnum.BLAST:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.blast,
          color: color
        });
        break;
      case SpriteMaterialEnum.BLOB:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.blob,
          color: color
        });
        break;
      case SpriteMaterialEnum.CIRCLE:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.circle,
          alphaMap: ChartFactory.alphas.circle,
          color: color
        });
        result.transparent = true;
        break;
      case SpriteMaterialEnum.DIAMOND:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.diamond,
          color: color
        });
        break;
      case SpriteMaterialEnum.POLYGON:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.polygon,
          color: color
        });
        break;
      case SpriteMaterialEnum.SQUARE:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.square,
          color: color
        });
        break;
      case SpriteMaterialEnum.STAR:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.star,
          color: color
        });
        break;
      case SpriteMaterialEnum.TRIANGLE:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.triangle,
          color: color
        });
        break;
      case SpriteMaterialEnum.NA:
        result =  new THREE.SpriteMaterial({
          map: ChartFactory.textures.na,
          color: color
        });
        break;
    }
    if(!result) {
      return new THREE.SpriteMaterial({
        map: ChartFactory.textures.na,
        color: color
      });
    } else {
      result.transparent = true;
      return result;
    }
  }

  // @memoize
  public static getShape(shape: ShapeEnum): THREE.Geometry {
    switch (shape) {
      case ShapeEnum.BOX:
        return new THREE.BoxGeometry(3, 3, 3);
      case ShapeEnum.CIRCLE:
        return new THREE.SphereGeometry(3, 15, 15);
      case ShapeEnum.SQUARE:
        return new THREE.BoxGeometry(3, 3, 3); // was CubeGeometry, which is a now-deprecated alias for BoxGeometry.
      case ShapeEnum.TRIANGLE:
        return new THREE.TetrahedronGeometry(3);
      case ShapeEnum.CONE:
        return new THREE.ConeGeometry(3, 3, 10, 10);
      default:
        return new THREE.TorusGeometry(2);
    }
  }

  public static configPerspectiveOrbit(
    view: VisualizationView,
    box: THREE.Box3
  ): void {
    const perspective = view.camera as PerspectiveCamera;
    const orbit = view.controls as OrbitControls;
    const fovyR = (perspective.fov * Math.PI) / 180;
    const sphere = box.getBoundingSphere(new THREE.Sphere());
    const modelHeight = sphere.radius;
    const zPosition = modelHeight / 2 / Math.tan(fovyR / 2);
    const distance = zPosition;
    orbit.minDistance = -distance * 0.5;
    orbit.maxDistance = distance * 2;
    perspective.position.z = distance * 2;
    perspective.lookAt(0, 0, 0);
    perspective.updateProjectionMatrix();
  }
}
